<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img alt="eCommerce Catalog Management Services" appCdnUrl="assets/E-commerce Catalog Management Services/Main-banner.webp " class="card-img-desktop desktop-banner" />
    <div class="card-img-overlay overlay-text bannerText">
        <div class="main_bannar" >
          <div data-aos="fade-up">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item"><a href="/bpm-and-bpo-services">Services</a></li>
                <li class="breadcrumb-item" aria-current="page">
                  <a href="/services/e-commerce-outsourcing-services">E-commerce</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Catalog Management
                </li>
              </ol>
            </nav>
            <h1 class="card-text font-36-24 text-primary">
              E-commerce Catalog Management Services
            </h1>
            <p class="card-text font-20-16 pt-1">
              Would you agree if we say product information management can ride sales, reinforce brand identity, and
              increase website traffic? Connect to walk down the success lane where our catalog management solutions,
              including compelling product descriptions, image enhancements, and competitor price tracking, are making it
              happen.
            </p>
            <a routerLink="/book-free-consultation-call"  class="CTA-Zindex anchorCTA">
              <h3 class="text-primary font-34-19 my-auto clickable-heading">
                Connect Now
                <div class="headingCTA">
                  <button class="blueCTA"></button>
                </div>
              </h3>
            </a>
          </div>
        </div>
      
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img alt="eCommerce Catalog Management Services"  appCdnUrl="assets/E-commerce Catalog Management Services/Mobile-banner.jpg" class="card-img-mob"  />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/bpm-and-bpo-services">Services</a></li>
          <li class="breadcrumb-item" aria-current="page">
            <a href="/services/e-commerce-outsourcing-services">E-commerce</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Catalog Management
          </li>
        </ol>
      </nav>
      <h1 class="card-text font-34-19 text-primary">E-commerce Catalog Management Services</h1>
      <p class="card-text font-20-16">
        Would you agree if we say product information management can ride sales, reinforce brand identity, and increase
        website traffic? Connect to walk down the success lane where our catalog management solutions, including
        compelling product descriptions, image enhancements, and competitor price tracking, are making it happen.
      </p>
      <a routerLink="/book-free-consultation-call"  class="CTA-Zindex anchorCTA">
        <h3 class="text-primary font-34-19 my-auto clickable-heading">
          Connect Now
          <div class="headingCTA">
            <button class="blueCTA"></button>
          </div>
        </h3>
      </a>
    </div>
  </div>
</div>
</div>
<app-ourAssets></app-ourAssets>
<section class="container-fluid policy-process-service" id="Our Catalog Management Solutions">
  <div class="container">
    <div class="container_emp ps-lg-3">
      <h2 class="text-white font-48-30 py-3" data-aos="fade-up">
        Lead the Change with Our Catalog Management Solutions
      </h2>
      <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-4" />
    </div>
    <div class="d-none d-lg-block">
      <div class="row py-3 px-2">
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Issuance p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Catalog Data Entry and Indexing
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <!-- Modal -->
          <div class="modal Policy-Processing fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Catalog Data Entry and Indexing</h3>
                      <p class="text-dark font-20-16">
                        From maintaining consistency throughout the product catalog to bringing images to life, our
                        services speed up processes and reduce errors that can lead to customer dissatisfaction.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Data validation
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Error-free data entry
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Quality control checks
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Product searchability and visibility
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/E-commerce Catalog Management Services/Group 138.png" alt="eCommerce Catalog Data Entry and Indexing " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy-Checking p-3"  data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal1">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Catalog Update
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal1" tabindex="-1"
            aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Catalog Update</h3>
                      <p class="text-dark font-20-16">
                        Inspire customers' confidence in your brand by giving them better insights into product details
                        and specifications.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Keep the catalog fresh and accurate
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Regular product information updation
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Latest product data and analysis
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/E-commerce Catalog Management Services/Group 139.png" alt="Catalog Update Services " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Loss_Runs_Processing p-3"  data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal2">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Product Listing
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal2" tabindex="-1"
            aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Product Listing</h3>
                      <p class="text-dark font-20-16">
                        Improve website ranking and increase click-through rates with our convenient yet smart product
                        listing services.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Comprehensive details
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />High-quality images
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Product availability status
                          monitoring
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/E-commerce Catalog Management Services/Group 140.png" alt="eCommerce Product Listing Services " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Renewals p-3"  data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal3">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Inventory Management Services
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal3" tabindex="-1"
            aria-labelledby="exampleModalLabel3" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Inventory Management Services</h3>
                      <p class="text-dark font-20-16">
                        Know how much inventory you need and when. <a href="/services/e-commerce-inventory-management" class="anchor">Our comprehensive inventory management services</a> let
                        you refrain from investing in materials you do not need.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Track stock levels in real-time
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Data Analytics
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Demand forecasting
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Inventory planning
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Optimize warehouse layout
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/E-commerce Catalog Management Services/Group 141.png" alt="Inventory Management Services " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class=" p-3">
    <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-12 mt-0 mt-sm-4">
          <div class="accordion" id="ServicePage">
            <div class="policy-processing" data-aos="zoom-in">
              <div data-bs-toggle="collapse" data-bs-target="#Policy_Processing" data-bs-toggle="modal" data-bs-target="#MobModal" aria-expanded="true"   
                aria-controls="Policy_Processing" id="headingOne" class="headingCTA CTA-Zindex d-flex  p-3">
                <h3 class="CTAs text-black fs-24  me-3">Catalog Data Entry and Indexing</h3>
                <img class="service_btn" data-bs-toggle="modal" data-bs-target="#MobModal"
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
            </div>
            <div class="modal Policy-Processing fade" id="MobModal" tabindex="-1" aria-labelledby="MobModalModalLabel"
              aria-hidden="true">
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                  </div>
                  <div class="card policy-processing-services-card-mob mx-3 py-3">
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Catalog Data Entry and Indexing</h3>
                        <p class="text-dark font-20-16">
                          From maintaining consistency throughout the product catalog to bringing images to life, our
                          services speed up processes and reduce errors that can lead to customer dissatisfaction.
                        </p>
                        <ul class="ul-policy">
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Data validation
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Error-free data entry
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Quality control checks
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Product searchability and
                            visibility
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div data-bs-toggle="modal" data-bs-target="#MobModal1" aria-expanded="true" 
                aria-controls="Claims_Management" id="headingOne" class="headingCTA CTA-Zindex d-flex  p-3">
                <h3 class="CTAs text-black fs-24  me-3">Catalog Update</h3>
                <img class="service_btn" data-bs-toggle="modal" data-bs-target="#MobModal1"
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal1" tabindex="-1" aria-labelledby="MobModal1Lable1"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Catalog Update</h3>
                          <p class="text-dark font-20-16">
                            Inspire customers' confidence in your brand by giving them better insights into product
                            details and specifications.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Keep the catalog fresh and
                              accurate
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Regular product information
                              updation
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Latest product data and analysis
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div class="d-flex p-3" data-bs-toggle="modal" data-bs-target="#MobModal2">
                <h3 class="CTAs text-black fs-24  me-3">
                  Product Listing
                </h3>
                <img class="service_btn" data-bs-toggle="modal" data-bs-target="#MobModal2"
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal2" tabindex="-1" aria-labelledby="MobModalLabel2"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Product Listing
                          </h3>
                          <p class="text-dark font-20-16">
                            Improve website ranking and increase click-through rates with our convenient yet smart
                            product listing services.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Comprehensive details
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />High-quality images
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Product availability status
                              monitoring
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div class="d-flex p-3" data-bs-toggle="modal" data-bs-target="#MobModal3">
                <h3 class="CTAs text-black fs-24  me-3">Inventory Management Services</h3>
                <img class="service_btn" data-bs-toggle="modal" data-bs-target="#MobModal3"
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal3" tabindex="-1" aria-labelledby="MobModalLabel3"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Inventory Management Services</h3>
                          <p class="text-dark font-20-16">
                            Know how much inventory you need and when. <a href="/services/e-commerce-inventory-management" class="anchor">Our comprehensive inventory management services</a> let
                            you refrain from investing in materials you do not need.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Track stock levels in real-time
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Data Analytics
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Demand forecasting
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Inventory planning
                            </li>

                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Optimize warehouse layout
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <img class="mt-5"  appCdnUrl="assets/Icon/white-snake-vector.svg" />
  </div>
</section>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
        <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="100">
        <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="200">
        <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="300">
        <img class="img-fluid" alt="ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="ecommerce tool Zendesk" appCdnUrl="assets/Tools/zendesk.svg"/>
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt=" ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="ecommerce tool Zendesk " appCdnUrl="assets/Tools/zendesk.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="ecommerce tool Zendesk" appCdnUrl="assets/Tools/zendesk.svg"/>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Thinking -->
<app-service-case-study  [category]="[{ category: 'ecommerce' }]"></app-service-case-study>
<!-- Know best -->
<app-know-best
[cards]="[
  { description: 'We have had an incredible experience with fusion so far. We have actually increased our fusion team size twice now. And the process has been very seamless, very easy. The fusion team is very communicative, and the quality of the work has rivaled that of any employee we could have in-house. ',
  name: 'Joey Cumley', designation: 'Chief Merchandising Officer'
 },
]"
[videos]="[
  { videoUrl: 'https://player.vimeo.com/video/963295022',
  thumbnail:'assets/thumbnail-know-best/Client Testimonials YT 03 Joey Cumley.jpg',
  alt:'Listen to our client'

   },
]">
</app-know-best>
<section class="py-6"  data-aos="fade-up">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6"  data-aos="fade-right">
        <app-faq></app-faq>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0"  data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button font-28-20" type="button" data-bs-toggle="collapse"
                data-bs-target="#Industries" aria-expanded="true" aria-controls="Industries">
                How does your team ensure that catalog product images are fine-tuned as per the brand guidelines?
              </button>
            </h2>
            <div id="Industries" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                We thoroughly understand guidelines to ensure images reflect your brand's unique aesthetic value across
                all channels. Achieving excellence in everything from color correction and image sharpness to alignment
                consistency. When you outsource your <a class="anchor" href="/services/e-commerce-catalog-management#Our Catalog Management Solutions">catalog management service</a> to us, we meet the highest standards in
                the visual journey by passing images through various review stages involving experienced designers and
                quality analysts.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Services" aria-expanded="false" aria-controls="Services">
                How do you ensure quality and consistency with an increasing product information base?
              </button>
            </h2>
            <div id="Services" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Our quality assurance team keeps up with the evolving trends, ensuring image quality, metadata, and
                product categorization are aligned with them. To handle increasing volume while maintaining the highest
                standards, our team undergoes continuous training to <a href="/services/e-commerce-data-management" class="anchor">gain proficiency in data management</a> and the latest
                error detection techniques.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#About" aria-expanded="false" aria-controls="About">
                Can I establish centralized product data with your catalog management solutions?
              </button>
            </h2>
            <div id="About" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Yes, you can do this effortlessly, as our catalog management process is designed to integrate easily
                with other applications and automate synchronization across various systems. Ensuring your team access
                to, update, and manage product information from a single, unified platform.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Resources" aria-expanded="false" aria-controls="Resources">
                How do your catalog management services enhance the experience of my consumers?
              </button>
            </h2>
            <div id="Resources" class="accordion-collapse collapse" aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                It does so by: 
                <ul>
                  <li>Ensuring that product listings are accurate, detailed, and up to date.</li>
                  <li>Maintaining consistency and reliability in product descriptions and managing information across various channels.</li>
                  <li>Optimizing your catalog management for search engines and other standard industry practices.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Differentiators" aria-expanded="false" aria-controls="Differentiators">
                Why should I choose you as my right ecommerce outsourcing service provider?
              </button>
            </h2>
            <div id="Differentiators" class="accordion-collapse collapse" aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                <ul class="pt-3">
                  <li>You should partner with us if you are looking for:</li>
                  <li>360-degree assistance that meets your dynamic business needs round the clock</li>
                  <li>Professionally trained employees</li>
                  <li>Buck-up services at zero additional cost</li>
                  <li>Dedicated <a class="anchor" target="_blank" href="/services/customer-support-outsourcing">customer support</a>, vendor management, and much more</li>
                </ul>
                Partnering with us means aligning with the industry excellence.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>