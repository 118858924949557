import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { SeoService } from '../../../services/seo/seo.service';
import { SeoData } from '../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../components/HomeComponents/faq/faq.component';
import { ServiceVimeoComponent } from '../../../components/HomeComponents/service-vimeo/service-vimeo.component';
import { ServiceCaseStudyComponent } from '../../../components/HomeComponents/service-case-study/service-case-study.component';
import { KnowBestComponent } from '../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { environment } from '../../../../environments/environment';
import { ourAssetsComponent } from '../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-service-rpo',
  standalone: true,
  templateUrl: './service-rpo.component.html',
  styleUrl: './service-rpo.component.css',
  imports: [
    RouterLink,
    RouterOutlet,
    SlickCarouselModule,
    FaqComponent,
    ServiceVimeoComponent,
    ServiceCaseStudyComponent,
    KnowBestComponent,
    CdnUrlDirective,
    ourAssetsComponent,
  ],
})
export class ServiceRPOComponent implements OnInit {
  cdnUrl = environment.cdnUrl;
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Recruitment Process Outsourcing (RPO) Solutions | FBSPL',
      description:
        'We provide recruitment & staffing (rpo) solutions to top industries to save time, money and find the right candidates. Contact us today.',
      keywords:
        'Recruitment process outsourcing, RPO services,Recruitment solutions',
      ogImage: '/ogImage/RPO Banner 1.jpg',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({
      rel: 'canonical',
      href: 'https://www.fbspl.com/services/recruitment-process-outsourcing-services',
    });
  }
  slides = [
    {
      banner: true,
      img: `${this.cdnUrl}assets/RPO/Main Banner/RPO Banner.webp`,
      img_mob: `${this.cdnUrl}assets/RPO/Main Banner/RPO Banner Mobile 01.webp`,
      Title: 'Fast-Paced Recruitment Process Outsourcing Services  ',
      Description:
        'Hasten your hiring process with our advanced recruitment outsourcing services. Our RPO staffing professionals utilize the latest technologies to hire the best talent while reducing your time to hire candidates. ',
      serviceRPO: 'Onboard Talent with Us ',
      Link: '/book-free-consultation-call',
      alt: 'Recruitment Process Outsourcing (RPO) Services',
    },
    {
      banner: false,
      img: `${this.cdnUrl}assets/RPO/Main Banner/RPO Banner 02.webp`,
      img_mob: `${this.cdnUrl}assets/RPO/Main Banner/RPO Banner Mobile 02.webp`,
      Title: 'Case Study',
      Description:
        'Get a glimpse of the talent revolution of our clients with our result-oriented RPO services. Watch the transformative impact we have made on organizations and their recruitment strategies. ',
      serviceRPO: 'Onboard Talent with Us ',
      Link: '/case-studies#recruitment',
      alt: 'FBSPL RPO Case Study',
    },
    {
      banner: false,
      img: `${this.cdnUrl}assets/RPO/Main Banner/RPO Main Banner 3.webp`,
      img_mob: `${this.cdnUrl}assets/RPO/Main Banner/RPO Banner Mobile 03.webp`,
      Title: 'Know What’s Trending in RPO ',
      Description:
        'Stay at the forefront of talent acquisition! Explore the recruitment process outsourcing trends, market insights, best practices for talent acquisition, RPO solutions, and more. ',
      serviceRPO: 'Onboard Talent with Us ',
      Link: '/blogs#recruitment',
      alt: 'RPO Blogs & Latest Trends',
    },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Tablet breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on tablets
          slidesToScroll: 1, // Number of slides to scroll on tablets
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on tablets
          arrows: false, // Enable arrows on tablets
        },
      },
      {
        breakpoint: 1024, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
      {
        breakpoint: 1200, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
    ],
  };
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.document.head.querySelector('script[type="application/ld+json"]')
      ) {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "How does recruitment process outsourcing benefit my business?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "With the industry-best recruitment process outsourcing services and solutions, you can reduce costs, find better candidates, increase hiring efficiency, and greatly minimize hiring time. Overall, you can focus on the core business competencies while the RPO services provider will tackle the talent acquisition process for you." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "In what sectors do you offer RPO services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We offer the industry's best recruitment process outsourcing services for various sectors. Here are the major areas in which we offer RPO services. 

<ul><li>Healthcare / Pharmaceuticals/ Bio Tech/Bio Medical.</li> 

<li>Legal support</li> 

<li>Construction / Manufacturing</li> 

<li>Technology/IT sector</li> 

<li>Banking</li> 

<li>Consumer Goods / Retail</li> 

<li>Automotive</li> 

<li>Professional Services</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose FBSPL for recruitment outsourcing services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Though there are ample recruitment outsourcing services providers, the following key pointers set us apart. 

<ul><li>Huge internal database</li> 

<li>15+ industrial experience</li> 

<li>Trained recruiters</li> 

<li>Industry knowledge<li> 

<li>Quality over quantity</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What are the different RPO activities you follow?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our area of expertise and common activities that we do for our clients are as follows - 

<ul><li>Job analysis</li> 

<li>Candidate sourcing and job postings</li> 

<li>Contact details searching and verification</li> 

<li>ATS/database management</li> 

<li>Outreach (Email/call/SMS)</li> 

<li>Resume and candidate screening</li> 

<li>Resume formatting</li> 

<li>Candidate information submission</li> 

<li>Interview scheduling and feedback</li> 

<li>Offer, onboarding, and placement</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What are your key differentiators?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "<ul><li>Availability As Per Your Time Zone</li> 

<li>All-in One Expertise</li> 

<li>Dedicated Staff</li> 

<li>99% Accuracy</li> 

<li>Tailor-Made Solutions</li></ul>" 

      } 

    } 

  ] 

} `;
        this.renderer.appendChild(this.document.head, script);
      }
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Recruitment Process Outsourcing", 

      "item": "https://www.fbspl.com/services/recruitment-process-outsourcing-services" 

    } 

  ] 

} `;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
      const alternateLinkDefault = this.renderer.createElement('link');

      alternateLinkDefault.rel = 'alternate';
      alternateLinkDefault.href = `https://www.fbspl.com`;
      alternateLinkDefault.hreflang = 'x-default';
      this.renderer.appendChild(this.document.head, alternateLinkDefault);

      const alternateLinkEn = this.renderer.createElement('link');
      alternateLinkEn.rel = 'alternate';
      alternateLinkEn.href = `https://www.fbspl.com/services/recruitment-process-outsourcing-services`;
      alternateLinkEn.hreflang = 'en-us';
      this.renderer.appendChild(this.document.head, alternateLinkEn);
    }
  }
}
