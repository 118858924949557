import { Component , OnInit,Renderer2, Inject, PLATFORM_ID} from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-catalog-management',
    standalone: true,
    templateUrl: './catalog-management.component.html',
    styleUrl: './catalog-management.component.css',
    imports: [
    FaqComponent,
    ServiceCaseStudyComponent, KnowBestComponent, RouterLink, RouterOutlet, CdnUrlDirective,
  ourAssetsComponent
]
})
export class CatalogManagement implements OnInit{
  constructor(private meta: Meta, private seoService: SeoService,private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'eCommerce Catalog Management Solutions | FBSPL',
      description:
        "Our e-commerce catalog management solutions include inventory management, product listing, catalog indexing, and updates. Our team ensures quality and follows industry best practices.",
      keywords: 'catalog management services, catalog management, ecommerce catalog management',
      ogImage :'/ogImage/Catalog management- Banner.jpg'
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/e-commerce-catalog-management' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What are the benefits of our accounting & bookkeeping outsourcing services?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "If you are wondering whether we will be the best fit for your accounting and bookkeeping needs, here’s what you should know. While choosing us, you can get ample benefits, including - <ul><li>Multi-currency accounting</li><li>Hourly services</li><li>Centralized accounting</li><li>Intercompany reconciliations</li><li>Tailor-made solutions</li><li>All-in-one expertise</li><li>99% accuracy</li></ul>"
            }
          },
          {
            "@type": "Question",
            "name": "What are the major tools or software you have worked on?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Although our accounting and bookkeeping experts are well-versed in working on any tool or software, here is a set of tools we have worked on so far - <ul><li>QuickBooks</li><li>Zoho</li><li>Oddo</li><li>Sage</li><li>NetSuite</li><li>Xero</li></ul>"
            }
          },
          {
            "@type": "Question",
            "name": "What are the industries where you can avail our outsourcing accounting and bookkeeping services?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Here are the major industries to whom we cater accounting and bookkeeping services. <ul><li>Consulting</li><li>Healthcare</li><li>Hospitality</li><li>Insurance</li><li>E-commerce</li></ul>"
            }
          },
          {
            "@type": "Question",
            "name": "Will outsourcing accounting and bookkeeping help cut operational costs?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, you can save huge costs with our outsourced bookkeeping and accounting solutions. We hold years of expertise, and our professionals can ensure 99% accuracy with tailor-made solutions. Discuss your project requirements with our experts and let us assist you with the best and most reasonable outsourcing services."
            }
          },
          {
            "@type": "Question",
            "name": "What are your key differentiators?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "<ul><li>Availability As Per Your Time Zone</li><li>All-in One Expertise</li><li>Dedicated Staff</li><li>99% Accuracy</li><li>Tailor-Made Solutions</li></ul>"
            }
          }
        ]
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      {  

  

  "@context": "https://schema.org",  

   "@type": "BreadcrumbList",  

   "itemListElement": [  

     {  

       "@type": "ListItem",  

       "position": 1,  

       "name": "Home",  

       "item": "https://www.fbspl.com/"  

     },  

     {  

       "@type": "ListItem",  

       "position": 2,  

       "name": "Services",  

       "item": "https://www.fbspl.com/bpm-and-bpo-services"  

     },  

     {  

  

      "@type": "ListItem",  

       "position": 3,  

       "name": "eCommerce",  

       "item": "https://www.fbspl.com/services/e-commerce-outsourcing-services"  

     },  

     {  

  

      "@type": "ListItem",  

  

      "position": 4,  

       "name": "E-commerce Catalog Management Services",  

       "item": "https://www.fbspl.com/services/e-commerce-catalog-management"  

     }  

  

  ]  

  

}  `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/e-commerce-catalog-management`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
