<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img  appCdnUrl="assets/ecom-pre-sales/pre sales.jpg" class="card-img-desktop desktop-banner" alt="eCommerce Pre-Sales Support Services" />
    <div class="card-img-overlay overlay-text bannerText">
  
        <div class="main_bannar pt-3"  >
          <div data-aos="fade-up">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item"><a href="/bpm-and-bpo-services">Services</a></li>
                <li class="breadcrumb-item" aria-current="page">
                  <a href="/services/e-commerce-outsourcing-services">E-commerce</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Pre-Sales Support
                </li>
              </ol>
            </nav>
            <h1 class="card-text font-34-19 text-primary">
              E-commerce Pre-Sales Support
            </h1>
            <p class="card-text font-20-16 pt-3">
              Whether it is a technical question, or a query related to your product's efficacy and brand value, our
              360-degree assistance with B2B lead generation services and pre-sales support is all you need to leave an
              impact.
            </p>
            <a routerLink="/book-free-consultation-call"  class="CTA-Zindex anchorCTA">
              <h3 class="text-primary font-24-16 my-auto clickable-heading">
                Begin the journey
                <div class="headingCTA">
                  <button class="blueCTA"></button>
                </div>
              </h3>
            </a>
          </div>
        </div>
  
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img  appCdnUrl="assets/ecom-pre-sales/Mobile-banner.jpg" class="card-img-mob" alt="eCommerce Pre-Sales Support Services" />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div  data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/bpm-and-bpo-services">Services</a></li>
          <li class="breadcrumb-item" aria-current="page">
            <a href="/services/e-commerce-outsourcing-services">E-commerce</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Pre-Sales Support
          </li>
        </ol>
      </nav>
      <h1 class="card-text font-34-19 text-text-primary">E-commerce Pre-Sales Support</h1>
      <p class="card-text font-20-16">
        Whether it is a technical question, or a query related to your product's efficacy and brand value, our
            360-degree assistance with B2B lead generation services and pre-sales support is all you need to leave an
            impact.
      </p>
      <a routerLink="/book-free-consultation-call"  class="CTA-Zindex anchorCTA">
        <h3 class="text-primary font-24-16 my-auto clickable-heading">
          Begin the journey
          <div class="headingCTA">
            <button class="blueCTA"></button>
          </div>
        </h3>
      </a>
    </div>
  </div>
</div>
</div>
<app-ourAssets></app-ourAssets>
<section class="container-fluid policy-process-service">
  <div class="container">
    <div class="container_emp ps-lg-3">
      <h2 class="text-white font-48-30 py-3"  data-aos="fade-up">
        Lead the Change with Our Pre-Sales Support  
      </h2>
      <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-4" />
    </div>
    <div class="d-none d-lg-block">
      <div class="row py-3 px-2">
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Issuance p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Live Chat Support
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <!-- Modal -->
          <div class="modal Policy-Processing fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5"  data-aos="zoom-in">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Live Chat Support</h3>
                      <p class="text-dark font-20-16">
                        Easy to integrate with your existing technologies, a real-time and affordable way to solve
                        common customer queries.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Real-time solutions
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Immediate responses
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Product recommendations
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Chatbots for after-hours queries
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />24/7 chat support
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/ecom-pre-sales/subservice/Group 44.png" alt="Live Chat Support Services " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy-Checking p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal1">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Call and Email Support
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal1" tabindex="-1"
            aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Call and Email Support</h3>
                      <p class="text-dark font-20-16">
                        We bring you a new world of customer satisfaction - addressing complex issues with detailed and
                        traceable solutions.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Dedicated hotline
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Trained customer service
                          representatives
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Automated Email support
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Shorter response time
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/ecom-pre-sales/subservice/Group 47.png" alt="Call and Email Support Services " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Loss_Runs_Processing p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal2">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Product Information and Guidance
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal2" tabindex="-1"
            aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Product Information and Guidance</h3>
                      <p class="text-dark font-20-16">
                        Our accurate, persuasive, detailed, and <a class="anchor" target="_blank" href="/blogs/how-to-create-best-ecommerce-product-description-with-example">comprehensive product descriptions</a> and guidance not only
                        inform but also convince customers to make the final purchase.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Detailed product descriptions
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Specs for all listings
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />User manuals
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Size charts
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Comparison guides
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Webinars
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Live demos
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/ecom-pre-sales/subservice/Group 46.png" alt="Product Information and Guidance   " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Renewals p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal3">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Email Marketing Campaigns
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal3" tabindex="-1"
            aria-labelledby="exampleModalLabel3" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Email Marketing Campaigns</h3>
                      <p class="text-dark font-20-16">
                        Explore the true potential of email marketing campaigns with us. Create targeted campaigns that
                        help you connect with your audience in a personalized way.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Personalized messages
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Engaging content
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Targeted promotions
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />New arrivals and exclusive emails
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Analysis of customer interaction
                          data
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Refined email campaigns
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/ecom-pre-sales/subservice/Group 45.png" alt="Email Marketing Campaigns " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Management p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal4">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Lead Generation
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal4" tabindex="-1"
            aria-labelledby="exampleModalLabel4" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Lead Generation</h3>
                      <p class="text-dark font-20-16">
                        Being a critical point in your sales funnel, our lead generation efforts are centered around
                        evaluating and improving the effectiveness of your marketing campaigns.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" /><a class="anchor" target="_blank" href="/services/digital-marketing">SEO</a>
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" /><a class="anchor" target="_blank" href="/services/digital-marketing">Content marketing</a>
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" /><a class="anchor" target="_blank" href="/services/digital-marketing">Social media marketing</a>
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Lead capture forms
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Newsletters
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />eBooks
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Free trials
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/ecom-pre-sales/subservice/Group 48.png" alt="Lead Generation " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Endorsements p-3">
            <a class="CTA-Zindex anchorCTA mb-3 p-2">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Live Chat Support
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" data-bs-toggle="modal" data-bs-target="#exampleModal5"></button>
                </div>
              </h3>
            </a>
          </div>
        </div> -->
      </div>
    </div>
    <div class="modal Policy-Processing fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel5"
      aria-hidden="true">
      <div class="modal-dialog policy-dialog pt-0 mt-0">
        <div class="modal-content">
          <div class="modal-header policy-header">
            <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
          </div>
          <div class="card policy-processing-services-card mx-5 container">
            <div class="details">
              <div class="content">
                <h3 class="font-48-30 text-dark">Live Chat Support</h3>
                <p class="text-dark font-20-16">
                  Easy to integrate with your existing technologies, a real-time and affordable way to solve common
                  customer queries.
                </p>
                <ul class="ul-policy">
                  <li class="pt-2 text-dark font-20-16">
                    <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Real-time solutions
                  </li>
                  <li class="pt-2 text-dark font-20-16">
                    <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Immediate responses
                  </li>
                  <li class="pt-2 text-dark font-20-16">
                    <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Product recommendations
                  </li>
                  <li class="pt-2 text-dark font-20-16">
                    <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Chatbots for after-hours queries
                  </li>
                  <li class="pt-2 text-dark font-20-16">
                    <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />24/7 chat support
                  </li>
                </ul>
              </div>
            </div>
            <div class="imgBx d-none d-lg-block">
              <img  appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class=" p-3">
    <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-12 mt-0 mt-sm-4">
          <div class="accordion" id="ServicePage">
            <div class="policy-processing mt-4" data-aos="zoom-in"  data-bs-toggle="modal" data-bs-target="#MobModal4">
              <div class="d-flex p-3">
                <h3 class="CTAs text-black fs-24  me-3">
                  Live Chat Support
                </h3>
                <img class="service_btn"
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal4" tabindex="-1" aria-labelledby="MobModalLabel4"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Live Chat Support
                          </h3>
                          <p class="text-dark font-20-16">
                            Easy to integrate with your existing technologies, a real-time and affordable way to solve
                            common customer queries.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Real-time solutions
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Immediate responses
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Product recommendations
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Chatbots for after-hours queries
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />24/7 chat support
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4"  data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal">
              <div data-bs-toggle="collapse" data-bs-target="#Policy_Processing" aria-expanded="true" 
                aria-controls="Policy_Processing" id="headingOne" class="headingCTA CTA-Zindex d-flex  p-3">
                <h3 class="CTAs text-black fs-24  me-3">Call and Email Support</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
            </div>
            <div class="modal Policy-Processing fade" id="MobModal" tabindex="-1" aria-labelledby="MobModalModalLabel"
              aria-hidden="true">
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                  </div>
                  <div class="card policy-processing-services-card-mob mx-3 py-3">
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Call and Email Support</h3>
                        <p class="text-dark font-20-16">
                          We bring you a new world of customer satisfaction - addressing complex issues with detailed
                          and traceable solutions.
                        </p>
                        <ul class="ul-policy">
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Dedicated hotline
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Trained customer service
                            representatives
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Automated Email support
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Shorter response time
                            verification
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img  appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal1">
              <div data-bs-toggle="collapse" data-bs-target="#Claims_Management" aria-expanded="true" 
                aria-controls="Claims_Management" id="headingOne" class="headingCTA CTA-Zindex d-flex  p-3">
                <h3 class="CTAs text-black fs-24  me-3">Product Information and Guidance</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal1" tabindex="-1" aria-labelledby="MobModal1Lable1"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Product Information and Guidance</h3>
                          <p class="text-dark font-20-16">
                            Our accurate, persuasive, detailed, and <a class="anchor" target="_blank" href="/blogs/how-to-create-best-ecommerce-product-description-with-example">comprehensive product descriptions</a> and guidance not only
                        inform but also convince customers to make the final purchase.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Detailed product descriptions
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Specs for all listings
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />User manuals
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Size charts
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Comparison guides
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Webinars
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Live demos
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal2" data-aos="zoom-in">
              <div class="d-flex p-3">
                <h3 class="CTAs text-black fs-24  me-3">
                  Email Marketing Campaigns
                </h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal2" tabindex="-1" aria-labelledby="MobModalLabel2"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Email Marketing Campaigns
                          </h3>
                          <p class="text-dark font-20-16">
                            Explore the true potential of email marketing campaigns with us. Create targeted campaigns
                            that help you connect with your audience in a personalized way.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Personalized messages
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Engaging content
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Targeted promotions
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />New arrivals and exclusive
                              emails
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Analysis of customer interaction
                              data
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Refined email campaigns
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal3">
              <div class="d-flex p-3">
                <h3 class="CTAs text-black fs-24  me-3">Lead Generation</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal3" tabindex="-1" aria-labelledby="MobModalLabel3"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Lead Generation</h3>
                          <p class="text-dark font-20-16">
                            Being a critical point in your sales funnel, our lead generation efforts are centered around
                            evaluating and improving the effectiveness of your marketing campaigns.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" /><a class="anchor" target="_blank" href="/services/digital-marketing">SEO</a>
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" /><a class="anchor" target="_blank" href="/services/digital-marketing">Content marketing</a>
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" /><a class="anchor" target="_blank" href="/services/digital-marketing">Social media marketing</a>
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Lead capture forms
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Newsletters
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />eBooks
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Free trials
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <img class="mt-5" appCdnUrl="assets/Icon/white-snake-vector.svg" />
  </div>
</section>
<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
        <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="100">
        <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="200">
        <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="300">
        <img class="img-fluid" alt="ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="ecommerce tool Zendesk" appCdnUrl="assets/Tools/zendesk.svg"/>
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt=" ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="ecommerce tool Zendesk " appCdnUrl="assets/Tools/zendesk.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="ecommerce tool Zendesk" appCdnUrl="assets/Tools/zendesk.svg"/>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Thinking -->
<app-service-case-study  [category]="[{ category: 'ecommerce' }]"></app-service-case-study>
<!-- Know best -->
<app-know-best
[cards]="[
  { description: 'We have had an incredible experience with fusion so far. We have actually increased our fusion team size twice now. And the process has been very seamless, very easy. The fusion team is very communicative, and the quality of the work has rivaled that of any employee we could have in-house. ',
  name: 'Joey Cumley', designation: 'Chief Merchandising Officer'
 },
]"
[videos]="[
  { videoUrl: 'https://player.vimeo.com/video/963295022',
  thumbnail:'assets/thumbnail-know-best/Client Testimonials YT 03 Joey Cumley.jpg',
  alt:'Listen to our client '

   },
]">
</app-know-best>
<section class="py-6">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6" data-aos="fade-right">
        <app-faq></app-faq>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0" data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button font-28-20" type="button" data-bs-toggle="collapse"
                data-bs-target="#Industries" aria-expanded="true" aria-controls="Industries">
                Why should I choose you as my right ecommerce outsourcing service provider?
              </button>
            </h2>
            <div id="Industries" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                You should partner with us if you are looking for:
                <ul class="pt-3">
                  <li>360-degree assistance that meets your dynamic business needs round the clock</li>
                  <li>Professionally trained employees</li>
                  <li>Buck-up services at zero additional cost</li>
                  <li><a href="/services/customer-support-outsourcing" class="anchor" target="_blank">Dedicated customer support</a>, vendor management, and much more
                   </li>
                   <p>Partnering with us means aligning with the industry excellence.</p>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Services" aria-expanded="false" aria-controls="Services">
                Why do I need strong pre-sales customer service? Is it worth the investment?
              </button>
            </h2>
            <div id="Services" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                You should invest in pre-sales support if you think the following are essential for your E-commerce success: 
                <ul>
                  <li><b>Timely and Accurate Answers: </b>Build trust and credibility by accurately addressing your
                    potential customers' queries.</li>
                  <li><b>Buying Decisions: </b>Make consumers familiar with your unique selling points and boost your conversion rates.  </li>
                  <li><b>Reduce Cart Abandonment: </b>Know what makes your customers leave their purchase journey
                    midway.
                    Provide solutions that convince them to buy.</li>
                  <li><b>Competitive Advantage:</b> Gain competitive advantages by digging deeper into your consumer's
                    and competitors' persona.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#About" aria-expanded="false" aria-controls="About">
                How do you ensure my brand has a competitive advantage over other brands selling similar products with
                almost identical pricing?
              </button>
            </h2>
            <div id="About" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Each brand has its unique selling points, and our specialists are adept at emphasizing them at the right
                moment to persuade customers. We believe a brand represents more than its cost. The value, credibility,
                and experience it provides distinguish it from competitors.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Resources" aria-expanded="false" aria-controls="Resources">
                How does your pre-sales support team deal with confused, frustrated customers with vague expectations?
              </button>
            </h2>
            <div id="Resources" class="accordion-collapse collapse" aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                We understand that customers may feel frustrated before or after purchasing a product. Therefore, before
                offering any recommendations, our team puts themselves in their position. The goal is to present
                solutions and foster a situation that benefits both parties.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Differentiators" aria-expanded="false" aria-controls="Differentiators">
                What is your most common approach with customers who demand unrealistic discounts or coupons?
              </button>
            </h2>
            <div id="Differentiators" class="accordion-collapse collapse" aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                We first review the customers' order history to understand their buying patterns and whether they have
                been purchasing with us frequently or rarely. Next, we check for any existing coupons. However, in the
                first place, offering coupons is not our primary focus. Instead, we assess the product's profit margins
                to determine if there is any room for discount. Our strategy is aligned to maintain a balance between
                customer satisfaction and business profitability.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>