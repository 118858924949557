import { Component, OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterLink, RouterOutlet } from '@angular/router';

import { SeoService } from '../../../services/seo/seo.service';
import { SeoData } from '../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../components/HomeComponents/faq/faq.component';
import { ServiceCaseStudyComponent } from '../../../components/HomeComponents/service-case-study/service-case-study.component';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { environment } from '../../../../environments/environment';
import { KnowBestComponent } from '../../../components/HomeComponents/know-best/know-best.component';
import { ourAssetsComponent } from "../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-data-annotation',
  standalone: true,
  templateUrl: './data-annotation.component.html',
  styleUrl: './data-annotation.component.css',
  imports: [
    RouterLink,
    RouterOutlet,
    SlickCarouselModule,
    FaqComponent,
    ServiceCaseStudyComponent,
    CdnUrlDirective,
    KnowBestComponent,
    ourAssetsComponent
],
})
export class DataAnnotationComponent implements OnInit {
  cdnUrl = environment.cdnUrl;
  constructor(private meta: Meta, private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'AI Data Annotation & Labeling Outsourcing Services',
      description:
        'FBSPL offers high-quality data annotation outsourcing services for machine learning, ai, finance & insurance industries to improve their data and business model.',
      keywords: 'data annotation services, labeling services',
      ogImage: '/Data Annotation/Data Annotation Banner 1.jpg',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/data-annotation-services' });
  }
  slides = [
    {
      banner: true,
      img: `${this.cdnUrl}assets/Data Annotation/Data Annotation Banner 1.jpg`,
      img_mob: `${this.cdnUrl}assets/Data Annotation/Data Annotation Mobile 1.jpg`,
      Title: 'Data Annotation Solution – Being Responsibly Advanced ',
      Description:
        'We bring the future of data annotation to you. Following established guidelines and annotation standards, our data annotation services are accurate and known to achieve meaningful results. Right from speech and video datasets to object detection and sentiment analysis, you can trust our experts with any dataset and annotation. ',
      Dataannotation: 'Click to advance',
      Link: '/book-free-consultation-call',
      alt: 'Data Annotation & Labelling Services '
    },
    {
      banner: false,
      img: `${this.cdnUrl}assets/Data Annotation/Data Annotation Banner 2.jpg`,
      img_mob: `${this.cdnUrl}assets/Data Annotation/Data Annotation Mobile 2.jpg`,
      Title:
        'How Did Our Data Annotation Services Increase the Number of Customers for a Client?',
      Description:
        'The partnership enhanced invoice processing efficiency, increased visibility and controls, and lowered operational costs. ',
      Link: '/case-studies#dataAnnotation',
      alt: 'Data Annotation Case Study '
    },
    {
      banner: false,
      img: `${this.cdnUrl}assets/Data Annotation/Data Annotation Banner 3.jpg`,
      img_mob: `${this.cdnUrl}assets/Data Annotation/Data Annotation Mobile 3.jpg`,
      Title:
        'Basic & Comprehensive Guide to Data Annotation and Labelling in Machine Learning & Artificial Intelligence ',
      Description:
        'Read our blog to know what data annotation and labeling is, how it is done, types of data annotation, their use cases, and more. ',
      Link: '/blogs#dataAnnotation',
      alt: ' Data Annotation Blogs & Latest Trends'
    },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Tablet breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on tablets
          slidesToScroll: 1, // Number of slides to scroll on tablets
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on tablets
          arrows: false, // Enable arrows on tablets
        },
      },
      {
        breakpoint: 1024, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
      {
        breakpoint: 1200, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
    ],
  };
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
           { 

      "@type": "Question", 

      "name": "What is your turnaround time?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": " The approximate timing varies from 30 seconds to 15 minutes, and heavily depends on:  
        <ul><li>Project size</li> 
        <li>Complexity of the project</li> 
        <li>Annotation type</li></ul> 
        However, we are dedicated to providing high-quality annotated data within a reasonable timeframe." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Do I get customer support that addresses my concerns throughout the data annotation outsourcing partnership?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Certainly, with us, you have: 
        <ul><li>A dedicated person who acts as your main point of contact and ensures your expectations are met throughout the partnership.</li> 
        <li>Various communication channels, including e-mail, phone, and Teams chat. You can contact us through any of these channels.</li> 
        <li>Regular updates on your project's progress and more.</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How does your team ensure data diversity in data annotation services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "To ensure data diversity, we have a set of guidelines in place, which include but are not limited to the following: 
        <ul><li>Identifying and annotating a wide range of features that capture different aspects of the data</li> 
        <li>Applying changes like rotation, cropping, and noise to enhance the existing dataset</li> 
        <li>Engaging annotators from diverse backgrounds and demographics to bring a range of perspectives to the dataset</li> 
        <li>Eliminating human biases or discriminatory outcomes</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Can you explain your strategy for eliminating human biases from data annotation?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "To eliminate human biases, we have: 
        <ul><li>Well-defined, clear, and comprehensive guidelines and Standard Operating Procedures (SOPs)</li> 
        <li>Ongoing knowledge transfer training to maintain team alignment with the importance of impartiality and consistency in data labeling services.</li> 
        <li>Frequent quality check sessions to verify compliance with established SOPs and guidelines.</li> 
        <li>Recurring discussions on critical aspects such as ethical reviews and blind annotations. </li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose you as my right ecommerce outsourcing service provider?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should partner with us if you are looking for: 
        <ul><li>360-degree assistance that meets your dynamic business needs round the clock</li> 
        <li>Professionally trained employees</li> 
        <li>Buck-up services at zero additional cost</li> 
        <li>Dedicated customer support, vendor management, and much more</li></ul> 
        Partnering with us means aligning with the industry excellence. " 

      } 

    } 
        ]
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Data Annotation", 

      "item": "https://www.fbspl.com/services/data-annotation-services" 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/data-annotation-services`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
