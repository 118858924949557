<div class="video-container" [class.no-click]="!videoUrl">
  @if (showOverlay) {
  <div class="overlay" (click)="playVideo()">
    <img class="thumbnails" [src]="cdnThumbnail" [alt]="alt" />
    @if (videoUrl) {
    <div
      class="play-button"
      [ngClass]="{
        'white-button': useWhitePlayButton,
        'blue-button': !useWhitePlayButton
      }"
    >
      <img src="" />
    </div>
    }
  </div>
  } @if (safeVideoUrl && videoUrl) {
  <iframe
    #videoPlayer
    class="video-iframe | safe"
    allowfullscreen
    allow="clipboard-write"
    title="Vimeo Video Player"
    [src]="safeVideoUrl"
    frameborder="0"
  ></iframe>
  }
</div>
