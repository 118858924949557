<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img alt="Data Management Services" appCdnUrl="assets/data-management/main-banner.jpg" class="card-img-desktop desktop-banner"/>
    <div class="card-img-overlay overlay-text bannerText">
        <div class="main_bannar" >
          <div  data-aos="fade-up">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item"><a href="/bpm-and-bpo-services">Services</a></li>
                <li class="breadcrumb-item" aria-current="page">
                  <a href="/services/e-commerce-outsourcing-services">E-commerce</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Data Management
                </li>
              </ol>
            </nav>
            <h1 class="card-text font-34-19 text-primary">
              Data Management Solutions
            </h1>
            <p class="card-text font-20-16 pt-3">
              Data quality, technological limitations, cost-effectiveness, and data protection - Your concerns?
              <br>
              Highly skilled data management experts, multi-media entry, uniformity and accuracy, stringent data
              regulations, and emerging technologies - Our solutions!
            </p>
            <a routerLink="/book-free-consultation-call"  class="CTA-Zindex anchorCTA">
              <h3 class="text-primary font-24-16 my-auto clickable-heading">
                Let's get started
                <div class="headingCTA">
                  <button class="blueCTA"></button>
                </div>
              </h3>
            </a>
          </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img alt="Data Management Services" appCdnUrl="assets/data-management/Mobile-banner .jpg" class="card-img-mob"  />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/bpm-and-bpo-services">Services</a></li>
          <li class="breadcrumb-item">
            <a href="/services/e-commerce-outsourcing-services">E-commerce</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Data Management
          </li>
        </ol>
      </nav>
      <h1 class="card-text font-34-19 text-dark">Data Management Solutions</h1>
      <p class="card-text font-20-16">
        Data quality, technological limitations, cost-effectiveness, and data protection - Your concerns?
        <br>
        Highly skilled data management experts, multi-media entry, uniformity and accuracy, stringent data
        regulations, and emerging technologies - Our solutions!
      </p>
      <a routerLink="/book-free-consultation-call"  class="CTA-Zindex anchorCTA">
        <h3 class="text-primary font-24-16 my-auto clickable-heading">
          Let's get started
          <div class="headingCTA">
            <button class="blueCTA"></button>
          </div>
        </h3>
      </a>
    </div>
  </div>
</div>
</div>
<app-ourAssets></app-ourAssets>
<section class="container-fluid policy-process-service">
  <div class="container">
    <div class="container_emp ps-lg-3">
      <h2 class="text-white font-48-30 py-3" data-aos="fade-up">
        Lead the Change with Our Data Management Solutions
      </h2>
        <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-4" />
    </div>
    <div class="d-none d-lg-block">
      <div class="row py-3 px-2">
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Issuance p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Product Data Entry
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <!-- Modal -->
          <div class="modal Policy-Processing fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Product Data Entry</h3>
                      <p class="text-dark font-20-16">
                        Boost the shopping experience and assist customers with informed decision-making through bulk
                        product uploading, visual clues, and product comparisons.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Regular audits
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Input product variants
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Tag relevant keywords
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Verify product availability
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Assign product categories and more
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                  <img  appCdnUrl="assets/data-management/Group 122.png" alt="Product Data Entry  " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy-Checking p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal1">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Customer Data Management
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal1" tabindex="-1"
            aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Customer Data Management</h3>
                      <p class="text-dark font-20-16">
                        Turn every interaction your customers have with your brand into an opportunity that drives
                        conversions and more thoughtful marketing decisions.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Implement data encryption
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Personalized recommendations
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Monitor customer engagement
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Segment customer data
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Comply with regulations
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Obtain consent for data usage
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Manage customer preferences
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/data-management/Group 123.png" alt="Customer Data Management " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Loss_Runs_Processing p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal2">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Data Process
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal2" tabindex="-1"
            aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Data Process</h3>
                      <p class="text-dark font-20-16">
                        Gathering data is fine but analyzing it the right way is what gives your E-commerce the distinct
                        advantage it needs. Let's convert data into actionable insights.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Automate order fulfillment
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Track Order Status
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Generate sales reports
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Manage returns and exchanges
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Coordinate with suppliers
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Optimize supply chain operations,
                          etc.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/data-management/Group 124.png" alt="Data Processing Services" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Renewals p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal3">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Image Data Entry
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal3" tabindex="-1"
            aria-labelledby="exampleModalLabel3" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Image Data Entry</h3>
                      <p class="text-dark font-20-16">
                        Unlock the true potential of your product images with our advanced image manipulation
                        techniques. Let your customers get an exact idea about the product and its dimensions.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Edit and enhance product images
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Tag images with relevant keywords
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Manage image metadata
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Conduct A/B testing for image
                          performance
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Monitor image loading speed, etc.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/data-management/Group 125.png" alt="Image Data Entry Services" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Management p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal" data-bs-target="#exampleModal4">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Bookkeeping Services
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal4" tabindex="-1"
            aria-labelledby="exampleModalLabel4" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Bookkeeping Services</h3>
                      <p class="text-dark font-20-16">
                        Achieve financial stability with our <a class="anchor" target="_blank" href="/services/accounting-bookkeeping-services">bookkeeping services.</a> Collaborate to monitor transactions
                        and inventory levels, adhere to regulations, and forecast finances well.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Invoicing
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Cleaning up books of accounts
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Maintaining <a class="anchor" target="_blank" href="/services/accounts-receivable-management">accounts receivable</a> and
                          <a class="anchor" target="_blank" href="/services/accounts-payable-management">accounts payable</a>
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" /> <a class="anchor" target="_blank" href="/services/accounts-reconciliation-services">Reconciling financial statements</a>
                          with bank statements
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Undertaking budgeting or cash flow
                          analysis
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img  appCdnUrl="assets/data-management/Group 126.png" alt="Bookkeeping Services" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <!-- Mobile View -->
  <div class=" p-3">
    <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-12 mt-0 mt-sm-4">
          <div class="accordion" id="ServicePage">
            <div class="policy-processing" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal">
              <div data-bs-toggle="collapse" data-bs-target="#Policy_Processing" aria-expanded="true"
                aria-controls="Policy_Processing" id="headingOne" class="headingCTA CTA-Zindex d-flex  p-3">
                <h3 class="CTAs text-black fs-24  me-3">Product Data Entry</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
            </div>
            <div class="modal Policy-Processing fade" id="MobModal" tabindex="-1" aria-labelledby="MobModalModalLabel"
              aria-hidden="true">
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                  </div>
                  <div class="card policy-processing-services-card-mob mx-3 py-3">
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Product Data Entry</h3>
                        <p class="text-dark font-20-16">
                          Boost the shopping experience and assist customers with informed decision-making through bulk
                          product uploading, visual clues, and product comparisons.
                        </p>
                        <ul class="ul-policy">
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Regular audits
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Input product variants
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Tag relevant keywords
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Verify product availability
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Assign product categories and more
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal1">
              <div data-bs-toggle="collapse" data-bs-target="#Claims_Management" aria-expanded="true"  
                aria-controls="Claims_Management" id="headingOne" class="headingCTA CTA-Zindex d-flex  p-3">
                <h3 class="CTAs text-black fs-24  me-3">Customer Data Management</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal1" tabindex="-1" aria-labelledby="MobModal1Lable1"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Customer Data Management</h3>
                          <p class="text-dark font-20-16">
                            Turn every interaction your customers have with your brand into an opportunity that drives
                            conversions and more thoughtful marketing decisions.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Implement data encryption
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Personalized recommendations
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Monitor customer engagement
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Segment customer data
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Comply with regulations
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Obtain consent for data usage
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Manage customer preferences
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal2">
              <div class="d-flex p-3" >
                <h3 class="CTAs text-black fs-24  me-3">
                  Data Process
                </h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal2" tabindex="-1" aria-labelledby="MobModalLabel2"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Data Process
                          </h3>
                          <p class="text-dark font-20-16">
                            Gathering data is fine but analyzing it the right way is what gives your E-commerce the
                            distinct advantage it needs. Let's convert data into actionable insights.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Automate order fulfillment
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Track Order Status
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Generate sales reports
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Manage returns and exchanges
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Coordinate with suppliers
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Optimize supply chain
                              operations, etc.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal3">
              <div class="d-flex p-3"  >
                <h3 class="CTAs text-black fs-24  me-3">Image Data Entry</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal3" tabindex="-1" aria-labelledby="MobModalLabel3"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Image Data Entry</h3>
                          <p class="text-dark font-20-16">
                            Unlock the true potential of your product images with our advanced image manipulation
                            techniques. Let your customers get an exact idea about the product and its dimensions.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Edit and enhance product images
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Tag images with relevant
                              keywords
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Manage image metadata
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Conduct A/B testing for image
                              performance
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Monitor image loading speed,
                              etc.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="policy-processing mt-4"  data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal4">
              <div class="d-flex p-3">
                <h3 class="CTAs text-black fs-24  me-3">
                  Bookkeeping Services
                </h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal4" tabindex="-1" aria-labelledby="MobModalLabel4"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Bookkeeping Services
                          </h3>
                          <p class="text-dark font-20-16">
                            Achieve financial stability with our bookkeeping services. Collaborate to monitor
                            transactions and inventory levels, adhere to regulations, and forecast finances well.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Invoicing
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Cleaning up books of accounts
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Maintaining <a class="anchor" target="_blank" href="/services/accounts-receivable-management">accounts receivable</a> and
                              <a class="anchor" target="_blank" href="/services/accounts-payable-management">accounts payable</a>
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" /> <a class="anchor" target="_blank" href="/services/accounts-reconciliation-services">Reconciling financial statements</a>
                              with bank statements
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Undertaking budgeting or cash flow
                              analysis
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-5"  appCdnUrl="assets/Icon/white-snake-vector.svg" />
  </div>
</section>
<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
        <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="100">
        <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="200">
        <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="300">
        <img class="img-fluid" alt="ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="ecommerce tool Zendesk" appCdnUrl="assets/Tools/zendesk.svg"/>
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt=" ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="ecommerce tool Zendesk " appCdnUrl="assets/Tools/zendesk.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="ecommerce tool Zendesk" appCdnUrl="assets/Tools/zendesk.svg"/>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Thinking -->
<app-service-case-study  [category]="[{ category: 'ecommerce' }]"></app-service-case-study>
<!-- Know best -->
<app-know-best
[cards]="[
  { description: 'We have had an incredible experience with fusion so far. We have actually increased our fusion team size twice now. And the process has been very seamless, very easy. The fusion team is very communicative, and the quality of the work has rivaled that of any employee we could have in-house. ',
  name: 'Joey Cumley', designation: 'Chief Merchandising Officer'
 },
]"
[videos]="[
  { videoUrl: 'https://player.vimeo.com/video/963295022',
  thumbnail:'assets/thumbnail-know-best/Client Testimonials YT 03 Joey Cumley.jpg',
  alt:'Listen to our client '

   },
]">
</app-know-best>
<section class="py-6">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6" data-aos="fade-right">
        <app-faq></app-faq>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0" data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button font-28-20" type="button" data-bs-toggle="collapse"
                data-bs-target="#Industries" aria-expanded="true" aria-controls="Industries">
                How do your data entry services enhance my brand visibility?
              </button>
            </h2>
            <div id="Industries" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Our data entry services go beyond populating fields. From optimizing product titles and descriptions
                with relevant keywords to running targeted e-mail campaigns and sending personalized messages, our data
                processing solutions are your key to driving higher customer engagement and conversion rates.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Services" aria-expanded="false" aria-controls="Services">
                What approach does your e-commerce data management team take to handle data overload?
              </button>
            </h2>
            <div id="Services" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                In case of data overload, our team brings in the following approach: 
                <ul>
                  <li>Align data inputs and analysis efforts with current business objectives.</li>
                  <li>Focus on metrics that directly affect customer satisfaction and conversion rates.</li>
                  <li>Identify and remove duplicate and inaccurate data.</li>
                  <li>Reduce manual efforts and improve data consistency.</li>
                  <li>Present data clearly and concisely to facilitate informed decision-making.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#About" aria-expanded="false" aria-controls="About">
                How do you ensure the security and protection of my data?
              </button>
            </h2>
            <div id="About" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                To safeguard your data's security and integrity, we:
                <ul>
                  <li>Employ strict access control</li>
                  <li>Comply with industry standards and regulations</li>
                  <li>Encrypt all your sensitive data during transmission and storage</li>
                  <li>Conduct regular data backups to prevent data loss</li>
                  <li>Continuously monitor and audit your system to identify and promptly address security risks</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Resources" aria-expanded="false" aria-controls="Resources">
                In what ways can your data management solutions help me improve customer satisfaction? 
              </button>
            </h2>
            <div id="Resources" class="accordion-collapse collapse" aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Our data management solutions help you with:
                <ul>
                  <li>Improved search functionality, enabling customers to easily find products they are looking for </li>
                  <li>A personalized experience for your consumers through targeted recommendations and promotions</li>
                  <li><a href="/services/e-commerce-order-management" class="anchor">Efficient order processing and fulfillment</a></li>
                  <li>A system that provides prompt and practical assistance to your customers' queries</li>
                  <li>Real-time insights into your customers' challenges and whether your services expertly address them</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Differentiators" aria-expanded="false" aria-controls="Differentiators">
                Why should I choose you as my right ecommerce outsourcing service provider?
              </button>
            </h2>
            <div id="Differentiators" class="accordion-collapse collapse" aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                You should partner with us if you are looking for:
                <ul class="pt-3">
                  <li>360-degree assistance that meets your dynamic business needs round the clock</li>
                  <li>Professionally trained employees</li>
                  <li>Buck-up services at zero additional cost</li>
                  <li>Dedicated <a class="anchor" target="_blank" href="/services/customer-support-outsourcing">customer support,</a> vendor management, and much more</li>
                </ul>
                Partnering with us means aligning with the industry excellence.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>