import { Component, OnInit,Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-general-ledger-accounting',
    standalone: true,
    templateUrl: './general-ledger-accounting.component.html',
    styleUrl: './general-ledger-accounting.component.css',
    imports: [
    FaqComponent, KnowBestComponent,
    ServiceCaseStudyComponent, CdnUrlDirective,
    ourAssetsComponent
]
})
export class GeneralLedgerAccountingComponent implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Outsource Expert General Ledger Accounting Services - FBSPL',
      description:
        'Get accurate and reliable general ledger accounting services with FBSPL. We specialize in accounting process outsourcing and management. Contact us today.',
        keywords:'general ledger accounting services, ledger management',
        ogImage:'/accoutning genral ledger/main-banner.jpg'
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/general-ledger-accounting-services' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          { 

      "@type": "Question", 

      "name": "What are the benefits of outsourcing accounting services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Accounting serves a crucial function for any business, but only if you hold the expertise. So, when you outsource general ledger accounting, here are the benefits you can experience - 

<ul> 

<li>Access to expertise and specialization</li> 

<li>Save a huge cost</li> 

<li>Scalability and flexibility</li>  

<li>Streamlined operations</li> 

<li>Accurate accounting</li>  

<li>Round-the-clock availability</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is the process of general ledger accounting?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": " We follow a very transparent general ledger accounting service. Here is a simplified process we follow -  

<ul><li>Understanding the client's requirements</li> 

<li>Establishing business goals</li> 

<li>Planning a general ledger accounting solution</li> 

<li>Managing general ledger accounting activities</li> 

<li>Preparing general ledger accounting reports</li></ul> " 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose you for accounting services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "While outsourcing general ledger accounting services to us, you get access to a wide range of perks, such as -  
<ul><li>All-in-one expertise</li> 

<li>Availability as per your time zone</li> 

<li>Quick turnaround time</li> 

<li>Tailor-made solutions</li> 

<li>99% accuracy</li> 

<li>Scalable solutions</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What type of general ledger accounting solutions do you offer?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We offer multiple types of general ledger solutions, ranging from MIS, SOPs, Budgeting, Adhoc JEs, Bank Reconciliations, and more. Bring your accounting and bookkeeping requirements to us and let our experts offer you the best solution that will help you keep your financial transactions on track while helping you focus on the core business activities." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is the major software you use?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Although our services aren’t specific to using a particular software, our professionals are well-versed in integrating all types of software ranging from QuickBooks, Zoho, Xero, Sage, Oddo, Sage NetSuite, and more." 

      } 

    } 
        ]
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Accounting & Bookkeeping", 

      "item": "https://www.fbspl.com/services/accounting-bookkeeping-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "General Ledger Accounting Services", 

      "item": "https://www.fbspl.com/services/general-ledger-accounting-services" 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/general-ledger-accounting-services`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}