import { Component,OnInit, Renderer2, Inject , PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-payroll-processing',
    standalone: true,
    templateUrl: './payroll-processing.component.html',
    styleUrl: './payroll-processing.component.css',
    imports: [
    FaqComponent, KnowBestComponent,
    ServiceCaseStudyComponent, CdnUrlDirective,
    ourAssetsComponent
]
})
export class PayrollProcessingComponent implements OnInit{
  constructor(private meta: Meta, private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {    
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Outsource Payroll Processing & Solution | FBSPL',
      description:
        "Partner with us to simplify and automate your payroll management. Let us help you save time on processing, payments, compliance, and reporting. ",
      keywords: 'payroll management, payroll outsourcing services',
      ogImage : '/accoutning Payroll Processing/Main-banner.jpg'
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/payroll-processing-services' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "What services do you offer in payroll processing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We cover all types of payroll processing services. Here are the major services we deal in - 

<ul> 

<li>Payroll calculation</li> 

<li>Payroll record-keeping</li> 

<li>Accounting system administration</li> 

<li>Payroll reporting</li> 

<li>Compliance</li> 

<li>Benefit administration</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you ensure compliance?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We understand the importance of accurate and compliant payroll processing. Hence, our team strictly adheres to the US Generally Accepted Accounting Principles (US GAAP), which are a set of accounting standards. These principles ensure consistency, transparency, and comparability across different businesses." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How long does your outsourcing company take for payroll processing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "The time required for payroll processing completely depends on the client, whether they want payroll weekly, monthly, or bi-weekly." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What technology do you use for payroll processing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Generally, we use third-party payroll processing management companies' assistance for payroll processing, including ADP payroll and Paychex. But if a client wants a direct electronic cheque from the client's bank, we can do that as well." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Can you handle global payroll processing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Yes, we can help you with global payroll processing. Feel free to share your requirements with our professionals and get the best payroll processing outsourcing services." 

      } 

    } 

  ] 

}  `;
      this.renderer.appendChild(this.document.head, script);
    }
      // Check and add BreadcrumbList script
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Accounting & Bookkeeping", 

      "item": "https://www.fbspl.com/services/accounting-bookkeeping-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "Payroll Processing Services", 

      "item": "https://www.fbspl.com/services/payroll-processing-services" 

    } 

  ] 

} 

 `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/payroll-processing-services`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
    }
  }
}
