import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = environment.apiUrl; // Update with your email API endpoint

  constructor(private http: HttpClient) {}

  sendEmail(data: any): Observable<any> {
    // This is a hypothetical API call. Replace it with your actual API endpoint and payload structure.
    const emailPayload = {
      Subject: `Case Study`,
      Name:`${data.firstname}`,
      Email:`${data.email}`,
    };

    return this.http.post(`${this.apiUrl}/api/hubspot/data`, emailPayload); // Replace with your backend API endpoint
  }
}
