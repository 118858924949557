import { Component, OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-claim-management',
  standalone: true,
  templateUrl: './claim-management.component.html',
  styleUrl: './claim-management.component.css',
  imports: [
    FaqComponent, KnowBestComponent,
    ServiceCaseStudyComponent, CdnUrlDirective,
    ourAssetsComponent
  ]
})
export class claimManagement implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Insurance Claim Processing & Management Services - FBSPL',
      description:
        "Our comprehensive claim management services handle your complex and time-consuming claim processing procedures, enhancing efficiency and customer satisfaction for your business.",
      keywords: 'insurance claim management, claims processing outsourcing, insurance claim outsourcing',
      ogImage: '/Sub-service-Pages/policy 1 1.png'
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/insurance-claim-management' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
           { 

      "@type": "Question", 

      "name": "What are the benefits of insurance claims management outsourcing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "While availing insurance claims management services from industry experts, businesses can ensure that the claims are processed efficiently and zero errors. Besides this, there are other benefits too - 

<ul> 

<li>Reduce non-compliance risk</li> 

<li>Insurance claims automation</li> 

<li>Access to dedicated experts</li> 

<li>Reduced operational cost</li> 

<li>Focus on strategic growth</li> 

<li>99% accuracy and 0% attrition</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why outsource claims management services to FBSPL?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": " Being a renowned claims management company, we offer a wide range of services ranging from claims processing to claims investigation. Our services are excellence are defined by our key differentiators mentioned below - 
        <ul><li>All-in expertise</li> 
        <li>Certified company</li> 
        <li>99% work accuracy</li> 
        <li>Quick turnaround time</li> 
        <li>Tailor-made solutions</li> 
        <li>Client time-zone availability</li></ul> " 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What Is the turnaround time for claims processing and management?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "It takes 15 to 25 minutes to collect the information for loss from policyholder. Turn-around time for follow up on claim status vary carrier to carrier depending on their hold time." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you ensure quality in claims handling?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We check the documentation and details such as Date of Loss, Proof of Losses and Description of Loss, and check the Adjustor Details and Claim# while sending out information to policyholder." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is the process of outsourcing claims management?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "As an outsourcing vendor, we can assist Agencies, MGA with following activities: 
        <ul><li>Collect Loss Info from Policyholder: Filing claims to insurance carrier by collecting the loss info such as Date of Loss, How Loss had occurred, and Proof of the Losses.</li> 
        <li>Register a claim to Insurance Company: We prepare the Acord Loss Letter for E&S Market and for Admitted Market we can directly file a claim using carrier portal.</li> 
        <li>Process FNOL (First Notice of Loss): Carrier assigns Claim# along with Adjustor's information. We create a log-in system and share the information with policyholders.</li> 
        <li>Claim Support: We call carriers to check the status on filed claim and share the status with policyholders. </li></ul>" 

      } 

    } 

  ] 
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
     // Check and add BreadcrumbList script
  if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
    const breadcrumbScript = this.renderer.createElement('script');
    breadcrumbScript.type = 'application/ld+json';
    breadcrumbScript.setAttribute('data-script', 'breadcrumb');
    breadcrumbScript.text = `
    { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Insurance", 

      "item": "https://www.fbspl.com/services/insurance-bpo-outsourcing" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "Claim Management", 

      "item": "https://www.fbspl.com/services/insurance-claim-management" 

    } 

  ] 

} `;
    this.renderer.appendChild(this.document.head, breadcrumbScript);
  }
  const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/insurance-claim-management`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
