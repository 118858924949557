import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HubspotService } from '../../../services/hubspot/hubspot.service';
import { requiredButNotAllowBlank, customEmailValidator } from '../../../utils/Validator';
import { EmailService } from '../../../services/faq/faq';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
})
export class FaqComponent implements OnInit {
  FaqForm!: FormGroup;
  currentRoute!: string;
  submitted = false;
  successfull = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private hubspotService: HubspotService,
    private emailService: EmailService, // Inject EmailService
  ) {}

  ngOnInit(): void {
    this.getCurrentRoute();

    // Initialize form
    this.FaqForm = this.formBuilder.group({
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      write_your_message: ['', requiredButNotAllowBlank()],
      url: [this.currentRoute],
    });
  }

  get FaqFormf() {
    return this.FaqForm.controls;
  }

  validateForm() {
    this.submitted = true;
    if (this.FaqForm.invalid) {
      return;
    }

    const formId = 'a430154e-4a38-4a35-a5f7-59e72a4b2a59';
    const formData = this.FaqForm.value;
    
    // Submit form data to HubSpot
    this.hubspotService.submitForm(formId, formData).subscribe(
      (response) => {
        console.log('Form submitted successfully:', response);
        this.successfull = true;
        // Send an email after successful submission
        this.emailService.sendEmail(formData).subscribe(
          (emailResponse) => {
            console.log('Email sent successfully:', emailResponse);
          },
          (error) => {
            console.error('Error sending email:', error);
          }
        );
      },
      (error) => {
        console.error('Error submitting form:', error);
        alert('Error submitting form. Please try again.');
      }
    );
  }


  private getCurrentRoute(): void {
    const urlSegments: UrlSegment[] = this.activatedRoute.snapshot.url;
    this.currentRoute = urlSegments.map((segment) => segment.path).join('/');
  }
}
