<div class="container d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/Career/CareerApplyFormBanner.jpg"
      class="desktop-banner"
      alt="Career Opportunities at FBSPL "
    />
    <div class="bannerText">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item">
            <a href="./careers/current-openings">Careers</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Career Apply Form
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary fw-500 pt-3">Career Apply Form</h1>
    </div>
  </div>
</div>
<div class="container text-dark slide d-block d-lg-none pt-5">
  <div class="gradientBannerMob">
    <img
      appCdnUrl="assets/Career/CareerApplyFormMobileBanner.jpg"
      class="card-img-mob"
      alt="Career Opportunities at FBSPL "
    />
    <div class="bannerTextMob">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item">
            <a href="./careers/current-openings">Careers</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Career Apply Form
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary fw-500 pt-3">Career Apply Form</h1>
    </div>
  </div>
</div>
<div id="top" #top></div>
<section class="py-6 container position-relative d-none d-md-block">
  <div
    class="progress"
    role="progressbar"
    aria-label="Basic example"
    [attr.aria-valuenow]="(currentFormIndex / 3) * 100"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <div class="progress-bar" [style.width]="getProgressWidth()"></div>
  </div>
  <div class="row position-relative z-1">
    <div class="col-12 col-md-3">
      <div
        class="logo-wrapper mx-auto"
        [ngClass]="{
          active: currentFormIndex === 0
        }"
      >
        <img appCdnUrl="assets/Icon/positionform.svg" class="svg-logo" />
      </div>
      <h3 class="text-light font-34-19 mt-3 text-center">Position Details</h3>
    </div>
    <div class="col-12 col-md-3">
      <div
        class="logo-wrapper mx-auto"
        [ngClass]="{
          active: currentFormIndex === 1
        }"
      >
        <img appCdnUrl="assets/Icon/personalform.svg" class="svg-logo" />
      </div>
      <h3 class="text-light font-34-19 mt-3 text-center">Personal Details</h3>
    </div>
    <div class="col-12 col-md-3">
      <div
        class="logo-wrapper mx-auto"
        [ngClass]="{
          active: currentFormIndex === 2
        }"
      >
        <img appCdnUrl="assets/Icon/educationform.svg" class="svg-logo" />
      </div>
      <h3 class="text-light font-34-19 mt-3 text-center">Education Details</h3>
    </div>
    <div class="col-12 col-md-3">
      <div
        class="logo-wrapper mx-auto"
        [ngClass]="{
          active: currentFormIndex === 3
        }"
      >
        <img appCdnUrl="assets/Icon/workform.svg" class="svg-logo" />
      </div>
      <h3 class="text-light font-34-19 mt-3 text-center">Work Experience</h3>
    </div>
  </div>
</section>

<section class="container py-6">
  @if (currentFormIndex === 0) {
  <div class="col-12 my-4 d-md-none">
    <div class="logo-wrapper mx-auto sm-logo-wrapper active">
      <img appCdnUrl="assets/Icon/positionform.svg" class="svg-logo" />
    </div>
    <h3 class="text-light font-34-19 mt-3 text-center">Position Details</h3>
  </div>
  <form
    autocomplete="off"
    [formGroup]="PositionDetails"
    (ngSubmit)="validateForm(PositionDetails)"
  >
    <div class="row d-flex align-items-center">
      <div class="col-md-6 col-lg-4 my-2">
        <input
          type="text"
          class="lets-form"
          readonly
          placeholder="Department"
          id="deptType"
          formControlName="deptType"
          [ngClass]="{
            'is-invalid': submitted && PositionDetailsf['deptType'].errors
          }"
        />
        @if (submitted && PositionDetailsf['deptType'].errors) {
        <div class="invalid-feedback">
          @if (PositionDetailsf['deptType'].errors['required']) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 my-2">
        <input
          type="text"
          class="lets-form"
          readonly
          placeholder="Poistion"
          id="position"
          formControlName="PositionApplied"
          [ngClass]="{
            'is-invalid':
              submitted && PositionDetailsf['PositionApplied'].errors
          }"
        />
        @if (submitted && PositionDetailsf['PositionApplied'].errors) {
        <div class="invalid-feedback">
          @if (PositionDetailsf['PositionApplied'].errors['required']) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 my-2">
        <p class="font-20 mb-1">Willingness for night shift?</p>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="WillingnessforNightShiftYes"
            formControlName="WillingnessforNightShift"
            value="Yes"
            [ngClass]="{
              'is-invalid':
                (submitted &&
                  PositionDetailsf['WillingnessforNightShift'].errors) ||
                PositionDetailsf['WillingnessforNightShift'].value === 'No'
            }"
          />
          <label
            class="form-check-label font-20"
            for="WillingnessforNightShiftYes"
            >Yes</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="WillingnessforNightShiftNo"
            formControlName="WillingnessforNightShift"
            value="No"
            [ngClass]="{
              'is-invalid':
                submitted && PositionDetailsf['WillingnessforNightShift'].errors
            }"
          />
          <label
            class="form-check-label font-20"
            for="WillingnessforNightShiftNo"
            >No</label
          >
        </div>
        @if ( submitted && PositionDetailsf['WillingnessforNightShift'].errors )
        {
        <div class="invalid-feedback d-block">
          @if ( PositionDetailsf['WillingnessforNightShift'].errors['required']
          ) {
          <div>Please indicate your willingness for night shift</div>
          }
        </div>
        } @if (PositionDetailsf['WillingnessforNightShift'].value === 'No') {
        <div class="invalid-feedback d-block">
          Unfortunately, currently we don't have openings for the day shift.
        </div>
        }
      </div>
      @if (!this.erpData!.campaignDetail) {
      <div class="col-md-6 col-lg-4 my-2">
        <select
          class="form-select lets-form"
          id="SourceOfInformationType"
          formControlName="SourceOfInformationType"
          [ngClass]="{
            'is-invalid':
              submitted && PositionDetailsf['SourceOfInformationType'].errors
          }"
          aria-label="Source of Information"
        >
          <option value="" disabled selected hidden>
            How did you know about this opening?
          </option>
          <option value="Job Portals">Job Portals</option>
          <option value="Social Media">Social Media</option>
          <option value="Walk-In">Walk-In</option>
          <option value="Referral">Referral</option>
          <option value="Campus">Campus</option>
          <option value="Job Fair">Job Fair</option>
          <option value="Billboard">Billboard</option>
          <option value="Consultancy">Consultancy</option>
          <option value="FBSPL Academy">FBSPL Academy</option>
        </select>
        @if ( submitted && PositionDetailsf['SourceOfInformationType'].errors )
        {
        <div class="invalid-feedback">
          @if ( PositionDetailsf['SourceOfInformationType'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      }
    </div>
    @if ( PositionDetails.get('SourceOfInformationType')!.value === 'Job Portals' ) {
    <div class="row">
      <div class="col-md-6 col-lg-4 my-2">
        <select
          class="form-select lets-form"
          id="jobportalname"
          formControlName="jobportalname"
          [ngClass]="{
            'is-invalid': submitted && PositionDetailsf['jobportalname'].errors
          }"
          aria-label="Source of Information"
        >
          <option value="" disabled selected hidden>
            Name of the Job Portal?
          </option>
          <option value="Naukri.com">Naukri.com</option>
          <option value="Indeed.com">Indeed.com</option>
          <option value="Linkedin.com">Linkedin.com</option>
          <option value="Other">Other</option>
        </select>
        @if (submitted && PositionDetailsf['jobportalname'].errors) {
        <div class="invalid-feedback">
          @if ( PositionDetailsf['jobportalname'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
    </div>
    } @if ( PositionDetails.get('SourceOfInformationType')!.value === 'Social Media' ) {
    <div class="row">
      @if (!this.erpData!.campaignDetail) {
      <div class="col-md-6 col-lg-4 my-2">
        <select
          class="form-select lets-form"
          id="socialmedianame"
          formControlName="socialmedianame"
          [ngClass]="{
            'is-invalid':
              submitted && PositionDetailsf['socialmedianame'].errors
          }"
          aria-label="Source of Information"
        >
          <option value="" disabled selected hidden>
            Name of the Social Media?
          </option>
          <option value="Facebook.com">Facebook.com</option>
          <option value="Linkedin.com">Linkedin.com</option>
          <option value="Instagram.com">Instagram.com</option>
        </select>
        @if (submitted && PositionDetailsf['socialmedianame'].errors) {
        <div class="invalid-feedback">
          @if ( PositionDetailsf['socialmedianame'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      }
    </div>
    } @if ( PositionDetails.get('SourceOfInformationType')!.value === 'Referral'
    ) {
    <div class="row">
      <div class="col-md-6 col-lg-4 my-2">
        <input
          type="text"
          class="lets-form"
          formControlName="R1Person"
          placeholder="Referral Name"
          [ngClass]="{
            'is-invalid': submitted && PositionDetailsf['R1Person'].errors
          }"
        />
        @if (submitted && PositionDetailsf['R1Person'].errors) {
        <div class="invalid-feedback">
          @if ( PositionDetailsf['R1Person'].errors['requiredButNotAllowBlank']
          ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 my-2">
        <input
          type="text"
          class="lets-form"
          formControlName="R1Designation"
          placeholder="Designation at Fusion"
          [ngClass]="{
            'is-invalid': submitted && PositionDetailsf['R1Designation'].errors
          }"
        />
        @if (submitted && PositionDetailsf['R1Designation'].errors) {
        <div class="invalid-feedback">
          @if ( PositionDetailsf['R1Designation'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 my-2">
        <input
          type="text"
          class="lets-form"
          formControlName="R1Contact"
          placeholder="Phone"
          minlength="10"
          maxlength="10"
          [ngClass]="{
            'is-invalid': submitted && PositionDetailsf['R1Contact'].errors
          }"
        />
        @if (submitted && PositionDetailsf['R1Contact'].errors) {
        <div class="invalid-feedback">
          @if ( PositionDetailsf['R1Contact'].errors['requiredButNotAllowBlank']
          ) {
          <div>*This Field is Required</div>
          } @if ( !PositionDetailsf['R1Contact'].errors[
          'requiredButNotAllowBlank' ] &&
          PositionDetailsf['R1Contact'].errors['exactLength'] ) {
          <div>Invalid Phone</div>
          }
        </div>
        }
      </div>
    </div>
    } @if ( PositionDetails.get('SourceOfInformationType')!.value === 'Campus' )
    {
    <div class="row">
      <div class="col-md-6 col-lg-4 my-2">
        <input
          type="text"
          class="lets-form"
          formControlName="campusname"
          placeholder="Name of the Campus"
          [ngClass]="{
            'is-invalid': submitted && PositionDetailsf['campusname'].errors
          }"
        />
        @if (submitted && PositionDetailsf['campusname'].errors) {
        <div class="invalid-feedback">
          @if ( PositionDetailsf['campusname'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 my-2">
        <input
          type="text"
          class="lets-form"
          formControlName="campuslocation"
          placeholder="Location of the Campus"
          [ngClass]="{
            'is-invalid': submitted && PositionDetailsf['campuslocation'].errors
          }"
        />
        @if (submitted && PositionDetailsf['campuslocation'].errors) {
        <div class="invalid-feedback">
          @if ( PositionDetailsf['campuslocation'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
    </div>
    }
    <div class="d-flex justify-content-start mt-5">
      <button class="FormCTA" type="submit">
        <h6 class="font-20 text-primary text-md-end">
          Next Step
          <div class="headingCTA">
            <div class="blueCTA"></div>
          </div>
        </h6>
      </button>
    </div>
  </form>
  } @if (currentFormIndex === 1) {
  <div class="col-12 my-4 d-md-none">
    <div class="logo-wrapper mx-auto sm-logo-wrapper active">
      <img appCdnUrl="assets/Icon/personalform.svg" class="svg-logo" />
    </div>
    <h3 class="text-light font-34-19 mt-3 text-center">Personal Details</h3>
  </div>
  <form
    autocomplete="off"
    [formGroup]="PersonalDetails"
    (ngSubmit)="validateForm(PersonalDetails)"
  >
    <div class="row">
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="firstName"
          autocomplete="off"
          appNoNumbers
          placeholder="First Name"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['firstName'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['firstName'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['firstName'].errors['requiredButNotAllowBlank']
          ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="lastName"
          placeholder="Last Name"
          appNoNumbers
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['lastName'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['lastName'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['lastName'].errors['requiredButNotAllowBlank']
          ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="fatherName"
          placeholder="Father Name"
          appNoNumbers
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['fatherName'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['fatherName'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['fatherName'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4 ps-4">
        <p class="mb-0">Gender</p>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="gender"
            value="male"
            [ngClass]="{
              'is-invalid': submitted && PersonalDetailsf['gender'].errors
            }"
          />
          <label class="form-check-label font-20">Male</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="gender"
            value="female"
            [ngClass]="{
              'is-invalid': submitted && PersonalDetailsf['gender'].errors
            }"
          />
          <label class="form-check-label font-20">Female</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="gender"
            value="other"
            [ngClass]="{
              'is-invalid': submitted && PersonalDetailsf['gender'].errors
            }"
          />
          <label class="form-check-label font-20">Other</label>
        </div>
        @if (submitted && PersonalDetailsf['gender'].errors) {
        <div class="invalid-feedback d-block">
          @if ( PersonalDetailsf['gender'].errors['requiredButNotAllowBlank'] )
          {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <div class="position-relative">
          <label
            for="dob"
            class="form-place-label"
            (click)="onFocus()"
            [ngClass]="{
              'd-none': isFocused || dobValue
            }"
          >
            Date of Birth
          </label>
          <input
            type="date"
            class="lets-form"
            formControlName="dob"
            [attr.max]="maxDate"
            placeholder="Date"
            (focus)="onFocus()"
            (blur)="onBlur()"
            [(ngModel)]="dobValue"
            (change)="createYearList()"
            [ngClass]="{
              'is-invalid': submitted && PersonalDetailsf['dob'].errors
            }"
          />
          @if (submitted && PersonalDetailsf['dob'].errors) {
          <div class="invalid-feedback">
            @if ( PersonalDetailsf['dob'].errors['requiredButNotAllowBlank'] ) {
            <div>*This Field is Required</div>
            }
          </div>
          }
        </div>
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <select
          class="form-select lets-form"
          formControlName="maritalStatus"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['maritalStatus'].errors
          }"
        >
          <option value="" disabled selected hidden>Marital Status</option>
          <option value="Single">Single</option>
          <option value="Married">Married</option>
          <option value="Widow">Widow</option>
          <option value="Separated">Separated</option>
          <option value="Divorced">Divorced</option>
        </select>
        @if (submitted && PersonalDetailsf['maritalStatus'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['maritalStatus'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <!-- row 3 -->
      @if (PersonalDetails.get('maritalStatus')!.value === 'Married') {
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="spouseName"
          id="spouseName"
          name="spouseName"
          placeholder="Spouse Name"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['spouseName'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['spouseName'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['spouseName'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      }
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="aadhaar"
          id="aadhaar"
          name="aadhaar"
          placeholder="Aadhar Number"
          (input)="formatAadhaar($event)"
          maxlength="14"
          appOnlyNumbers
          [(ngModel)]="aadhaarValue"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['aadhaar'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['aadhaar'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['aadhaar'].errors['requiredButNotAllowBlank'] )
          {
          <div>*This Field is Required</div>
          }
          <!-- <div *ngIf="PersonalDetailsf['aadhaar'].errors?.['minlength']">
                                                          Invalid Aadhar Number
                                                        </div> -->
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="email"
          class="lets-form"
          formControlName="email"
          placeholder="Email Address"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['email'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['email'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['email'].errors['requiredButNotAllowBlank'] ) {
          <div>*This Field is Required</div>
          } @if (PersonalDetailsf['email'].errors['email']) {
          <div>Invalid email address</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="mobile"
          placeholder="Phone Number"
          name="mobile"
          id="mobile"
          minlength="10"
          maxlength="10"
          appOnlyNumbers
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['mobile'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['mobile'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['mobile'].errors['requiredButNotAllowBlank'] )
          {
          <div>*This Field is Required</div>
          } @if (PersonalDetailsf['mobile'].errors?.['minlength']) {
          <div>Invalid phone number</div>
          }
        </div>
        }
      </div>
      <!-- row 4 -->
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="home_phone"
          placeholder="Alternate Number"
          minlength="10"
          maxlength="10"
          appOnlyNumbers
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['home_phone'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['home_phone'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['home_phone'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          } @if (PersonalDetailsf['home_phone'].errors!['minlength']) {
          <div>Invalid phone number</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <div class="position-relative">
          <input
            type="file"
            id="photo-upload"
            class="d-none"
            (change)="onPhotoFileSelected($event)"
          />
          <label
            for="photo-upload"
            class="cursor-pointer lets-form"
            [ngClass]="{
              'is-invalid': submitted && PersonalDetailsf['image_url'].invalid
            }"
          >
            <span>{{ PhotofileName || "Upload Photo" }}</span>
            <img
              appCdnUrl="assets/Icon/upload-svg.svg"
              alt="Upload Icon"
              class="upload-icon"
            />
          </label>
          <!-- Error Messages -->
          @if (submitted && PersonalDetailsf['image_url']?.errors) {
          <div class="invalid-feedback">
            <!-- Invalid file format error -->
            @if
            (PersonalDetailsf['image_url'].errors?.['fileExtensionValidator']) {
            <div>
              *Invalid file format. Only JPG, and PNG files are allowed.
            </div>
            }
            <!-- File size error -->
            @if (PersonalDetailsf['image_url'].errors?.['fileSizeExceeded']) {
            <div>*File size exceeds 2 MB. Please upload a smaller image.</div>
            }
          </div>
          }
        </div>
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <div class="position-relative">
          <input
            type="file"
            id="resume-upload"
            class="d-none"
            (change)="onResumeFileSelected($event)"
          />
          <label
            for="resume-upload"
            class="cursor-pointer lets-form"
            [ngClass]="{
              'is-invalid': submitted && PersonalDetailsf['resume_url'].errors
            }"
          >
            <span>{{ ResumefileName || "Upload Resume" }}</span>
            <img
              appCdnUrl="assets/Icon/upload-svg.svg"
              alt="Upload Icon"
              class="upload-icon"
            />
          </label>
          <!-- Error Messages -->
          @if (submitted && PersonalDetailsf['resume_url']?.errors) {
          <div class="invalid-feedback">
            <!-- Invalid file format error -->
            @if
            (PersonalDetailsf['resume_url'].errors?.['fileExtensionValidator'])
            {
            <div>
              *Invalid file format. Only PDF, DOC, and DOCX files are allowed.
            </div>
            }
            <!-- File size error -->
            @if (PersonalDetailsf['resume_url'].errors?.['fileSize']) {
            <div>
              *File size exceeds the 5 MB limit. Please upload a smaller Resume
            </div>
            }
          </div>
          }
        </div>
      </div>
      <!-- Current Address -->
      <h2 class="text-primary pt-5 font-48-30">Current Address</h2>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="currentAddress"
          placeholder="Street Address"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['currentAddress'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['currentAddress'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['currentAddress'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <select
          class="form-select lets-form"
          formControlName="State"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['State'].errors
          }"
        >
          <option value="" disabled selected hidden>State / UT</option>
          @for (state of stateList; track state) {
          <option [value]="state">
            {{ state }}
          </option>
          }
        </select>
        @if (submitted && PersonalDetailsf['State'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['State'].errors['requiredButNotAllowBlank'] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="City"
          placeholder="City"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['City'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['City'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['City'].errors['requiredButNotAllowBlank'] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="pin"
          appOnlyNumbers
          minlength="6"
          maxlength="6"
          placeholder="Zip Code"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['pin'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['pin'].errors) {
        <div class="invalid-feedback">
          @if (PersonalDetailsf['pin'].errors['requiredButNotAllowBlank']) {
          <div>*This Field is Required</div>
          } @if (PersonalDetailsf['pin'].errors?.['minlength']) {
          <div>Invalid Zip Code</div>
          }
        </div>
        }
      </div>
      <!-- Permanent Address -->
      <h2 class="text-primary pt-5 font-48-30">Permanent Address</h2>
      <div class="col-12 ms-3 pt-2">
        <div class="px-2 form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="permanentAddressSame"
            value="yes"
          />
          <label class="form-check-label font-20">Same as Current</label>
        </div>
      </div>
      <!-- row 5 -->
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="permanentAddress"
          placeholder="Street Address"
          [ngClass]="{
            'is-invalid':
              submitted && PersonalDetailsf['permanentAddress'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['permanentAddress'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['permanentAddress'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <select
          class="form-select lets-form"
          formControlName="permanentState"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['permanentState'].errors
          }"
        >
          <option value="" disabled selected hidden>State / UT</option>
          @for (state of stateList; track state) {
          <option [value]="state">
            {{ state }}
          </option>
          }
        </select>
        @if (submitted && PersonalDetailsf['permanentState'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['permanentState'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="permanentCity"
          placeholder="City"
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['permanentCity'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['permanentCity'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['permanentCity'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          formControlName="permanentPin"
          placeholder="Zip Code"
          appOnlyNumbers
          minlength="6"
          maxlength="6"
          appOnlyNumbers
          [ngClass]="{
            'is-invalid': submitted && PersonalDetailsf['permanentPin'].errors
          }"
        />
        @if (submitted && PersonalDetailsf['permanentPin'].errors) {
        <div class="invalid-feedback">
          @if ( PersonalDetailsf['permanentPin'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          } @if (PersonalDetailsf['permanentPin'].errors?.['minlength']) {
          <div>Invalid Zip Code</div>
          }
        </div>
        }
      </div>
    </div>
    <div class="d-flex justify-content-end mt-5">
      <button class="FormCTA">
        <h6 class="font-20 text-md-start PreviousBtn" (click)="goToBackForm()">
          Previous Step
          <div class="headingCTA">
            <div class="blueCTA m-0"></div>
          </div>
        </h6>
      </button>
      <button class="FormCTA" type="submit">
        <h6 class="font-20 text-primary text-md-start">
          Next Step
          <div class="headingCTA">
            <div class="blueCTA"></div>
          </div>
        </h6>
      </button>
    </div>
  </form>
  } @if (currentFormIndex === 2) {
  <div class="col-12 my-4 d-md-none">
    <div class="logo-wrapper mx-auto sm-logo-wrapper active">
      <img appCdnUrl="assets/Icon/educationform.svg" class="svg-logo" />
    </div>
    <h3 class="text-light font-34-19 mt-3 text-center">Education Details</h3>
  </div>
  <form
    autocomplete="off"
    [formGroup]="EducationDetails"
    (ngSubmit)="validateForm(EducationDetails)"
  >
    <div class="row">
      <h2 class="text-primary pt-5 font-48-30">10th Standard</h2>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          placeholder="School Name"
          formControlName="SchoolName10th"
          appNoNumbers
          [ngClass]="{
            'is-invalid':
              submitted && EducationDetailsf['SchoolName10th'].errors
          }"
        />
        @if (submitted && EducationDetailsf['SchoolName10th'].errors) {
        <div class="invalid-feedback">
          @if ( EducationDetailsf['SchoolName10th'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          placeholder="Board"
          appNoNumbers
          formControlName="Board10th"
          [ngClass]="{
            'is-invalid': submitted && EducationDetailsf['Board10th'].errors
          }"
        />
        @if (submitted && EducationDetailsf['Board10th'].errors) {
        <div class="invalid-feedback">
          @if ( EducationDetailsf['Board10th'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          appDecimalNumbers
          class="lets-form"
          placeholder="Percentage"
          formControlName="Percentage10th"
          [ngClass]="{
            'is-invalid':
              submitted && EducationDetailsf['Percentage10th'].errors
          }"
        />
        @if (submitted && EducationDetailsf['Percentage10th'].errors) {
        <div class="invalid-feedback">
          @if (EducationDetailsf['Percentage10th'].errors['required']) {
          <div>*This Field is Required</div>
          } @if (!EducationDetailsf['Percentage10th'].errors['required'] &&
          EducationDetailsf['Percentage10th'].errors?.['numberGreaterThan45']) {
          <div>
            Unfortunately, you are not eligible. The eligibility criteria is 45
            or above percentage.
          </div>
          } @if ( EducationDetailsf['Percentage10th'].errors?.['maxPercentage']
          ) {
          <div>The value cannot exceed 100.</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <select
          class="form-select lets-form"
          formControlName="YearofPassing10th"
          [ngClass]="{
            'is-invalid':
              submitted && EducationDetailsf['YearofPassing10th'].errors
          }"
        >
          <option value="" disabled selected hidden>Year of Passing</option>
          @for (year of yearsList; track year) {
          <option [value]="year">
            {{ year }}
          </option>
          }
        </select>
        @if (submitted && EducationDetailsf['YearofPassing10th'].errors) {
        <div class="invalid-feedback">
          @if ( EducationDetailsf['YearofPassing10th'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <h2 class="text-primary pt-5 font-48-30">12th Standard</h2>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          placeholder="School Name"
          appNoNumbers
          formControlName="SchoolName12th"
          [ngClass]="{
            'is-invalid':
              submitted && EducationDetailsf['SchoolName12th'].errors
          }"
        />
        @if (submitted && EducationDetailsf['SchoolName12th'].errors) {
        <div class="invalid-feedback">
          @if ( EducationDetailsf['SchoolName12th'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          appNoNumbers
          placeholder="Board"
          formControlName="Board12th"
          [ngClass]="{
            'is-invalid': submitted && EducationDetailsf['Board12th'].errors
          }"
        />
        @if (submitted && EducationDetailsf['Board12th'].errors) {
        <div class="invalid-feedback">
          @if ( EducationDetailsf['Board12th'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          appDecimalNumbers
          class="lets-form"
          placeholder="Percentage"
          formControlName="Percentage12th"
          [ngClass]="{
            'is-invalid':
              submitted && EducationDetailsf['Percentage12th'].errors
          }"
        />
        @if (submitted && EducationDetailsf['Percentage12th'].errors) {
        <div class="invalid-feedback">
          @if (EducationDetailsf['Percentage12th'].errors['required']) {
          <div>*This Field is Required</div>
          } @if (!EducationDetailsf['Percentage12th'].errors['required'] &&
          EducationDetailsf['Percentage12th'].errors?.['numberGreaterThan45']) {
          <div>
            Unfortunately, you are not eligible. The eligibility criteria is 45
            or above percentage.
          </div>
          } @if ( EducationDetailsf['Percentage12th'].errors?.['maxPercentage']
          ) {
          <div>The value cannot exceed 100.</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <select
          class="form-select lets-form"
          formControlName="YearofPassing12th"
          [ngClass]="{
            'is-invalid':
              submitted && EducationDetailsf['YearofPassing12th'].errors
          }"
        >
          <option value="" disabled selected hidden>Year of Passing</option>
          @for (year of filteredYearsList('YearofPassing10th'); track year) {
          <option [value]="year">
            {{ year }}
          </option>
          }
        </select>
        @if (submitted && EducationDetailsf['YearofPassing12th'].errors) {
        <div class="invalid-feedback">
          @if ( EducationDetailsf['YearofPassing12th'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <h2 class="text-primary pt-5 font-48-30">Graduation</h2>
      <div class="col-md-6 col-lg-4 pt-4">
        <select
          class="form-select lets-form"
          formControlName="GraduationDegree"
          [ngClass]="{
            'is-invalid':
              submitted && EducationDetailsf['GraduationDegree'].errors
          }"
        >
          <option value="" disabled selected hidden>Degree</option>
          <option value="BA">Bachelor of Arts - B.A.</option>
          <option value="BBA">
            Bachelor of Business Administration - B.B.A.
          </option>
          <option value="BCOM">Bachelor of Commerce - B.Com.</option>
          <option value="BCA">
            Bachelor of Computer Applications - B.C.A.
          </option>
          <option value="BE">
            Bachelor of Engineering / Bachelor of Technology - B.E./B.Tech.
          </option>
          <option value="BFA">Bachelor of Fine Arts - BFA / BVA</option>
          <option value="BSC">Bachelor of Science - B.Sc.</option>
          <option value="Diploma">Diploma (10+2)</option>
          <option value="Diploma12th">After 12th Diploma</option>
          <option value="Other">Other</option>
        </select>
        @if (submitted && EducationDetailsf['GraduationDegree'].errors) {
        <div class="invalid-feedback">
          @if (EducationDetailsf['GraduationDegree'].errors['required']) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4 ps-4">
        <p class="font-20">Status of Completion</p>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="Graduation_Status_of_Completion"
            value="Completed"
            [ngClass]="{
              'is-invalid':
                submitted &&
                EducationDetailsf['Graduation_Status_of_Completion'].errors
            }"
          />
          <label class="form-check-label font-20">Completed</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="Graduation_Status_of_Completion"
            value="Result Awaited"
            [ngClass]="{
              'is-invalid':
                submitted &&
                EducationDetailsf['Graduation_Status_of_Completion'].errors
            }"
          />
          <label class="form-check-label font-20">Result Awaited</label>
        </div>
        @if ( submitted &&
        EducationDetailsf['Graduation_Status_of_Completion'].errors ) {
        <div class="invalid-feedback d-block">
          @if ( EducationDetailsf['Graduation_Status_of_Completion'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      @if ( EducationDetails.get('Graduation_Status_of_Completion')!.value ===
      'Completed' ) {
      <div>
        <h2 class="text-primary pt-5 font-48-30">If Completed?</h2>
        <div class="row">
          <div class="col-md-6 col-lg-4 pt-4">
            <input
              type="text"
              class="lets-form"
              appNoNumbers
              placeholder="University/College"
              appNoNumbers
              formControlName="GBoard"
              [ngClass]="{
                'is-invalid': submitted && EducationDetailsf['GBoard'].errors
              }"
            />
            @if (submitted && EducationDetailsf['GBoard'].errors) {
            <div class="invalid-feedback">
              @if ( EducationDetailsf['GBoard'].errors[
              'requiredButNotAllowBlank' ] ) {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 col-lg-4 pt-4">
            <input
              type="text"
              class="lets-form"
              appDecimalNumbers
              placeholder="Graduation Percentage"
              formControlName="GPercentage"
              [ngClass]="{
                'is-invalid':
                  submitted && EducationDetailsf['GPercentage'].errors
              }"
            />
            @if (submitted && EducationDetailsf['GPercentage'].errors) {
            <div class="invalid-feedback">
              @if (EducationDetailsf['GPercentage'].errors['required']) {
              <div>*This Field is Required</div>
              } @if (!EducationDetailsf['GPercentage'].errors['required'] &&
              EducationDetailsf['GPercentage'].errors?.['numberGreaterThan45'])
              {
              <div>
                Unfortunately, you are not eligible. The eligibility criteria is
                45 or above percentage.
              </div>
              } @if ( EducationDetailsf['GPercentage'].errors?.['maxPercentage']
              ) {
              <div>The value cannot exceed 100.</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 col-lg-4 pt-4">
            <select
              class="form-select lets-form"
              formControlName="GYearofPassing"
              [ngClass]="{
                'is-invalid':
                  submitted && EducationDetailsf['GYearofPassing'].errors
              }"
            >
              <option value="" disabled selected hidden>Year of Passing</option>
              @for (year of filteredYearsList('YearofPassing12th'); track year)
              {
              <option [value]="year">
                {{ year }}
              </option>
              }
            </select>
            @if (submitted && EducationDetailsf['GYearofPassing'].errors) {
            <div class="invalid-feedback">
              @if ( EducationDetailsf['GYearofPassing'].errors[
              'requiredButNotAllowBlank' ] ) {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
        </div>
      </div>
      } @if ( EducationDetails.get('Graduation_Status_of_Completion')!.value ===
      'Result Awaited' ) {
      <div>
        <h2 class="text-primary pt-5 font-48-30">If Results awaited?</h2>
        <div class="row">
          <div class="col-md-6 col-lg-4 pt-4">
            <input
              type="text"
              class="lets-form"
              placeholder="Semester"
              formControlName="GCurrentSemester"
              appNoNumbers
              readonly
              [ngClass]="{
                'is-invalid':
                  submitted && EducationDetailsf['GCurrentSemester'].errors
              }"
            />
            @if ( submitted && EducationDetailsf['GCurrentSemester'].errors ) {
            <div class="invalid-feedback">
              @if ( EducationDetailsf['GCurrentSemester'].errors[
              'requiredButNotAllowBlank' ] ) {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 col-lg-4 pt-4">
            <div class="position-relative">
              <label
                for="GCurrentPassingYear"
                class="form-place-label"
                (click)="onFocus()"
                [ngClass]="{
                  'd-none':
                    isFocused || EducationDetailsf['GCurrentPassingYear'].value
                }"
              >
                Tentative Date of Passing
              </label>
              <input
                type="date"
                class="lets-form"
                formControlName="GCurrentPassingYear"
                [attr.min]="minDate"
                placeholder="Date"
                (focus)="onFocus()"
                (blur)="onBlur()"
                [ngClass]="{
                  'is-invalid':
                    submitted && EducationDetailsf['GCurrentPassingYear'].errors
                }"
              />
              @if ( submitted && EducationDetailsf['GCurrentPassingYear'].errors
              ) {
              <div class="invalid-feedback">
                @if ( EducationDetailsf['GCurrentPassingYear'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      }
      <h2 class="text-primary pt-5 font-48-30">Post Graduation</h2>
      <div class="col-md-6 col-lg-4 pt-4 ps-4">
        <p class="mb-0">Have you done post graduation?</p>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="PostGraduation"
            value="yes"
            [ngClass]="{
              'is-invalid':
                submitted && EducationDetailsf['PostGraduation'].errors
            }"
          />
          <label class="form-check-label font-20">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="PostGraduation"
            value="no"
            [ngClass]="{
              'is-invalid':
                submitted && EducationDetailsf['PostGraduation'].errors
            }"
          />
          <label class="form-check-label font-20">No</label>
        </div>
        @if (submitted && EducationDetailsf['PostGraduation'].errors) {
        <div class="invalid-feedback d-block">
          @if (EducationDetailsf['PostGraduation'].errors['required']) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      @if (EducationDetails.get('PostGraduation')!.value === 'yes') {
      <div class="row">
        <div class="col-md-6 col-lg-4 pt-4">
          <select
            class="form-select lets-form"
            formControlName="PostGraduationDegree"
            [ngClass]="{
              'is-invalid':
                submitted && EducationDetailsf['PostGraduationDegree'].errors
            }"
          >
            <option value="" disabled selected hidden>Degree</option>
            <option value="MA">Master of Arts (MA)</option>
            <option value="MSC">Master of Science (M.Sc)</option>
            <option value="MCOM">Master of Commerce (M.Com)</option>
            <option value="ME">
              Master of Engineering/Technology (ME/M.Tech)
            </option>
            <option value="MLAW">Master of Law (LLM)</option>
            <option value="MCA">Master of Computer Application (MCA)</option>
            <option value="MBA">Master of Business Administration (MBA)</option>
            <option value="PGM">Post Graduate Program in Management</option>
            <option value="EXM">Executive Management Program</option>
            <option value="MPSE">
              Post Graduate Diploma in Software Engineering
            </option>
            <option value="MPGPEX">
              Post Graduate Programs for Executives (PGPEX)
            </option>
            <option value="Other">Other</option>
          </select>
          @if ( submitted && EducationDetailsf['PostGraduationDegree'].errors )
          {
          <div class="invalid-feedback">
            @if ( EducationDetailsf['PostGraduationDegree'].errors['required'] )
            {
            <div>*This Field is Required</div>
            }
          </div>
          }
        </div>
        <div class="col-md-6 col-lg-4 pt-4 ps-4">
          <p class="font-20">Status of Completion</p>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              formControlName="PostGraduation_Status_of_Completion"
              value="Completed"
              [ngClass]="{
                'is-invalid':
                  submitted &&
                  EducationDetailsf['PostGraduation_Status_of_Completion']
                    .errors
              }"
            />
            <label class="form-check-label font-20">Completed</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              formControlName="PostGraduation_Status_of_Completion"
              value="Result Awaited"
              [ngClass]="{
                'is-invalid':
                  submitted &&
                  EducationDetailsf['PostGraduation_Status_of_Completion']
                    .errors
              }"
            />
            <label class="form-check-label font-20">Result Awaited</label>
          </div>
          @if ( submitted &&
          EducationDetailsf['PostGraduation_Status_of_Completion'].errors ) {
          <div class="invalid-feedback d-block">
            @if ( EducationDetailsf['PostGraduation_Status_of_Completion']
            .errors['requiredButNotAllowBlank'] ) {
            <div>*This Field is Required</div>
            }
          </div>
          }
        </div>
      </div>
      } @if ( EducationDetails.get('PostGraduation_Status_of_Completion')!
      .value === 'Completed' && EducationDetails.get('PostGraduation')!.value
      === 'yes' ) {
      <div>
        <h2 class="text-primary pt-5 font-48-30">If Completed?</h2>
        <div class="row">
          <div class="col-md-6 col-lg-4 pt-4">
            <input
              type="text"
              class="lets-form"
              placeholder="University/College"
              appNoNumbers
              formControlName="PBoard"
              [ngClass]="{
                'is-invalid': submitted && EducationDetailsf['PBoard'].errors
              }"
            />
            @if (submitted && EducationDetailsf['PBoard'].errors) {
            <div class="invalid-feedback">
              @if ( EducationDetailsf['PBoard'].errors[
              'requiredButNotAllowBlank' ] ) {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 col-lg-4 pt-4">
            <input
              type="text"
              appDecimalNumbers
              class="lets-form"
              placeholder="Graduation Percentage"
              formControlName="PPercentage"
              [ngClass]="{
                'is-invalid':
                  submitted && EducationDetailsf['PPercentage'].errors
              }"
            />
            @if (submitted && EducationDetailsf['PPercentage'].errors) {
            <div class="invalid-feedback">
              @if (EducationDetailsf['PPercentage'].errors['required']) {
              <div>*This Field is Required</div>
              } @if (!EducationDetailsf['PPercentage'].errors['required'] &&
              EducationDetailsf['PPercentage'].errors?.['numberGreaterThan45'])
              {
              <div>
                Unfortunately, you are not eligible. The eligibility criteria is
                45 or above percentage.
              </div>
              } @if ( EducationDetailsf['PPercentage'].errors?.['maxPercentage']
              ) {
              <div>The value cannot exceed 100.</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 col-lg-4 pt-4">
            <select
              class="form-select lets-form"
              formControlName="PYearofPassing"
              [ngClass]="{
                'is-invalid':
                  submitted && EducationDetailsf['PYearofPassing'].errors
              }"
            >
              <option value="" disabled selected hidden>Year of Passing</option>
              @for (year of filteredYearsList('GYearofPassing'); track year) {
              <option [value]="year">
                {{ year }}
              </option>
              }
            </select>
            @if (submitted && EducationDetailsf['PYearofPassing'].errors) {
            <div class="invalid-feedback">
              @if ( EducationDetailsf['PYearofPassing'].errors[
              'requiredButNotAllowBlank' ] ) {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
        </div>
      </div>
      } @if ( EducationDetails.get('PostGraduation_Status_of_Completion')!
      .value === 'Result Awaited' &&
      EducationDetails.get('PostGraduation')!.value === 'yes' ) {
      <div>
        <h2 class="text-primary pt-5 font-48-30">If Results awaited?</h2>
        <div class="row">
          <div class="col-md-6 col-lg-4 pt-4">
            <input
              type="text"
              class="lets-form"
              placeholder="Semester"
              formControlName="PCurrentSemester"
              readonly
              [ngClass]="{
                'is-invalid':
                  submitted && EducationDetailsf['PCurrentSemester'].errors
              }"
            />
            @if ( submitted && EducationDetailsf['PCurrentSemester'].errors ) {
            <div class="invalid-feedback">
              @if ( EducationDetailsf['PCurrentSemester'].errors[
              'requiredButNotAllowBlank' ] ) {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 col-lg-4 pt-4">
            <div class="position-relative">
              <label
                for="PCurrentPassingYear"
                class="form-place-label"
                (click)="onFocus()"
                [ngClass]="{
                  'd-none':
                    isFocused || EducationDetailsf['PCurrentPassingYear'].value
                }"
              >
                Tentative Date of Passing
              </label>
              <input
                type="date"
                class="lets-form"
                formControlName="PCurrentPassingYear"
                [attr.min]="minDate"
                placeholder="Date"
                (focus)="onFocus()"
                (blur)="onBlur()"
                [ngClass]="{
                  'is-invalid':
                    submitted && EducationDetailsf['PCurrentPassingYear'].errors
                }"
              />
              @if ( submitted && EducationDetailsf['PCurrentPassingYear'].errors
              ) {
              <div class="invalid-feedback">
                @if ( EducationDetailsf['PCurrentPassingYear'].errors[
                'requiredButNotAllowBlank' ] ) {
                <div>*This Field is Required</div>
                }
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      }
      <h2 class="text-primary pt-5 font-48-30">Certificates/Diploma/Courses</h2>
      <div class="col-md-6 col-lg-4 pt-4 ps-4">
        <p class="mb-0">Do you have any other Courses?</p>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="otherCouseEnrolled"
            value="Yes"
            [ngClass]="{
              'is-invalid':
                submitted && EducationDetailsf['otherCouseEnrolled'].errors
            }"
          />
          <label class="form-check-label font-20">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            formControlName="otherCouseEnrolled"
            value="No"
            [ngClass]="{
              'is-invalid':
                submitted && EducationDetailsf['otherCouseEnrolled'].errors
            }"
          />
          <label class="form-check-label font-20">No</label>
        </div>
        @if (submitted && EducationDetailsf['otherCouseEnrolled'].errors) {
        <div class="invalid-feedback d-block">
          @if (EducationDetailsf['otherCouseEnrolled'].errors['required']) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      @if (EducationDetails.get('otherCouseEnrolled')!.value === 'Yes') {
      <div class="row">
        <div class="col-md-6 col-lg-4 pt-4">
          <input
            type="text"
            class="lets-form"
            placeholder="Name"
            appNoNumbers
            formControlName="otherCourses"
            [ngClass]="{
              'is-invalid':
                submitted && EducationDetailsf['otherCourses'].errors
            }"
          />
          @if (submitted && EducationDetailsf['otherCourses'].errors) {
          <div class="invalid-feedback">
            @if ( EducationDetailsf['otherCourses'].errors[
            'requiredButNotAllowBlank' ] ) {
            <div>*This Field is Required</div>
            }
          </div>
          }
        </div>
        <div class="col-md-6 col-lg-4 pt-4 ps-4">
          <p class="font-20">Status of Completion</p>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              formControlName="OStatus"
              value="Completed"
              [ngClass]="{
                'is-invalid': submitted && EducationDetailsf['OStatus'].errors
              }"
            />
            <label class="form-check-label font-20">Completed</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              formControlName="OStatus"
              value="Result Awaited"
              [ngClass]="{
                'is-invalid': submitted && EducationDetailsf['OStatus'].errors
              }"
            />
            <label class="form-check-label font-20">Result Awaited</label>
          </div>
          @if (submitted && EducationDetailsf['OStatus'].errors) {
          <div class="invalid-feedback d-block">
            @if ( EducationDetailsf['OStatus'].errors[
            'requiredButNotAllowBlank' ] ) {
            <div>*This Field is Required</div>
            }
          </div>
          }
        </div>
      </div>
      }
      <div class="d-flex justify-content-end mt-5">
        <button class="FormCTA">
          <h6
            class="font-20 text-md-start PreviousBtn"
            (click)="goToBackForm()"
          >
            Previous Step
            <div class="headingCTA">
              <div class="blueCTA m-0"></div>
            </div>
          </h6>
        </button>
        <button class="FormCTA" type="submit">
          <h6 class="font-20 text-primary text-md-start">
            Next Step
            <div class="headingCTA">
              <div class="blueCTA"></div>
            </div>
          </h6>
        </button>
      </div>
    </div>
  </form>
  } @if (currentFormIndex === 3) {
  <div class="col-12 my-4 d-md-none">
    <div class="logo-wrapper mx-auto sm-logo-wrapper active">
      <img appCdnUrl="assets/Icon/workform.svg" class="svg-logo" />
    </div>
    <h3 class="text-light font-34-19 mt-3 text-center">Work Details</h3>
  </div>
  <form
    autocomplete="off"
    [formGroup]="WorkExperience"
    (ngSubmit)="validateForm(WorkExperience)"
  >
    <h2 class="text-primary pt-5 font-48-30">Working Experience</h2>
    <div class="col-md-6 col-lg-4 pt-4 ps-4">
      <p class="font-20">Working Experience</p>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          formControlName="isExperienced"
          value="Yes"
          [ngClass]="{
            'is-invalid': submitted && WorkExperiencef['isExperienced'].errors
          }"
        />
        <label class="form-check-label font-20">Yes</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          formControlName="isExperienced"
          value="No"
          [ngClass]="{
            'is-invalid': submitted && WorkExperiencef['isExperienced'].errors
          }"
        />
        <label class="form-check-label font-20">No</label>
      </div>
      @if (submitted && WorkExperiencef['isExperienced'].errors) {
      <div class="invalid-feedback d-block">
        @if (WorkExperiencef['isExperienced'].errors['required']) {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    @if (WorkExperience.get('isExperienced')!.value === 'Yes') {
    <div class="row">
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          placeholder="Company Name"
          formControlName="WE1CompanyName"
          [ngClass]="{
            'is-invalid': submitted && WorkExperiencef['WE1CompanyName'].errors
          }"
        />
        @if (submitted && WorkExperiencef['WE1CompanyName'].errors) {
        <div class="invalid-feedback">
          @if ( WorkExperiencef['WE1CompanyName'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          placeholder="Department"
          formControlName="WE1Departement"
          [ngClass]="{
            'is-invalid': submitted && WorkExperiencef['WE1Departement'].errors
          }"
        />
        @if (submitted && WorkExperiencef['WE1Departement'].errors) {
        <div class="invalid-feedback">
          @if ( WorkExperiencef['WE1Departement'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          placeholder="Roles and Responsibilities"
          formControlName="WE1RoleandResposibilities"
          [ngClass]="{
            'is-invalid':
              submitted && WorkExperiencef['WE1RoleandResposibilities'].errors
          }"
        />
        @if ( submitted && WorkExperiencef['WE1RoleandResposibilities'].errors )
        {
        <div class="invalid-feedback">
          @if ( WorkExperiencef['WE1RoleandResposibilities'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          placeholder="Last Drawn Montlhy CTC"
          appOnlyNumbers
          formControlName="WE1LastDrawnSalary"
          (input)="
            limitInputLength($event, WorkExperience, 'WE1LastDrawnSalary', 9)
          "
          [ngClass]="{
            'is-invalid':
              submitted && WorkExperiencef['WE1LastDrawnSalary'].errors
          }"
        />
        @if (submitted && WorkExperiencef['WE1LastDrawnSalary'].errors) {
        <div class="invalid-feedback">
          @if ( WorkExperiencef['WE1LastDrawnSalary'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
      <div class="col-md-6 col-lg-4 pt-4">
        <input
          type="text"
          class="lets-form"
          placeholder="Experience (In Years)"
          appOnlyNumbers
          formControlName="WE1ExperienceInYears"
          (input)="
            limitInputLength($event, WorkExperience, 'WE1ExperienceInYears', 2)
          "
          [ngClass]="{
            'is-invalid':
              submitted && WorkExperiencef['WE1ExperienceInYears'].errors
          }"
        />
        @if (submitted && WorkExperiencef['WE1ExperienceInYears'].errors) {
        <div class="invalid-feedback">
          @if ( WorkExperiencef['WE1ExperienceInYears'].errors[
          'requiredButNotAllowBlank' ] ) {
          <div>*This Field is Required</div>
          }
        </div>
        }
      </div>
    </div>
    }
    <h2 class="text-primary pt-5 font-48-30">
      Have you previously worked with FBSPL?
    </h2>
    <div class="col-md-6 col-lg-4 pt-4 ps-4">
      <p class="font-20">Working Experience</p>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          formControlName="previouslyWorked"
          value="Yes"
          [ngClass]="{
            'is-invalid':
              submitted && WorkExperiencef['previouslyWorked'].errors
          }"
        />
        <label class="form-check-label font-20">Yes</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          formControlName="previouslyWorked"
          value="No"
          [ngClass]="{
            'is-invalid':
              submitted && WorkExperiencef['previouslyWorked'].errors
          }"
        />
        <label class="form-check-label font-20">No</label>
      </div>
      @if (submitted && WorkExperiencef['previouslyWorked'].errors) {
      <div class="invalid-feedback d-block">
        @if (WorkExperiencef['previouslyWorked'].errors['required']) {
        <div>*This Field is Required</div>
        }
      </div>
      }
    </div>
    <div class="d-flex justify-content-end mt-5">
      <button class="FormCTA">
        <h6 class="font-20 text-md-start PreviousBtn" (click)="goToBackForm()">
          Previous Step
          <div class="headingCTA">
            <div class="blueCTA m-0"></div>
          </div>
        </h6>
      </button>
      <button class="FormCTA" type="submit">
        <h6 class="font-20 text-primary text-md-start">
          Submit
          <div class="headingCTA">
            <div class="blueCTA"></div>
          </div>
        </h6>
      </button>
    </div>
  </form>
  }
</section>
