import { Component, OnInit,Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { ServiceCaseStudyComponent } from '../../../../../components/HomeComponents/service-case-study/service-case-study.component';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-financial-reporting',
  standalone: true,
  templateUrl: './financial-reporting.component.html',
  styleUrl: './financial-reporting.component.css',
  imports: [FaqComponent, KnowBestComponent, ServiceCaseStudyComponent, CdnUrlDirective,ourAssetsComponent],
})
export class FinancialReportingComponent implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService,   private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object,) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Improve Your Financial Reporting with FBSPL',
      description:
        'From accurate data analysis to detailed reporting, we handle your financial report management for bookkeeping and accounting. Check out our services today.',
      keywords: 'financial reporting',
      ogImage: '/accoutning Financial Reporting/Main-banner.jpg'
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/accounting-and-financial-reporting-services' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
           {  

      "@type": "Question",  

      "name": "What is the month-end close process?",  

      "acceptedAnswer": {  

        "@type": "Answer",  

        "text": "The month-end close process is a crucial process that is done at the end of each month to ensure accurate and timely financial reporting. It involves several steps, including reconciling accounts, reviewing transactions, adjusting entries, preparing financial statements, and analyzing performance."  

      }  

    },  

    {  

      "@type": "Question",  

      "name": "What types of tools do you use for accounting and bookkeeping processes including financial reporting?",  

      "acceptedAnswer": {  

        "@type": "Answer",  

        "text": "We hold expertise in leveraging multiple types of tools for accounting and bookkeeping, including financial reporting. Here are the major tools we use:<ul><li>QuickBooks</li><li>Zoho</li><li>Odoo</li><li>Sage</li><li>NetSuite</li><li>Xero</li></ul>"  

      }  

    },  

    {  

      "@type": "Question",  

      "name": "Why should I choose you for financial reporting services?",  

      "acceptedAnswer": {  

        "@type": "Answer",  

        "text": "At FBSPL, we make sure to maintain data accuracy and precision while offering financial reporting services. If you are still wondering why we are the best fit for you, here is what sets us apart:<ul><li>All-in-one expertise</li><li>98% data accuracy</li><li>Cost-effective solutions</li><li>Contractual services</li><li>Availability as per client’s time zone</li><li>Tailor-made solutions</li></ul>"  

      }  

    },  

    {  

      "@type": "Question",  

      "name": "Can you describe the process of reviewing and preparing financial statements?",  

      "acceptedAnswer": {  

        "@type": "Answer",  

        "text": "Here is a checklist for financial statement review and preparation:<ul><li>Verify the accuracy of financial documents</li><li>Scrutinize the balance sheet & review the income statement</li><li>Evaluate the cash flow statement</li><li>Compare current statements with previous period statements</li><li>Assess the company's financial performance against industry benchmarks</li><li>Understand the financial ratios and indicators</li><li>Formulate preliminary conclusions and recommendations</li><li>Prepare financial statement review report</li><li>Submit review report to the management</li><li>Review updated financials for compliance with recommendations</li></ul>"  

      }  

    },  

    {  

      "@type": "Question",  

      "name": "Can a non-CPA prepare financial statements using the FRF framework?",  

      "acceptedAnswer": {  

        "@type": "Answer",  

        "text": "Non-CPAs may prepare financial statements using available financial frameworks, including the FRF framework, cash, tax, and even GAAP bases of accounting."  

      }  

    },  

    {  

      "@type": "Question",  

      "name": "Why is GAAP so important?",  

      "acceptedAnswer": {  

        "@type": "Answer",  

        "text": "With GAAP, companies and any entity in the finance sector can ensure consistent functioning. GAAP allows stakeholders to quickly evaluate companies by assessing their financial statements. When an investor is in two minds about companies in the same sector, they can compare those companies' financial statements to make an investment decision. GAAP also helps companies gain key insights into their practices and performances. Moreover, it helps minimize the risk of errors in financial reporting by implementing checks and safeguards at multiple levels during the preparation of financial statements."  

      }  

    }  
        ]
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Accounting & Bookkeeping", 

      "item": "https://www.fbspl.com/services/accounting-bookkeeping-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "Financial Reporting Services", 

      "item": "https://www.fbspl.com/services/accounting-and-financial-reporting-services" 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
  alternateLinkDefault.rel = 'alternate';
  alternateLinkDefault.href = `https://www.fbspl.com`;
  alternateLinkDefault.hreflang = 'x-default';
  this.renderer.appendChild(this.document.head, alternateLinkDefault);

  const alternateLinkEn = this.renderer.createElement('link');
  alternateLinkEn.rel = 'alternate';
  alternateLinkEn.href = `https://www.fbspl.com/services/accounting-and-financial-reporting-services`;
  alternateLinkEn.hreflang = 'en-us';
  this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
  }
}
