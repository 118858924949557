import { Component, OnInit, Renderer2, SecurityContext  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { SeoService } from '../../../../src/app/services/seo/seo.service';
import { SeoData } from '../../../../src/app/services/seo/seo-data.model';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  customEmailValidator,
  fileExtensionValidator,
} from '../../../../src/app/utils/Validator';
import { HubspotService } from '../../../../src/app/services/hubspot/hubspot.service';
import { FormApiService } from '../../../../src/app/services/formApi/formApi.service';
import { HttpClient } from '@angular/common/http';
import { Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NoNumbersDirective } from '../../../../src/app/directives/noNumbers.directive';
import { OnlyNumbersDirective } from '../../../../src/app/directives/onlyNumbers.directive';
import { MediaUploadService } from '../../../../src/app/services/mediaUpload/mediaUpload.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CdnUrlDirective } from '../../../../src/app/directives/cdn-url.directive';
import { environment } from '../../../../src/environments/environment';
import { EmailService } from '../../../../src/app/services/Contact_us/Contact_us';
import { Meta, Title } from '@angular/platform-browser';

type SlideType =
  | 'Services'
  | 'Careers'
  | 'Alliances & Partnerships'
  | 'Website Feedback'
  | 'Media & General Query';

interface Content {
  image: string;
  parra: string;
}

@Component({
  selector: 'app-contactUs',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    NoNumbersDirective,
    OnlyNumbersDirective,
    CdnUrlDirective,
  ],
  templateUrl: './contactUs.component.html',
  styleUrls: ['./contactUs.component.css'],
})
export class ContactUsComponent implements OnInit {
  cdnUrl = environment.cdnUrl;
  outSourcingService!: FormGroup;
  career!: FormGroup;
  AlliancesPartnerships!: FormGroup;
  WebsiteFeedback!: FormGroup;
  MediaGeneralQuery!: FormGroup;
  submitted = false;
  selectedCountryCode: string = '+91'; // Default country code set to India (+91)
  countryList: { name: string; code: string }[] = []; // Country list
  ResumefileName: string | null = null;
  ResumefilePath: string = '';
  safeResumeUrl!: SafeUrl;
  allowedResumeExtensions = ['pdf', 'doc', 'docx'];

  constructor(
    private meta: Meta,
    private seoService: SeoService,
    private formBuilder: FormBuilder,
    private hubspotService: HubspotService,
    private formApiService: FormApiService,
    private router: Router,
    private http: HttpClient,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
    private uploadService: MediaUploadService,
    private sanitizer: DomSanitizer,
    private emailService: EmailService // Inject EmailService
  ) {}

  ngOnInit(): void {
    const seoData: SeoData = {
      title: 'Contact FBSPL | Get in Touch with Us',
      description:
        'Reach out to FBSPL for inquiries or support. Our team is here to assist with your business process outsourcing (BPO) and management needs. Contact us today to learn more!',
      keywords: 'contact, get in touch',
      ogImage: '/contact-us/ContactUs.jpg',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/contact-us' });
    this.initializeForms(); // Initialize forms

    // Initialize country list dynamically from a static JSON or API
    this.addJsonLdScript();
    this.countryList = this.getCountryList();
    if (isPlatformBrowser(this.platformId)) {
      this.http
        .get(`${this.cdnUrl}assets/contact-us/mapNew.svg`, {
          responseType: 'text',
        })
        .subscribe((svg) => {
          const svgContainer = this.renderer.createElement('div');
          svgContainer.innerHTML = svg;

          // Append the svgContainer to the desired parent element
          document.getElementById('svg-container')!.appendChild(svgContainer);

          // Access the SVG element and set its width and height to 100%
          const svgElement = svgContainer.querySelector('svg');
          if (svgElement) {
            svgElement.style.width = '100%';
            svgElement.style.height = '100%';
          }

          this.addInteractivity();
        });
    }
  }
  addInteractivity() {
    if (isPlatformBrowser(this.platformId)) {
    const pointer1 = document.getElementById('pointer1');
    const box1 = document.getElementById('box1');
    const arrow1 = document.getElementById('arrow1');
    const head1 = document.getElementById('head1');
    const para1 = document.getElementById('para1');
    const pointer2 = document.getElementById('pointer2');
    const box2 = document.getElementById('box2');
    const arrow2 = document.getElementById('arrow2');
    const box21 = document.getElementById('box21');
    const arrow21 = document.getElementById('arrow21');
    const head2 = document.getElementById('head2');
    const para2 = document.getElementById('para2');
    const pointer3 = document.getElementById('pointer3');
    const box3 = document.getElementById('box3');
    const arrow3 = document.getElementById('arrow3');
    const head3 = document.getElementById('head3');
    const para3 = document.getElementById('para3');
    const pointer4 = document.getElementById('pointer4');
    const box4 = document.getElementById('box4');
    const arrow4 = document.getElementById('arrow4');
    const head4 = document.getElementById('head4');
    const para4 = document.getElementById('para4');
    const point1 = document.getElementById('point1');
    const point2 = document.getElementById('point2');
    const point3 = document.getElementById('point3');
    const point4 = document.getElementById('point4');
    const poi1 = document.getElementById('poi1');
    const poi2 = document.getElementById('poi2');
    const poi3 = document.getElementById('poi3');
    const poi4 = document.getElementById('poi4');
    if (
      pointer1 !== null &&
      point1 !== null &&
      box1 !== null &&
      arrow1 !== null &&
      para1 !== null &&
      head1 !== null &&
      poi1 !== null
    ) {
      pointer1.addEventListener('mouseover', (e) => {
        box1.style.display = 'block';
        arrow1.style.display = 'block';
        para1.style.display = 'block';
        head1.style.display = 'block';
      });
      point1.addEventListener('mouseover', (e) => {
        box1.style.display = 'block';
        arrow1.style.display = 'block';
        para1.style.display = 'block';
        head1.style.display = 'block';
      });
      box1.addEventListener('mouseover', (e) => {
        box1.style.display = 'none';
        arrow1.style.display = 'none';
        para1.style.display = 'none';
        head1.style.display = 'none';
      });
      arrow1.addEventListener('mouseover', (e) => {
        box1.style.display = 'none';
        arrow1.style.display = 'none';
        para1.style.display = 'none';
        head1.style.display = 'none';
      });
      para1.addEventListener('mouseover', (e) => {
        box1.style.display = 'none';
        arrow1.style.display = 'none';
        para1.style.display = 'none';
        head1.style.display = 'none';
      });
      head1.addEventListener('mouseover', (e) => {
        box1.style.display = 'none';
        arrow1.style.display = 'none';
        para1.style.display = 'none';
        head1.style.display = 'none';
      });
      poi1.addEventListener('mouseout', (e) => {
        box1.style.display = 'none';
        arrow1.style.display = 'none';
        para1.style.display = 'none';
        head1.style.display = 'none';
      });
    }

    if (
      pointer1 !== null &&
      box1 !== null &&
      arrow1 !== null &&
      para1 !== null &&
      head1 !== null
    ) {
      pointer1.addEventListener('mouseout', (e) => {
        box1.style.display = 'none';
        arrow1.style.display = 'none';
        para1.style.display = 'none';
        head1.style.display = 'none';
      });
    }

    // for the first pointer

    if (
      pointer2 !== null &&
      point2 !== null &&
      box2 !== null &&
      arrow2 !== null &&
      para2 !== null &&
      head2 !== null &&
      poi2 !== null
    ) {
      pointer2.addEventListener('mouseover', (e) => {
        box2.style.display = 'block';
        arrow2.style.display = 'block';
        para2.style.display = 'block';
        head2.style.display = 'block';
      });
      point2.addEventListener('mouseover', (e) => {
        box2.style.display = 'block';
        arrow2.style.display = 'block';
        para2.style.display = 'block';
        head2.style.display = 'block';
      });
      box2.addEventListener('mouseover', (e) => {
        box2.style.display = 'none';
        arrow2.style.display = 'none';
        para2.style.display = 'none';
        head2.style.display = 'none';
      });
      arrow2.addEventListener('mouseover', (e) => {
        box2.style.display = 'none';
        arrow2.style.display = 'none';
        para2.style.display = 'none';
        head2.style.display = 'none';
      });
      para2.addEventListener('mouseover', (e) => {
        box2.style.display = 'none';
        arrow2.style.display = 'none';
        para2.style.display = 'none';
        head2.style.display = 'none';
      });
      head2.addEventListener('mouseover', (e) => {
        box2.style.display = 'none';
        arrow2.style.display = 'none';
        para2.style.display = 'none';
        head2.style.display = 'none';
      });
      poi2.addEventListener('mouseout', (e) => {
        box2.style.display = 'none';
        arrow2.style.display = 'none';
        para2.style.display = 'none';
        head2.style.display = 'none';
      });
    }

    if (
      pointer2 !== null &&
      box2 !== null &&
      arrow2 !== null &&
      box21 !== null &&
      arrow21 !== null &&
      para2 !== null &&
      head2 !== null
    ) {
      pointer2.addEventListener('mouseout', (e) => {
        box2.style.display = 'none';
        arrow2.style.display = 'none';
        para2.style.display = 'none';
        head2.style.display = 'none';
        box21.style.display = 'none';
        arrow21.style.display = 'none';
      });
    }

    // for the second pointer

    if (
      pointer3 !== null &&
      point3 !== null &&
      poi3 !== null &&
      box3 !== null &&
      arrow3 !== null &&
      para3 !== null &&
      head3 !== null
    ) {
      pointer3.addEventListener('mouseover', (e) => {
        box3.style.display = 'block';
        arrow3.style.display = 'block';
        para3.style.display = 'block';
        head3.style.display = 'block';
      });
      point3.addEventListener('mouseover', (e) => {
        box3.style.display = 'block';
        arrow3.style.display = 'block';
        para3.style.display = 'block';
        head3.style.display = 'block';
      });
      box3.addEventListener('mouseover', (e) => {
        box3.style.display = 'none';
        arrow3.style.display = 'none';
        para3.style.display = 'none';
        head3.style.display = 'none';
      });
      arrow3.addEventListener('mouseover', (e) => {
        box3.style.display = 'none';
        arrow3.style.display = 'none';
        para3.style.display = 'none';
        head3.style.display = 'none';
      });
      para3.addEventListener('mouseover', (e) => {
        box3.style.display = 'none';
        arrow3.style.display = 'none';
        para3.style.display = 'none';
        head3.style.display = 'none';
      });
      head3.addEventListener('mouseover', (e) => {
        box3.style.display = 'none';
        arrow3.style.display = 'none';
        para3.style.display = 'none';
        head3.style.display = 'none';
      });
      poi3.addEventListener('mouseout', (e) => {
        box3.style.display = 'none';
        arrow3.style.display = 'none';
        para3.style.display = 'none';
        head3.style.display = 'none';
      });
    }

    if (
      pointer3 !== null &&
      box3 !== null &&
      arrow3 !== null &&
      para3 !== null &&
      head3 !== null
    ) {
      pointer3.addEventListener('mouseout', (e) => {
        box3.style.display = 'none';
        arrow3.style.display = 'none';
        para3.style.display = 'none';
        head3.style.display = 'none';
      });
    }

    // for the third pointer

    if (
      pointer4 !== null &&
      point4 !== null &&
      poi4 !== null &&
      box4 !== null &&
      arrow4 !== null &&
      para4 !== null &&
      head4 !== null
    ) {
      pointer4.addEventListener('mouseover', (e) => {
        box4.style.display = 'block';
        arrow4.style.display = 'block';
        para4.style.display = 'block';
        head4.style.display = 'block';
      });
      point4.addEventListener('mouseover', (e) => {
        box4.style.display = 'block';
        arrow4.style.display = 'block';
        para4.style.display = 'block';
        head4.style.display = 'block';
      });
      box4.addEventListener('mouseover', (e) => {
        box4.style.display = 'none';
        arrow4.style.display = 'none';
        para4.style.display = 'none';
        head4.style.display = 'none';
      });
      arrow4.addEventListener('mouseover', (e) => {
        box4.style.display = 'none';
        arrow4.style.display = 'none';
        para4.style.display = 'none';
        head4.style.display = 'none';
      });
      para4.addEventListener('mouseover', (e) => {
        box4.style.display = 'none';
        arrow4.style.display = 'none';
        para4.style.display = 'none';
        head4.style.display = 'none';
      });
      head4.addEventListener('mouseover', (e) => {
        box4.style.display = 'none';
        arrow4.style.display = 'none';
        para4.style.display = 'none';
        head4.style.display = 'none';
      });
      poi4.addEventListener('mouseout', (e) => {
        box4.style.display = 'none';
        arrow4.style.display = 'none';
        para4.style.display = 'none';
        head4.style.display = 'none';
      });
    }

    if (
      pointer4 !== null &&
      box4 !== null &&
      arrow4 !== null &&
      para4 !== null &&
      head4 !== null
    ) {
      pointer4.addEventListener('mouseout', (e) => {
        box4.style.display = 'none';
        arrow4.style.display = 'none';
        para4.style.display = 'none';
        head4.style.display = 'none';
      });
    }
  }
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    // Check and add BreadcrumbList script
  const alternateLinkDefault = this.renderer.createElement('link');

      alternateLinkDefault.rel = 'alternate';
      alternateLinkDefault.href = `https://www.fbspl.com`;
      alternateLinkDefault.hreflang = 'x-default';
      this.renderer.appendChild(this.document.head, alternateLinkDefault);

      const alternateLinkEn = this.renderer.createElement('link');
      alternateLinkEn.rel = 'alternate';
      alternateLinkEn.href = `https://www.fbspl.com/contact-us`;
      alternateLinkEn.hreflang = 'en-us';
      this.renderer.appendChild(this.document.head, alternateLinkEn);
    }
  }
  // Function to initialize all forms
  initializeForms() {
    this.outSourcingService = this.formBuilder.group({
      firstname: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      countrycode: ['+91', Validators.required], // Default country selection is India (+91)
      phone: [
        '',
        [
          requiredButNotAllowBlank(),
          Validators.minLength(10),
          Validators.maxLength(15),
          Validators.pattern(/^\d+$/), // Ensure only digits
        ],
      ],
      state:['',requiredButNotAllowBlank()],
      country:['',requiredButNotAllowBlank()],
      where_did_you_find_us: ['', requiredButNotAllowBlank()],
      write_message_to_us: ['', requiredButNotAllowBlank()],
    });

    this.career = this.formBuilder.group({
      name: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      phone: [
        '',
        [
          requiredButNotAllowBlank(),
          Validators.minLength(10),
          Validators.maxLength(15),
          Validators.pattern(/^\d+$/), // Ensure only digits
        ],
      ],
      state:['',requiredButNotAllowBlank()],
      country:['',requiredButNotAllowBlank()],
      whereUDoFindUs: ['', requiredButNotAllowBlank()],
      whereDidYouGotToKnow: ['', requiredButNotAllowBlank()],
      resume: ['', [requiredButNotAllowBlank()]], // Simplified validation
    });

    this.AlliancesPartnerships = this.formBuilder.group({
      name: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      position: ['', requiredButNotAllowBlank()],
      companyName: ['', requiredButNotAllowBlank()],
      countrycode: ['', Validators.required],
      industry: ['', requiredButNotAllowBlank()],
      partnership: ['', requiredButNotAllowBlank()],
      partnerProgram: ['', requiredButNotAllowBlank()],
      motivationToPartner: ['', requiredButNotAllowBlank()],
    });

    this.MediaGeneralQuery = this.formBuilder.group({
      name: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      countrycode: ['+91', Validators.required], // Default country selection is India (+91)
      phone: [
        '',
        [
          requiredButNotAllowBlank(),
          Validators.minLength(10),
          Validators.maxLength(15),
          Validators.pattern(/^\d+$/), // Ensure only digits
        ],
      ],
      state:['',requiredButNotAllowBlank()],
      country:['',requiredButNotAllowBlank()],
      whereUFindUs: ['', requiredButNotAllowBlank()],
      message: ['', requiredButNotAllowBlank()],
    });

    this.WebsiteFeedback = this.formBuilder.group({
      name: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      isFirstVisit: ['', Validators.required],
      FindYourNeed: ['', Validators.required],
      feedback: ['', requiredButNotAllowBlank()],
    });
  }



  // This function simulates loading country codes from a JSON or API endpoint
  getCountryList(): { name: string; code: string }[] {
    return [
      { name: 'AFG', code: '+93' },
      { name: 'ALB', code: '+355' },
      { name: 'DZA', code: '+213' },
      { name: 'ASM', code: '+1 684' },
      { name: 'AND', code: '+376' },
      { name: 'AGO', code: '+244' },
      { name: 'AIA', code: '+1 264' },
      { name: 'ATA', code: '+672' },
      { name: 'ATG', code: '+1 268' },
      { name: 'ARG', code: '+54' },
      { name: 'ARM', code: '+374' },
      { name: 'ABW', code: '+297' },
      { name: 'SHN', code: '+247' },  // Ascension Island is part of Saint Helena
      { name: 'AUS', code: '+61' },
      { name: 'AUT', code: '+43' },
      { name: 'AZE', code: '+994' },
      { name: 'BHS', code: '+1 242' },
      { name: 'BHR', code: '+973' },
      { name: 'BGD', code: '+880' },
      { name: 'BRB', code: '+1 246' },
      { name: 'BLR', code: '+375' },
      { name: 'BEL', code: '+32' },
      { name: 'BLZ', code: '+501' },
      { name: 'BEN', code: '+229' },
      { name: 'BMU', code: '+1 441' },
      { name: 'BTN', code: '+975' },
      { name: 'BOL', code: '+591' },
      { name: 'BIH', code: '+387' },
      { name: 'BWA', code: '+267' },
      { name: 'BRA', code: '+55' },
      { name: 'VGB', code: '+1 284' },
      { name: 'BRN', code: '+673' },
      { name: 'BGR', code: '+359' },
      { name: 'BFA', code: '+226' },
      { name: 'MMR', code: '+95' },
      { name: 'BDI', code: '+257' },
      { name: 'KHM', code: '+855' },
      { name: 'CMR', code: '+237' },
      { name: 'CAN', code: '+1' },
      { name: 'CPV', code: '+238' },
      { name: 'CYM', code: '+1 345' },
      { name: 'CAF', code: '+236' },
      { name: 'TCD', code: '+235' },
      { name: 'CHL', code: '+56' },
      { name: 'CHN', code: '+86' },
      { name: 'CXR', code: '+61' },
      { name: 'CCK', code: '+61' },
      { name: 'COL', code: '+57' },
      { name: 'COM', code: '+269' },
      { name: 'COG', code: '+242' },
      { name: 'COK', code: '+682' },
      { name: 'CRI', code: '+506' },
      { name: 'HRV', code: '+385' },
      { name: 'CUB', code: '+53' },
      { name: 'CYP', code: '+357' },
      { name: 'CZE', code: '+420' },
      { name: 'COD', code: '+243' },
      { name: 'DNK', code: '+45' },
      { name: 'DGA', code: '+246' },
      { name: 'DJI', code: '+253' },
      { name: 'DMA', code: '+1 767' },
      { name: 'DOM', code: '+1 809' },
      { name: 'ECU', code: '+593' },
      { name: 'EGY', code: '+20' },
      { name: 'SLV', code: '+503' },
      { name: 'GNQ', code: '+240' },
      { name: 'ERI', code: '+291' },
      { name: 'EST', code: '+372' },
      { name: 'ETH', code: '+251' },
      { name: 'FLK', code: '+500' },
      { name: 'FRO', code: '+298' },
      { name: 'FJI', code: '+679' },
      { name: 'FIN', code: '+358' },
      { name: 'FRA', code: '+33' },
      { name: 'GUF', code: '+594' },
      { name: 'PYF', code: '+689' },
      { name: 'GAB', code: '+241' },
      { name: 'GMB', code: '+220' },
      { name: 'GEO', code: '+995' },
      { name: 'DEU', code: '+49' },
      { name: 'GHA', code: '+233' },
      { name: 'GIB', code: '+350' },
      { name: 'GRC', code: '+30' },
      { name: 'GRL', code: '+299' },
      { name: 'GRD', code: '+1 473' },
      { name: 'GLP', code: '+590' },
      { name: 'GUM', code: '+1 671' },
      { name: 'GTM', code: '+502' },
      { name: 'GIN', code: '+224' },
      { name: 'GNB', code: '+245' },
      { name: 'GUY', code: '+592' },
      { name: 'HTI', code: '+509' },
      { name: 'VAT', code: '+39' },
      { name: 'HND', code: '+504' },
      { name: 'HKG', code: '+852' },
      { name: 'HUN', code: '+36' },
      { name: 'ISL', code: '+354' },
      { name: 'IND', code: '+91' },
      { name: 'IDN', code: '+62' },
      { name: 'IRN', code: '+98' },
      { name: 'IRQ', code: '+964' },
      { name: 'IRL', code: '+353' },
      { name: 'IMN', code: '+44' },
      { name: 'ISR', code: '+972' },
      { name: 'ITA', code: '+39' },
      { name: 'CIV', code: '+225' },
      { name: 'JAM', code: '+1 876' },
      { name: 'JPN', code: '+81' },
      { name: 'JEY', code: '+44' },
      { name: 'JOR', code: '+962' },
      { name: 'KAZ', code: '+7' },
      { name: 'KEN', code: '+254' },
      { name: 'KIR', code: '+686' },
      { name: 'KWT', code: '+965' },
      { name: 'KGZ', code: '+996' },
      { name: 'LAO', code: '+856' },
      { name: 'LVA', code: '+371' },
      { name: 'LBN', code: '+961' },
      { name: 'LSO', code: '+266' },
      { name: 'LBR', code: '+231' },
      { name: 'LBY', code: '+218' },
      { name: 'LIE', code: '+423' },
      { name: 'LTU', code: '+370' },
      { name: 'LUX', code: '+352' },
      { name: 'MAC', code: '+853' },
      { name: 'MKD', code: '+389' },
      { name: 'MDG', code: '+261' },
      { name: 'MWI', code: '+265' },
      { name: 'MYS', code: '+60' },
      { name: 'MDV', code: '+960' },
      { name: 'MLI', code: '+223' },
      { name: 'MLT', code: '+356' },
      { name: 'MHL', code: '+692' },
      { name: 'MTQ', code: '+596' },
      { name: 'MRT', code: '+222' },
      { name: 'MUS', code: '+230' },
      { name: 'MYT', code: '+262' },
      { name: 'MEX', code: '+52' },
      { name: 'FSM', code: '+691' },
      { name: 'MDA', code: '+373' },
      { name: 'MCO', code: '+377' },
      { name: 'MNG', code: '+976' },
      { name: 'MNE', code: '+382' },
      { name: 'MSR', code: '+1 664' },
      { name: 'MAR', code: '+212' },
      { name: 'MOZ', code: '+258' },
      { name: 'NAM', code: '+264' },
      { name: 'NRU', code: '+674' },
      { name: 'NPL', code: '+977' },
      { name: 'NLD', code: '+31' },
      { name: 'NCL', code: '+687' },
      { name: 'NZL', code: '+64' },
      { name: 'NIC', code: '+505' },
      { name: 'NER', code: '+227' },
      { name: 'NGA', code: '+234' },
      { name: 'NIU', code: '+683' },
      { name: 'NFK', code: '+672' },
      { name: 'PRK', code: '+850' },
      { name: 'MNP', code: '+1 670' },
      { name: 'NOR', code: '+47' },
      { name: 'OMN', code: '+968' },
      { name: 'PAK', code: '+92' },
      { name: 'PLW', code: '+680' },
      { name: 'PSE', code: '+970' },
      { name: 'PAN', code: '+507' },
      { name: 'PNG', code: '+675' },
      { name: 'PRY', code: '+595' },
      { name: 'PER', code: '+51' },
      { name: 'PHL', code: '+63' },
      { name: 'PCN', code: '+870' },
      { name: 'POL', code: '+48' },
      { name: 'PRT', code: '+351' },
      { name: 'PRI', code: '+1 787' },
      { name: 'QAT', code: '+974' },
      { name: 'COG', code: '+242' },
      { name: 'REU', code: '+262' },
      { name: 'ROU', code: '+40' },
      { name: 'RUS', code: '+7' },
      { name: 'RWA', code: '+250' },
      { name: 'BLM', code: '+590' },
      { name: 'SHN', code: '+290' },
      { name: 'KNA', code: '+1 869' },
      { name: 'LCA', code: '+1 758' },
      { name: 'MAF', code: '+590' },
      { name: 'SPM', code: '+508' },
      { name: 'VCT', code: '+1 784' },
      { name: 'WSM', code: '+685' },
      { name: 'SMR', code: '+378' },
      { name: 'STP', code: '+239' },
      { name: 'SAU', code: '+966' },
      { name: 'SEN', code: '+221' },
      { name: 'SRB', code: '+381' },
      { name: 'SYC', code: '+248' },
      { name: 'SLE', code: '+232' },
      { name: 'SGP', code: '+65' },
      { name: 'SXM', code: '+1 721' },
      { name: 'SVK', code: '+421' },
      { name: 'SVN', code: '+386' },
      { name: 'SLB', code: '+677' },
      { name: 'SOM', code: '+252' },
      { name: 'ZAF', code: '+27' },
      { name: 'KOR', code: '+82' },
      { name: 'SSD', code: '+211' },
      { name: 'ESP', code: '+34' },
      { name: 'LKA', code: '+94' },
      { name: 'SDN', code: '+249' },
      { name: 'SUR', code: '+597' },
      { name: 'SWZ', code: '+268' },
      { name: 'SWE', code: '+46' },
      { name: 'CHE', code: '+41' },
      { name: 'SYR', code: '+963' },
      { name: 'TWN', code: '+886' },
      { name: 'TJK', code: '+992' },
      { name: 'TZA', code: '+255' },
      { name: 'THA', code: '+66' },
      { name: 'TLS', code: '+670' },
      { name: 'TGO', code: '+228' },
      { name: 'TKL', code: '+690' },
      { name: 'TON', code: '+676' },
      { name: 'TTO', code: '+1 868' },
      { name: 'TUN', code: '+216' },
      { name: 'TUR', code: '+90' },
      { name: 'TKM', code: '+993' },
      { name: 'TCA', code: '+1 649' },
      { name: 'TUV', code: '+688' },
      { name: 'UGA', code: '+256' },
      { name: 'UKR', code: '+380' },
      { name: 'ARE', code: '+971' },
      { name: 'GBR', code: '+44' },
      { name: 'USA', code: '+1' },
      { name: 'URY', code: '+598' },
      { name: 'VIR', code: '+1 340' },
      { name: 'UZB', code: '+998' },
      { name: 'VUT', code: '+678' },
      { name: 'VEN', code: '+58' },
      { name: 'VNM', code: '+84' },
      { name: 'WLF', code: '+681' },
      { name: 'ESH', code: '+212' },
      { name: 'YEM', code: '+967' },
      { name: 'ZMB', code: '+260' },
      { name: 'ZWE', code: '+263' },
    ];
  }

  // Accessor for form controls
  get LetsConnectFormf() {
    return this.outSourcingService.controls;
  }

  // Handler for country selection change
  onCountryChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCountryCode = selectElement.value;
  }

  onResumeFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (
        fileExtension === 'pdf' ||
        fileExtension === 'doc' ||
        fileExtension === 'docx'
      ) {
        this.ResumefileName = this.trimFileName(file.name);
        this.uploadService.uploadFile(file).subscribe(
          (response) => {
            this.ResumefilePath = response.media;
            this.safeResumeUrl = this.sanitizer.bypassSecurityTrustUrl(
              this.ResumefilePath
            );
            const urlString =
              this.sanitizer.sanitize(
                SecurityContext.URL,
                this.safeResumeUrl
              ) || '';
            this.career.patchValue({
              resume: urlString,
            });
            console.log('Upload successful:', response);
          },
          (error) => {
            console.error('Upload failed:', error);
          }
        );
      } else {
        this.career.controls['resume'].setErrors({ invalidExtension: true });
      }
    }
  }

  trimFileName(fileName: string): string {
    const parts = fileName.split('.');
    const extension = parts.pop();
    const namePart = parts.join('.');
    const trimmedFileName = namePart.slice(0, 10) + '...' + extension;
    return trimmedFileName;
  }

  get outSourcingServicef() {
    return this.outSourcingService.controls;
  }
  get careerf() {
    return this.career.controls;
  }
  get AlliancesPartnershipsf() {
    return this.AlliancesPartnerships.controls;
  }
  get WebsiteFeedbackf() {
    return this.WebsiteFeedback.controls;
  }
  get MediaGeneralQueryf() {
    return this.MediaGeneralQuery.controls;
  }

  activeSlide: SlideType = 'Services';

  updateContent(slide: SlideType) {
    this.submitted = false;
    this.activeSlide = slide;
  }

  validateForm(form: FormGroup): void {
    this.submitted = true;
    if (form.invalid) {
      return;
    }
// Concatenate country code and phone number
const fullPhoneNumber = `${this.selectedCountryCode}${form.value.phone}`;

// Prepare the form data
const formData = {
  ...form.value,
  phone: fullPhoneNumber, // Replace phone field with concatenated value
};

    // const formData = form.value;
    if (form === this.outSourcingService) {
      const formId = '2f5a8da1-3c4a-4747-891f-b28919b43450';
      delete formData.countrycode;
      this.hubspotService.submitForm(formId, formData).subscribe(
        (response) => {
          console.log('Form submitted successfully:', response);
          this.router.navigate(['/services/thankyou']);
          // Send an email after successful submission
          this.emailService.sendEmail(formData).subscribe(
            (emailResponse) => {
              console.log('Email sent successfully:', emailResponse);
            },
            (error) => {
              console.error('Error sending email:', error);
            }
          );
        },
        (error) => {
          console.error('Error submitting form:', error);
          alert('Error submitting form. Please try again.');
        }
      );
    } else if (form === this.career) {
      this.formApiService.submitCareerForm(formData).subscribe(
        (response) => {
          console.log('Form submitted successfully:', response);
          this.router.navigate(['/careers/thankyou']);
        },
        (error) => {
          console.error('Error submitting form:', error);
          alert('Error submitting form. Please try again.');
        }
      );
    } else if (form === this.AlliancesPartnerships) {
      this.formApiService.submitAlliancesPartnershipsForm(formData).subscribe(
        (response) => {
          console.log('Form submitted successfully:', response);
          this.router.navigate([
            '/alliances-and-partnership-with-fbspl/thankyou',
          ]);
        },
        (error) => {
          console.error('Error submitting form:', error);
          alert('Error submitting form. Please try again.');
        }
      );
    } else if (form === this.MediaGeneralQuery) {
      this.formApiService.submitMediaGeneralQueryForm(formData).subscribe(
        (response) => {
          console.log('Form submitted successfully:', response);
          this.router.navigate(['/media-and-general-query/thankyou']);
        },
        (error) => {
          console.error('Error submitting form:', error);
          alert('Error submitting form. Please try again.');
        }
      );
    } else if (form === this.WebsiteFeedback) {
      this.formApiService.submitWebsiteFeedbackForm(formData).subscribe(
        (response) => {
          console.log('Form submitted successfully:', response);
          this.router.navigate(['/website-feedback/thankyou']);
        },
        (error) => {
          console.error('Error submitting form:', error);
          alert('Error submitting form. Please try again.');
        }
      );
    }
  }
}



// Custom Validator Functions
function requiredButNotAllowBlank(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (
      value === null ||
      value === undefined ||
      (typeof value === 'string' && value.trim() === '') ||
      value.length < 3
    ) {
      return { requiredButNotAllowBlank: true }; // Custom error key
    }
    return null;
  };
}
