import {
  Component,
  AfterViewInit,
  OnInit,
  Inject,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RouterLink } from '@angular/router';
import bodymovin from 'lottie-web';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { DOCUMENT } from '@angular/common';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { environment } from '../../../environments/environment';
import errorAnimation from '../../../assets/Animations/404.json';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [RouterLink, CdnUrlDirective],
  templateUrl: './error.component.html',
  styleUrl: './error.component.css',
})
export class ErrorComponent implements OnInit, AfterViewInit {
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
   this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Page Not Found | 404 Error',
      description:
        "Oops! The page you are looking for does not exist. Please check the URL or return to our homepage for more information. We're here to help you find what you need.",
      keywords: '404 error, Page not found,Missing page',
      ogImage: '/meta/404.jpg',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/404' });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Animation initialization code
      const animationInline1 = bodymovin.loadAnimation({
        container: document.getElementById('animationError') as HTMLElement,
        autoplay: true,
        renderer: 'svg',
        loop: true,
        animationData: errorAnimation,
      });
    }
    if (isPlatformBrowser(this.platformId)) {
      // Declare the dataLayer property on the window object
      (window as any).dataLayer = (window as any).dataLayer || [];

      // Push the event to dataLayer
      (window as any).dataLayer.push({
        event: 'PageNotFound',
        errorType: '404 Error',
      });
    }
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
  const alternateLinkDefault = this.renderer.createElement('link');
      
      alternateLinkDefault.rel = 'alternate';
      alternateLinkDefault.href = `https://www.fbspl.com`;
      alternateLinkDefault.hreflang = 'x-default';
      this.renderer.appendChild(this.document.head, alternateLinkDefault);
    
      const alternateLinkEn = this.renderer.createElement('link');
      alternateLinkEn.rel = 'alternate';
      alternateLinkEn.href = `https://www.fbspl.com/404`;
      alternateLinkEn.hreflang = 'en-us';
      this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
