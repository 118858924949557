<section class="container py-5">
  <h2 class="text-primary font-48-30" data-aos="slide-up">Trusted Globally</h2>
  <div class="trusted-slider-logo">
    <div class="trusted-slide-track">
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/Softwareworld.svg"
          alt="Recognized by SoftwareWorld"
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/ISO.svg"
          alt="Recognized by ISO 27001 "
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/ISO-2015.svg"
          alt="Recognized by 9001:2015 "
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/Top Developer.svg"
          alt="Recognized by Shopify"
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/TDA.svg"
          alt="Recognized by TDA "
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/Clutch.svg"
          alt="Recognized by Clutch "
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/Softwareworld.svg"
          alt="Recognized by SoftwareWorld"
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/ISO.svg"
          alt="Recognized by ISO 27001"
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/ISO-2015.svg"
          alt="Recognized by 9001:2015 "
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/Top Developer.svg"
          alt="Recognized by Shopify"
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/TDA.svg"
          alt="Recognized by TDA"
        />
      </div>
      <div class="trust-slide">
        <img
          class=""
          appCdnUrl="assets/services/assets-logos/Clutch.svg"
          alt="Recognized by Clutch "
        />
      </div>
    </div>
  </div>
</section>
