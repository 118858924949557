import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  Inject,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import {
  SlickCarouselComponent,
  SlickCarouselModule,
} from 'ngx-slick-carousel';
import { SeoService } from '../../../services/seo/seo.service';
import { SeoData } from '../../../services/seo/seo-data.model';
import { SubGuideService } from '../../../services/subGuide/subGuide.service';
import { NoNumbersDirective } from '../../../directives/noNumbers.directive';
import { OnlyNumbersDirective } from '../../../directives/onlyNumbers.directive';
import { PLATFORM_ID } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { FormApiService } from '../../../services/formApi/formApi.service';
import { HubspotService } from '../../../services/hubspot/hubspot.service';
import {
  requiredButNotAllowBlank,
  customEmailValidator,
} from '../../../utils/Validator';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-guide-page',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    SlickCarouselModule,
    RouterLink,
    NoNumbersDirective,
    OnlyNumbersDirective,
    CdnUrlDirective
  ],
  templateUrl: './guide-page.component.html',
  styleUrl: './guide-page.component.css'
})
export class GuidePageComponent implements OnInit {
  faqData: any[] = []; // Add this property to store FAQ data
  @ViewChild('slickModal') slickModal!: SlickCarouselComponent;
  @ViewChild('htmlContent', { static: true }) htmlContent: ElementRef | undefined;
  CommentForm!: FormGroup;
  SubscribeForm!: FormGroup;
  slug!: string;
  featuredBlogs: any = [];
  relatedBlogs: any = [];
  recentBlogs: any = [];
  BlogData: any = {};
  ApiData: any = {};
  headings: string[] = [];
  submitted = false;
  commentSubmission = false;
  SubscribeFormsubmitted = false;
  subscribeSuccess = false;
  processedHtml: SafeHtml | undefined;
  faqHtml: SafeHtml | undefined; // Added for FAQ HTML

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private SubGuideService: SubGuideService,
    private seoService: SeoService,
    private formApiService: FormApiService,
    private router: Router,
    private hubspotService: HubspotService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document

  ) {}

  hasApiData(): boolean {
    return this.ApiData && Object.keys(this.ApiData).length > 0;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.slug = params.get('slug') || '';
      if (this.slug) {
        this.addJsonScript(this.slug);
        this.loadBlogPost(this.slug);
      } else {
        console.error('Slug is null');
      }
    });
    this.handleBodyClass();
    this.setSeo();
  }

  private handleBodyClass() {
    if (isPlatformBrowser(this.platformId)) {
    let body = document.getElementById('body');
    let url = '';
    if (body !== null) {
      if (url === `/guides`) {
        body.style.overflow = 'hidden';
        debugger;
      } else {
        body.style.overflow = 'visible';
      }
    }
  }
  }

  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    const existingScript = this.document.head.querySelector('script[type="application/ld+json"]');
    if (existingScript) {
      this.renderer.removeChild(this.document.head, existingScript);
    }

    if (this.BlogData?.faq_schema) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';

      try {
        const faqSchemaString = typeof this.BlogData.faq_schema === 'string'
          ? this.BlogData.faq_schema
          : JSON.stringify(this.BlogData.faq_schema);
        JSON.parse(faqSchemaString);

        script.text = faqSchemaString;
        this.renderer.appendChild(this.document.head, script);
        console.log('Added JSON-LD script:', script.text);
      } catch (error) {
        console.error('Failed to stringify or validate faq_schema:', error);
        return;
      }
    } else {
      console.warn('faq_schema is not defined or is invalid.');
    }
  }
  }

  private addArticleJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    const existingScript = this.document.head.querySelector('script[type="application/ld+json"][data-schema="article"]');
    if (existingScript) {
      this.renderer.removeChild(this.document.head, existingScript);
    }

    if (this.BlogData?.article_schema) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.setAttribute('data-schema', 'article');

      try {
        const articleSchemaString = typeof this.BlogData.article_schema === 'string'
          ? this.BlogData.article_schema
          : JSON.stringify(this.BlogData.article_schema);
        JSON.parse(articleSchemaString);

        script.text = articleSchemaString;
        this.renderer.appendChild(this.document.head, script);
        console.log('Added article JSON-LD script:', script.text);
      } catch (error) {
        console.error('Failed to stringify or validate article_schema:', error);
        return;
      }
    } else {
      console.warn('article_schema is not defined or is invalid.');
    }
  }
  }
  private addJsonScript(slug:any) {
    if (isPlatformBrowser(this.platformId)) {
    const alternateLinkDefault = this.renderer.createElement('link');

      alternateLinkDefault.rel = 'alternate';
      alternateLinkDefault.href = `https://www.fbspl.com/guide/${this.slug}`;
      alternateLinkDefault.hreflang = 'x-default';
      this.renderer.appendChild(this.document.head, alternateLinkDefault);

      const alternateLinkEn = this.renderer.createElement('link');
      alternateLinkEn.rel = 'alternate';
      alternateLinkEn.href = `https://www.fbspl.com/guide/${this.slug}`;
      alternateLinkEn.hreflang = 'en-us';
      this.renderer.appendChild(this.document.head, alternateLinkEn);
    }
    }
  get CommentFormf() {
    return this.CommentForm.controls;
  }

  get SubscribeFormf() {
    return this.SubscribeForm.controls;
  }

  initializeForm(): void {
    this.CommentForm = this.formBuilder.group({
      blog_id: this.BlogData.id,
      name: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      mobile: ['', [requiredButNotAllowBlank(), this.exactLengthValidator(10)]],
      comments: ['', requiredButNotAllowBlank()],
    });
    this.SubscribeForm = this.formBuilder.group({
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
    });
  }

  setSeo(): void {
    const seoData: SeoData = {
      title: this.BlogData?.meta_title,
      description: this.BlogData?.meta_description,
      keywords: this.BlogData?.keywords,
      ogImage: this.BlogData?.feature_image,
      author: this.BlogData?.written_by,
    };
    this.seoService.setMetaTags(seoData);
  }

  loadBlogPost(slug: string): void {
    this.SubGuideService.getSubBlogData(slug).subscribe(
      (data) => {
        this.ApiData = data.data;
        this.BlogData = this.ApiData.guide;
        this.featuredBlogs = this.ApiData.featured_guides;
        this.relatedBlogs = this.ApiData.related_guides;
        this.recentBlogs = this.ApiData.recent_guides;
        this.setSeo();
        this.addJsonLdScript(); // Call to add JSON-LD
        this.addArticleJsonLdScript();
        this.initializeForm();
        this.processHtml();
        this.generateFaqHtml(); // Generate FAQ HTML
        this.reinitializeCarousel();
      },
      (error) => {
        if (error.error.status === 0) {
          this.router.navigate(['/guides']);
        }
        console.error('Error fetching blog data', error);
      }
    );
  }

  processHtml(): void {
    const tempDiv = this.renderer.createElement('div');
    this.renderer.setProperty(tempDiv, 'innerHTML', this.BlogData.description);
    console.log(this.BlogData.description);

    const headingTags = tempDiv.querySelectorAll('h2');
    const tocList = this.renderer.createElement('ul');
    let index = 0;
    if (headingTags && headingTags.length > 0) {
      const headingArray = Array.prototype.slice.call(headingTags); // Convert NodeList to Array

      headingArray?.forEach((heading: { textContent: any }) => {
        const id = `heading-${index++}`;
        const hashId = heading.textContent
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .replace(/\s+/g, ' ')
          .trim()
          .split(' ')
          .join('-');
        this.renderer.setAttribute(heading, 'id', hashId);

        const listItem = this.renderer.createElement('li');
        const anchor = this.renderer.createElement('a');

        // Generate the router link
        const currentRoute = this.router.url.split('#')[0]; // Get the current route without fragment
        this.renderer.setAttribute(anchor, 'href', `${currentRoute}#${hashId}`);
        this.renderer.setProperty(
          anchor,
          'textContent',
          heading.textContent || ''
        );
        this.renderer.appendChild(listItem, anchor);
        this.renderer.appendChild(tocList, listItem);
      });

      const firstParagraph = tempDiv.querySelector('p');
      if (firstParagraph) {
        const tocAccordion = this.renderer.createElement('div');
        this.renderer.setAttribute(tocAccordion, 'class', 'accordion my-4');
        this.renderer.setAttribute(tocAccordion, 'id', 'tocAccordion');

        const tocCard = this.renderer.createElement('div');
        this.renderer.setAttribute(
          tocCard,
          'class',
          'accordion-item table-of-content '
        );

        const tocCardHeader = this.renderer.createElement('h2');
        this.renderer.setAttribute(
          tocCardHeader,
          'class',
          'accordion-header px-3'
        );
        this.renderer.setAttribute(tocCardHeader, 'id', 'headingToc');

        const tocButton = this.renderer.createElement('button');
        this.renderer.setAttribute(
          tocButton,
          'class',
          'accordion-button tableOfContentBtn font-34-19 collapsed'
        );
        this.renderer.setAttribute(tocButton, 'type', 'button');
        this.renderer.setAttribute(tocButton, 'id', 'tableOfContentBtn');
        this.renderer.setAttribute(tocButton, 'data-bs-toggle', 'collapse');
        this.renderer.setAttribute(tocButton, 'data-bs-target', '#collapseToc');
        this.renderer.setAttribute(tocButton, 'aria-expanded', 'true');
        this.renderer.setAttribute(tocButton, 'aria-controls', 'collapseToc');
        this.renderer.setProperty(
          tocButton,
          'textContent',
          'Table of Contents'
        );

        this.renderer.appendChild(tocCardHeader, tocButton);
        this.renderer.appendChild(tocCard, tocCardHeader);

        const tocCollapse = this.renderer.createElement('div');
        this.renderer.setAttribute(tocCollapse, 'id', 'collapseToc');
        this.renderer.setAttribute(
          tocCollapse,
          'class',
          'accordion-collapse collapse'
        );
        this.renderer.setAttribute(
          tocCollapse,
          'aria-labelledby',
          'headingToc'
        );
        this.renderer.setAttribute(
          tocCollapse,
          'data-bs-parent',
          '#tocAccordion'
        );

        const tocCardBody = this.renderer.createElement('div');
        this.renderer.setAttribute(tocCardBody, 'class', 'accordion-body');

        this.renderer.appendChild(tocCardBody, tocList);
        this.renderer.appendChild(tocCollapse, tocCardBody);
        this.renderer.appendChild(tocCard, tocCollapse);
        this.renderer.appendChild(tocAccordion, tocCard);

        this.renderer.insertBefore(
          firstParagraph.parentNode,
          tocAccordion,
          firstParagraph.nextSibling
        );
      }

      this.processedHtml = this.sanitizer.bypassSecurityTrustHtml(
        tempDiv.innerHTML
      );
    } else {
      this.processedHtml = this.sanitizer.bypassSecurityTrustHtml(
        tempDiv.innerHTML
      );
    }
  }

  generateFaqHtml(): void {
    if (!this.BlogData.faq) return;

    const tempDiv = this.renderer.createElement('div');

    const faqSection = this.renderer.createElement('section');
    // this.renderer.setAttribute(faqSection, 'class', 'py-6');

    const heading = this.renderer.createElement('h2');
    this.renderer.setAttribute(heading, 'class', 'text-primary font-48-30 mb-4');
    // this.renderer.setProperty(heading, 'textContent', 'Frequently Asked Questions');
    this.renderer.appendChild(faqSection, heading);

    const accordion = this.renderer.createElement('div');
    this.renderer.setAttribute(accordion, 'class', 'accordion');
    this.renderer.setAttribute(accordion, 'id', 'faqAccordion');

    this.BlogData.faq.forEach((faq: any, i: number) => {
      const accordionItem = this.renderer.createElement('div');
      this.renderer.setAttribute(accordionItem, 'class', 'accordion-item');

      const accordionHeader = this.renderer.createElement('h2');
      this.renderer.setAttribute(accordionHeader, 'class', 'accordion-header accordion-collapse');
      this.renderer.setAttribute(accordionHeader, 'id', `heading${i} faqAccordian accordionFAQ`);

      const button = this.renderer.createElement('button');
      this.renderer.setAttribute(button, 'class', 'accordion-button font-28-20');
      this.renderer.setAttribute(button, 'type', 'button');
      this.renderer.setAttribute(button, 'data-bs-toggle', 'collapse');
      this.renderer.setAttribute(button, 'data-bs-target', `#collapse${i}`);
      this.renderer.setAttribute(button, 'aria-expanded', i === 0 ? 'true' : 'false');
      this.renderer.setAttribute(button, 'aria-controls', `collapse${i}`);
      this.renderer.setProperty(button, 'textContent', faq.f_question);
      if (i !== 0) {
        this.renderer.addClass(button, 'collapsed');
      }

      this.renderer.appendChild(accordionHeader, button);
      this.renderer.appendChild(accordionItem, accordionHeader);

      const collapseDiv = this.renderer.createElement('div');
      this.renderer.setAttribute(collapseDiv, 'id', `collapse${i}`);
      this.renderer.setAttribute(collapseDiv, 'class', 'accordion-collapse collapse');
      if (i === 0) {
        this.renderer.addClass(collapseDiv, 'show');
      }
      this.renderer.setAttribute(collapseDiv, 'aria-labelledby', `heading${i}`);
      this.renderer.setAttribute(collapseDiv, 'data-bs-parent', '#faqAccordion');

      const accordionBody = this.renderer.createElement('div');
      this.renderer.setAttribute(accordionBody, 'class', 'accordion-body');

      // Keep <p>, <ul>, <li>, <a> tags and sanitize the HTML content
      const sanitizedAnswer = this.sanitizeFaqContent(faq.f_answer);
      const answerDiv = this.renderer.createElement('div');
      this.renderer.setProperty(answerDiv, 'innerHTML', sanitizedAnswer);

      this.renderer.appendChild(accordionBody, answerDiv);
      this.renderer.appendChild(collapseDiv, accordionBody);
      this.renderer.appendChild(accordionItem, collapseDiv);
      this.renderer.appendChild(accordion, accordionItem);
    });

    this.renderer.appendChild(faqSection, accordion);
    this.faqHtml = this.sanitizer.bypassSecurityTrustHtml(faqSection.outerHTML);
  }

  // Utility function to sanitize HTML content while allowing <p>, <ul>, <li>, and <a> tags
  sanitizeFaqContent(html: string): string {
    // Check if running in the browser environment
    if (isPlatformBrowser(this.platformId)) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
  
      // Define allowed classes
      const allowedClasses = ['accordion-collapse'];
  
      // Convert NodeList to an array
      const elements = Array.from(tempDiv.querySelectorAll('*'));
  
      elements.forEach((element) => {
        const classList = Array.from(element.classList);
  
        // Check if the class is not allowed
        if (!classList.some(cls => allowedClasses.includes(cls))) {
          // Create a <p> element to wrap the content of disallowed classes
          const newElement = document.createElement('p');
          newElement.innerHTML = element.innerHTML;
  
          // Preserve classes and IDs
          if (element.className) newElement.className = element.className;
          if (element.id) newElement.id = element.id;
  
          // Replace disallowed element with the new <p> element
          element.replaceWith(newElement);
        }
      });
  
      return tempDiv.innerHTML;
    }
  
    // Fallback for server-side rendering
    return html;
  }

  reinitializeCarousel(): void {
    if (this.slickModal) {
      this.slickModal.unslick(); // Unslick the current instance
      this.cdr.detectChanges(); // Apply changes
      this.slickModal.initSlick(); // Reinitialize slick
    }
  }

  validateForm() {
    this.submitted = true;
    if (this.CommentForm.invalid) {
      return;
    }
    const formData = this.CommentForm.value;
    this.formApiService.submitBlogComment(formData).subscribe(
      (response) => {
        this.commentSubmission = true;
        console.log('Form submitted successfully:', response);
      },
      (error) => {
        console.error('Error submitting form:', error);
        alert('Error submitting form. Please try again.');
      }
    );
  }

  validateSubscribeForm() {
    this.SubscribeFormsubmitted = true;
    if (this.SubscribeForm.invalid) {
      return;
    }
    const formId = '15539b1c-0561-41cb-9e07-b21dd901ad3d';
    const formData = this.SubscribeForm.value;
    this.hubspotService.submitForm(formId, formData).subscribe(
      (response) => {
        console.log('Form submitted successfully:', response);
        this.subscribeSuccess = true;
      },
      (error) => {
        console.error('Error submitting form:', error);
        alert('Error submitting form. Please try again.');
      }
    );
  }

  exactLengthValidator(requiredLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value.replace(/\D/g, ''); // Remove non-digit characters
      return value.length === requiredLength
        ? null
        : { exactLength: { requiredLength, actualLength: value.length } };
    };
  }

  shareOnFacebook() {
    if (isPlatformBrowser(this.platformId)) {
      const pageLink = window.location.href;
      const pageTitle = String(document.title).replace(/\&/g, '%26');
      window.open(
        `http://www.facebook.com/sharer.php?u=${encodeURIComponent(
          pageLink
        )}&quote=${encodeURIComponent(pageTitle)}`,
        'sharer',
        'toolbar=0,status=0,width=626,height=436'
      );
      return false;
    }
    return undefined;
  }

  shareOnTwitter() {
    if (isPlatformBrowser(this.platformId)) {
      const pageLink = window.location.href;
      const pageTitle = String(document.title).replace(/\&/g, '%26');
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          pageTitle
        )}&url=${encodeURIComponent(pageLink)}`,
        'sharer',
        'toolbar=0,status=0,width=626,height=436'
      );
      return false;
    }
    return undefined;
  }

  shareOnLinkedIn() {
    if (isPlatformBrowser(this.platformId)) {
      const pageLink = window.location.href;
      const pageTitle = String(document.title).replace(/\&/g, '%26');
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          pageLink
        )}&title=${encodeURIComponent(pageTitle)}`,
        'sharer',
        'toolbar=0,status=0,width=626,height=436'
      );
      return false;
    }
    return undefined;
  }

  shareOnPinterest() {
    if (isPlatformBrowser(this.platformId)) {
      const pageLink = window.location.href;
      const pageTitle = String(document.title).replace(/\&/g, '%26');
      window.open(
        `https://www.pinterest.com/pin/create/button/?&text=${encodeURIComponent(
          pageTitle
        )}&url=${encodeURIComponent(pageLink)}&description=${encodeURIComponent(
          pageTitle
        )}`,
        'sharer',
        'toolbar=0,status=0,width=626,height=436'
      );
      return false;
    }
    return undefined;
  }

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    arrows: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
