<div class="container text-dark  d-none d-lg-block">
    <div class="gradientBanner">
      <img
         appCdnUrl="assets/accoutning Account Reconciliation/main-banner (1).jpg"
        class="card-img-desktop desktop-banner" alt="Account Reconciliation Services "
      
      />
      <div class="card-img-overlay overlay-text bannerText">
          <div class="main_bannar pt-3" >
            <div  data-aos="fade-up">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a  href="/">Home</a></li>
                  <li class="breadcrumb-item" aria-current="page">
                    <a  href="/bpm-and-bpo-services">Services</a>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">
                      <a  href="/services/accounting-bookkeeping-services">Accounting & Bookkeeping</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <a >Account Reconciliation Services  </a>
                </li>
                </ol>
              </nav>
              <h1 class="card-text font-34-19 text-primary pt-3">
                  Account Reconciliation Services   
              </h1>
              <p class="card-text font-20-16 pt-3">
                  Make your financial statements 100% accurate and fix all the discrepancies at cost-effective prices. Avail account reconciliation service from us and ensure an error-free books of accounts. While you focus on the core, let us do the reconciliation for you.    
              </p>
              <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
                <h3 class="text-primary font-24-16 clickable-heading">
                  Connect with Experts
                  <div class="headingCTA">
                    <button class="blueCTA"></button>
                  </div>
                </h3>
              </a>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container text-dark slide d-block d-lg-none">
    <div class="gradientBannerMob d-flex justify-content-center">
      <img
         appCdnUrl="assets/accoutning_Account_Reconciliation/Mobile-banner.jpg"
        class="card-img-mob" alt="Account Reconciliation Services "
       
      />
      <div class="card-img-overlay overlay-text-Mob bannerTextMob">
        <div  data-aos="fade-up">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a  href="/">Home</a></li>
              <li class="breadcrumb-item" aria-current="page">
                <a  href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                  <a  href="/services/accounting-bookkeeping-services">Accounting & Bookkeeping</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <a >Account Reconciliation Services  </a>
            </li>
            </ol>
          </nav>
        <h1 class="card-text font-34-19 text-primary">Account Reconciliation Services</h1>
        <p class="card-text font-20-16">
          Make your financial statements 100% accurate and fix all the discrepancies at cost-effective prices. Avail account reconciliation service from us and ensure an error-free books of accounts. While you focus on the core, let us do the reconciliation for you.    
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Connect with Experts
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
        </div>
      </div>
    </div>
  </div>
<app-ourAssets></app-ourAssets>
  
  
  <section class="container-fluid policy-process-service" id="account reconciliation service">
    <div class="container">
      <div class="container_emp ps-lg-3">
        <h2 class="text-white font-48-30 py-3"  data-aos="fade-up">
            Empower Change with Account Reconciliation Services  
        </h2>
        <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-4" />
      </div>
      <div class="d-none d-lg-block">
        <div class="row py-3 px-2">
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Issuance p-3"  data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal">
                <h3 class="text-black font-34-19 clickable-heading">
                    Credit Card Reconciliation   
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <!-- Modal -->
            <div
              class="modal Policy-Processing fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Credit Card Reconciliation </h3>
                        <p class="text-dark font-20-16">
                            Are you worried about credit card transactions and reconciliation? Choose our services and get seamless credit card transaction experience. We make sure to streamline the process, facilitate timely resolution, and identify discrepancies.     
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying the accounts for reconciliation 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering all the necessary documents   
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Comparing credit card statements with corresponding entries 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Investigating discrepancies and finding solutions 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reviewing reconciliation and documentation  
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Account Reconciliation/Group 204.webp"
                        alt="Credit Card Reconciliation Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy-Checking p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"    data-bs-toggle="modal"
              data-bs-target="#exampleModal1">
                <h3 class="text-black font-34-19 clickable-heading">
                    Full and Partial Account Reconciliation 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                   
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel1"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Full and Partial Account Reconciliation </h3>
                        <p class="text-dark font-20-16">
                            We offer full and partial account reconciliation solutions, from comprehensive audits to targeted assessments. We can do it for you whether you want to avail full account reconciliation or for a specific transaction.    
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying the accounts for reconciliation 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering documents for full or partial reconciliation 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Comparing account statements with corresponding entries 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Investigating discrepancies and finding solutions 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reviewing reconciliation and documentation   
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Account Reconciliation/Group 205.webp"
                        alt="Full and Partial Account Reconciliation Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Loss_Runs_Processing p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal2">
                <h3 class="text-black font-34-19 clickable-heading">
                    Vendor Invoice Reconciliation 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Vendor Invoice Reconciliation </h3>
                        <p class="text-dark font-20-16">
                            Are you struggling with vendor invoice reconciliation? Get our assistance! Our team carefully compares invoices with corresponding payments, detects inconsistency, and ensures the accuracy of financial records.     
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Setting an invoice reconciliation schedule 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Collecting all the necessary documents  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Comparing vendor statements with company accounts 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Authorizing the purchase transactions  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Eliminating discrepancies and processing reconciliation 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Account Reconciliation/Group 206.webp"
                        alt="Vendor Invoice Reconciliation Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Renewals p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal3">
                <h3 class="text-black font-34-19 clickable-heading">
                    Bank Statement Reconciliation 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal3"
              tabindex="-1"
              aria-labelledby="exampleModalLabel3"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Bank Statement Reconciliation </h3>
                        <p class="text-dark font-20-16">
                            Do you want to keep your bank statements 100% accurate? Avail bank statement reconciliation services from us. Our experts carefully identify and resolve discrepancies between your records and bank statements.    
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Collecting all the bank statements  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Aggregating business records  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Matching deposits and withdrawals 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Verifying the income and expenses   
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Adjusting balances and performing a final check 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Account Reconciliation/Group 207.webp"
                        alt="Bank Statement Reconciliation Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Management p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal4">
                <h3 class="text-black font-34-19 clickable-heading">
                    Petty Cash Reconciliation 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal4"
              tabindex="-1"
              aria-labelledby="exampleModalLabel4"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Petty Cash Reconciliation </h3>
                        <p class="text-dark font-20-16">
                            Ensure all your cash funds are spent according to internal guidelines and policies. Seek petty cash reconciliation services from us and get all your transactions properly documented with a receipt or invoice.    
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reviewing the company’s petty cash policy 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Obtaining the reconciliation form 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Calculating the amount of withdrawn cash
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Adding up each voucher in the petty cash fund
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Investigating the differences  
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Account Reconciliation/Group 208.webp"
                        alt="Petty Cash Reconciliation Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Endorsements p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal5">
                <h3 class="text-black font-34-19 clickable-heading">
                    Customer Statement Reconciliation
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal Policy-Processing fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel5"
        aria-hidden="true"
      >
        <div class="modal-dialog policy-dialog pt-0 mt-0">
          <div class="modal-content">
            <div class="modal-header policy-header">
              <img
                data-bs-dismiss="modal"
                aria-label="Close"
                 appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
              />
            </div>
            <div class="card policy-processing-services-card mx-5">
              <div class="details ms-4">
                <div class="content">
                  <h3 class="font-48-30 text-dark">Customer Statement Reconciliation</h3>
                  <p class="text-dark font-20-16">
                    Keep your customer statements accurate with our assistance. Seek customer statement reconciliation services from us. Our team carefully identifies and resolves discrepancies between your records and customers’ statements.     
                  </p>
                  <ul class="ul-policy">
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Obtaining customer statements   
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Matching transactions with business records 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Identifying the discrepancies
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Adjusting business records 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Verifying adjusted balances
                    </li>
                  </ul>
                </div>
              </div>
              <div class="imgBx d-none d-lg-block">
                <img
                   appCdnUrl="assets/accoutning Account Reconciliation/Group 209.webp"
                  alt="Customer Statement Reconciliation Service"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile View -->
    <div class="p-3">
      <div class="d-block d-lg-none">
        <div class="row">
          <div class="col-12 mt-0 mt-sm-4">
            <div class="accordion" id="ServicePage">
              <div class="policy-processing" data-aos="zoom-in">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#Policy_Processing"
                  aria-expanded="true"
                  aria-controls="Policy_Processing"
                  id="headingOne"
                  class="headingCTA CTA-Zindex d-flex  p-3"
                  data-bs-toggle="modal"
                    data-bs-target="#MobModal"
                >
                  <h3 class="CTAs text-black fs-24  me-3">Credit Card Reconciliation </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal"
                tabindex="-1"
                aria-labelledby="MobModalModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Credit Card Reconciliation </h3>
                          <p class="text-dark font-20-16">
                            Are you worried about credit card transactions and reconciliation? Choose our services and get seamless credit card transaction experience. We make sure to streamline the process, facilitate timely resolution, and identify discrepancies.    
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Identifying the accounts for reconciliation
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Gathering all the necessary documents   
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Comparing credit card statements with corresponding entries  
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Investigating discrepancies and finding solutions  
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Reviewing reconciliation and documentation  
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#Claims_Management"
                  aria-expanded="true"
                  aria-controls="Claims_Management"
                  id="headingOne"
                  class="headingCTA CTA-Zindex d-flex  p-3"
                  data-bs-toggle="modal"
                    data-bs-target="#MobModal1"
                >
                  <h3 class="CTAs text-black fs-24  me-3">Full and Partial Account Reconciliation</h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal1"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal1"
                  tabindex="-1"
                  aria-labelledby="MobModal1Lable1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">Full and Partial Account Reconciliation</h3>
                            <p class="text-dark font-20-16">
                                We offer full and partial account reconciliation solutions, from comprehensive audits to targeted assessments. We can do it for you whether you want to avail full account reconciliation or for a specific transaction.      
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Identifying the accounts for reconciliation   
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Gathering documents for full or partial reconciliation    
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Comparing account statements with corresponding entries 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Investigating discrepancies and finding solutions 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Reviewing reconciliation and documentation 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal2"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Vendor Invoice Reconciliation 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal2"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal2"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel2"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Vendor Invoice Reconciliation 
                            </h3>
                            <p class="text-dark font-20-16">
                                Are you struggling with vendor invoice reconciliation? Get our assistance! Our team carefully compares invoices with corresponding payments, detects inconsistency, and ensures the accuracy of financial records.     
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Setting an invoice reconciliation schedule 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Collecting all the necessary documents 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Comparing vendor statements with company accounts 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Authorizing the purchase transactions    
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Eliminating discrepancies and processing reconciliation 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal3"
                >
                  <h3 class="CTAs text-black fs-24  me-3">Bank Statement Reconciliation </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal3"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal3"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel3"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">Bank Statement Reconciliation </h3>
                            <p class="text-dark font-20-16">
                                Do you want to keep your bank statements 100% accurate? Avail bank statement reconciliation services from us. Our experts carefully identify and resolve discrepancies between your records and bank statements.    
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Collecting all the bank statements 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Aggregating business records  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Matching deposits and withdrawals  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Verifying the income and expenses 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Adjusting balances and performing a final check 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal4"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Petty Cash Reconciliation 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal4"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal4"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel4"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Petty Cash Reconciliation  
                            </h3>
                            <p class="text-dark font-20-16">
                                Ensure all your cash funds are spent according to internal guidelines and policies. Seek petty cash reconciliation services from us and get all your transactions properly documented with a receipt or invoice.       
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Reviewing the company’s petty cash policy 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Obtaining the reconciliation form    
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Calculating the amount of withdrawn cash 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Adding up each voucher in the petty cash fund  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Investigating the differences   
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div id="headingOne" class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal5"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Customer Statement Reconciliation 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal5"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal5"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel5"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Customer Statement Reconciliation 
                            </h3>
                            <p class="text-dark font-20-16">
                                Keep your customer statements accurate with our assistance. Seek customer statement reconciliation services from us. Our team carefully identifies and resolves discrepancies between your records and customers’ statements.    
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Obtaining customer statements 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Matching transactions with business records 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Identifying the discrepancies   
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Adjusting business records 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Verifying adjusted balances
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <img class="mt-5"  appCdnUrl="assets/Icon/white-snake-vector.svg" />
    </div>
  </section>
  <section class="container py-5">
    <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
    <div class="d-none d-xl-flex">
      <div class="row">
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool QuickBooks "  appCdnUrl="assets/Tools/quickbooks.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"/>
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
        </div>
      </div>
    </div>
    <div class="d-flex d-xl-none">
      <div class="trusted-slider-logo">
        <div class="trusted-slide-track">
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Thinking -->
  <app-service-case-study  [category]="[{ category: 'accountingandbookkeeping' }]"></app-service-case-study>
  <!-- Know best -->
<app-know-best
[cards]="[
  { description: 'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
  name: 'Francesca Furlanetto', designation: 'Director Owner'
 },
  { description: 'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
  name: 'Monica Spivey', designation: 'Directory of Accounting'
 },

]"
  [videos]="[
     { 
     thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
     alt:'Listen to our client '
     },
     {
     
      thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt:'Our Success Stories '
    },
  ]">
</app-know-best>
  <section class="py-6">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6"  data-aos="fade-right">
          <app-faq></app-faq>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0"  data-aos="fade-left">
          <div class="accordion" id="accordionFAQ">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-28-20"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Industries"
                  aria-expanded="true"
                  aria-controls="Industries"
                >
                What are the different types of reconciliation services you offer? 
                </button>
              </h2>
              <div
                id="Industries"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    Every business requires different types of reconciliation services at one point or the other. However, the major reconciliation services we offer are -   
                    <ul class="pt-3">
                        <li>Credit card reconciliation    </li>
                        <li>Petty cash reconciliation    </li>
                        <li>Account payable reconciliation    </li>
                        <li>Bank account reconciliation    </li>
                        <li>Customer statement reconciliation    </li>
                      </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Services"
                  aria-expanded="false"
                  aria-controls="Services"
                >
                What is the major purpose behind account reconciliation services? 
                </button>
              </h2>
              <div
                id="Services"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    While outsourcing account reconciliation, businesses can avoid errors, fraud, and discrepancies that might cause trouble during the taxation period. The experts ensure that your financial information stays accurate and thus help you make the right strategic decisions. 
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#About"
                  aria-expanded="false"
                  aria-controls="About"
                >
                Do account reconciliation services save time for my businesses?
                </button>
              </h2>
              <div
                id="About"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    You can save a lot of time by outsourcing account reconciliation services to our professionals. You can focus on your core business activities while our experts handle account reconciliation and ensure 100% accuracy and 0% discrepancy.   
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Resources"
                  aria-expanded="false"
                  aria-controls="Resources"
                >
                What are the different types of accounts that can be reconciled?  
                </button>
              </h2>
              <div
                id="Resources"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    With our account reconciliation services, you can get your accounts reconciled for different accounts, including vendors, credit cards, bank statements, customer accounts, and more. To know more about account reconciliation solutions, contact our experts right away!   
                </div>
              </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button font-28-20 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#differentiators"
                    aria-expanded="false"
                    aria-controls="differentiators"
                  >
                  Why should I avail your account reconciliation services?  
                  </button>
                </h2>
                <div
                  id="differentiators"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionFAQ"
                >
                  <div class="accordion-body">
                    We always put in our best efforts to deliver the industry-best outsourcing services to all-scale businesses. With us, businesses can experience amazing perks, including -                 
                    <ul class="pt-3">
                        <li>Availability As Per Client Time Zone </li>
                        <li>All-in-one Expertise </li>
                        <li>Dedicated Staff </li>
                        <li>99% Accuracy </li>
                        <li>Tailor-Made Solutions </li>
                        <li>Quick turnaround time </li>
                      </ul>
                </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  