import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToolsSectionModule } from '../../../components/ServiceComponent/ToolsSection/ToolsSection.module';
import { ourAssetsModule } from '../../../components/HomeComponents/ourAssets/ourAssets.module';
import { SeoService } from '../../../services/seo/seo.service';
import { SeoData } from '../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../components/HomeComponents/know-best/know-best.component';
import { ServiceVimeoComponent } from '../../../components/HomeComponents/service-vimeo/service-vimeo.component';
import { ServiceCaseStudyComponent } from '../../../components/HomeComponents/service-case-study/service-case-study.component';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { environment } from '../../../../environments/environment';
import { ourAssetsComponent } from '../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-insurance-agency-optimization',
  standalone: true,
  templateUrl: './insurance-agency-optimization.component.html',
  styleUrl: './insurance-agency-optimization.component.css',
  imports: [
    RouterLink,
    RouterOutlet,
    SlickCarouselModule,
    ToolsSectionModule,
    ourAssetsModule,
    KnowBestComponent,
    FaqComponent,
    CdnUrlDirective,
    ServiceVimeoComponent,
    ServiceCaseStudyComponent,
    ourAssetsComponent,
  ],
})
export class InsuranceAgencyOptimizationComponent implements OnInit {
  cdnUrl = environment.cdnUrl;
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title:
        'Expert Insurance Optimization Services for P&C Insurance Industry |AOS',
      description:
        'Enhance your P&C insurance operations, optimize workflow, boost lead conversion, and ensure client satisfaction with our agency optimization service.',
      keywords: 'insurance agency optimization, insurance agency consultant',
      ogImage: '/insurance-agency/AOS Banner copy 01.jpg',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({
      rel: 'canonical',
      href: 'https://www.fbspl.com/services/insurance-agency-optimization-services',
    });
  }
  slides = [
    {
      banner: true,
      img: `${this.cdnUrl}assets/insurance-agency/AOS Banner copy 01.jpg`,
      img_mob: `${this.cdnUrl}assets/insurance-agency/AOS Banner Mobile 1.jpg`,
      Title: 'Insurance Agency Optimization Services  ',
      Description:
        'Make smarter decisions and ensure higher ROI with our Agency Optimization Services! Be ready to re-adapt your P&C insurance operations to streamline agency workflows, increase staff efficiency, and minimize agency E&O liability risks. ',
      alt: 'Insurance Agency Optimization ',
      Link: '/book-free-consultation-call',
    },
    {
      banner: false,
      img: `${this.cdnUrl}assets/insurance-agency/AOS Banner copy 02.jpg`,
      img_mob: `${this.cdnUrl}assets/insurance-agency/AOS Banner Mobile 2.jpg`,
      Title: 'Transformed Client Service with a 50% Faster Processing ',
      Description:
        'Up to 90% productivity improvement. 80% streamlined processing. 50% quicker processing. 70% faster policy updates. ',
      Link: '/case-studies',
      fragment: 'insurance-agency-optimization-services',
      alt: 'Insurance Agency Optimization ',
    },
    {
      banner: false,
      img: `${this.cdnUrl}assets/insurance-agency/AOS Banner copy 03.jpg`,
      img_mob: `${this.cdnUrl}assets/insurance-agency/AOS Banner Mobile 3.jpg`,
      Title: ' Latest Insurtech Trends You Shouldn’t Miss!',
      Description:
        'Keep your edge in the insurance market. Discover the hottest InsurTech trends that are reshaping the roles of MGAs and wholesalers today. ',
      Link: '/blogs',
      fragment: '',
      alt: 'Insurance Agency Optimization ',
    },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Tablet breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on tablets
          slidesToScroll: 1, // Number of slides to scroll on tablets
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on tablets
          arrows: false, // Enable arrows on tablets
        },
      },
      {
        breakpoint: 1024, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
      {
        breakpoint: 1200, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
    ],
  };
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.document.head.querySelector('script[type="application/ld+json"]')
      ) {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          { 

"@type": "Question", 

"name": "How does an insurance Agency Optimization Services benefit my agency?", 

"acceptedAnswer": { 

"@type": "Answer", 

"text": "Our insurance agency optimization services can significantly enhance your agency’s efficiency and effectiveness. These services streamline lead conversion, automate tasks, and improve client satisfaction, all while providing valuable data-driven insights and reducing human errors. Partnering with a leading insurance consulting agency can drive business growth and success by creating scalable processes that reduce dependency on individual staff members." 

} 

}, 

{ 

"@type": "Question", 

"name": "What is the process for insurance Agency Optimization?", 

"acceptedAnswer": { 

"@type": "Answer", 

"text": "We follow a very streamlined and transparent insurance agency optimization process. Here’s how we do it. 

<ul><li>Our team conducts a thorough audit of the agency’s current procedures, agency management system and overall operations.</li> 

<li>We analyze the agency workflows, processes, workflow, data integrity, redundancy, bottlenecks, communication, agency management system and interview staff members to understand their pain point points.</li> 

<li>Based on the analysis and understanding, we prepare an audit report with our health check findings and recommendations to improve.</li> 

<li>It is up to the agencies to work on the recommendations or if they want, our expert team can implement those for you.</li></ul> " 

} 

}, 

{ 

"@type": "Question", 

"name": "How do you ensure smooth client servicing workflows?", 

"acceptedAnswer": { 

"@type": "Answer", 

"text": "Our team reviews all insurance agency workflows, current procedures for processing client servicing tasks. We prepare written workflow task procedures and automations to ensure consistent and efficient processing customized to fit the agency’s current practices." 

} 

}, 

{ 

"@type": "Question", 

"name": "How does Agency Optimization Services help to reduce my agency's E&O liability?", 

"acceptedAnswer": { 

"@type": "Answer", 

"text": "Agency Optimization services help reduce agency E&O (Errors and Omissions) exposure by implementing standardized procedures, automating routine tasks, and ensuring accurate documentation. These measures enhance consistency and accuracy in client servicing, minimizing the risk of errors and omissions." 

} 

}, 

{ 

"@type": "Question", 

"name": "How is FBSPL different from others?", 

      
 "@type": "Answer", 

"text": "FBSPL focuses on offering innovative solutions specifically crafted as per the needs of insurance professionals. Here's what sets us apart - 

<ul><li>Dedicated team of industry experts</li> 

<li>Data-driven approach</li> 

<li>Scalable and flexible solutions</li> 

<li>Commitment to client success</li> 

<li>Technological innovation </li></ul>" 

} 

} 
        ]
      }`;
        this.renderer.appendChild(this.document.head, script);
      }
      // Check and add BreadcrumbList script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = `
    { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Insurance", 

      "item": "https://www.fbspl.com/services/insurance-claim-management" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "Claim Management", 

      "item": "https://www.fbspl.com/services/insurance-claim-management" 

    } 

  ] 

} `;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
      const alternateLinkDefault = this.renderer.createElement('link');

      alternateLinkDefault.rel = 'alternate';
      alternateLinkDefault.href = `https://www.fbspl.com`;
      alternateLinkDefault.hreflang = 'x-default';
      this.renderer.appendChild(this.document.head, alternateLinkDefault);

      const alternateLinkEn = this.renderer.createElement('link');
      alternateLinkEn.rel = 'alternate';
      alternateLinkEn.href = `https://www.fbspl.com/services/insurance-claim-management`;
      alternateLinkEn.hreflang = 'en-us';
      this.renderer.appendChild(this.document.head, alternateLinkEn);
    }
  }
}
