<section class="bg-secondary-30 my-6 mb-5 mx-2">
  <div class="container" data-aos="fade-up">
    <div class="row d-flex">
      <div class="col-12 col-md-5 col-lg-7 my-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="./">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Corporate Social Responsibility
            </li>
          </ol>
        </nav>
        <h1 class="font-48-30 mt-5 mt-90">Doing our little bit of good.</h1>
        <p class="font-24-16">
          We believe it's these little goods that hold the power<br />
          to make the biggest difference.
        </p>
      </div>
      <div class="col-12 col-md-7 col-lg-5">
        <div class="image-grid">
          <div class="item" id="item1"></div>
          <div class="item" id="item2"></div>
          <div class="item" id="item3"></div>
          <div class="item" id="item4"></div>
          <div class="item" id="item5"></div>
          <div class="item" id="item6"></div>
          <div class="item" id="item7"></div>
          <div class="item" id="item8"></div>
          <div class="item" id="item9"></div>
          <div class="item" id="item10"></div>
          <div class="item" id="item11"></div>
          <div class="item" id="item12"></div>
          <div class="item" id="item13"></div>
          <div class="item" id="item14"></div>
          <div class="item" id="item15"></div>
          <div class="item" id="item16"></div>
          <div class="item" id="item17"></div>
          <div class="item" id="item18"></div>
          <div class="item" id="item19"></div>
          <div class="item" id="item20"></div>
          <div class="item" id="item21"></div>
          <div class="item" id="item22"></div>
          <div class="item" id="item23"></div>
          <div class="item" id="item24"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="py-6" data-aos="slide-up">
  <h2 class="text-primary font-48-30 text-center">Words in Action</h2>
  <p class="font-24-16 text-center">
    Our social responsibility is driven by our core values
  </p>
  <main class="container-fluid action-container pt-5 bg-primary">
    <div class="d-flex align-items-end">
      <img appCdnUrl="assets/Icon/triangle-blue.svg" width="100%" />
    </div>
    <div class="container">
      <ngx-slick-carousel
        #slickModal="slick-carousel"
        class="slider blog_slider slider_card"
        [config]="slideConfig"
      >
        <div ngxSlickItem class="slide">
          <div class="card-body custom-card position-relative">
            <canvas
              #starCanvas1
              id="starCanvas1"
              class="starcanvas position-absolute w-100"
              style="z-index: 0"
            ></canvas>
            <div style="z-index: 2" class="d-flex justify-content-between pt-3">
              <div class="img-donation">
                <img
                  class="Donation_box"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Donat-Box.png"
                  alt="Donation Box"
                />
              </div>
              <div class="pt-4">
                <img
                  class="Donation"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Donation.png"
                  alt="Donation"
                />
              </div>
              <div class="pt-3">
                <img
                  class="Child"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Child.png"
                  alt="Child"
                />
              </div>
              <div>
                <img
                  class="Mother"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Mother.png"
                  alt="Mother"
                />
              </div>
            </div>
            <div
              style="z-index: 2"
              class="d-flex justify-content-around-lg justify-content-center"
            >
              <div>
                <img
                  class="Clip-path"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group.png"
                  alt="Clip-path"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Group (5).png"
                  alt="Group5"
                />
              </div>
              <div style="text-align: center" class="middleSection">
                <img class="Smile" appCdnUrl="assets/CSR/Smile.png" />
                <p class="text-center text-primary font-36-24 pt-1 mb-1">
                  Happiness
                </p>
                <p class="text-center text-dark font-20-16 pb-3">
                  Putting a smile out there
                </p>
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (1).png"
                  alt="Mother"
                />
              </div>
              <div style="padding-top: 50px">
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Group (6).png"
                  alt="Mother"
                />
              </div>
            </div>
            <div style="z-index: 2" class="d-flex justify-content-center">
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  style="margin-top: -80px"
                  appCdnUrl="assets/CSR/Group (7).png"
                  alt="Donation Box"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (2).png"
                  alt="Donation"
                />
              </div>

              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (3).png"
                  alt="Mother"
                />
              </div>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="card-body custom-card position-relative">
            <canvas
              #starCanvas2
              id="starCanvas2"
              class="starcanvas position-absolute w-100"
              style="z-index: 0"
            ></canvas>
            <div style="z-index: 2" class="d-flex justify-content-between pt-3">
              <div class="img-donation">
                <img
                  class="Donation_box"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Donat-Box.png"
                  alt="Donation Box"
                />
              </div>
              <div class="pt-4">
                <img
                  class="Donation"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Donation.png"
                  alt="Donation"
                />
              </div>
              <div class="pt-3">
                <img
                  class="Child"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Child.png"
                  alt="Child"
                />
              </div>
              <div>
                <img
                  class="Mother"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Mother.png"
                  alt="Mother"
                />
              </div>
            </div>
            <div
              style="z-index: 2"
              class="d-flex justify-content-around-lg justify-content-center"
            >
              <div>
                <img
                  class="Clip-path"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group.png"
                  alt="Clip-path"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Group (5).png"
                  alt="Group5"
                />
              </div>
              <div style="text-align: center" class="middleSection">
                <img
                  class="Smile"
                  appCdnUrl="assets/CSR/continouslearning.png"
                />
                <p class="text-center text-primary font-36-24 pt-1 mb-1">
                  Continuous Learning
                </p>
                <p class="text-center text-dark font-20-16 pb-3">
                  Planting the seeds of future
                </p>
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (1).png"
                  alt="Mother"
                />
              </div>
              <div style="padding-top: 50px">
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Group (6).png"
                  alt="Mother"
                />
              </div>
            </div>
            <div
              style="z-index: 2"
              class="d-flex justify-content-center position-relative bottom_issue"
            >
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  style="margin-top: -80px"
                  appCdnUrl="assets/CSR/Group (7).png"
                  alt="Donation Box"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (2).png"
                  alt="Donation"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (3).png"
                  alt="Mother"
                />
              </div>
            </div>
          </div>
        </div>

        <div ngxSlickItem class="slide">
          <div class="card-body custom-card position-relative">
            <canvas
              #starCanvas2
              id="starCanvas2"
              class="starcanvas position-absolute w-100"
              style="z-index: 0"
            ></canvas>
            <div style="z-index: 2" class="d-flex justify-content-between pt-3">
              <div class="img-donation">
                <img
                  class="Donation_box"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Donat-Box.png"
                  alt="Donation Box"
                />
              </div>
              <div class="pt-4">
                <img
                  class="Donation"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Donation.png"
                  alt="Donation"
                />
              </div>
              <div class="pt-3">
                <img
                  class="Child"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Child.png"
                  alt="Child"
                />
              </div>
              <div>
                <img
                  class="Mother"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Mother.png"
                  alt="Mother"
                />
              </div>
            </div>
            <div
              style="z-index: 2"
              class="d-flex justify-content-around-lg justify-content-center"
            >
              <div>
                <img
                  class="Clip-path"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group.png"
                  alt="Clip-path"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Group (5).png"
                  alt="Group5"
                />
              </div>
              <div style="text-align: center" class="middleSection">
                <img class="Smile" appCdnUrl="assets/CSR/keeping.png" />
                <p class="text-center text-primary font-36-24 pt-1 mb-1">
                  Keeping You First
                </p>
                <p class="text-center text-dark font-20-16 pb-3">
                  You matter, your dreams matter
                </p>
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (1).png"
                  alt="Mother"
                />
              </div>
              <div style="padding-top: 50px">
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Group (6).png"
                  alt="Mother"
                />
              </div>
            </div>
            <div
              style="z-index: 2"
              class="d-flex justify-content-center position-relative bottom_issue"
            >
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  style="margin-top: -80px"
                  appCdnUrl="assets/CSR/Group (7).png"
                  alt="Donation Box"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (2).png"
                  alt="Donation"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (3).png"
                  alt="Mother"
                />
              </div>
            </div>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="card-body custom-card position-relative">
            <canvas
              #starCanvas2
              id="starCanvas2"
              class="starcanvas position-absolute w-100"
              style="z-index: 0"
            ></canvas>
            <div style="z-index: 2" class="d-flex justify-content-between pt-3">
              <div class="img-donation">
                <img
                  class="Donation_box"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Donat-Box.png"
                  alt="Donation Box"
                />
              </div>
              <div class="pt-4">
                <img
                  class="Donation"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Donation.png"
                  alt="Donation"
                />
              </div>
              <div class="pt-3">
                <img
                  class="Child"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Child.png"
                  alt="Child"
                />
              </div>
              <div>
                <img
                  class="Mother"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Mother.png"
                  alt="Mother"
                />
              </div>
            </div>
            <div
              style="z-index: 2"
              class="d-flex justify-content-around-lg justify-content-center"
            >
              <div>
                <img
                  class="Clip-path"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group.png"
                  alt="Clip-path"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Group (5).png"
                  alt="Group5"
                />
              </div>
              <div style="text-align: center" class="middleSection">
                <img class="Smile" appCdnUrl="assets/CSR/discipline.png" />
                <p class="text-center text-primary font-36-24 pt-1 mb-1">
                  Discipline
                </p>
                <p class="text-center text-dark font-20-16 pb-3">
                  Bridging the gap between goals and <br />
                  accomplishment
                </p>
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (1).png"
                  alt="Mother"
                />
              </div>
              <div style="padding-top: 50px">
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Group (6).png"
                  alt="Mother"
                />
              </div>
            </div>
            <div style="z-index: 2" class="d-flex justify-content-center">
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  style="margin-top: -80px"
                  appCdnUrl="assets/CSR/Group (7).png"
                  alt="Donation Box"
                />
              </div>
              <div>
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (2).png"
                  alt="Donation"
                />
              </div>
              <div class="position-relative" style="top: -25px">
                <img
                  class="Group5"
                  data-aos="zoom-in"
                  appCdnUrl="assets/CSR/Clip path group (3).png"
                  alt="Mother"
                />
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="d-flex align-items-baseline">
      <img appCdnUrl="assets/Icon/squarevector.svg" width="100%" alt="" />
    </div>
  </main>
</section>

<!-- Choose Us, Choose Excellence  -->
<!-- Choose Us, Choose Excellence  -->
<section class="container py-6 mt-5">
  <h2 class="font-48-30 text-primary" data-aos="slide-up">Our Focus Areas</h2>
  <div class="d-none d-lg-block">
    <div class="container_horizontal pt-3">
      <div
        class="card choice_accordion choice_accordion1 mx-2 Education expand"
      >
        <button
          class="accordion-button accordion-button-horizontal"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h3 class="text-white font-36-24">Education</h3>
        </button>
        <div id="collapseOne" class="accordion-collapse collapse show">
          <div class="card-body accordion_body accordion_width pt-0">
            <h3 class="text-primary font-36-24">Educating India</h3>
            <p class="font-20-16">
              We know that when educating a child, we educate India. From
              providing financial assistance with educational expenses to
              ensuring sanitation facilities, we are playing our part in
              enhancing education accessibility for children in rural and
              underserved regions.
            </p>
          </div>
        </div>
        <img
          class="mt-auto me-auto ps-5 ms-5"
          appCdnUrl="assets/Icon/triangle-blue.svg"
        />
      </div>
      <div
        class="card choice_accordion choice_accordion2 mx-2 empowerment small"
      >
        <button
          class="accordion-button accordion-button-horizontal"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          <h3 class="text-white font-36-24">Empowerment</h3>
        </button>
        <div id="collapseTwo" class="accordion-collapse collapse show">
          <div class="card-body accordion_body accordion_width pt-0">
            <h3 class="text-primary font-36-24">
              Taking women's empowerment to new heights
            </h3>
            <p class="font-20-16 w-75">
              Women's empowerment starts with independence—the freedom to pursue
              dreams and aspirations. We embrace and embody the essence of
              women's empowerment in its entirety. Whether through vocational
              training or education, we strive to provide the essential support
              women require to achieve their goals.
            </p>
          </div>
        </div>
        <img
          class="mt-auto me-auto ps-5 ms-5"
          appCdnUrl="assets/Icon/triangle-blue.svg"
        />
      </div>

      <div class="card choice_accordion mx-2 healthcare small">
        <button
          class="accordion-button accordion-button-horizontal"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="true"
          aria-controls="collapseThree"
        >
          <h3 class="text-white font-36-24">Healthcare</h3>
        </button>
        <div id="collapseThree" class="accordion-collapse collapse show">
          <div class="card-body accordion_body accordion_width pt-0">
            <h3 class="text-primary font-36-24">
              Offering healthcare facilities to vulnerable sections
            </h3>
            <p class="font-20-16">
              We believe in lending a hand when it's most crucial. This belief
              inspired our efforts during the COVID-19 pandemic. We worked to
              the best of our potential to provide unmatched medical assistance
              to our community and continue doing so.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- mobile View -->
  <div class="d-block d-lg-none">
    <div class="accordion pt-3" id="accordionMob">
      <div class="accordion-item accordion-mob accordion-radius1">
        <h2 class="accordion-header">
          <button
            class="accordion-button accordion-radius1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mobcollapseOne"
            aria-expanded="true"
            aria-controls="mobcollapseOne"
          >
            <h3 class="text-primary font-36-24 px-2">Educating India</h3>
          </button>
        </h2>
        <div
          id="mobcollapseOne"
          class="accordion-collapse collapse show"
          data-bs-parent="#accordionMob"
        >
          <div class="accordion_body">
            <p class="text-dark font-20-16 px-4">
              We know that when educating a child, we educate India. From
              providing financial assistance with educational expenses to
              ensuring sanitation facilities, we are playing our part in
              enhancing education accessibility for children in rural and
              underserved regions.
            </p>
          </div>
          <img class="img_triangle" appCdnUrl="assets/Icon/triangle-blue.svg" />
          <img appCdnUrl="assets/CSR/Educating.png" class="img_acordion" />
        </div>
      </div>
      <div class="accordion-item accordion-mob">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed accordion-radius2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mobcollapseTwo"
            aria-expanded="false"
            aria-controls="mobcollapseTwo"
          >
            <h3 class="text-primary font-36-24 px-2">
              Taking women's empowerment to new heights
            </h3>
          </button>
        </h2>
        <div
          id="mobcollapseTwo"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionMob"
        >
          <div class="accordion_body">
            <p class="text-dark font-20-16 px-4">
              Women's empowerment starts with independence—the freedom to pursue
              dreams and aspirations. We embrace and embody the essence of
              women's empowerment in its entirety. Whether through vocational
              training or education, we strive to provide the essential support
              women require to achieve their goals.
            </p>
          </div>
          <img class="img_triangle" appCdnUrl="assets/Icon/triangle-blue.svg" />
          <img appCdnUrl="assets/CSR/empowerment.png" class="img_acordion" />
        </div>
      </div>
      <div class="accordion-item accordion-mob">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed accordion-radius3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mobcollapseThree"
            aria-expanded="false"
            aria-controls="mobcollapseThree"
          >
            <h3 class="text-primary font-36-24 px-2">
              Offering healthcare facilities to vulnerable sections
            </h3>
          </button>
        </h2>
        <div
          id="mobcollapseThree"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionMob"
        >
          <div class="accordion_body">
            <p class="text-dark font-20-16 px-4">
              We believe in lending a hand when it's most crucial. This belief
              inspired our efforts during the COVID-19 pandemic. We worked to
              the best of our potential to provide unmatched medical assistance
              to our community and continue doing so.
            </p>
          </div>
          <img appCdnUrl="assets/CSR/healthcare.png" class="img_acordion" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Choose Us, Choose Excellence  -->

<section class="container py-4">
  <h2 class="text-primary font-48-30 pb-5 text-center">
    We've always believed that a little effort can go a long way<br />
    and we trust you do, too.
  </h2>
  <div class="row">
    <div class="col-lg-10">
      <div id="animationCSR"></div>
    </div>
    <div class="col-lg-2 d-flex justify-content-center align-items-center">
      <h2
        class="text-primary font-48-30 text-center slidingVertical d-flex justify-content-center align-items-center mb-0 mt-3"
      >
        <span>Dreams</span>
        <span>Empowerment</span>
        <span>Healthcare</span>
        <span>Independence</span>
        <span>Education</span>
      </h2>
    </div>
  </div>
</section>
<!-- honeycomb -->
<div class="container-fluid py-6 mt-5 d-none d-xl-block">
  <div class="gall">
    <div class="gallery imageChange">
      <img appCdnUrl="assets/CSR/Honey_1.png" />
      <img appCdnUrl="assets/CSR/Honey_2.png" />
      <img appCdnUrl="assets/CSR/Honey_3.png" />
      <img appCdnUrl="assets/CSR/Honey_4.png" />
      <img appCdnUrl="assets/CSR/Honey_5.png" />
      <img appCdnUrl="assets/CSR/Honey_6.png" />
      <img appCdnUrl="assets/CSR/Honey_7.png" />
    </div>
    <div class="spacer-gallery imageChange">
      <img appCdnUrl="assets/CSR/Honey_8.png" />
      <img appCdnUrl="assets/CSR/Honey_9.png" />
      <!-- its a left hexagonal -->
      <img appCdnUrl="assets/CSR/Honey_10.png" />
      <img appCdnUrl="assets/CSR/Honey_11.png" />
      <!-- spacer -->
      <img appCdnUrl="assets/CSR/Honey_12.png" />
    </div>
    <div class="gallery imageChange">
      <img appCdnUrl="assets/CSR/Honey_13.png" />
      <img appCdnUrl="assets/CSR/Honey_14.png" />
      <img appCdnUrl="assets/CSR/Honey_15.png" />
      <img appCdnUrl="assets/CSR/Honey_16.png" />
      <img appCdnUrl="assets/CSR/Honey_17.png" />
      <img appCdnUrl="assets/CSR/Honey_18.png" />
      <img appCdnUrl="assets/CSR/Honey_20.png" />
    </div>
  </div>
</div>

<!-- Mobile View -->
<div class="container py-6 my-5 d-block d-md-none">
  <div class="gall1">
    <div class="gallery1 imageChange">
      <img appCdnUrl="assets/CSR/Honey_13.png" />
      <img appCdnUrl="assets/CSR/Honey_14.png" />
      <img appCdnUrl="assets/CSR/Honey_15.png" />
      <img appCdnUrl="assets/CSR/Honey_16.png" />
      <img appCdnUrl="assets/CSR/Honey_17.png" />
      <img appCdnUrl="assets/CSR/Honey_18.png" />
      <img appCdnUrl="assets/CSR/Honey_20.png" />
    </div>
  </div>
</div>

<!-- Ipad view -->
<div class="d-none d-md-block d-lg-flex d-xl-none justify-content-center">
  <div class="honeycomb p-5 imageChange w-60">
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_1.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_2.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_3.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_4.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_15.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_16.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_17.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_10.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_6.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_7.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_8.png" />
    </div>
    <div class="hexa">
      <img appCdnUrl="assets/CSR/Honey_18.png" />
    </div>
  </div>
</div>
