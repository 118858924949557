<div class="container text-dark d-none d-lg-block">
    <div class="gradientBanner">
      <img
         appCdnUrl="assets/accoutning Receivable Management/main-banner.jpg"
        class="card-img-desktop desktop-banner"
        alt="Accounts Receivable Services "
      
      />
      <div class="card-img-overlay overlay-text bannerText">
          <div class="main_bannar pt-3" >
            <div data-aos="fade-up">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a  href="/">Home</a></li>
                  <li class="breadcrumb-item">
                    <a  href="/bpm-and-bpo-services">Services</a>
                  </li>
                  <li class="breadcrumb-item" >
                      <a  href="/services/accounting-bookkeeping-services">Accounting & Bookkeeping</a>
                  </li>
                  <li class="breadcrumb-item active">
                    <a  aria-current="page">Accounts Recievable Management</a>
                </li>
                </ol>
              </nav>
              <h1 class="card-text font-34-19 text-primary pt-3">
                  Accounts Receivable Services
              </h1>
              <p class="card-text font-20-16 pt-3">
                  Reinforce your working capital with full-fledged accounts receivable management solutions. Unlock access to standardized workflows & processes and make your business compliant to witness growth. Get your account receivables optimized the right way.  
              </p>
              <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
                <h3 class="text-primary font-24-16 clickable-heading">
                  Connect with Experts
                  <div class="headingCTA">
                    <button class="blueCTA"></button>
                  </div>
                </h3>
              </a>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container text-dark slide d-block d-lg-none">
    <div class="gradientBannerMob d-flex justify-content-center">
      <img
         appCdnUrl="assets/accoutning Receivable Management/Mobile-banner.jpg"
        class="card-img-mob"
        alt="Accounts Receivable Services "
       
      />
      <div class="card-img-overlay overlay-text-Mob bannerTextMob">
        <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a  href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a  href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                  <a  href="/services/accounting-bookkeeping-services">Accounting & Bookkeeping</a>
              </li>
              <li class="breadcrumb-item active">
                <a  aria-current="page">Accounts Recievable Management</a>
            </li>
            </ol>
          </nav>
        <h1 class="card-text font-34-19 text-primary">Accounts Receivable Services</h1>
        <p class="card-text font-20-16">
          Reinforce your working capital with full-fledged accounts receivable management solutions. Unlock access to standardized workflows & processes and make your business compliant to witness growth. Get your account receivables optimized the right way.  
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Connect with Experts
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
        </div>
      </div>
    </div>
  </div>
<app-ourAssets></app-ourAssets>
  
  
  <section class="container-fluid policy-process-service" id="Accounts Receivable Outsourcing Services">
    <div class="container">
      <div class="container_emp ps-lg-3">
        <h2 class="text-white font-48-30 py-3" data-aos="fade-up">
          Accounts Receivable Outsourcing Services 
        </h2>
        <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-4" />
      </div>
      <div class="d-none d-lg-block">
        <div class="row py-3 px-2">
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Issuance p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal">
                <h3 class="text-black font-34-19 clickable-heading">
                    Customer Invoices   
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <!-- Modal -->
            <div
              class="modal Policy-Processing fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Customer Invoices </h3>
                        <p class="text-dark font-20-16">
                            Effortlessly generate and deliver accurate customer invoices with our receivable management services. We ensure a seamless billing process, reducing manual errors and streamlining receivables while providing 100% clarity.   
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Assessing the customer's creditworthiness  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Generating an invoice  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Sending an invoice to the customer 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Tracking the invoice to ensure payment received  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reconciling the received payment 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Receivable Management/Group 222.webp"
                        alt="Customer Invoices Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy-Checking p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal1">
                <h3 class="text-black font-34-19 clickable-heading">
                    Customer Statement Reconciliation  
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel1"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Customer Statement Reconciliation </h3>
                        <p class="text-dark font-20-16">
                            Simplify your customer statement reconciliation with automated tools, ensuring swift transactions. Our team quickly identifies discrepancies and maintains accurate financial records for improved cash flow visibility.  
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Collecting invoices, receipts, and other data  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Comparing account entries with corresponding invoices 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Investigating unmatched items or entries to resolve discrepancies  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Making necessary adjustments to the accounts
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Conducting regular reconciliations on a monthly or quarterly basis 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Receivable Management/Group 223.webp"
                        alt="Customer Statement Reconciliation Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div 
              class="card insurance-card policy-process-card Loss_Runs_Processing p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal2">
                <h3 class="text-black font-34-19 clickable-heading">
                    Revenue Recognition 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Revenue Recognition</h3>
                        <p class="text-dark font-20-16">
                          Adopt compliant and transparent revenue recognition processes tailored to your business needs. Our accounts receivable services accurately capture your revenue streams, ensuring precise <a class="anchor" target="_blank" href="/services/accounting-and-financial-reporting-services">financial reporting</a> and aiding in compliance. 
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Evaluating the contract with the customers  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Analyzing the performance obligations within the contract 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Determining the amount of revenue considerations 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Additional transactional pricing to each performance obligation 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Recognizing revenue after performance obligation 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Receivable Management/Group 224.webp"
                        alt="Revenue Recognition Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Renewals p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal3">
                <h3 class="text-black font-34-19 clickable-heading">
                    Dispute Handling
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal3"
              tabindex="-1"
              aria-labelledby="exampleModalLabel3"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Dispute Handling </h3>
                        <p class="text-dark font-20-16">
                            Effectively manage your customer disputes with our structured workflow, ensuring swift resolution. Streamline communication, maintain accurate records, and minimize delays to foster better customer relationships and consistent cash flow.  
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Receiving all the dispute cases from the clients  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Tracking and prioritizing the dispute cases 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Collecting necessary data and resolving disputes 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Submitting the dispute resolution to the team  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Informing the customers after dispute resolution 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Receivable Management/Group 225.webp"
                        alt="Dispute Handling"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Management p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal4">
                <h3 class="text-black font-34-19 clickable-heading">
                    Payment Receipt & Allocation 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal4"
              tabindex="-1"
              aria-labelledby="exampleModalLabel4"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Payment Receipt & Allocation </h3>
                        <p class="text-dark font-20-16">
                            Automate your payment receipt and allocation for faster and error-free processing. Our receivable management services experts ensure every payment is accurately tracked and allocated, improving efficiency and providing your team with updated financial data.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Collecting payments from the customers  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Recording the incoming payment receipts  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Allocating the payments to the customers' accounts 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Resolving payment discrepancies if any 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Updating cash receipts and doing <a class="anchor" target="_blank" href="/services/accounts-reconciliation-services">&nbsp; accounts reconciliation </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Receivable Management/Group 226.webp"
                        alt="Payment Receipt & Allocation Services"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Endorsements p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal5">
                <h3 class="text-black font-34-19 clickable-heading">
                    Receivable Reporting 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal Policy-Processing fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel5"
        aria-hidden="true"
      >
        <div class="modal-dialog policy-dialog pt-0 mt-0">
          <div class="modal-content">
            <div class="modal-header policy-header">
              <img
                data-bs-dismiss="modal"
                aria-label="Close"
                 appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
              />
            </div>
            <div class="card policy-processing-services-card mx-5">
              <div class="details ms-4">
                <div class="content">
                  <h3 class="font-48-30 text-dark">Receivable Reporting </h3>
                  <p class="text-dark font-20-16">
                    Get comprehensive receivable reporting services to gain detailed insights into your cash flow management. With intuitive dashboards and detailed analytics, monitor outstanding invoices and make informed strategic decisions right away. 
                  </p>
                  <ul class="ul-policy">
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Establishing credit policy for the customer  
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Generating invoices and recording the sale 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Collecting payment information from the customer
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Reconciling customers’ accounts to ensure timely payments 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Managing all the bad debts of the customers 
                    </li>
                  </ul>
                </div>
              </div>
              <div class="imgBx d-none d-lg-block">
                <img
                   appCdnUrl="assets/accoutning Receivable Management/Group 227.webp"
                  alt="Receivable Reporting Services "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile View -->
    <div class="p-3">
      <div class="d-block d-lg-none">
        <div class="row">
          <div class="col-12 mt-0 mt-sm-4">
            <div class="accordion" id="ServicePage">
              <div class="policy-processing" data-aos="zoom-in">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#Policy_Processing"
                  aria-expanded="true"
                  aria-controls="Policy_Processing"
                  id="headingOne"
                  class="headingCTA CTA-Zindex d-flex  p-3"
                  data-bs-toggle="modal"
                    data-bs-target="#MobModal"
                >
                  <h3 class="CTAs text-black fs-24 me-3">Customer Invoices </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal"
                tabindex="-1"
                aria-labelledby="MobModalModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Customer Invoices</h3>
                          <p class="text-dark font-20-16">
                            Effortlessly generate and deliver accurate customer invoices with our receivable management services. We ensure a seamless billing process, reducing manual errors and streamlining receivables while providing 100% clarity.   
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Assessing the customer's creditworthiness  
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Generating an invoice   
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Sending an invoice to the customer  
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Tracking the invoice to ensure payment received  
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Reconciling the received payment 
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#Claims_Management"
                  aria-expanded="true"
                  aria-controls="Claims_Management"
                  id="headingOne"
                  class="headingCTA CTA-Zindex d-flex  p-3"
                  data-bs-toggle="modal"
                    data-bs-target="#MobModal1"
                >
                  <h3 class=" CTAs text-black fs-24  me-3">Customer Statement Reconciliation </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal1"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal1"
                  tabindex="-1"
                  aria-labelledby="MobModal1Lable1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">Customer Statement Reconciliation </h3>
                            <p class="text-dark font-20-16">
                                Simplify your customer statement reconciliation with automated tools, ensuring swift transactions. Our team quickly identifies discrepancies and maintains accurate financial records for improved cash flow visibility.  
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Collecting invoices, receipts, and other data 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Comparing account entries with corresponding invoices  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Investigating unmatched items or entries to resolve discrepancies 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Making necessary adjustments to the accounts 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Conducting regular reconciliations on a monthly or quarterly basis  
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal2"
                >
                  <h3 class=" CTAs text-black fs-24  me-3">
                    Revenue Recognition 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal2"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal2"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel2"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Revenue Recognition  
                            </h3>
                            <p class="text-dark font-20-16">
                                Adopt compliant and transparent revenue recognition processes tailored to your business needs. Our accounts receivable services accurately capture your revenue streams, ensuring precise <a class="anchor" target="_blank" href="/services/accounting-and-financial-reporting-services">financial reporting</a> and aiding in compliance. 
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Evaluating the contract with the customers  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Analyzing the performance obligations within the contract
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Determining the amount of revenue considerations  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Additional transactional pricing to each performance obligation  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Recognizing revenue after performance obligation 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal3"
                >
                  <h3 class=" CTAs text-black fs-24  me-3">Dispute Handling  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal3"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal3"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel3"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">Dispute Handling  </h3>
                            <p class="text-dark font-20-16">
                                Effectively manage your customer disputes with our structured workflow, ensuring swift resolution. Streamline communication, maintain accurate records, and minimize delays to foster better customer relationships and consistent cash flow.  
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Receiving all the dispute cases from the clients 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Tracking and prioritizing the dispute cases  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Collecting necessary data and resolving disputes 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Submitting the dispute resolution to the team 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Informing the customers after dispute resolution
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal4"
                >
                  <h3 class=" CTAs text-black fs-24  me-3">
                    Payment Receipt & Allocation  
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal4"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal4"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel4"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Payment Receipt & Allocation 
                            </h3>
                            <p class="text-dark font-20-16">
                                Automate your payment receipt and allocation for faster and error-free processing. Our receivable management services experts ensure every payment is accurately tracked and allocated, improving efficiency and providing your team with updated financial data.   
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Collecting payments from the customers 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Recording the incoming payment receipts  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Allocating the payments to the customers' accounts 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Resolving payment discrepancies if any 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Updating cash receipts and doing <a class="anchor" target="_blank" href="/services/accounts-reconciliation-services">&nbsp; accounts reconciliation </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div id="headingOne" class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal5"
                >
                  <h3 class=" CTAs text-black fs-24  me-3">
                    Receivable Reporting 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal5"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal5"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel5"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Receivable Reporting  
                            </h3>
                            <p class="text-dark font-20-16">
                                Get comprehensive receivable reporting services to gain detailed insights into your cash flow management. With intuitive dashboards and detailed analytics, monitor outstanding invoices and make informed strategic decisions right away.  
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Establishing credit policy for the customer  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Generating invoices and recording the sale  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Collecting payment information from the customer 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Reconciling customers’ accounts to ensure timely payments 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Managing all the bad debts of the customers  
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <img class="mt-5" appCdnUrl="assets/Icon/white-snake-vector.svg" />
    </div>
  </section>
  <section class="container py-5">
    <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
    <div class="d-none d-xl-flex">
      <div class="row">
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool QuickBooks "  appCdnUrl="assets/Tools/quickbooks.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"/>
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
        </div>
      </div>
    </div>
    <div class="d-flex d-xl-none">
      <div class="trusted-slider-logo">
        <div class="trusted-slide-track">
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Thinking -->
  <app-service-case-study  [category]="[{ category: 'accountingandbookkeeping' }]"></app-service-case-study>
  <!-- Know best -->
<app-know-best
[cards]="[
  { description: 'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
  name: 'Francesca Furlanetto', designation: 'Director Owner'
 },
  { description: 'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
  name: 'Monica Spivey', designation: 'Directory of Accounting'
 },

]"
  [videos]="[
     { 
     thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
     alt:'Listen to our client '
     },
     {
     
      thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt:'Our Success Stories'
    },
  ]">
</app-know-best>
  <section class="py-6">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6" data-aos="fade-right">
          <app-faq></app-faq>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0" data-aos="fade-left">
          <div class="accordion" id="accordionFAQ">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-28-20"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Industries"
                  aria-expanded="true"
                  aria-controls="Industries"
                >
                Why should I choose you?  
                </button>
              </h2>
              <div
                id="Industries"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    You should partner with us if you are looking for:
                    <ul class="pt-3">
                        <li>360-degree assistance that meets your dynamic business needs round the clock </li>
                        <li>Professionally trained employees </li>
                        <li>Buck-up services at zero additional cost </li>
                        <li>Dedicated <a class="anchor" target="_blank" href="/services/customer-support-outsourcing">customer support</a>, vendor management, and much more </li>
                      </ul> 
                      Partnering with us means aligning with the industry excellence. 
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Services"
                  aria-expanded="false"
                  aria-controls="Services"
                >
                Is there any benefit to outsourcing accounts receivable management?
                </button>
              </h2>
              <div
                id="Services"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    Outsourcing account receivable management can free up your time and resources, allowing you to focus on other business aspects. You can ensure cost savings, increased efficiency, and get access to the latest tools and technologies to ensure smooth account receivables.                         
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#About"
                  aria-expanded="false"
                  aria-controls="About"
                >
                How does your outsourcing company handle Accounts Receivable management?   
                </button>
              </h2>
              <div
                id="About"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    Our outsourcing company follows a comprehensive process for managing accounts receivable. Here is a quick glimpse of the process we follow -
                    <ul class="pt-3">
                        <li>Processing the invoices </li>
                        <li>Tracking customers payments </li>
                        <li>Tracking and resolving customer queries </li>
                        <li>Ensuring debt recovery </li>
                        <li>Conducting final reporting </li>
                      </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Resources"
                  aria-expanded="false"
                  aria-controls="Resources"
                >
                What is your tool's expertise? 
                </button>
              </h2>
              <div
                id="Resources"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    Our team is well-versed with using and integrating all the latest tools and technologies required to ensure smooth accounting and bookkeeping. Here are some of the major tools we have hands-on experience of.   
                    <ul class="pt-3">
                        <li>QuickBooks </li>
                        <li>Xero </li>
                        <li>Zoho </li>
                        <li>Oddo </li>
                        <li>Sage </li>
                        <li>NetSuite  </li>
                      </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button font-28-20 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#differentiators"
                    aria-expanded="false"
                    aria-controls="differentiators"
                  >
                  How do you expedite the account receivable procedures?   
                  </button>
                </h2>
                <div
                  id="differentiators"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionFAQ"
                >
                  <div class="accordion-body">
                    For expediting the account receivable, we do cash collections for the amount overdue, due and will be due in the near future.     
                    
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  