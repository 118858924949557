<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/accoutning genral ledger/main-banner.jpg"
      class="card-img-desktop desktop-banner"
      alt="General Ledger Accounting Services "
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/accounting-bookkeeping-services"
                  >Accounting & Bookkeeping</a
                >
              </li>
              <li class="breadcrumb-item active">
                <a aria-current="page">General Ledger Accounting</a>
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary my-auto pt-3">
            General Ledger Accounting Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Efficiently manage all your accounting books and ledger like an
            expert. Choose our high-quality general ledger accounting services
            and get more time to focus on your core business activities. Book a
            consultation with our experts today!
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA mt-2">
            <h3 class="text-primary font-24-16 clickable-heading">
              Connect with Experts
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      appCdnUrl="assets/accoutning genral ledger/Mobile-banner.jpg"
      class="card-img-mob"
      alt="General Ledger Accounting Services "
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/accounting-bookkeeping-services"
                >Accounting & Bookkeeping</a
              >
            </li>
            <li class="breadcrumb-item active">
              <a aria-current="page">General Ledger Accounting</a>
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          General Ledger Accounting Services
        </h1>
        <p class="card-text font-20-16">
          Efficiently manage all your accounting books and ledger like an
          expert. Choose our high-quality general ledger accounting services and
          get more time to focus on your core business activities. Book a
          consultation with our experts today!
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Connect with Experts
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>
<app-ourAssets></app-ourAssets>
<section
  class="container-fluid policy-process-service"
  id="general ledger services"
>
  <div class="container">
    <div class="container_emp ps-lg-3">
      <h2 class="text-white font-48-30 py-3" data-aos="fade-up">
        Empower Change with General Ledger Accounting Services
      </h2>
      <img
        appCdnUrl="assets/Icon/squarevector.svg"
        class="top-right-float vector mt-4"
      />
    </div>
    <div class="d-none d-lg-block">
      <div class="row py-3 px-2">
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Issuance p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                MIS Reporting
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <!-- Modal -->
          <div
            class="modal Policy-Processing fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">MIS Reporting</h3>
                      <p class="text-dark font-20-16">
                        Systematically record, categorize, and analyze your
                        financial transactions with our top-notch MIS Reporting
                        services. Our team carefully monitors and presents the
                        information required for effective cash management.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Gathering all the financial documents
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Recording transactions within the general ledger
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Comparing all the discrepancies
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Changing discrepancies and updating document
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Generating balance sheets and reports
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning genral ledger/Group 198.webp"
                      alt="MIS Reporting Services "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy-Checking p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Budgeting & Forecasting
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">
                        Budgeting & Forecasting
                      </h3>
                      <p class="text-dark font-20-16">
                        Experience strategic financial management with our
                        budgeting and forecasting services. Our budgeting
                        encompasses detailed budget creation, financial
                        performance forecasting, and comparison of actual
                        results with budgeted figures.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Choosing best suitable budgeting tool
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Setting up the accounts
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Recording transactions within the account
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Balancing the ledger
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          /><a
                            href="/services/accounting-and-financial-reporting-services"
                            class="anchor"
                            >Preparing budgeting financial reports</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning genral ledger/Group 199.webp"
                      alt="Budgeting & Forecasting Services "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Loss_Runs_Processing p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Bank Reconciliation
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel2"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Bank Reconciliation</h3>
                      <p class="text-dark font-20-16">
                        Are you struggling to reconcile the accounts in your
                        general ledger and want to obtain an accurate general
                        ledger? Avail bank
                        <a
                          class="anchor"
                          target="_blank"
                          href="/services/accounts-reconciliation-services"
                          >reconciliation services</a
                        >
                        from us and efficiently reconcile your accounts like an
                        expert.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Collecting all the necessary documents
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Comparing transactions in the ledgers
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Identifying and investigating discrepancies
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Making changes within the document
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Reviewing the reconciliation process
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning genral ledger/Group 200.webp"
                      alt="Bank Reconciliation Services "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Renewals p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal3"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Centralized Accounting
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal3"
            tabindex="-1"
            aria-labelledby="exampleModalLabel3"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">
                        Centralized Accounting
                      </h3>
                      <p class="text-dark font-20-16">
                        Leave all the concerns regarding your
                        <a
                          class="anchor"
                          target="_blank"
                          href="/services/accounts-receivable-management"
                          >account receivables</a
                        >
                        and payables with us. Get general ledger centralized
                        account solutions to our professionals and ensure 100%
                        accuracy and efficiency within your accounting
                        activities.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Identifying and organizing accounts
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Assigning a unique account number
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Doing general entries into the ledger
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Calculating the total balance monthly or yearly
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Preparing financial statements and closing
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning genral ledger/Group 201.webp"
                      alt="Centralized Accounting Services "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Management p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal4"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                SOPs
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal4"
            tabindex="-1"
            aria-labelledby="exampleModalLabel4"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">SOPs</h3>
                      <p class="text-dark font-20-16">
                        Manage all your accounting operations internally and
                        externally with streamlined general ledger SOPs. Our
                        team will help you keep track of your financial
                        transactions and maintain an accurate general ledger.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Gathering the data that needs to be recorded
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Categorizing all the transactions
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Recording each transaction in the ledger
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Reconciling the general ledger
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Preparing financial statements and reviewing them
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning genral ledger/Group 202.webp"
                      alt="SOPs "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Endorsements p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal5"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Intercompany JE’s and Reconciliation
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>

          <div
            class="modal Policy-Processing fade"
            id="exampleModal5"
            tabindex="-1"
            aria-labelledby="exampleModalLabel5"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">
                        Intercompany JE’s and Reconciliation
                      </h3>
                      <p class="text-dark font-20-16">
                        Seamlessly track debit and credit transactions posted to
                        the general ledger while verifying the transactions
                        happening between two legal entries. Get intercompany
                        JEs and reconciliation services from us.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Reconciling accounts by eliminating duplicate
                          entries
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Doing journal entry for intercompany accounting
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Beginning the intercompany reconciliation process
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Reviewing each company transaction and comparing
                          them
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Using tools to simplify the process of entries into
                          the general ledger
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning genral ledger/Group 203.webp"
                      alt="Intercompany JE’s and Reconciliation Services "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="p-3">
    <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-12 mt-0 mt-sm-4">
          <div class="accordion" id="ServicePage">
            <div class="policy-processing" data-aos="zoom-in">
              <div
                data-bs-toggle="collapse"
                data-bs-target="#Policy_Processing"
                aria-expanded="true"
                aria-controls="Policy_Processing"
                id="headingOne"
                class="headingCTA CTA-Zindex d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal"
              >
                <h3 class="CTAs text-black fs-24  me-3">MIS Reporting</h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal"
              tabindex="-1"
              aria-labelledby="MobModalModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">MIS Reporting</h3>
                        <p class="text-dark font-20-16">
                          Systematically record, categorize, and analyze your
                          financial transactions with our top-notch MIS
                          Reporting services. Our team carefully monitors and
                          presents the information required for effective cash
                          management.
                        </p>
                        <ul class="ul-policy">
                          <li
                            class="list-item list-item pt-2 text-dark font-20-16"
                          >
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering all the financial documents
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Recording transactions within the general ledger
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Comparing all the discrepancies
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Changing discrepancies and updating document
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Generating balance sheets and reports
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                data-bs-toggle="collapse"
                data-bs-target="#Claims_Management"
                aria-expanded="true"
                aria-controls="Claims_Management"
                id="headingOne"
                class="headingCTA CTA-Zindex d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal1"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Budgeting & Forecasting
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal1"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal1"
              tabindex="-1"
              aria-labelledby="MobModal1Lable1"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">
                          Budgeting & Forecasting
                        </h3>
                        <p class="text-dark font-20-16">
                          Experience strategic financial management with our
                          budgeting and forecasting services. Our budgeting
                          encompasses detailed budget creation, financial
                          performance forecasting, and comparison of actual
                          results with budgeted figures.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Choosing best suitable budgeting tool
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Setting up the accounts
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Recording transactions within the account
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Balancing the ledger
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            /><a
                              href="/services/accounting-and-financial-reporting-services"
                              class="anchor"
                              >Preparing budgeting financial reports</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                class="d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal2"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Bank Reconciliation
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal2"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal2"
              tabindex="-1"
              aria-labelledby="MobModalLabel2"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">
                          Bank Reconciliation
                        </h3>
                        <p class="text-dark font-20-16">
                          Are you struggling to reconcile the accounts in your
                          general ledger and want to obtain an accurate general
                          ledger? Avail bank
                          <a
                            class="anchor"
                            target="_blank"
                            href="/services/accounts-reconciliation-services"
                            >reconciliation services</a
                          >
                          from us and efficiently reconcile your accounts like
                          an expert.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Collecting all the necessary documents
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Comparing transactions in the ledgers
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying and investigating discrepancies
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Making changes within the document
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reviewing the reconciliation process
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                class="d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal3"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Centralized Accounting
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal3"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal3"
              tabindex="-1"
              aria-labelledby="MobModalLabel3"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">
                          Centralized Accounting
                        </h3>
                        <p class="text-dark font-20-16">
                          Leave all the concerns regarding your
                          <a
                            class="anchor"
                            target="_blank"
                            href="/services/accounts-receivable-management"
                            >account receivables</a
                          >
                          and payables with us. Get general ledger centralized
                          account solutions to our professionals and ensure 100%
                          accuracy and efficiency within your accounting
                          activities.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying and organizing accounts
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Assigning a unique account number
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Doing general entries into the ledger
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Calculating the total balance monthly or yearly
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Preparing financial statements and closing
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                class="d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal4"
              >
                <h3 class="CTAs text-black fs-24  me-3">SOPs</h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal4"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal4"
              tabindex="-1"
              aria-labelledby="MobModalLabel4"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">SOPs</h3>
                        <p class="text-dark font-20-16">
                          Manage all your accounting operations internally and
                          externally with streamlined general ledger SOPs. Our
                          team will help you keep track of your financial
                          transactions and maintain an accurate general ledger.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering the data that needs to be recorded
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Categorizing all the transactions
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Recording each transaction in the ledger
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reconciling the general ledger
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Preparing financial statements and reviewing them
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                id="headingOne"
                class="d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal5"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Intercompany JE’s and Reconciliation
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal5"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal5"
              tabindex="-1"
              aria-labelledby="MobModalLabel5"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">
                          Intercompany JE’s and Reconciliation
                        </h3>
                        <p class="text-dark font-20-16">
                          Seamlessly track debit and credit transactions posted
                          to the general ledger while verifying the transactions
                          happening between two legal entries. Get intercompany
                          JEs and reconciliation services from us.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reconciling accounts by eliminating duplicate
                            entries
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Doing journal entry for intercompany accounting
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Beginning the intercompany reconciliation process
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reviewing each company transaction and comparing
                            them
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Using tools to simplify the process of entries
                            into the general ledger
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-5" appCdnUrl="assets/Icon/white-snake-vector.svg" />
  </div>
</section>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool QuickBooks "  appCdnUrl="assets/Tools/quickbooks.svg" />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg" />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"/>
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Thinking -->
<app-service-case-study
  [category]="[{ category: 'accountingandbookkeeping' }]"
></app-service-case-study>

<!-- Know best -->
<app-know-best
  [cards]="[
    {
      description:
        'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
      name: 'Francesca Furlanetto',
      designation: 'Director Owner'
    },
    {
      description:
        'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
      name: 'Monica Spivey',
      designation: 'Directory of Accounting'
    }
  ]"
  [videos]="[
{ 
 thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
 alt:'Listen to our client '
 },
 {

  thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
  alt:'Our Success Stories '
},
]"
>
</app-know-best>
<section class="py-6">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6" data-aos="fade-right">
        <app-faq></app-faq>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0" data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button font-28-20"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Industries"
                aria-expanded="true"
                aria-controls="Industries"
              >
                What are the benefits of outsourcing accounting services?
              </button>
            </h2>
            <div
              id="Industries"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Accounting serves a crucial function for any business, but only
                if you hold the expertise. So, when you outsource general ledger
                accounting, here are the benefits you can experience -
                <ul class="pt-3">
                  <li>Access to expertise and specialization</li>
                  <li>Save a huge cost</li>
                  <li>Scalability and flexibility </li>
                  <li>Streamlined operations</li>
                  <li>Accurate accounting </li>
                  <li>Round-the-clock availability</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Services"
                aria-expanded="false"
                aria-controls="Services"
              >
                What is the process of general ledger accounting?
              </button>
            </h2>
            <div
              id="Services"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                We follow a very transparent general ledger accounting service.
                Here is a simplified process we follow -
                <ul class="pt-3">
                  <li>Understanding the client's requirements</li>
                  <li>Establishing business goals</li>
                  <li>Planning a general ledger accounting solution</li>
                  <li>Managing general ledger accounting activities</li>
                  <li>Preparing general ledger accounting reports</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#About"
                aria-expanded="false"
                aria-controls="About"
              >
                Why should I choose you for accounting services?
              </button>
            </h2>
            <div
              id="About"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                While outsourcing general ledger accounting services to us, you
                get access to a wide range of perks, such as -
                <ul class="pt-3">
                  <li>All-in-one expertise</li>
                  <li>Availability as per your time zone</li>
                  <li>Quick turnaround time</li>
                  <li>Tailor-made solutions</li>
                  <li>99% accuracy</li>
                  <li>Scalable solutions</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Resources"
                aria-expanded="false"
                aria-controls="Resources"
              >
                What type of general ledger accounting solutions do you offer?
              </button>
            </h2>
            <div
              id="Resources"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                We offer multiple types of general ledger solutions, ranging
                from MIS, SOPs, Budgeting, Adhoc JEs, Bank Reconciliations, and
                more. Bring your
                <a
                  class="anchor"
                  href="/services/accounting-bookkeeping-services"
                  >accounting and bookkeeping requirements</a
                >
                to us and let our experts offer you the best solution that will
                help you keep your financial transactions on track while helping
                you focus on the core business activities.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#differentiators"
                aria-expanded="false"
                aria-controls="differentiators"
              >
                What is the major software you use?
              </button>
            </h2>
            <div
              id="differentiators"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Although our services aren’t specific to using a particular
                software, our professionals are well-versed in integrating all
                types of software ranging from QuickBooks, Zoho, Xero, Sage,
                Oddo, Sage NetSuite, and more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
