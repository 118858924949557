import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { HubspotService } from '../../../services/hubspot/hubspot.service';
import {
  customEmailValidator,
  requiredButNotAllowBlank,
} from '../../../utils/Validator';
import { NoNumbersDirective } from '../../../directives/noNumbers.directive';
import { OnlyNumbersDirective } from '../../../directives/onlyNumbers.directive';
import { EmailService } from '../../../services/connect_email_service/connect_email';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    NoNumbersDirective,
    OnlyNumbersDirective,
  ],
})
export class ConnectComponent implements OnInit {
  LetsConnectForm!: FormGroup;
  submitted = false;
  selectedCountryCode: string = '+91'; // Default country code set to India (+91)
  countryList: { name: string; code: string }[] = []; // Country list

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private hubspotService: HubspotService,
    private emailService: EmailService // Inject EmailService
  ) {}

  ngOnInit(): void {
    // Initialize the form with default values
    this.LetsConnectForm = this.formBuilder.group({
      firstname: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      country: ['+91', Validators.required], // Default country selection is India (+91)
      phone: [
        '',
        [
          requiredButNotAllowBlank(),
          Validators.minLength(10),
          Validators.maxLength(15),
          Validators.pattern(/^\d+$/), // Ensure only digits
        ],
      ],
      company: ['', requiredButNotAllowBlank()],
      write_us: ['', requiredButNotAllowBlank()],
    });

    // Initialize country list dynamically from a static JSON or API
    this.countryList = this.getCountryList();

    // Set initial country code to the form value
    this.selectedCountryCode = this.LetsConnectForm.value.country;
  }

  // This function simulates loading country codes from a JSON or API endpoint
  getCountryList(): { name: string; code: string }[] {
    return [
      { name: 'AFG', code: '+93' },
      { name: 'ALB', code: '+355' },
      { name: 'DZA', code: '+213' },
      { name: 'ASM', code: '+1 684' },
      { name: 'AND', code: '+376' },
      { name: 'AGO', code: '+244' },
      { name: 'AIA', code: '+1 264' },
      { name: 'ATA', code: '+672' },
      { name: 'ATG', code: '+1 268' },
      { name: 'ARG', code: '+54' },
      { name: 'ARM', code: '+374' },
      { name: 'ABW', code: '+297' },
      { name: 'SHN', code: '+247' },  // Ascension Island is part of Saint Helena
      { name: 'AUS', code: '+61' },
      { name: 'AUT', code: '+43' },
      { name: 'AZE', code: '+994' },
      { name: 'BHS', code: '+1 242' },
      { name: 'BHR', code: '+973' },
      { name: 'BGD', code: '+880' },
      { name: 'BRB', code: '+1 246' },
      { name: 'BLR', code: '+375' },
      { name: 'BEL', code: '+32' },
      { name: 'BLZ', code: '+501' },
      { name: 'BEN', code: '+229' },
      { name: 'BMU', code: '+1 441' },
      { name: 'BTN', code: '+975' },
      { name: 'BOL', code: '+591' },
      { name: 'BIH', code: '+387' },
      { name: 'BWA', code: '+267' },
      { name: 'BRA', code: '+55' },
      { name: 'VGB', code: '+1 284' },
      { name: 'BRN', code: '+673' },
      { name: 'BGR', code: '+359' },
      { name: 'BFA', code: '+226' },
      { name: 'MMR', code: '+95' },
      { name: 'BDI', code: '+257' },
      { name: 'KHM', code: '+855' },
      { name: 'CMR', code: '+237' },
      { name: 'CAN', code: '+1' },
      { name: 'CPV', code: '+238' },
      { name: 'CYM', code: '+1 345' },
      { name: 'CAF', code: '+236' },
      { name: 'TCD', code: '+235' },
      { name: 'CHL', code: '+56' },
      { name: 'CHN', code: '+86' },
      { name: 'CXR', code: '+61' },
      { name: 'CCK', code: '+61' },
      { name: 'COL', code: '+57' },
      { name: 'COM', code: '+269' },
      { name: 'COG', code: '+242' },
      { name: 'COK', code: '+682' },
      { name: 'CRI', code: '+506' },
      { name: 'HRV', code: '+385' },
      { name: 'CUB', code: '+53' },
      { name: 'CYP', code: '+357' },
      { name: 'CZE', code: '+420' },
      { name: 'COD', code: '+243' },
      { name: 'DNK', code: '+45' },
      { name: 'DGA', code: '+246' },
      { name: 'DJI', code: '+253' },
      { name: 'DMA', code: '+1 767' },
      { name: 'DOM', code: '+1 809' },
      { name: 'ECU', code: '+593' },
      { name: 'EGY', code: '+20' },
      { name: 'SLV', code: '+503' },
      { name: 'GNQ', code: '+240' },
      { name: 'ERI', code: '+291' },
      { name: 'EST', code: '+372' },
      { name: 'ETH', code: '+251' },
      { name: 'FLK', code: '+500' },
      { name: 'FRO', code: '+298' },
      { name: 'FJI', code: '+679' },
      { name: 'FIN', code: '+358' },
      { name: 'FRA', code: '+33' },
      { name: 'GUF', code: '+594' },
      { name: 'PYF', code: '+689' },
      { name: 'GAB', code: '+241' },
      { name: 'GMB', code: '+220' },
      { name: 'GEO', code: '+995' },
      { name: 'DEU', code: '+49' },
      { name: 'GHA', code: '+233' },
      { name: 'GIB', code: '+350' },
      { name: 'GRC', code: '+30' },
      { name: 'GRL', code: '+299' },
      { name: 'GRD', code: '+1 473' },
      { name: 'GLP', code: '+590' },
      { name: 'GUM', code: '+1 671' },
      { name: 'GTM', code: '+502' },
      { name: 'GIN', code: '+224' },
      { name: 'GNB', code: '+245' },
      { name: 'GUY', code: '+592' },
      { name: 'HTI', code: '+509' },
      { name: 'VAT', code: '+39' },
      { name: 'HND', code: '+504' },
      { name: 'HKG', code: '+852' },
      { name: 'HUN', code: '+36' },
      { name: 'ISL', code: '+354' },
      { name: 'IND', code: '+91' },
      { name: 'IDN', code: '+62' },
      { name: 'IRN', code: '+98' },
      { name: 'IRQ', code: '+964' },
      { name: 'IRL', code: '+353' },
      { name: 'IMN', code: '+44' },
      { name: 'ISR', code: '+972' },
      { name: 'ITA', code: '+39' },
      { name: 'CIV', code: '+225' },
      { name: 'JAM', code: '+1 876' },
      { name: 'JPN', code: '+81' },
      { name: 'JEY', code: '+44' },
      { name: 'JOR', code: '+962' },
      { name: 'KAZ', code: '+7' },
      { name: 'KEN', code: '+254' },
      { name: 'KIR', code: '+686' },
      { name: 'KWT', code: '+965' },
      { name: 'KGZ', code: '+996' },
      { name: 'LAO', code: '+856' },
      { name: 'LVA', code: '+371' },
      { name: 'LBN', code: '+961' },
      { name: 'LSO', code: '+266' },
      { name: 'LBR', code: '+231' },
      { name: 'LBY', code: '+218' },
      { name: 'LIE', code: '+423' },
      { name: 'LTU', code: '+370' },
      { name: 'LUX', code: '+352' },
      { name: 'MAC', code: '+853' },
      { name: 'MKD', code: '+389' },
      { name: 'MDG', code: '+261' },
      { name: 'MWI', code: '+265' },
      { name: 'MYS', code: '+60' },
      { name: 'MDV', code: '+960' },
      { name: 'MLI', code: '+223' },
      { name: 'MLT', code: '+356' },
      { name: 'MHL', code: '+692' },
      { name: 'MTQ', code: '+596' },
      { name: 'MRT', code: '+222' },
      { name: 'MUS', code: '+230' },
      { name: 'MYT', code: '+262' },
      { name: 'MEX', code: '+52' },
      { name: 'FSM', code: '+691' },
      { name: 'MDA', code: '+373' },
      { name: 'MCO', code: '+377' },
      { name: 'MNG', code: '+976' },
      { name: 'MNE', code: '+382' },
      { name: 'MSR', code: '+1 664' },
      { name: 'MAR', code: '+212' },
      { name: 'MOZ', code: '+258' },
      { name: 'NAM', code: '+264' },
      { name: 'NRU', code: '+674' },
      { name: 'NPL', code: '+977' },
      { name: 'NLD', code: '+31' },
      { name: 'NCL', code: '+687' },
      { name: 'NZL', code: '+64' },
      { name: 'NIC', code: '+505' },
      { name: 'NER', code: '+227' },
      { name: 'NGA', code: '+234' },
      { name: 'NIU', code: '+683' },
      { name: 'NFK', code: '+672' },
      { name: 'PRK', code: '+850' },
      { name: 'MNP', code: '+1 670' },
      { name: 'NOR', code: '+47' },
      { name: 'OMN', code: '+968' },
      { name: 'PAK', code: '+92' },
      { name: 'PLW', code: '+680' },
      { name: 'PSE', code: '+970' },
      { name: 'PAN', code: '+507' },
      { name: 'PNG', code: '+675' },
      { name: 'PRY', code: '+595' },
      { name: 'PER', code: '+51' },
      { name: 'PHL', code: '+63' },
      { name: 'PCN', code: '+870' },
      { name: 'POL', code: '+48' },
      { name: 'PRT', code: '+351' },
      { name: 'PRI', code: '+1 787' },
      { name: 'QAT', code: '+974' },
      { name: 'COG', code: '+242' },
      { name: 'REU', code: '+262' },
      { name: 'ROU', code: '+40' },
      { name: 'RUS', code: '+7' },
      { name: 'RWA', code: '+250' },
      { name: 'BLM', code: '+590' },
      { name: 'SHN', code: '+290' },
      { name: 'KNA', code: '+1 869' },
      { name: 'LCA', code: '+1 758' },
      { name: 'MAF', code: '+590' },
      { name: 'SPM', code: '+508' },
      { name: 'VCT', code: '+1 784' },
      { name: 'WSM', code: '+685' },
      { name: 'SMR', code: '+378' },
      { name: 'STP', code: '+239' },
      { name: 'SAU', code: '+966' },
      { name: 'SEN', code: '+221' },
      { name: 'SRB', code: '+381' },
      { name: 'SYC', code: '+248' },
      { name: 'SLE', code: '+232' },
      { name: 'SGP', code: '+65' },
      { name: 'SXM', code: '+1 721' },
      { name: 'SVK', code: '+421' },
      { name: 'SVN', code: '+386' },
      { name: 'SLB', code: '+677' },
      { name: 'SOM', code: '+252' },
      { name: 'ZAF', code: '+27' },
      { name: 'KOR', code: '+82' },
      { name: 'SSD', code: '+211' },
      { name: 'ESP', code: '+34' },
      { name: 'LKA', code: '+94' },
      { name: 'SDN', code: '+249' },
      { name: 'SUR', code: '+597' },
      { name: 'SWZ', code: '+268' },
      { name: 'SWE', code: '+46' },
      { name: 'CHE', code: '+41' },
      { name: 'SYR', code: '+963' },
      { name: 'TWN', code: '+886' },
      { name: 'TJK', code: '+992' },
      { name: 'TZA', code: '+255' },
      { name: 'THA', code: '+66' },
      { name: 'TLS', code: '+670' },
      { name: 'TGO', code: '+228' },
      { name: 'TKL', code: '+690' },
      { name: 'TON', code: '+676' },
      { name: 'TTO', code: '+1 868' },
      { name: 'TUN', code: '+216' },
      { name: 'TUR', code: '+90' },
      { name: 'TKM', code: '+993' },
      { name: 'TCA', code: '+1 649' },
      { name: 'TUV', code: '+688' },
      { name: 'UGA', code: '+256' },
      { name: 'UKR', code: '+380' },
      { name: 'ARE', code: '+971' },
      { name: 'GBR', code: '+44' },
      { name: 'USA', code: '+1' },
      { name: 'URY', code: '+598' },
      { name: 'VIR', code: '+1 340' },
      { name: 'UZB', code: '+998' },
      { name: 'VUT', code: '+678' },
      { name: 'VEN', code: '+58' },
      { name: 'VNM', code: '+84' },
      { name: 'WLF', code: '+681' },
      { name: 'ESH', code: '+212' },
      { name: 'YEM', code: '+967' },
      { name: 'ZMB', code: '+260' },
      { name: 'ZWE', code: '+263' },
    ];
  }

  // Accessor for form controls
  get LetsConnectFormf() {
    return this.LetsConnectForm.controls;
  }

  // Handler for country selection change
  onCountryChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCountryCode = selectElement.value;
  }

  // Form submission handler
  validateForm(form: FormGroup): void {
    this.submitted = true;
    if (form.invalid) {
      return;
    }

    // Concatenate country code and phone number
    const fullPhoneNumber = `${this.selectedCountryCode}${form.value.phone}`;

    // Prepare the form data
    const formData = {
      ...form.value,
      phone: fullPhoneNumber, // Replace phone field with concatenated value
    };

    const formId = '9752f77d-ad36-4a60-ac4d-e531fdf855a7';
    this.hubspotService.submitForm(formId, formData).subscribe(
      (response) => {
        console.log('Form submitted successfully:', response);
        this.router.navigate(['/thankyou']);
        // Send an email after successful submission
        this.emailService.sendEmail(formData).subscribe(
          (emailResponse) => {
            console.log('Email sent successfully:', emailResponse);
          },
          (error) => {
            console.error('Error sending email:', error);
          }
        );
      },
      (error) => {
        console.error('Error submitting form:', error);
        alert('Error submitting form. Please try again.');
      }
    );
  }
}
