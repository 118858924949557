<footer class="footer bg-secondary">
  <div class="container">
    <div class="row py-6">
      <div class="col-sm-12 col-md-8 col-lg-4 d-flex flex-column my-3">
        <img [src]="getcdnUrl('assets/Logo/Logo.svg')" class="footer-logo" />
        <div class="footer-parra">
          <p class="footer-text my-4 font-20-16">
            Fusion Business Solutions (P) Limited (FBSPL) is a leading B2B
            organization specializing in business process management and
            consulting. Since 2006, we’ve been streamlining operations and
            driving growth for businesses of all sizes.
          </p>
        </div>
        <div class="d-flex flex-wrap d-none d-md-flex">
          <div>
            <a
              href="https://www.facebook.com/FBSPLTeam"
              target="_blank"
              title="Facebook"
              rel="nofollow"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/facebook.svg')"
                alt="facebook"
              />
            </a>
          </div>
          <div>
            <a
              href="https://twitter.com/FBSPLTeam"
              target="_blank"
              title="Twitter"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/twitter.svg')"
                alt="twitter"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.youtube.com/@FBSPLTeam"
              target="_blank"
              title="Youtube"
              rel="nofollow"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/youtube.svg')"
                alt="youtube"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/fbsplteam/"
              target="_blank"
              title="Instagram"
              rel="nofollow"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/instagram.svg')"
                alt="instagram"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/fbsplteam"
              target="_blank"
              title="LinkedIn"
              rel="nofollow"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/linkedin.svg')"
                alt="linkedin"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-2 my-3 d-none d-md-block">
        <h3 class="footer-heading font-36-24">About</h3>
        <ul class="list-unstyled">
          <li>
            <a routerLink="/about-who-we-are" class="footerLink font-20-16">Who we are</a>
          </li>
          <li>
            <a routerLink="/our-leadership" class="footerLink font-20-16">Leadership</a>
          </li>
          <li>
            <a routerLink="/csr-social-responsibilities" class="footerLink font-20-16"
              >Social Responsibility</a
            >
          </li>
          <li>
            <a routerLink="/life-at-fbspl" class="footerLink font-20-16">Life at FBSPL</a>
          </li>
        </ul>
      </div>
      <div class="col-md-4 col-lg-2 my-3 d-none d-md-block">
        <h3 class="footer-heading font-36-24">Services</h3>
        <ul class="list-unstyled">
          <li>
            <a
              routerLink="/services/insurance-bpo-outsourcing"
              class="footerLink font-20-16"
              >Insurance</a
            >
          </li>
          <li>
            <a
              routerLink="/services/accounting-bookkeeping-services"
              class="footerLink font-20-16"
              >Accounting & Bookkeeping</a
            >
          </li>

          <li>
            <a
              routerLink="/services/data-annotation-services"
              class="footerLink font-20-16"
              >Data Annotation</a
            >
          </li>
          <li>
            <a
              routerLink="/services/recruitment-process-outsourcing-services"
              class="footerLink font-20-16"
              >Recruitment</a
            >
          </li>
          <li>
            <a
              routerLink="/services/customer-support-outsourcing"
              class="footerLink font-20-16"
              >Customer Support</a
            >
          </li>
          <li>
            <a
              routerLink="/services/insurance-agency-optimization-services"
              class="footerLink font-20-16"
              >Agency Optimization</a
            >
          </li>
          <li>
            <a routerLink="/services/digital-marketing" class="footerLink font-20-16"
              >Digital Marketing</a
            >
          </li>
          <li>
            <a
              routerLink="/services/e-commerce-outsourcing-services"
              class="footerLink font-20-16"
              >Ecommerce</a
            >
          </li>
        </ul>
      </div>
      <div class="col-md-4 col-lg-2 my-3 d-none d-md-block">
        <h3 class="footer-heading font-36-24">Resources</h3>
        <ul class="list-unstyled">
          <li><a routerLink="/blogs" class="footerLink font-20-16">Blog</a></li>
          <li>
            <a href="/case-studies" class="footerLink font-20-16">Client Stories</a>
          </li>
          <li><a routerLink="/podcast" class="footerLink font-20-16">Podcast</a></li>
          <li><a routerLink="/guides" class="footerLink font-20-16">Guides</a></li>
          <li>
            <a href="/client-testimonial-reviews" class="footerLink font-20-16">Videos</a>
          </li>
          <li><a routerLink="/news" class="footerLink font-20-16">In the news</a></li>


        </ul>
      </div>
      <div class="col-md-4 col-lg-2 my-3 d-none d-md-block">
        <h3 class="footer-heading font-36-24">Connect</h3>
        <ul class="list-unstyled">
          <li>
            <a routerLink="/book-free-consultation-call" class="footerLink font-20-16"
              >Book a Consultation</a
            >
          </li>
          <li><a routerLink="/careers/current-openings" class="footerLink font-20-16">Careers</a></li>
          <li>
            <a routerLink="/contact-us" class="footerLink font-20-16">Contact</a>
          </li>
        </ul>
      </div>
      <div class="d-block d-md-none">
        <div class="accordion" id="footerAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header " id="headingOne">
              <button
                class="accordion-button font-36-24"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Services"
                aria-expanded="true"
                aria-controls="Services"
              >
                Services
              </button>
            </h2>
            <div
              id="Services"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#footerAccordion"
            >
              <div class="accordion-body pt-0">
                <ul class="list-unstyled">
                  <li>
                    <a
                      routerLink="/services/insurance-bpo-outsourcing"
                      class="footerLink font-24-16"
                      >Insurance</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/services/accounting-bookkeeping-services"
                      class="footerLink font-24-16"
                      >Accounting & Bookkeeping</a
                    >
                  </li>

                  <li>
                    <a
                      routerLink="/services/data-annotation-services"
                      class="footerLink font-24-16"
                      >Data Annotation</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/services/recruitment-process-outsourcing-services"
                      class="footerLink font-24-16"
                      >Recruitment</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/services/customer-support-outsourcing"
                      class="footerLink font-24-16"
                      >Customer Support</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/services/insurance-agency-optimization-services"
                      class="footerLink font-24-16"
                      >Agency Optimization</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/services/digital-marketing"
                      class="footerLink font-24-16"
                      >Digital Marketing</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/services/e-commerce-outsourcing-services"
                      class="footerLink font-24-16"
                      >E-commerce</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed font-36-24"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#About"
                aria-expanded="false"
                aria-controls="About"
              >
                About
              </button>
            </h2>
            <div
              id="About"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#footerAccordion"
            >
              <div class="accordion-body pt-0">
                <ul class="list-unstyled">
                  <li class="my-2 font-24-16 ">
                    <a routerLink="/about-who-we-are" class="footerLink"
                      >Who we are</a
                    >
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a routerLink="/our-leadership" class="footerLink"
                      >Leadership</a
                    >
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a
                      routerLink="/csr-social-responsibilities"
                      class="footerLink"
                      >Social Responsibility</a
                    >
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a routerLink="/life-at-fbspl" class="footerLink"
                      >Life at FBSPL</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed font-36-24"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Resources"
                aria-expanded="false"
                aria-controls="Resources"
              >
                Resources
              </button>
            </h2>
            <div
              id="Resources"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#footerAccordion"
            >
              <div class="accordion-body pt-0">
                <ul class="list-unstyled">
                  <li class="my-2 font-24-16 ">
                    <a routerLink="/blogs" class="footerLink">Blog</a>
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a href="/case-studies" class="footerLink"
                      >Client Stories</a
                    >
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a routerLink="/podcast" class="footerLink">Podcast</a>
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a href="/client-testimonial-reviews" class="footerLink"
                      >Videos</a
                    >
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a routerLink="/news" class="footerLink">In the news</a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed font-36-24"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Connect"
                aria-expanded="false"
                aria-controls="Connect"
              >
                Connect
              </button>
            </h2>
            <div
              id="Connect"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#footerAccordion"
            >
              <div class="accordion-body pt-0">
                <ul class="list-unstyled">
                  <li class="my-2 font-24-16 ">
                    <a
                      routerLink="/book-free-consultation-call"
                      class="footerLink"
                      >Book a Consultation</a
                    >
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a routerLink="/careers/current-openings" class="footerLink">Careers</a>
                  </li>
                  <li class="my-2 font-24-16 ">
                    <a routerLink="/contact-us" class="footerLink">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-center mt-3">
          <div>
            <a
              href="https://www.facebook.com/FBSPLTeam"
              target="_blank"
              title="Facebook"
              rel="nofollow"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/facebook.svg')"
                alt="facebook"
              />
            </a>
          </div>
          <div>
            <a
              href="https://twitter.com/FBSPLTeam"
              target="_blank"
              title="Twitter"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/twitter.svg')"
                alt="twitter"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.youtube.com/@FBSPLTeam"
              target="_blank"
              title="Youtube"
              rel="nofollow"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/youtube.svg')"
                alt="youtube"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/fbsplteam/"
              target="_blank"
              title="Instagram"
              rel="nofollow"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/instagram.svg')"
                alt="instagram"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/fbsplteam"
              target="_blank"
              title="LinkedIn"
              rel="nofollow"
              class="footerLink"
              ><img
                class="logo upTranslate me-3"
                [src]="getcdnUrl('assets/Icon/linkedin.svg')"
                alt="linkedin"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-light py-3">
    <div class="container">
      <div class="row text-primary text-center lastParra">
        <div
          class="col-sm-12 col-lg-7 d-flex mb-3 mb-lg-0 flex-wrap text-center text-lg-start footerEndingLinks"
        >
          <span class="me-4 fs-14 "
            ><a
              routerLink="/terms-&-condition"
              class="footerLink text-decoration-underline"
              >Terms & Condition
            </a></span
          >
          <span class="me-4 fs-14 "
            ><a
              routerLink="/sitemap"
              class="footerLink text-decoration-underline"
              >Sitemap
            </a></span
          >
          <span class="me-4 fs-14 "
            ><a
              routerLink="/privacy-policy"
              class="footerLink text-decoration-underline"
              >Privacy Policy</a
            ></span
          >
          <span class="me-4 fs-14 "
            ><a
              routerLink="/security-measures"
              class="footerLink text-decoration-underline"
              >Security Measures
            </a></span
          >
          <span class="me-4 fs-14 "
            ><a
              [href]="Csr_Event"
              target="_blank"
              class="footerLink text-decoration-underline"
              >CSR Event</a
            ></span
          >
          <span class="me-4 fs-14 "
            ><a
              [href]="ISO2700"
              target="_blank"
              class="footerLink text-decoration-underline"
              >ISO 27001</a
            ></span
          >
          <span class="me-4 fs-14 "
            ><a
              [href]="ISO9001"
              target="_blank"
              class="footerLink text-decoration-underline"
              >ISO 9001</a
            ></span
          >
        </div>
        <div class="col-sm-12 col-lg-5 fs-14 ">
          © 2024 All Rights Reserved - Fusion Business Solutions (P) Limited
        </div>
      </div>
    </div>
  </div>
</footer>
