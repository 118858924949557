<div
  class="modal fade"
  id="headerModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  (shown.bs.modal)="onModalShown()"
  (hidden.bs.modal)="onModalHidden()"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  >
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content bordered bg-secondary overflow-visible">
      <button
        type="button"
        class="btn-close ms-auto position-absolute z-3 modalCrossButton"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="onClose()"
      ></button>
      <div class="modal-body">
        <div class="ratio ratio-16x9">
          <!-- <iframe #videoPlayer *ngIf="safeUrl" class="video-iframe | safe" allowfullscreen allow="clipboard-write"
        title="Vimeo Video Player" [src]="safeUrl" frameborder="0"></iframe> -->
        @if (safeVideoUrl) {
          <iframe
            #videoPlayer
            class="video-iframe | safe"
            allowfullscreen
            allow="clipboard-write"
            title="Vimeo Video Player"
            [src]="safeVideoUrl"
            frameborder="0"
          ></iframe>
        }
      </div>
    </div>
  </div>
</div>
</div>
