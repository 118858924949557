import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = environment.apiUrl; // Update with your email API endpoint

  constructor(private http: HttpClient) {}

  sendEmail(data: any): Observable<any> {
    const emailPayload = {
      subject: "FAQ",  // Subject field
      email:`${data.email}`,
      Brief_Requirement:`${data.write_your_message}`
       // Use \n\n for a new line between sections
    };

    return this.http.post(`${this.apiUrl}/api/hubspot/data`, emailPayload); // Replace with your backend API endpoint
  }
}
