import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  ElementRef,
  ViewChild,
  PLATFORM_ID,
  Inject,
  Renderer2
} from '@angular/core';
import { TrustedModule } from '../../components/HomeComponents/trustedSlider/trusted.module';
import { RouterLink } from '@angular/router';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { VimeoPlayerComponent } from '../../components/vimeo-player/vimeo-player.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    TrustedModule,
    RouterLink,
    VimeoPlayerComponent,
    CommonModule,
    CdnUrlDirective,
  ],
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit, OnDestroy {
  @ViewChild('AboutVision', { static: false }) AboutVision!: ElementRef;

  currentSection: 'Mission' | 'Vision' | 'Values' = 'Vision';
  private sections: ('Mission' | 'Vision' | 'Values')[] = [
    'Mission',
    'Vision',
    'Values',
  ];
  private sectionIndex = 0;
  private intervalId: any;
  showText = true;

  hideText() {
    this.showText = false;
  }
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: object,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'About FBSPL | Global Business Process Outsourcing & Management',
      description:
        'Get accurate and reliable general ledger accounting services with FBSPL. We specialize in accounting process outsourcing and management. Contact us today.',
      keywords: 'business process outsourcing, outsourcing business',
      ogImage: '/meta/about.jpg',
    };

    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/about-who-we-are' });
    this.startSectionRotation();
  }

  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
  // Check and add BreadcrumbList script
  if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
    const breadcrumbScript = this.renderer.createElement('script');
    breadcrumbScript.type = 'application/ld+json';
    breadcrumbScript.setAttribute('data-script', 'breadcrumb');
    breadcrumbScript.text = `
    { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "About FBSPL", 

      "item": "https://www.fbspl.com/about-who-we-are" 

    } 

  ] 

} `;
    this.renderer.appendChild(this.document.head, breadcrumbScript);
  }
  const alternateLinkDefault = this.renderer.createElement('link');
    
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/about-who-we-are`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
}
  }

  ngOnDestroy(): void {
    this.clearSectionRotation();
  }

  setSection(section: 'Mission' | 'Vision' | 'Values') {
    this.currentSection = section;
  }
  setSectiononClick(section: 'Mission' | 'Vision' | 'Values') {
    if (isPlatformBrowser(this.platformId)) {
      const isMobileView = window.innerWidth <= 768;

      if (isMobileView) {
        this.AboutVision.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          top: '-20',
        });
      }
    }

    this.currentSection = section;
  }
  private startSectionRotation(): void {
    this.ngZone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        this.ngZone.run(() => {
          if (this.sections[this.sectionIndex] !== undefined) {
            this.setSection(this.sections[this.sectionIndex]);
            this.sectionIndex = (this.sectionIndex + 1) % this.sections.length;
          } else {
            this.clearSectionRotation();
          }
        });
      }, 4000);
    });
  }

  private clearSectionRotation(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
