<div class="container text-dark d-none d-lg-block ">
  <div class="gradientBanner">
    <img alt="FBSPL Policy Processing Services"
       appCdnUrl="assets/insurane policy processing/Policy Processing Main Banner 1.jpg"
      class="card-img-desktop desktop-banner"
    
    />
    <div class="card-img-overlay overlay-text bannerText">
        <div class="main_bannar"  >
          <div data-aos="fade-up">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item" aria-current="page">
                  <a href="/bpm-and-bpo-services">Services</a>
                </li>
                <li class="breadcrumb-item" >
                  <a href="/services/insurance-bpo-outsourcing">Insurance</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Policy Processing
                </li>
              </ol>
            </nav>
            <h1 class="card-text font-34-19 text-primary pt-3">
              Policy Processing Services
            </h1>
            <p class="card-text font-20-16 pt-3">
              Policy inaccuracies lead to risk exposure! With our policy
                processing services, we efficiently handle policy changes and
              endorsements while keeping an eye on your clients’ insurance policy
              accuracies.
            </p>
            <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
              <h3 class="text-primary font-24-16 clickable-heading">
                Connect with Experts 
                <div class="headingCTA">
                  <button class="blueCTA"></button>
                </div>
              </h3>
            </a>
          </div>
        </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none ">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img alt="FBSPL Policy Processing Services"
       appCdnUrl="assets/insurane policy processing/Policy Processing Main Banner Mobile 1.jpg"
      class="card-img-mob"
      alt="Policy"
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item" aria-current="page">
            <a href="/bpm-and-bpo-services">Services</a>
          </li>
          <li class="breadcrumb-item" >
            <a href="/services/insurance-bpo-outsourcing">Insurance</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Policy Processing
          </li>
        </ol>
      </nav>
      <h1 class="card-text font-34-19 text-primary">Policy Processing Services</h1>
      <p class="card-text font-20-16">
        Policy inaccuracies lead to risk exposure! With our policy
          processing services, we efficiently handle policy changes and
        endorsements while keeping an eye on your clients’ insurance policy
        accuracies.
      </p>
      <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
        <h3 class="text-primary font-24-16 clickable-heading">
          Connect with Experts 
          <div class="headingCTA">
            <button class="blueCTA"></button>
          </div>
        </h3>
      </a>
    </div>
  </div>
</div>
</div>
<app-ourAssets></app-ourAssets>


<section class="container-fluid policy-process-service" id="Empower Change with Policy Processing Services">
  <div class="container">
    <div class="container_emp ps-lg-3">
      <h2 class="text-white font-48-30 py-3"  data-aos="fade-up">
        Empower Change with Policy Processing Services
      </h2>
      <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-3" />
    </div>
    <div class=" d-none d-lg-block">
      <div class="row py-3 px-2">
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Issuance p-3"  data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
            data-bs-target="#exampleModal">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Policy Issuance
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                   
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <!-- Modal -->
          <div
            class="modal Policy-Processing fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Policy Issuance</h3>
                      <p class="text-dark font-20-16">
                        Optimize your insurance operations with our future-proof
                        policy issuance solutions. Be ready to drive business
                        efficiency and process standardization with our
                        end-to-end policy issuance services.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Insurance policy paperwork validation
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Insurance policy generation
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Policy setup document creation
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Customer policy details verification
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Final insurance policy generation
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img 
                       appCdnUrl="assets/insurane policy processing/Policy Issuance.png" class="bordered"
                      alt="FBSPL Policy Issuance Service "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy-Checking p-3"  data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
            data-bs-target="#exampleModal1">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Policy Checking
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                  
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Policy Checking</h3>
                      <p class="text-dark font-20-16">
                        Get top-notch insurance policy-checking solutions
                        focused on enhancing accuracy and compliance. We manage
                        every aspect of the policy life cycle efficiently while
                        ensuring error-free operations.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Retrieving policy details
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Verifying the policy authenticity
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Confirming policy details coverage
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Checking the policy standing and payments
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Reviewing policy claims history
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/insurane policy processing/Policy Checking.png" class="bordered"
                      alt="FBSPL Policy Checking Service"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Loss_Runs_Processing p-3" data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
            data-bs-target="#exampleModal2">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Loss Runs Processing
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                  
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel2"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Loss Runs Processing</h3>
                      <p class="text-dark font-20-16">
                        Avail comprehensive insurance loss runs processing
                        solutions and achieve 99% accurate reports. Relieve your
                        back-office resources and let them focus on the bigger
                        picture, driving business growth.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Filing loss runs report requests
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Receiving and analyzing reports
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Submitting claims reports
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Extracting loss return information
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Studying loss runs and preparing reports
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/insurane policy processing/Loss Runs Processing.png" class="bordered"
                      alt="FBSPL Loss Runs Processing Service "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Renewals p-3" data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
            data-bs-target="#exampleModal3">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Policy Renewals
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                  
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal3"
            tabindex="-1"
            aria-labelledby="exampleModalLabel3"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Policy Renewals</h3>
                      <p class="text-dark font-20-16">
                        Retain your potential clients and expand your customer
                        base with our world-class policy renewal solutions. Our
                        end-to-end insurance renewal services maintain customer
                        service standards and efficiency.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Tracking renewals request
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Creating detailed summaries
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Coordinating with insurers
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Evaluating policy renewal risks
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Making policy adjustments
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/insurane policy processing/Policy Renewals_.png" class="bordered" 
                      alt="FBSPL Policy Renewals Service "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Management p-3" data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
            data-bs-target="#exampleModal4">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Policy Management
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                  
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal4"
            tabindex="-1"
            aria-labelledby="exampleModalLabel4"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Policy Management</h3>
                      <p class="text-dark font-20-16">
                        Improve your <a class="anchor" target="_blank" href="/services/customer-support-outsourcing">customer service experience</a> and retain it
                        for the long term with our growth-oriented policy
                        management services. Focus on creating value for your
                        business while we accurately manage your policies.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Analyzing policy account history
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Reviewing policy cancellations
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Policy data exchange
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Preparing policy quotes
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Generating policy proposal
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/insurane policy processing/Policy Management.png" class="bordered"
                      alt="FBSPL Policy Management Service "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Endorsements p-3" data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
            data-bs-target="#exampleModal5">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Policy Endorsements
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                   
                  ></button>
                </div>
              </h3>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal Policy-Processing fade"
      id="exampleModal5"
      tabindex="-1"
      aria-labelledby="exampleModalLabel5"
      aria-hidden="true"
    >
      <div class="modal-dialog policy-dialog pt-0 mt-0">
        <div class="modal-content">
          <div class="modal-header policy-header">
            <img
              data-bs-dismiss="modal"
              aria-label="Close"
               appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
            />
          </div>
          <div class="card policy-processing-services-card mx-5">
            <div class="details ms-5">
              <div class="content">
                <h3 class="font-48-30 text-dark">Policy Endorsements</h3>
                <p class="text-dark font-20-16">
                  Add an extra layer of insurance coverage for your potential
                  customers while seeking our policy endorsement services. From
                  adding policy coverage to rectifying errors, our experts
                  incorporate every policy change with precision.
                </p>
                <ul class="ul-policy">
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Reviewing policy endorsement request
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Submitting policy endorsement request
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Notifying policy approval or rejection
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Modifying the policy updates
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Policy endorsements issuance
                  </li>
                </ul>
              </div>
            </div>
            <div class="imgBx d-none d-lg-block">
              <img
                 appCdnUrl="assets/insurane policy processing/Policy Endorsements.png" class="bordered"
                alt="FBSPL Policy Endorsements Service "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class=" p-3">
    <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-12 mt-0 mt-sm-4">
          <div class="accordion" id="ServicePage">
            <div class="policy-processing" data-aos="zoom-in">
              <div
                data-bs-toggle="collapse"
                data-bs-target="#Policy_Processing"
                aria-expanded="true"
                aria-controls="Policy_Processing"
                id="headingOne"
                class="headingCTA CTA-Zindex d-flex  p-3"
                data-bs-toggle="modal"
                  data-bs-target="#MobModal"
              >
                <h3 class="CTAs text-black fs-24  me-3">Policy Issuance</h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal"
                   appCdnUrl="assets/Icon/Accord-close.svg"  class="close_btn"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal"
              tabindex="-1"
              aria-labelledby="MobModalModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card-mob mx-3 container py-3">
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Policy Issuance</h3>
                        <p class="text-dark font-20-16">
                          Optimize your insurance operations with our
                          future-proof policy issuance solutions. Be ready to
                          drive business efficiency and process standardization
                          with our end-to-end policy issuance services.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Insurance policy paperwork validation
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Insurance policy generation
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Policy setup document creation
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Customer policy details verification
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Final insurance policy generation
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                data-bs-toggle="collapse"
                data-bs-target="#Claims_Management"
                aria-expanded="true"
                aria-controls="Claims_Management"
                id="headingOne"
                class="headingCTA CTA-Zindex d-flex  p-3" 
                data-bs-toggle="modal"
                data-bs-target="#MobModal1"
              >
                <h3 class="CTAs text-black fs-24  me-3">Policy Checking</h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal1"
                   appCdnUrl="assets/Icon/Accord-close.svg"  class="close_btn"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal1"
                tabindex="-1"
                aria-labelledby="MobModal1Lable1"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Policy Checking</h3>
                          <p class="text-dark font-20-16">
                            Get top-notch insurance policy-checking solutions
                            focused on enhancing accuracy and compliance. We
                            manage every aspect of the policy life cycle
                            efficiently while ensuring error-free operations.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Retrieving policy details
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Verifying the policy authenticity
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Confirming policy details coverage
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Checking the policy standing and payments
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Reviewing policy claims history
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="imgBx d-none d-lg-block">
                        <img
                           appCdnUrl="assets/insurane policy processing/Policy Checking.png" class="bordered"
                          alt="FBSPL Policy Checking Service "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div class="d-flex p-3" 
              data-bs-toggle="modal"
              data-bs-target="#MobModal2"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Loss Runs Processing
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal2"
                   appCdnUrl="assets/Icon/Accord-close.svg"  class="close_btn"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal2"
                tabindex="-1"
                aria-labelledby="MobModalLabel2"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Loss Runs Processing
                          </h3>
                          <p class="text-dark font-20-16">
                            Avail comprehensive insurance loss runs processing
                            solutions and achieve 99% accurate reports. Relieve
                            your back-office resources and let them focus on the
                            bigger picture, driving business growth.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Filing loss runs report requests
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Receiving and analyzing reports
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Submitting claims reports
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Extracting loss return information
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Studying loss runs and preparing reports
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="imgBx d-none d-lg-block">
                        <img
                           appCdnUrl="assets/insurane policy processing/Loss Runs Processing.png" class="bordered"
                          alt="FBSPL Loss Runs Processing Service "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div class="d-flex p-3" 
              data-bs-toggle="modal"
                  data-bs-target="#MobModal3"
              >
                <h3 class="CTAs text-black fs-24  me-3">Policy Renewals</h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal3"
                   appCdnUrl="assets/Icon/Accord-close.svg"  class="close_btn"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal3"
                tabindex="-1"
                aria-labelledby="MobModalLabel3"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Policy Renewals</h3>
                          <p class="text-dark font-20-16">
                            Retain your potential clients and expand your
                            customer base with our world-class policy renewal
                            solutions. Our end-to-end insurance renewal services
                            maintain customer service standards and efficiency.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Tracking renewals request
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Creating detailed summaries
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Coordinating with insurers
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Evaluating policy renewal risks
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Making policy adjustments
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="imgBx d-none d-lg-block">
                        <img
                           appCdnUrl="assets/insurane policy processing/Policy Renewals_.png" class="bordered"
                          alt="FBSPL Policy Renewals Service "
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4"  data-aos="zoom-in">
              <div class="d-flex p-3"
              data-bs-toggle="modal"
                  data-bs-target="#MobModal4"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Policy Management
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal4"
                   appCdnUrl="assets/Icon/Accord-close.svg"  class="close_btn"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal4"
                tabindex="-1"
                aria-labelledby="MobModalLabel4"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Policy Management
                          </h3>
                          <p class="text-dark font-20-16">
                            Improve your <a class="anchor" target="_blank" href="/services/customer-support-outsourcing">customer service experience</a> and retain it
                        for the long term with our growth-oriented policy
                        management services. Focus on creating value for your
                        business while we accurately manage your policies.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Analyzing policy account history
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Reviewing policy cancellations
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Policy data exchange
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Preparing policy quotes
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Generating policy proposal
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="imgBx d-none d-lg-block">
                        <img
                           appCdnUrl="assets/insurane policy processing/Policy Management.png"
                          alt="FBSPL Policy Management Service "
                        />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div id="headingOne" class="d-flex p-3" 
              data-bs-toggle="modal"
                  data-bs-target="#MobModal5"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Policy Endorsements
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal5"
                   appCdnUrl="assets/Icon/Accord-close.svg"  class="close_btn"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal5"
                tabindex="-1"
                aria-labelledby="MobModalLabel5"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg" class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Policy Endorsements
                          </h3>
                          <p class="text-dark font-20-16">
                            Add an extra layer of insurance coverage for your
                            potential customers while seeking our policy
                            endorsement services. From adding policy coverage to
                            rectifying errors, our experts incorporate every
                            policy change with precision.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Reviewing policy endorsement request
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Submitting policy endorsement request
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Notifying policy approval or rejection
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Modifying the policy updates
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Policy endorsements issuance
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="imgBx d-none d-lg-block">
                        <img
                           appCdnUrl="assets/insurane policy processing/Policy Endorsements .png"
                          alt="FBSPL Policy Endorsements Service "
                        />
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-5" appCdnUrl="assets/Icon/white-snake-vector.svg" />
  </div>
</section>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
  <div class="d-none d-xl-flex">
    <div class="row g-5">
      <div class="col-2"  data-aos="fade-right" data-aos-delay="0">
        <img class="img-fluid" alt="Insurance tool Applied System  "  appCdnUrl="assets/Tools/applied.svg" />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="100">
        <img class="img-fluid" alt="Insurance tool Hawksoft   "  appCdnUrl="assets/Tools/hawksoft.svg" />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="200">
        <img
          class="img-fluid" alt="Insurance tool PL Rating "
           appCdnUrl="assets/Tools/pl rating.svg"
         
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="300">
        <img class="img-fluid" alt="Insurance tool QQCatalyst "  appCdnUrl="assets/Tools/catalyst.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="Insurance tool AMS 360 "  appCdnUrl="assets/Tools/ams360.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="500">
        <img class="img-fluid" alt="Insurance tool EZLynx " appCdnUrl="assets/Tools/ezlynx.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="600">
        <img class="img-fluid" alt="Insurance tool CSR24"  appCdnUrl="assets/Tools/csr27.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="700">
        <img class="img-fluid" alt="Insurance tool NowCerts"  appCdnUrl="assets/Tools/nowcerts.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="800">
        <img alt="Insurance tool Power Broker "
          class="img-fluid"
           appCdnUrl="assets/Tools/powerbroker.svg"
         
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="800">
        <img alt="Insurance tool AgencyMatrix "
          class="img-fluid"
           appCdnUrl="assets/Tools/agencymatrix.svg"
          
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="900">
        <img alt="Insurance tool employeeNavigator "
          class="img-fluid"
           appCdnUrl="assets/Tools/employeeNavigator.svg"
       
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="1000">
        <img class="img-fluid" alt=" Insurance tool indio " appCdnUrl="assets/Tools/indio.svg"  />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool Applied System  "  appCdnUrl="assets/Tools/applied.svg" data-aos="fade-right" data-aos-delay="0" />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="100"
            class="img-fluid" alt="Insurance tool Hawksoft  "
             appCdnUrl="assets/Tools/hawksoft.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="200"
            class="img-fluid" alt="Insurance tool PL Rating "
             appCdnUrl="assets/Tools/pl rating.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool QQCatalyst " data-aos="fade-right" data-aos-delay="300"  appCdnUrl="assets/Tools/catalyst.svg" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool AMS 360 "  appCdnUrl="assets/Tools/ams360.svg" data-aos="fade-right" data-aos-delay="400" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool EZLynx " appCdnUrl="assets/Tools/ezlynx.svg" data-aos="fade-right" data-aos-delay="500" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool CSR24  " appCdnUrl="assets/Tools/csr27.svg" data-aos="fade-right" data-aos-delay="600" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool NowCerts " appCdnUrl="assets/Tools/nowcerts.svg" data-aos="fade-right" data-aos-delay="700" />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid" alt=" Insurance tool Power Broker" data-aos="fade-right" data-aos-delay="800"
             appCdnUrl="assets/Tools/powerbroker.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="900"
            class="img-fluid" alt="Insurance tool AgencyMatrix "
             appCdnUrl="assets/Tools/agencymatrix.svg"
            
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="1000"
            class="img-fluid" aria-multiline=" Insurance tool employeeNavigator "
             appCdnUrl="assets/Tools/employeeNavigator.svg"
            alt="Employee Navigator"
          />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" aria-multiline=" Insurance tool indio " data-aos="fade-right" data-aos-delay="1100"  appCdnUrl="assets/Tools/indio.svg" alt="Indio" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool Applied System"  appCdnUrl="assets/Tools/applied.svg" data-aos="fade-right" data-aos-delay="0" />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="100"
            class="img-fluid" alt="Insurance tool Hawksoft   "
             appCdnUrl="assets/Tools/hawksoft.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="200"
            class="img-fluid" alt="Insurance tool PL Rating "
             appCdnUrl="assets/Tools/pl rating.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool QQCatalyst " data-aos="fade-right" data-aos-delay="300"  appCdnUrl="assets/Tools/catalyst.svg" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool AMS 360 "  appCdnUrl="assets/Tools/ams360.svg" data-aos="fade-right" data-aos-delay="400" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool EZLynx " appCdnUrl="assets/Tools/ezlynx.svg" data-aos="fade-right" data-aos-delay="500" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool CSR24  " appCdnUrl="assets/Tools/csr27.svg" data-aos="fade-right" data-aos-delay="600" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool NowCerts  "  appCdnUrl="assets/Tools/nowcerts.svg" data-aos="fade-right" data-aos-delay="700" />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid" data-aos="fade-right" data-aos-delay="800"
             appCdnUrl="assets/Tools/powerbroker.svg" alt="Insurance tool Power Broker "
           
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="900"
            class="img-fluid" alt="Insurance tool AgencyMatrix "
             appCdnUrl="assets/Tools/agencymatrix.svg"
            
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="1000"
            class="img-fluid" alt="Insurance tool employeeNavigator "
             appCdnUrl="assets/Tools/employeeNavigator.svg"
            alt="Employee Navigator"
          />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool indio " data-aos="fade-right" data-aos-delay="1100"  appCdnUrl="assets/Tools/indio.svg" alt="Indio" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Thinking -->
<app-service-case-study  [category]="[{ category: 'insurance' }]"></app-service-case-study>
<!-- Know best -->
<app-know-best
[cards]="[
  {
    description: 'I must say that our partnership with Fusion Business Solutions (FBSPL) has been truly exceptional. What sets FBSPL apart is their remarkable ability to not only grasp our ever-evolving business needs but also their genuine curiosity to understand our culture. Their flexibility and agility have been invaluable. FBSPL’s proactive approach, coupled with their rigorous processes and scalability, has significantly enhanced our customer experiences and AI operations.',
    name: 'Chin Ma',
    designation: 'Founder & President'
  },
  {
    description: 'We reached out to Fusion in the Fall of 2022 as we were having issues recruiting a skilled and motivated workforce locally to fill back-office roles in our organization. What started as a simple documentation validation exercise quickly grew into the team supporting more strategic review and reconciliation activities. The team has shown repeatedly that they are willing to take on more and more complicated tasks, and the leadership team is quick to rally and support their employees when things fall off track.',
    name: 'James Oickle',
    designation: 'President & CEO'
  },
  {
    description: 'Fusion Business Solutions has been a game-changer for our company. Their responsive team, seamless onboarding, and commitment to excellence have significantly reduced our stress levels, allowing us to focus on what matters most – our clients. We\'ve experienced measurable improvements in client retention and employee satisfaction since partnering with Fusion, making them the top choice for any business seeking efficient virtual assistant services.',
    name: 'Isom Rigell M. Ed.',
    designation: 'Chief People Officer'
  }
]"
  [videos]="[
      {
      videoUrl:
        'https://player.vimeo.com/video/969702390',
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 01 Chin Ma.jpg',
        alt:'Listen to our client-insurance policy processing '
    },
    {
      
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10  James Oickle.jpg',
        alt:'Our Success Stories-insurance policy processing '
    },
    {
      videoUrl:
        'https://player.vimeo.com/video/962238538',
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 07 Stacey DiSpigno copy.jpg',
        alt:'FBSPL Client Testimonial-insurance policy processing '
    },
   
  ]"
 >
</app-know-best>
<section class="py-6">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6" >
        <div data-aos="fade-right">
          <app-faq ></app-faq>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0" >
        <div  data-aos="fade-left">
          <div class="accordion" id="accordionFAQ">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-28-20"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Industries"
                  aria-expanded="true"
                  aria-controls="Industries"
                >
                What are the benefits of insurance policy processing outsourcing?
                </button>
              </h2>
              <div
                id="Industries"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  Outsourcing your <a class="anchor" target="_blank" href="/services/insurance-bpo-outsourcing">insurance back-office operations</a> including policy processing brings in a wide range of benefits. Here are some of them.
                  <ul class="pt-3">
                    <li>Reduce policy admin costs </li>
                    <li>Minimize E&O </li>
                    <li>Access to specialized expertise </li>
                    <li>Risk mitigation and compliance </li>
                    <li>Focus on strategic growth </li>
                    <li>99% accuracy and 0% attrition </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Services"
                  aria-expanded="false"
                  aria-controls="Services"
                >
                What domains do you cover in policy processing? 
                </button>
              </h2>
              <div
                id="Services"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  We specialize in processing a wide range of insurance policies, including P&C, employee benefits, commercial, and more. To know more about the policy processing solutions for a specific department, you can connect with our professionals.  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#About"
                  aria-expanded="false"
                  aria-controls="About"
                >
                How do you ensure accuracy in insurance policy processing? 
                </button>
              </h2>
              <div
                id="About"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  Accuracy is the key factor when it comes to policy processing and management. Therefore, we employ stringent quality control measures, including manual process review and data validation to minimize errors and discrepancies. 
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Resources"
                  aria-expanded="false"
                  aria-controls="Resources"
                >
                What is the insurance policy process you follow?
                </button>
              </h2>
              <div
                id="Resources"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  We follow a very comprehensive and transparent insurance policy processing process. Our team ensures accuracy and efficiency in every step of the policy processing cycle, from initial data entry to policy issuance and beyond. 
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Differentiators"
                  aria-expanded="false"
                  aria-controls="Differentiators"
                >
                What are your key differentiators? 
                </button>
              </h2>
              <div
                id="Differentiators"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  <ul class="pt-3">
                    <li>Availability As Per Your Time Zone </li>
                    <li>All-in One Expertise </li>
                    <li>Dedicated Staff </li>
                    <li>99% Accuracy </li>
                    <li>Tailor-Made Solutions </li>
                  </ul>              
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
