<div class="container text-dark d-none d-lg-block">
    <div class="gradientBanner">
      <img
         appCdnUrl="assets/accoutning Payable Management/Payable Management-banner-1.jpg"
        class="card-img-desktop desktop-banner" alt="Accounts Payable Management Services "
      />
      <div class="card-img-overlay overlay-text bannerText">
          <div class="main_bannar pt-3" >
            <div data-aos="fade-up">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a  href="/">Home</a></li>
                  <li class="breadcrumb-item">
                    <a  href="/bpm-and-bpo-services">Services</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a  href="/services/accounting-bookkeeping-services">Accounting & Bookkeeping</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <a  aria-current="page">Accounts Payable Management</a>
              </li>
                </ol>
              </nav>
              <h1 class="card-text font-34-19 text-primary pt-3">
                  Payable Management Services
              </h1>
              <p class="card-text font-20-16 pt-3">
                  Ensure compliance and minimize risks in your business operations with the industry-best payable management outsourcing services. Efficiently optimize working capital and improve customer relationships with our end-to-end accounts' payable services. 
              </p>
              <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
                <h3 class="text-primary font-24-16 clickable-heading">
                  Connect with Experts
                  <div class="headingCTA">
                    <button class="blueCTA"></button>
                  </div>
                </h3>
              </a>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container text-dark slide d-block d-lg-none ">
    <div class="gradientBannerMob d-flex justify-content-center" >
      <img
         appCdnUrl="assets/accoutning Payable Management/Mobile-banner.jpg"
        class="card-img-mob" alt="Accounts Payable Management Services "
      />
      <div class="card-img-overlay overlay-text-Mob bannerTextMob">
        <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a  href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a  href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                  <a  href="/services/accounting-bookkeeping-services">Accounting & Bookkeeping</a>
              </li>
              <li class="breadcrumb-item active">
                <a  aria-current="page">Accounts Payable Management</a>
            </li>
            </ol>
          </nav>
        <h1 class="card-text font-34-19 text-primary">Payable Management Services</h1>
        <p class="card-text font-20-16">
          Ensure compliance and minimize risks in your business operations with the industry-best payable management outsourcing services. Efficiently optimize working capital and improve customer relationships with our end-to-end accounts' payable services. 
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Connect with Experts
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
        </div>
      </div>
    </div>
  </div>
<app-ourAssets></app-ourAssets>
  
  
  <section class="container-fluid policy-process-service" id="payable management services">
    <div class="container">
      <div class="container_emp ps-lg-3">
        <h2 class="text-white font-48-30 py-3" data-aos="fade-up">
          Empower Change with Payable Management Services 
        </h2>
        <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-4" />
      </div>
      <div class="d-none d-lg-block">
        <div class="row py-3 px-2">
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Issuance p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal">
                <h3 class="text-black font-34-19 clickable-heading">
                    Payment Processing  
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <!-- Modal -->
            <div
              class="modal Policy-Processing fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" 
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Payment Processing </h3>
                        <p class="text-dark font-20-16">
                            Automate all your financial transactions by facilitating efficient invoice handling, on-time vendor payments, and financial records. With our payment processing services, we improve cash flow management and reduce human errors. 
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Invoice details verification
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Review invoices for approval 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Scheduling the payment  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Executing payments on the scheduled date 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Record maintenance & reconciliation 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payable Management/Group 228.webp"
                        alt="FBSPL Payment Processing Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy-Checking p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal1">
                <h3 class="text-black font-34-19 clickable-heading">
                    Vendor Bills Processing 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover "
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel1"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Vendor Bills Processing </h3>
                        <p class="text-dark font-20-16">
                          Receive, validate, and maintain invoices seamlessly with our exclusive invoice processing services. Our team will ensure timely payments and ensure efficient vendor bill processing while minimizing manual efforts and mitigating errors. 
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Receiving invoices from the vendors 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Invoices verification via three-way match 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Resolving invoices discrepancies
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Validating invoicing payments 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Processing invoices for payment 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payable Management/Group 229.webp"
                        alt="FBSPL Vendor Bills Processing Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Loss_Runs_Processing p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal2">
                <h3 class="text-black font-34-19 clickable-heading">
                    Cash Flow Management 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover "
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Cash Flow Management </h3>
                        <p class="text-dark font-20-16">
                            From sales receipts to vendor payments, every transaction matters. Our professionals understand this well, and hence they ensure accurate and timely cash flow management for our clients. 
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Evaluating the payables process 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying areas of improvement 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Organizing and tracking invoices 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Invoices verification via three-way match 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Aligning and maintaining payments 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payable Management/Group 230.webp"
                        alt="FBSPL Cash Flow Management"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Renewals p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal3">
                <h3 class="text-black font-34-19 clickable-heading">
                    Reconciliation 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal3"
              tabindex="-1"
              aria-labelledby="exampleModalLabel3"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Reconciliation </h3>
                        <p class="text-dark font-20-16">
                            We spot discrepancies, resolve mysteries, and ensure consistency with our <a class="anchor" target="_blank" href="/services/accounts-reconciliation-services" >reconciliation services.</a> Whether it’s reconciling bank balances or matching vendor statements, our process safeguards against errors and financial chaos. 
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering essential information 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Comparing reconciliation balance sheet 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Match invoices, POs, and delivery receipts.
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying the discrepancies 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Maintaining reconciliation records 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payable Management/Group 231.webp"
                        alt="FBSPL Accounts Reconciliation Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Management p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal4">
                <h3 class="text-black font-34-19 clickable-heading">
                    Payables Data Management
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal4"
              tabindex="-1"
              aria-labelledby="exampleModalLabel4"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Payables Data Management </h3>
                        <p class="text-dark font-20-16">
                            Fuel your business efficiency with organized payables data management solutions. Our team is well-versed in accurate data retrieval and <a class="anchor" href="/services/accounting-and-financial-reporting-services">financial reporting preparation.</a>  Leave all your payables management concerns to us.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering relevant data from invoices 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Invoices verification via three-way match 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Entering validated data into systems
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Interacting with vendors for discrepancies 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Initiating payments to vendors 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payable Management/Group 232.webp"
                        alt="FBSPL Payables Data Management "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Endorsements p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal5">
                <h3 class="text-black font-34-19 clickable-heading">
                    Financial Reporting 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal Policy-Processing fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel5"
        aria-hidden="true"
      >
        <div class="modal-dialog policy-dialog pt-0 mt-0">
          <div class="modal-content">
            <div class="modal-header policy-header">
              <img
                data-bs-dismiss="modal"
                aria-label="Close"
                 appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
              />
            </div>
            <div class="card policy-processing-services-card mx-5">
              <div class="details ms-4">
                <div class="content">
                  <h3 class="font-48-30 text-dark">Financial Reporting </h3>
                  <p class="text-dark font-20-16">
                    Are you struggling with maintaining balance sheets, income statements, and cash flow reports? Not anymore! Get <a class="anchor" target="_blank" href="/services/accounting-and-financial-reporting-services" >financial reporting services</a> from us adhering to accounting standards, and financial transparency. 
                  </p>
                  <ul class="ul-policy">
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Collecting financial data from different sources 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Preparing financial statements
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Ensure compliance with legal requirements  
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Conducting financial reports quality check 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Submitting financial reports on time 
                    </li>
                  </ul>
                </div>
              </div>
              <div class="imgBx d-none d-lg-block">
                <img
                   appCdnUrl="assets/accoutning Payable Management/Group 233.webp"
                  alt="FBSPL Financial Reporting Services "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile View -->
    <div class="p-3">
      <div class="d-block d-lg-none">
        <div class="row">
          <div class="col-12 mt-0 mt-sm-4">
            <div class="accordion" id="ServicePage">
              <div class="policy-processing" data-aos="zoom-in">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#Policy_Processing"
                  aria-expanded="true"
                  aria-controls="Policy_Processing"
                  id="headingOne"
                  class="headingCTA CTA-Zindex d-flex  p-3"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal"
                >
                  <h3 class="CTAs text-black fs-24 me-3">Payment Processing </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal"
                tabindex="-1"
                aria-labelledby="MobModalModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Payment Processing </h3>
                          <p class="text-dark font-20-16">
                            Automate all your financial transactions by facilitating efficient invoice handling, on-time vendor payments, and financial records. With our payment processing services, we improve cash flow management and reduce human errors. 
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Invoice details verification 
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Review invoices for approval 
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Scheduling the payment  
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Executing payments on the scheduled date 
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Record maintenance & reconciliation
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#Claims_Management"
                  aria-expanded="true"
                  aria-controls="Claims_Management"
                  id="headingOne"
                  class="headingCTA CTA-Zindex d-flex  p-3"
                  data-bs-toggle="modal"
                    data-bs-target="#MobModal1"
                >
                  <h3 class="CTAs text-black fs-24  me-3">Vendor Bills Processing </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal1"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal1"
                  tabindex="-1"
                  aria-labelledby="MobModal1Lable1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">Vendor Bills Processing </h3>
                            <p class="text-dark font-20-16">
                              Receive, validate, and maintain invoices seamlessly with our exclusive invoice processing services. Our team will ensure timely payments and ensure efficient vendor bill processing while minimizing manual efforts and mitigating errors.  
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Receiving invoices from the vendors
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Invoices verification via three-way match 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Resolving invoices discrepancies 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Validating invoicing payments
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Processing invoices for payment 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3" 
                data-bs-toggle="modal"
                    data-bs-target="#MobModal2"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Cash Flow Management 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal2"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal2"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel2"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Cash Flow Management 
                            </h3>
                            <p class="text-dark font-20-16">
                                From sales receipts to vendor payments, every transaction matters. Our professionals understand this well, and hence they ensure accurate and timely cash flow management for our clients.
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Evaluating the payables process 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Identifying areas of improvement 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Organizing and tracking invoices 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Invoices verification via three-way match 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Aligning and maintaining payments 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal3"
                >
                  <h3 class="CTAs text-black fs-24  me-3">Reconciliation </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal3"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal3"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel3"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">Reconciliation </h3>
                            <p class="text-dark font-20-16">
                                We spot discrepancies, resolve mysteries, and ensure consistency with our reconciliation services. Whether it’s reconciling bank balances or matching vendor statements, our process safeguards against errors and financial chaos. 
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Gathering essential information 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Comparing reconciliation balance sheet 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Match invoices, POs, and delivery receipts. 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Identifying the discrepancies 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Maintaining reconciliation records 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal4"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Payables Data Management 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal4"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal4"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel4"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                            Payables Data Management 
                            </h3>
                            <p class="text-dark font-20-16">
                              Fuel your business efficiency with organized payables data management solutions. Our team is well-versed in accurate data retrieval and <a class="anchor" href="/services/accounting-and-financial-reporting-services">financial reporting preparation.</a>  Leave all your payables management concerns to us.
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Gathering relevant data from invoices 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Invoices verification via three-way match 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Entering validated data into systems
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Interacting with vendors for discrepancies 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Initiating payments to vendors 
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="imgBx d-none d-lg-block">
                          <img
                             appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png"
                            alt=""
                          />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div id="headingOne" class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal5"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Financial Reporting 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal5"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal5"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel5"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Financial Reporting 
                            </h3>
                            <p class="text-dark font-20-16">
                              Are you struggling with maintaining balance sheets, income statements, and cash flow reports? Not anymore! Get <a class="anchor" target="_blank" href="/services/accounting-and-financial-reporting-services" >financial reporting services</a> from us adhering to accounting standards, and financial transparency. 
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Collecting financial data from different sources 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Preparing financial statements 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Ensure compliance with legal requirements
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Conducting financial reports quality check 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Submitting financial reports on time 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <img class="mt-5"  appCdnUrl="assets/Icon/white-snake-vector.svg"/>
    </div>
  </section>
  <section class="container py-5">
    <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
    <div class="d-none d-xl-flex">
      <div class="row">
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool QuickBooks "  appCdnUrl="assets/Tools/quickbooks.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"/>
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
        </div>
      </div>
    </div>
    <div class="d-flex d-xl-none">
      <div class="trusted-slider-logo">
        <div class="trusted-slide-track">
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Thinking -->
  <app-service-case-study  [category]="[{ category: 'accountingandbookkeeping' }]"></app-service-case-study>
  <!-- Know best -->
<app-know-best
[cards]="[
  { description: 'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
  name: 'Francesca Furlanetto', designation: 'Director Owner'
 },
  { description: 'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
  name: 'Monica Spivey', designation: 'Directory of Accounting'
 },

]"
  [videos]="[
     { 
     thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
     alt:' Listen to our client '
     },
     {
     
      thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt:'Our Success Stories '
    },
  ]">
</app-know-best>
  <section class="py-6">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6" data-aos="fade-right">
          <app-faq></app-faq>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0" data-aos="fade-left">
          <div class="accordion" id="accordionFAQ">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-28-20"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Industries"
                  aria-expanded="true"
                  aria-controls="Industries"
                >
                Why do I need account payable management services? 
                </button>
              </h2>
              <div
                id="Industries"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    Account payable management services help to handle and optimize your invoice payment and vendor relationship management. By outsourcing payable management, you can streamline your financial operations, reduce human errors, improve cash flow, and maintain strong vendor relationships. 
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Services"
                  aria-expanded="false"
                  aria-controls="Services"
                >
                What are the major services you offer under accounts payable management? 
                </button>
              </h2>
              <div
                id="Services"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  We offer wide range of services to help businesses manage their payables seamlessly. Our major payable management solutions include -                          
                    <ul class="pt-3">
                        <li>Invoice processing</li>
                        <li>Vendor management </li>
                        <li>Document management </li>
                        <li><a class="anchor" target="_blank" href="/services/accounting-and-financial-reporting-services">Financial reporting</a></li>
                        <li>Data management   </li>
                        <li><a class="anchor" target="_blank" href="/services/accounts-reconciliation-services">Reconciliation </a></li>
                        <li>Cash flow management   </li>
                      </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#About"
                  aria-expanded="false"
                  aria-controls="About"
                >
                How do your account payable management services ensure compliance and workflow approval?  
                </button>
              </h2>
              <div
                id="About"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    With our account payable management services, you can get customized approval workflows based on the business hierarchies and rules. It will ensure 100% compliance and proper authorization of account payable management.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Resources"
                  aria-expanded="false"
                  aria-controls="Resources"
                >
                What is the process to get started with account payable management services? 
                </button>
              </h2>
              <div
                id="Resources"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                    If you want our payable management outsourcing services, get in touch with us via our website, fill in the form, or contact us directly. We will schedule your consultation with one of our leading professionals, and they will offer you the most suitable payable management solution right away. Feel free to get in touch with us at any hour of the day.  
                </div>
              </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button font-28-20 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#differentiators"
                    aria-expanded="false"
                    aria-controls="differentiators"
                  >
                  Why should I avail your payable management services?   
                  </button>
                </h2>
                <div
                  id="differentiators"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionFAQ"
                >
                  <div class="accordion-body">
                    Although there are multitude of <a class="anchor" target="_blank" href="/services/accounting-bookkeeping-services">accounting and bookkeeping outsourcing services providers </a> offering payable management services, but what truly sets us apart are -    
                    <ul class="pt-3">
                        <li>Availability As Per Client Time Zones</li>
                        <li>All-in One Expertise </li>
                        <li>Dedicated Staff </li>
                        <li>99% Accuracy</li>
                        <li>Tailor-Made Solutions </li>
                        <li>Quick turnaround time  </li>
                      </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  