import { Component , OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from '../../../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-pre-sales-support',
    standalone: true,
    templateUrl: './pre-sales-support.component.html',
    styleUrl: './pre-sales-support.component.css',
    imports: [
        FaqComponent,
        ServiceCaseStudyComponent,KnowBestComponent,RouterLink,CdnUrlDirective,
        RouterOutlet,ourAssetsComponent
    ]
})
export class PreSalesSupport implements OnInit{
  constructor(private meta: Meta, private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    const seoData: SeoData = {
      title: 'Personalized Pre-Sales Support for E-Commerce | FBSPL',
      description:
        "We help you grow your e-commerce business with our pre-sales support, including 24/7 chat and email assistance, improving leads and customer experience. View our ecommerce services.",
      keywords: 'ecommerce sales, pre sales support',
      ogImage: '/Sub-service-Pages/policy 1 1.png'
    };
    this.addJsonLdScript();
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/e-commerce-pre-sales-support' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `{ 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "Why should I choose you as my right ecommerce outsourcing service provider?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should partner with us if you are looking for:  
<ul><li>360-degree assistance that meets your dynamic business needs round the clock</li> 

<li>Professionally trained employees</li> 

<li>Buck-up services at zero additional cost</li> 

<li>Dedicated customer support, vendor management, and much more</li></ul> 

Partnering with us means aligning with the industry excellence." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why do I need strong pre-sales customer service? Is it worth the investment?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should invest in pre-sales support if you think the following are essential for your E-commerce success: 
<ul> 

<li>Timely and Accurate Answers: Build trust and credibility by accurately addressing your potential customers' queries.</li> 

<li>Buying Decisions: Make consumers familiar with your unique selling points and boost your conversion rates.</li> 

<li>Reduce Cart Abandonment: Know what makes your customers leave their purchase journey midway. Provide solutions that convince them to buy.</li> 

<li>Competitive Advantage: Gain competitive advantages by digging deeper into your consumer's and competitors' persona.</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you ensure my brand has a competitive advantage over other brands selling similar products with almost identical pricing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Each brand has its unique selling points, and our specialists are adept at emphasizing them at the right moment to persuade customers. We believe a brand represents more than its cost. The value, credibility, and experience it provides distinguish it from competitors." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How does your pre-sales support team deal with confused, frustrated customers with vague expectations?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We understand that customers may feel frustrated before or after purchasing a product. Therefore, before offering any recommendations, our team puts themselves in their position. The goal is to present solutions and foster a situation that benefits both parties." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is your most common approach with customers who demand unrealistic discounts or coupons?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We first review the customers' order history to understand their buying patterns and whether they have been purchasing with us frequently or rarely. Next, we check for any existing coupons. However, in the first place, offering coupons is not our primary focus. Instead, we assess the product's profit margins to determine if there is any room for discount. Our strategy is aligned to maintain a balance between customer satisfaction and business profitability." 

      } 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      {  

  

  "@context": "https://schema.org",  

  

  "@type": "BreadcrumbList",  

  

  "itemListElement": [  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 1,  

  

      "name": "Home",  

  

      "item": "https://www.fbspl.com/"  

  

    },  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 2,  

  

      "name": "Services",  

  

      "item": "https://www.fbspl.com/bpm-and-bpo-services"  

  

    },  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 3,  

  

      "name": "eCommerce",  

  

      "item": "https://www.fbspl.com/services/e-commerce-outsourcing-services"  

  

    },  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 4,  

  

      "name": "E-commerce Pre-Sales Support",  

  

      "item": "https://www.fbspl.com/services/e-commerce-pre-sales-support"  

  

    }  

  

  ]  

  

}  `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/e-commerce-pre-sales-support`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
