<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img  appCdnUrl="assets/ecommerce-order-management/main-banner.jpg" class="card-img-desktop desktop-banner" alt="eCommerce Order Management Services" />
    <div class="card-img-overlay overlay-text bannerText">
   
        <div class="main_bannar pt-3" data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item"><a href="/bpm-and-bpo-services">Services</a></li>
              <li class="breadcrumb-item" aria-current="page">
                <a href="./">E-commerce</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Order Management
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary ">
            Order Management Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            As a business, we understand that it takes only a bunch of errors to make all your customer experience
            efforts go in vain. With us, you sign up for accuracy inorder management services.
          </p>
          <a routerLink="/book-free-consultation-call"  class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 my-auto clickable-heading">
              Sign Up Now
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
  
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img  appCdnUrl="assets/ecommerce-order-management/Mobile-banner.jpg" class="card-img-mob" alt="eCommerce Order Management Services" />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item"><a href="/bpm-and-bpo-services">Services</a></li>
          <li class="breadcrumb-item" aria-current="page">
            <a href="./">E-commerce</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Order Management
          </li>
        </ol>
      </nav>
      <h1 class="card-text font-34-19 text-primary ">Order Management Services</h1>
      <p class="card-text font-20-16 pt-3">
        As a business, we understand that it takes only a bunch of errors to make all your customer experience
        efforts go in vain. With us, you sign up for accuracy inorder management services.  
      </p>
      <a routerLink="/book-free-consultation-call"  class="CTA-Zindex anchorCTA">
        <h3 class="text-primary font-24-16 my-auto clickable-heading">
          Sign Up Now
          <div class="headingCTA">
            <button class="blueCTA"></button>
          </div>
        </h3>
      </a>
    </div>
  </div>
</div>
</div>
<app-ourAssets></app-ourAssets>
<section class="container-fluid policy-process-service" id="order management services">
  <div class="container">
    <div class="container_emp ps-lg-3">
      <h2 class="text-white font-48-30 py-3" data-aos="fade-up">
        Lead the Change with Our Order Management Services    
      </h2>
      <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-4" />
    </div>
    <div class="d-none d-lg-block">
      <div class="row py-3 px-2">
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Issuance p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Order Processing
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <!-- Modal -->
          <div class="modal Policy-Processing fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5" data-aos="zoom-in">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Order Processing</h3>
                      <p class="text-dark font-20-16">
                        We ensure your customer orders are prepared, processed, and accurately delivered on time. Join
                        us for customer satisfaction and operational efficiency.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Order entry automation
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Order updates in real-time
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Automate stock-related entries
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Streamlined workflow
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img alt="eCommerce Order Processing Services" appCdnUrl="assets/ecommerce-order-management/detail Order Processing.webp" class="bordered" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy-Checking p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal1">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Order Tracking
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal1" tabindex="-1"
            aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Order Tracking</h3>
                      <p class="text-dark font-20-16">
                        Brand loyalty, trust, and new prospects are what we boast about through our transparent and
                        real-time order-tracking procedures.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Order information database
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Order tracking portal
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Real-time shipping status
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Automated notification system
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img alt="eCommerce Order Tracking Services " appCdnUrl="assets/ecommerce-order-management/Detail Page - Order Tracking.webp"  class="bordered"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Loss_Runs_Processing p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal2">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Payment Processing
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal2" tabindex="-1"
            aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Payment Processing</h3>
                      <p class="text-dark font-20-16">
                        Ranging from traditional payment processes to the newer payment alternatives like digital
                        wallets, our practices cater to all your customers' payment needs and preferences.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Secured and reputable payment
                          gateways
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Multiple payment methods
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Fraud detection
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img alt="Payment Processing Services " appCdnUrl="assets/ecommerce-order-management/Detail Page Payment Processing.webp"  class="bordered"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Renewals p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2" data-bs-toggle="modal" data-bs-target="#exampleModal3">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Shipping inquiries
                <div class="headingCTA">
                  <button class="blueCTA btn_hover" ></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal3" tabindex="-1"
            aria-labelledby="exampleModalLabel3" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Shipping inquiries</h3>
                      <p class="text-dark font-20-16">
                        From flexible shipping methods to cost management, our professionals are trained in planning
                        routes efficiently, eliminating confusion and missed deliveries. Get in touch today.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Detailed FAQs
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Multiple shipping options
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Familiarity with shipping policies
                          and procedures
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img alt="Shipping Inquiries Services " appCdnUrl="assets/ecommerce-order-management/Detail Page Shipping Inquiries.webp"  class="bordered" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div class="card insurance-card policy-process-card Policy_Management p-3" data-aos="zoom-in">
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal" data-bs-target="#exampleModal4">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Return, Refund, and Exchange Management
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div class="modal Policy-Processing fade" id="exampleModal4" tabindex="-1"
            aria-labelledby="exampleModalLabel4" aria-hidden="true">
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-5">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Return, Refund, and Exchange Management</h3>
                      <p class="text-dark font-20-16">
                        No matter how unhappy your customers are with the product, we ensure they stay happy with your
                        brand. From cross-selling to earning positive reviews, getting the most out of every situation
                        is what we are known for.
                      </p>
                      <ul class="ul-policy">
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Clear and transparent policies
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Return requests status tracking
                        </li>
                        <li class="pt-2 text-dark font-20-16">
                          <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Trained staff and customer
                          satisfaction
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img alt="eCommerce Return, Refund, and Exchange Management Services" appCdnUrl="assets/ecommerce-order-management/Detail Page Return, Refund.webp"  class="bordered"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="p-3">
    <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-12 mt-0 mt-sm-4">
          <div class="accordion" id="ServicePage">
            <div class="policy-processing" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal">
              <div data-bs-toggle="collapse" data-bs-target="#Policy_Processing" aria-expanded="true"
                aria-controls="Policy_Processing" id="headingOne" class="headingCTA CTA-Zindex d-flex  p-3">
                <h3 class="CTAs text-black fs-24  me-3">Order Processing</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
            </div>
            <div class="modal Policy-Processing fade" id="MobModal" tabindex="-1" aria-labelledby="MobModalModalLabel"
              aria-hidden="true">
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                  </div>
                  <div class="card policy-processing-services-card-mob mx-3 py-3">
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Order Processing</h3>
                        <p class="text-dark font-20-16">
                          We ensure your customer orders are prepared, processed, and accurately delivered on time. Join
                          us for customer satisfaction and operational efficiency.
                        </p>
                        <ul class="ul-policy">
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Order entry automation
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Order updates in real-time
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Automate stock-related entries
                          </li>
                          <li class="pt-2 text-dark font-20-16">
                            <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Streamlined workflow
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal1">
              <div data-bs-toggle="collapse" data-bs-target="#Claims_Management" aria-expanded="true"
                aria-controls="Claims_Management" id="headingOne" class="headingCTA CTA-Zindex d-flex  p-3">
                <h3 class="CTAs text-black fs-24  me-3">Order Tracking</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal1" tabindex="-1" aria-labelledby="MobModal1Lable1"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Order Tracking</h3>
                          <p class="text-dark font-20-16">
                            Brand loyalty, trust, and new prospects are what we boast about through our transparent and
                            real-time order-tracking procedures.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Order information database
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Order tracking portal
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Real-time shipping status
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Automated notification system
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal2">
              <div class="d-flex p-3" >
                <h3 class="CTAs text-black fs-24  me-3">
                  Payment Processing
                </h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal2" tabindex="-1" aria-labelledby="MobModalLabel2"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Payment Processing
                          </h3>
                          <p class="text-dark font-20-16">
                            Ranging from traditional payment processes to the newer payment alternatives like digital
                            wallets, our practices cater to all your customers' payment needs and preferences.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Secured and reputable payment
                              gateways
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Multiple payment methods
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Fraud detection
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal3">
              <div class="d-flex p-3"  >
                <h3 class="CTAs text-black fs-24  me-3">Shipping inquiries</h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal3" tabindex="-1" aria-labelledby="MobModalLabel3"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Shipping inquiries</h3>
                          <p class="text-dark font-20-16">
                            From flexible shipping methods to cost management, our professionals are trained in planning
                            routes efficiently, eliminating confusion and missed deliveries. Get in touch today.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Detailed FAQs
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Multiple shipping options
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Familiarity with shipping
                              policies and procedures
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in" data-bs-toggle="modal" data-bs-target="#MobModal4">
              <div class="d-flex p-3" >
                <h3 class="CTAs text-black fs-24  me-3">
                  Return, Refund, and Exchange Management
                </h3>
                <img class="service_btn" 
                   appCdnUrl="assets/Icon/Accord-close.svg" />
              </div>
              </div>
              <div class="modal Policy-Processing fade" id="MobModal4" tabindex="-1" aria-labelledby="MobModalLabel4"
                aria-hidden="true">
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img data-bs-dismiss="modal" aria-label="Close"  appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn" />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Return, Refund, and Exchange Management
                          </h3>
                          <p class="text-dark font-20-16">
                            No matter how unhappy your customers are with the product, we ensure they stay happy with
                            your brand. From cross-selling to earning positive reviews, getting the most out of every
                            situation is what we are known for.
                          </p>
                          <ul class="ul-policy">
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Clear and transparent policies
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Return requests status tracking
                            </li>
                            <li class="pt-2 text-dark font-20-16">
                              <img class="arrow-img"  appCdnUrl="assets/Icon/Arrow-blue.svg" />Trained staff and customer
                              satisfaction
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-5" appCdnUrl="assets/Icon/white-snake-vector.svg"/>
  </div>
</section>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
        <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="100">
        <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="200">
        <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="300">
        <img class="img-fluid" alt="ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
      </div>
      <div class="col-2"  data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="ecommerce tool Zendesk" appCdnUrl="assets/Tools/zendesk.svg"/>
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt=" ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="ecommerce tool Zendesk " appCdnUrl="assets/Tools/zendesk.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="ecommerce tool Jira" appCdnUrl="assets/Tools/jira.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="ecommerce tool Magento" appCdnUrl="assets/Tools/magneto.svg" />
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="ecommerce tool Salesforce" appCdnUrl="assets/Tools/salesforce.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="ecommerce tool HubSpot" appCdnUrl="assets/Tools/hubspot.svg"/>
        </div>
        <div class="trust-slide"  data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="ecommerce tool Zendesk" appCdnUrl="assets/Tools/zendesk.svg"/>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Thinking -->
<app-service-case-study  [category]="[{ category: 'ecommerce' }]"></app-service-case-study>
<!-- Know best -->
<app-know-best
[cards]="[
  { description: 'We have had an incredible experience with fusion so far. We have actually increased our fusion team size twice now. And the process has been very seamless, very easy. The fusion team is very communicative, and the quality of the work has rivaled that of any employee we could have in-house. ',
  name: 'Joey Cumley', designation: 'Chief Merchandising Officer'
 },
]"
[videos]="[
  { videoUrl: 'https://player.vimeo.com/video/963295022',
  thumbnail:'assets/thumbnail-know-best/Client Testimonials YT 03 Joey Cumley.jpg',
  alt:'Listen to our client '

   },
]">
</app-know-best>
<section class="py-6"  data-aos="fade-up">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6"  data-aos="fade-right">
        <app-faq></app-faq>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0"  data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button font-28-20" type="button" data-bs-toggle="collapse"
                data-bs-target="#Industries" aria-expanded="true" aria-controls="Industries">
                How do you ensure last-mile deliveries in order processing services?
              </button>
            </h2>
            <div id="Industries" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Through our advanced tracking technologies and API integrations, we closely monitor the status of each
                shipment. This real-time tracking information also enables us to analyze delivery times, potential
                delays, and traffic conditions and dynamically adjust routes to confirm prompt and efficient deliveries.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Services" aria-expanded="false" aria-controls="Services">
                What is your take on forecasting future product demands in ecommerce order fulfillment?
              </button>
            </h2>
            <div id="Services" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Our approach involves utilizing advanced statistical analysis techniques to identify patterns and their
                correlations, enabling accurate prediction of shifts in consumer behavior and market trends. We use
                these insights to stock the right products in the right quantity at the right time.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#About" aria-expanded="false" aria-controls="About">
                How can I retain full control of my order management despite outsourcing the service?
              </button>
            </h2>
            <div id="About" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Our order management services employ real-time synchronization and provide you with instant updates on
                your consumers' order status, inventory levels, and shipping details. Moreover, our system's advanced
                encryption and authentication safeguard any sensitive order information, ensuring data security and
                compliance with industry regulations.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Resources" aria-expanded="false" aria-controls="Resources">
                How do you claim to reduce errors in order processing services?
              </button>
            </h2>
            <div id="Resources" class="accordion-collapse collapse" aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                Combining the capabilities of built-in error detection and exception handling protocols, our <a class="anchor" href="/services/e-commerce-order-management#order management services">ecommerce
                  order management solutions </a>are founded on the principles of high accuracy and customer satisfaction.
                Placing customer satisfaction at the core of our operations, we continuously identify and update areas
                for optimization and refinement.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button font-28-20 collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#Differentiators" aria-expanded="false" aria-controls="Differentiators">
                Why should I choose you as my right ecommerce outsourcing service provider?
              </button>
            </h2>
            <div id="Differentiators" class="accordion-collapse collapse" aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ">
              <div class="accordion-body">
                <P>You should partner with us if you are looking for: </P>
                <ul class="pt-3">
                  <li>360-degree assistance that meets your dynamic business needs round the clock</li>
                  <li>Professionally trained employees</li>
                  <li>Buck-up services at zero additional cost</li>
                  <li>Dedicated <a class="anchor" target="_blank" href="/services/customer-support-outsourcing">customer support</a>, vendor management, and much more</li>
                 
                </ul>
                <p>Partnering with us means aligning with the industry excellence.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>