<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/accoutning Financial Reporting/Main-banner.jpg"
      class="card-img-desktop desktop-banner" alt="Financial Reporting Services "
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/accounting-bookkeeping-services"
                  >Accounting & Bookkeeping</a
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <a aria-current="page">Financial Reporting</a>
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Financial Reporting Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Stay on top of your accounting and bookkeeping activities with
            precise financial reporting services. Our team ensures accurate
            financial reports management for bookkeeping and accounting while
            helping businesses stay compliant with rules and regulations.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Connect with Experts
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      appCdnUrl="assets/accoutning Financial Reporting/Mobile-banner.jpg"
      class="card-img-mob" alt="Financial Reporting Services "
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/accounting-bookkeeping-services"
                >Accounting & Bookkeeping</a
              >
            </li>
            <li class="breadcrumb-item active">
              <a aria-current="page">Financial Reporting</a>
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Financial Reporting Services
        </h1>
        <p class="card-text font-20-16">
          Stay on top of your accounting and bookkeeping activities with precise
          financial reporting services. Our team ensures accurate financial
          reports management for bookkeeping and accounting while helping
          businesses stay compliant with rules and regulations.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Connect with Experts
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>
<app-ourAssets></app-ourAssets>

<section class="container-fluid policy-process-service">
  <div class="container">
    <div class="container_emp ps-lg-3" data-aos="fade-up">
      <h2 class="text-white font-48-30 py-3" id="financial reporting services">
        Empower Change with Financial Reporting Services
      </h2>
      <img
        appCdnUrl="assets/Icon/squarevector.svg"
        class="top-right-float vector mt-4"
      />
    </div>
    <div class="d-none d-lg-block">
      <div class="row py-3 px-2">
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Issuance p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Cost Accounting
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <!-- Modal -->
          <div
            class="modal Policy-Processing fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Cost Accounting</h3>
                      <p class="text-dark font-20-16">
                        Improve your financial management process with apt cost
                        accounting services. Our team efficiently records,
                        analyzes, and reports a company's costs while
                        identifying the best cost-cutting methods. Ensure strong
                        decision-making with us.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />
                          Identifying cost objectives
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />
                          Analyzing direct and indirect costs
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />
                          Allocating direct and indirect costing
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />
                          Calculating the final costing
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />
                          Preparing the final financial report
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning Financial Reporting/cost-accounting.webp"
                      alt="Cost Accounting Services "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy-Checking p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Inventory Accounting
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Inventory Accounting</h3>
                      <p class="text-dark font-20-16">
                        Do not let the inefficient inventory accounting process
                        affect your business performance and growth. We offer
                        end-to-end inventory
                        <a
                          class="anchor"
                          target="_blank"
                          href="/services/accounting-bookkeeping-services"
                          >accounting outsourcing solutions</a
                        >
                        and help you manage your inventory like a pro.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Identifying all the inventory items
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Keeping track of inventory counts
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Assigning monetary value to each item
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Choosing the right inventory accounting method
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Recording inventory on the balance sheet
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning Financial Reporting/Group 217.webp"
                      alt="Inventory Accounting Services "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Loss_Runs_Processing p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Reconciliation Reporting
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel2"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">
                        Reconciliation Reporting
                      </h3>
                      <p class="text-dark font-20-16">
                        Experience precision with
                        <a
                          class="anchor"
                          href="/services/accounts-reconciliation-services"
                          >professional reconciliation reporting services</a
                        >. Our team ensures 100% accuracy of your financial
                        records by meticulously comparing and rectifying
                        discrepancies between internal and external data.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Gathering essential data
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Comparing and matching gathered data
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Identifying internal and external discrepancies
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Investigating and resolving discrepancies
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Preparing detailed reconciliation report
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning Financial Reporting/Group 218.webp"
                      alt="Professional Reconciliation Reporting Services"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Renewals p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal3"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Financial Planning & Analysis
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal3"
            tabindex="-1"
            aria-labelledby="exampleModalLabel3"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">
                        Financial Planning & Analysis
                      </h3>
                      <p class="text-dark font-20-16">
                        Grow your business in the right direction with our
                        financial reporting services. Our team prepares
                        <a
                          class="anchor"
                          href="/services/accounting-and-financial-reporting-services"
                          >monthly financial reports</a
                        >
                        to compare the growth of financials, focusing on the
                        assets, equity, and liabilities.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Gathering and validating financial data
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Using analytical tools to understand the data
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Communicating insights to stakeholders
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Creating a financial plan based on the analysis
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Implementing and monitoring the financial plan
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning Financial Reporting/Group 219.webp"
                      alt="Financial Planning & Analysis"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Management p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal4"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Cashflow Analysis
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal4"
            tabindex="-1"
            aria-labelledby="exampleModalLabel4"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    appCdnUrl="assets/Icon/Accord-open.svg"
                    class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Cashflow Analysis</h3>
                      <p class="text-dark font-20-16">
                        Get detailed insights into your cash inflows and
                        outflows and make the best-informed decisions ever.
                        Empower your business with 100% financial clarity and
                        control. Avail cashflow analysis solutions from us now!
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Gathering all the financial data
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Reviewing financial records to understand cashflow
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Predicting future cash inflows and outflows
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Creating cashflow management strategies
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Preparing cashflow analysis reports
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                      appCdnUrl="assets/accoutning Financial Reporting/Group 220.webp"
                      alt="Cashflow Analysis"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Endorsements p-3"
            data-aos="zoom-in"
          >
            <a
              class="CTA-Zindex anchorCTA mb-3 p-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal5"
            >
              <h3 class="text-black font-34-19 clickable-heading">
                Balance Sheet Preparation
                <div class="headingCTA">
                  <button class="blueCTA btn_hover"></button>
                </div>
              </h3>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal Policy-Processing fade"
      id="exampleModal5"
      tabindex="-1"
      aria-labelledby="exampleModalLabel5"
      aria-hidden="true"
    >
      <div class="modal-dialog policy-dialog pt-0 mt-0">
        <div class="modal-content">
          <div class="modal-header policy-header">
            <img
              data-bs-dismiss="modal"
              aria-label="Close"
              appCdnUrl="assets/Icon/Accord-open.svg"
              class="close_btn"
            />
          </div>
          <div class="card policy-processing-services-card mx-5">
            <div class="details ms-4">
              <div class="content">
                <h3 class="font-48-30 text-dark">Balance Sheet Preparation</h3>
                <p class="text-dark font-20-16">
                  Are you struggling with maintaining balance sheets, income
                  statements, and cash flow reports? Not anymore! Get financial
                  reporting services from us, adhering to accounting standards
                  and financial transparency.
                </p>
                <ul class="ul-policy">
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                      appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Organizing financial data into spreadsheets
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                      appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Totaling up all the business assets
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                      appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Adding short-term and long-term business liabilities
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                      appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Calculating the owner’s equity
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                      appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Preparing the balance sheet
                  </li>
                </ul>
              </div>
            </div>
            <div class="imgBx d-none d-lg-block">
              <img
                appCdnUrl="assets/accoutning Financial Reporting/Group 221.webp"
                alt="Balance Sheet Preparation "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="p-3">
    <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-12 mt-0 mt-sm-4">
          <div class="accordion" id="ServicePage">
            <div
              class="policy-processing"
              data-aos="zoom-in"
              data-bs-toggle="modal"
              data-bs-target="#MobModal"
            >
              <div
                data-bs-toggle="collapse"
                data-bs-target="#Policy_Processing"
                aria-expanded="true"
                aria-controls="Policy_Processing"
                id="headingOne"
                class="headingCTA CTA-Zindex d-flex p-3"
              >
                <h3 class="CTAs text-black fs-24">Cost Accounting</h3>
                <img
                  class="service_btn"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal"
              tabindex="-1"
              aria-labelledby="MobModalModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Cost Accounting</h3>
                        <p class="text-dark font-20-16">
                          Improve your financial management process with apt
                          cost accounting services. Our team efficiently
                          records, analyzes, and reports a company's costs while
                          identifying the best cost-cutting methods. Ensure
                          strong decision-making with us.
                        </p>
                        <ul class="ul-policy">
                          <li
                            class="list-item list-item pt-2 text-dark font-20-16"
                          >
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying cost objectives
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Analyzing direct and indirect costs
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Allocating direct and indirect costing
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Calculating the final costing
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Preparing the final financial report
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="policy-processing mt-4"
              data-aos="zoom-in"
              data-bs-toggle="modal"
              data-bs-target="#MobModal1"
            >
              <div
                data-bs-toggle="collapse"
                data-bs-target="#Claims_Management"
                aria-expanded="true"
                aria-controls="Claims_Management"
                id="headingOne"
                class="headingCTA CTA-Zindex d-flex p-3"
              >
                <h3 class="CTAs text-black fs-24">
                  Inventory Accounting
                </h3>
                <img
                  class="service_btn"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal1"
              tabindex="-1"
              aria-labelledby="MobModal1Lable1"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">
                          Inventory Accounting
                        </h3>
                        <p class="text-dark font-20-16">
                          Do not let the inefficient inventory accounting
                          process affect your business performance and growth.
                          We offer end-to-end inventory
                          <a
                            class="anchor"
                            target="_blank"
                            href="/services/accounting-bookkeeping-services"
                            >accounting outsourcing solutions</a
                          >
                          and help you manage your inventory like a pro.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying all the inventory items
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Keeping track of inventory counts
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Assigning monetary value to each item
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Choosing the right inventory accounting method
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Recording inventory on the balance sheet
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                class="d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal2"
              >
                <h3 class="CTAs text-black fs-24">
                  Reconciliation Reporting
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal2"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal2"
              tabindex="-1"
              aria-labelledby="MobModalLabel2"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">
                          Reconciliation Reporting
                        </h3>
                        <p class="text-dark font-20-16">
                          Experience precision with
                          <a
                            class="anchor"
                            href="/services/accounts-reconciliation-services"
                            >professional reconciliation reporting services</a
                          >. Our team ensures 100% accuracy of your financial
                          records by meticulously comparing and rectifying
                          discrepancies between internal and external data.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering essential data
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Comparing and matching gathered data
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Identifying internal and external discrepancies
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Investigating and resolving discrepancies
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Preparing detailed reconciliation report
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                class="d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal3"
              >
                <h3 class="CTAs text-black fs-24">
                  Financial Planning & Analysis
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal3"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal3"
              tabindex="-1"
              aria-labelledby="MobModalLabel3"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">
                          Financial Planning & Analysis
                        </h3>
                        <p class="text-dark font-20-16">
                          Grow your business in the right direction with our
                          financial reporting services. Our team prepares
                          <a
                            class="anchor"
                            href="/services/accounting-and-financial-reporting-services"
                            >monthly financial reports</a
                          >
                          to compare the growth of financials, focusing on the
                          assets, equity, and liabilities.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering and validating financial data
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Using analytical tools to understand the data
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Communicating insights to stakeholders
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Creating a financial plan based on the analysis
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Implementing and monitoring the financial plan
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                class="d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal4"
              >
                <h3 class="CTAs text-black fs-24">
                  Cashflow Analysis
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal4"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal4"
              tabindex="-1"
              aria-labelledby="MobModalLabel4"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Cashflow Analysis</h3>
                        <p class="text-dark font-20-16">
                          Get detailed insights into your cash inflows and
                          outflows and make the best-informed decisions ever.
                          Empower your business with 100% financial clarity and
                          control. Avail cashflow analysis solutions from us
                          now!
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering all the financial data
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Reviewing financial records to understand cashflow
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Predicting future cash inflows and outflows
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Creating cashflow management strategies
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Preparing cashflow analysis reports
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                id="headingOne"
                class="d-flex p-3"
                data-bs-toggle="modal"
                data-bs-target="#MobModal5"
              >
                <h3 class="CTAs text-black fs-24">
                  Balance Sheet Preparation
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal5"
                  appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal5"
              tabindex="-1"
              aria-labelledby="MobModalLabel5"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      appCdnUrl="assets/Icon/Accord-open.svg"
                      class="close_btn"
                    />
                  </div>
                  <div
                    class="card policy-processing-services-card-mob mx-3 py-3"
                  >
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">
                          Balance Sheet Preparation
                        </h3>
                        <p class="text-dark font-20-16">
                          Are you struggling with maintaining balance sheets,
                          income statements, and cash flow reports? Not anymore!
                          Get financial reporting services from us, adhering to
                          accounting standards and financial transparency.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Organizing financial data into spreadsheets
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Totaling up all the business assets
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Adding short-term and long-term business
                            liabilities
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Calculating the owner’s equity
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Preparing the balance sheet
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-5" appCdnUrl="assets/Icon/white-snake-vector.svg" />
  </div>
</section>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool QuickBooks "  appCdnUrl="assets/Tools/quickbooks.svg" />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg" />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"/>
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Thinking -->
<app-service-case-study
  [category]="[{ category: 'accountingandbookkeeping' }]"
></app-service-case-study>
<!-- Know best -->
<app-know-best
  [cards]="[
    {
      description:
        'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
      name: 'Francesca Furlanetto',
      designation: 'Director Owner'
    },
    {
      description:
        'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
      name: 'Monica Spivey',
      designation: 'Directory of Accounting'
    }
  ]"
  [videos]="[
     { 
     thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
     alt:' Listen to our client '
     },
     {
     
      thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt:'Our Success Stories'
    },
  ]"
>
</app-know-best>
<section class="py-6">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6" data-aos="fade-right">
        <app-faq></app-faq>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0" data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button font-28-20"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Industries"
                aria-expanded="true"
                aria-controls="Industries"
              >
                What is the month-end close process?
              </button>
            </h2>
            <div
              id="Industries"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                The month-end close process is a crucial process that is done at
                the end of each month to ensure accurate and timely financial
                reporting. It involves several steps, including reconciling
                accounts, reviewing transactions, adjusting entries, preparing
                financial statements, and analyzing performance.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Services"
                aria-expanded="false"
                aria-controls="Services"
              >
                What types of tools do you use for accounting and bookkeeping
                processes including financial reporting?
              </button>
            </h2>
            <div
              id="Services"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                We hold expertise in leveraging multiple types of tools for
                accounting and bookkeeping including financial reporting. Here
                are the major tools we use.
                <ul class="pt-3">
                  <li>QuickBooks</li>
                  <li>Zoho</li>
                  <li>Oddo</li>
                  <li>Sage</li>
                  <li>NetSuite</li>
                  <li>Xero</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#About"
                aria-expanded="false"
                aria-controls="About"
              >
                Why should I choose you for financial reporting services?
              </button>
            </h2>
            <div
              id="About"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                At
                <a href="./" class="anchor" target="_blank">FBSPL</a>, we make
                sure to maintain data accuracy and precision while offering
                financial reporting services. If you are still wondering why we
                are the best fit for you, here is what set us apart.
                <ul class="pt-3">
                  <li>All-in-one expertise</li>
                  <li>98% data accuracy</li>
                  <li>Cost-effective solutions</li>
                  <li>Contractual services</li>
                  <li>Availability as per client’s time zone</li>
                  <li>Tailor-made solutions</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Resources"
                aria-expanded="false"
                aria-controls="Resources"
              >
                Can you describe the process of reviewing and preparing
                financial statements?
              </button>
            </h2>
            <div
              id="Resources"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Here is a checklist for financial statement review and
                preparation.
                <ul class="pt-3">
                  <li>Verify the accuracy of financial documents</li>
                  <li>
                    Scrutinize the balance sheet & review the income statement
                  </li>
                  <li>Evaluate the cash flow statement</li>
                  <li>
                    Compare current statements with previous period statements
                  </li>
                  <li>
                    Assess the company's financial performance against industry
                    benchmarks
                  </li>
                  <li>Understand the financial ratios and indicators</li>
                  <li>Formulate preliminary conclusion and recommendations</li>
                  <li>Prepare financial statement review report</li>
                  <li>Submit review report to the management</li>
                  <li>
                    Review updated financials for compliance with
                    recommendations
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#differentiators"
                aria-expanded="false"
                aria-controls="differentiators"
              >
                Can a non-CPA prepare financial statements using the FRF
                framework?
              </button>
            </h2>
            <div
              id="differentiators"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Non-CPAs may prepare financial statements using available
                financial frameworks including the FRF framework, cash, tax, and
                even GAAP bases of accounting.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#GAAP"
                aria-expanded="false"
                aria-controls="GAAP"
              >
                Why is GAAP so important?
              </button>
            </h2>
            <div
              id="GAAP"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                With GAAP, companies and any entity in the finance sector would
                have their functioning continued. GAAP allows its stakeholders
                to quickly evaluate companies by assessing their financial
                statements. When an investor is in two minds about companies in
                the same sector, he can compare those companies' financial
                statements to make an investment decision. GAAP also helps
                companies gain key insights into their practices and
                performances. Moreover, it helps minimize the risk of errors in
                financial reporting by having checks and safeguards at multiple
                levels while preparing financial statements.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
