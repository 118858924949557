<div class="container text-dark d-none d-lg-block">
    <div class="gradientBanner">
      <img
         appCdnUrl="assets/accoutning Payroll Processing/payroll main-banner.jpg"
        class="card-img-desktop desktop-banner" alt="Financial Reporting Services "
     
      />
      <div class="card-img-overlay overlay-text bannerText">
          <div class="main_bannar pt-3" >
            <div data-aos="fade-up">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a  href="/">Home</a></li>
                  <li class="breadcrumb-item" >
                    <a  href="/bpm-and-bpo-services">Services</a>
                  </li>
                  <li class="breadcrumb-item">
                      <a  href="/services/accounting-bookkeeping-services">Accounting & Bookkeeping</a>
                  </li>
                  <li class="breadcrumb-item active">
                    <a  aria-current="page">Payroll Processing</a>
                </li>
                </ol>
              </nav>
              <h1 class="card-text font-34-19 text-primary pt-3">
                  Payroll Processing Services
              </h1>
              <p class="card-text font-20-16 pt-3">
                  Ensure accurate and timely disbursements with our top-notch payroll processing services. From monthly salary calculations to disbursements and withholding deductions, our payroll processing providers can quickly streamline your HR operations.. 
              </p>
              <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
                <h3 class="text-primary font-24-16 clickable-heading">
                  Connect with Experts
                  <div class="headingCTA">
                    <button class="blueCTA"></button>
                  </div>
                </h3>
              </a>
            </div>
          </div>
        </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container text-dark slide d-block d-lg-none ">
    <div class="gradientBannerMob d-flex justify-content-center">
      <img
         appCdnUrl="assets/accoutning Payroll Processing/payroll Mobile-banner.jpg"
        class="card-img-mob" alt="Financial Reporting Services "
       
      />
      <div class="card-img-overlay overlay-text-Mob bannerTextMob">
        <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a  href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a  href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                  <a  href="/services/accounting-bookkeeping-services">Accounting & Bookkeeping</a>
              </li>
              <li class="breadcrumb-item active" >
                <a  aria-current="page">Payroll Processing</a>
            </li>
            </ol>
          </nav>
        <h1 class="card-text font-34-19 text-primary">Payroll Processing Services</h1>
        <p class="card-text font-20-16">
            Ensure accurate and timely disbursements with our top-notch payroll processing services. From monthly salary calculations to disbursements and withholding deductions, our payroll processing providers can quickly streamline your HR operations.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Connect with Experts
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
        </div>
      </div>
    </div>
  </div>
<app-ourAssets></app-ourAssets>
  
  <section class="container-fluid policy-process-service">
    <div class="container">
      <div class="container_emp ps-lg-3">
        <h2 class="text-white font-48-30 py-3" data-aos="fade-up">
        Payroll Management Outsourcing Services 
        </h2>
        <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-4" />
      </div>
      <div class="d-none d-lg-block">
        <div class="row py-3 px-2">
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Issuance p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal">
                <h3 class="text-black font-34-19 clickable-heading">
                    Payroll Calculation   
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <!-- Modal -->
            <div
              class="modal Policy-Processing fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Payroll Calculation </h3>
                        <p class="text-dark font-20-16">
                            Let us tackle all the payroll calculations for you. Our payroll management company experts will calculate the net pay for each employee, including the gross pay and deductions. Get accurate payroll calculation solutions from us.  
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Determining company’s pay period 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Preparing payroll sheet for employees
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Calculating each employee’s working hours 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Recording payroll expenses 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Finalizing payroll calculation sheet 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payroll Processing/Group 211.webp"
                        alt="Payroll Calculation Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy-Checking p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal1">
                <h3 class="text-black font-34-19 clickable-heading">
                    Benefit Administration 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel1"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Benefit Administration </h3>
                        <p class="text-dark font-20-16">
                            Do you find managing employees' benefits a daunting task? Not anymore! Our experts handle everything from health insurance and retirement plans to pay time off. With our payroll processing outsourcing services, you can ensure smooth benefit administration. 
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Evaluating all the benefits programs  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Creating employee benefits program 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Managing all the employee benefits 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Enrolling employees in the benefits program 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Ensuring compliance with benefits administration 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payroll Processing/Group 212.webp"
                        alt="Benefit Administration Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Loss_Runs_Processing p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal2">
                <h3 class="text-black font-34-19 clickable-heading">
                    Payroll Record Keeping 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Payroll Record Keeping </h3>
                        <p class="text-dark font-20-16">
                            Accurate record-keeping is a must for every business. So, with our payroll processing services, you can get detailed records of all payroll transactions, ensuring accuracy and compliance with legal requirements. Get accurate payroll record keeping with us.  
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering employee details for record-keeping 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Tracking employee activities for payroll 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Accurately calculating employee salaries using software 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Disbursing the salaries to each employee 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Keeping a detailed record of employee payrolls 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payroll Processing/Group 213.webp"
                        alt="Payroll Record Keeping Services "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Renewals p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
              data-bs-target="#exampleModal3">
                <h3 class="text-black font-34-19 clickable-heading">
                    Payroll Reporting 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                    
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal3"
              tabindex="-1"
              aria-labelledby="exampleModalLabel3"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Payroll Reporting </h3>
                        <p class="text-dark font-20-16">
                            Stay informed about all the payroll activities with our in-depth payroll reporting solutions. Our professionals provide timely reports to help businesses and their customers keep track of payroll costs and make the best strategic decisions ever.   
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Defining the payroll policies  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Gathering all employees' inputs 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Calculating the payroll in different stages 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Managing all the payrolls within the system 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Preparing detailed payroll reports 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payroll Processing/Group 214.webp"
                        alt="Payroll Reporting Service "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Management p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
              data-bs-target="#exampleModal4">
                <h3 class="text-black font-34-19 clickable-heading">
                    Compliance 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                     
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="exampleModal4"
              tabindex="-1"
              aria-labelledby="exampleModalLabel4"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog pt-0 mt-0">
                <div class="modal-content">
                  <div class="modal-header policy-header">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card mx-5">
                    <div class="details ms-4">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Compliance </h3>
                        <p class="text-dark font-20-16">
                            Ensuring compliance with all the latest rules and regulations is a hard nut to crack. But, with our outsourcing payroll processing services, you can reduce the risk of penalties and fines with no miss and ensure smooth compliance during payroll processing.  
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Updating payroll compliance as per changing regulations 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Verifying employee classification  
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Ensuring employee benefits administration 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Maintaining accurate records for payroll compliance 
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Mitigating all the risks and penalties involved 
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="imgBx d-none d-lg-block">
                      <img
                         appCdnUrl="assets/accoutning Payroll Processing/Group 215.webp"
                        alt="Compliance- payroll processing "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 col-md-6 py-3">
            <div
              class="card insurance-card policy-process-card Policy_Endorsements p-3" data-aos="zoom-in"
            >
              <a class="CTA-Zindex anchorCTA mb-3 p-2"    data-bs-toggle="modal"
              data-bs-target="#exampleModal5">
                <h3 class="text-black font-34-19 clickable-heading">
                    Accounting System Integration 
                  <div class="headingCTA">
                    <button
                      class="blueCTA btn_hover"
                   
                    ></button>
                  </div>
                </h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal Policy-Processing fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel5"
        aria-hidden="true"
      >
        <div class="modal-dialog policy-dialog pt-0 mt-0">
          <div class="modal-content">
            <div class="modal-header policy-header">
              <img
                data-bs-dismiss="modal"
                aria-label="Close"
                 appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
              />
            </div>
            <div class="card policy-processing-services-card mx-5">
              <div class="details ms-4">
                <div class="content">
                  <h3 class="font-48-30 text-dark">Accounting System Integration </h3>
                  <p class="text-dark font-20-16">
                    Are you struggling with seamless data transfer and disrupted payroll processing efficiency? Our accounting system integration solutions can be your savior. We can integrate systems with existing accounting software to automate all the accounting processes.   
                  </p>
                  <ul class="ul-policy">
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Defining the integration scope 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Setting up the payroll accounting 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Integrating payroll software 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Conducting data synchronization 
                    </li>
                    <li class="list-item pt-2 text-dark font-20-16">
                      <img
                        class="arrow-img"
                         appCdnUrl="assets/Icon/Arrow-blue.svg"
                      />Ensuring payroll processing and managing changes
                    </li>
                  </ul>
                </div>
              </div>
              <div class="imgBx d-none d-lg-block">
                <img
                   appCdnUrl="assets/accoutning Payroll Processing/Group 210.webp"
                  alt="Accounting System Integration Service "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile View -->
    <div class="p-3">
      <div class="d-block d-lg-none">
        <div class="row">
          <div class="col-12 mt-0 mt-sm-4">
            <div class="accordion" id="ServicePage">
              <div class="policy-processing" data-aos="zoom-in">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#Policy_Processing"
                  aria-expanded="true"
                  aria-controls="Policy_Processing"
                  id="headingOne"
                  class="headingCTA CTA-Zindex d-flex  p-3"
                  data-bs-toggle="modal"
                    data-bs-target="#MobModal"
                >
                  <h3 class="CTAs text-black fs-24  me-3">Payroll Calculation </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal"
                tabindex="-1"
                aria-labelledby="MobModalModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Payroll Calculation </h3>
                          <p class="text-dark font-20-16">
                            Let us tackle all the payroll calculations for you. Our payroll management company experts will calculate the net pay for each employee, including the gross pay and deductions. Get accurate payroll calculation solutions from us.  
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Determining company’s pay period  
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Preparing payroll sheet for employees 
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Calculating each employee’s working hours 
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Recording payroll expenses 
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Finalizing payroll calculation sheet 
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div
                  data-bs-toggle="collapse"
                  data-bs-target="#Claims_Management"
                  aria-expanded="true"
                  aria-controls="Claims_Management"
                  id="headingOne"
                  class="headingCTA CTA-Zindex d-flex  p-3"
                  data-bs-toggle="modal"
                    data-bs-target="#MobModal1"
                >
                  <h3 class="CTAs text-black fs-24  me-3">Benefit Administration </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal1"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal1"
                  tabindex="-1"
                  aria-labelledby="MobModal1Lable1"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">Benefit Administration </h3>
                            <p class="text-dark font-20-16">
                                Do you find managing employees' benefits a daunting task? Not anymore! Our experts handle everything from health insurance and retirement plans to pay time off. With our payroll processing outsourcing services, you can ensure smooth benefit administration.     
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Evaluating all the benefits programs 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Creating employee benefits program 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Managing all the employee benefits 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Enrolling employees in the benefits program 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Ensuring compliance with benefits administration 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal2"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Payroll Record Keeping 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal2"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal2"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel2"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Payroll Record Keeping  
                            </h3>
                            <p class="text-dark font-20-16">
                                Accurate record-keeping is a must for every business. So, with our payroll processing services, you can get detailed records of all payroll transactions, ensuring accuracy and compliance with legal requirements. Get accurate payroll record keeping with us.
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Gathering employee details for record-keeping 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Tracking employee activities for payroll  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Accurately calculating employee salaries using software 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Disbursing the salaries to each employee
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Keeping a detailed record of employee payrolls 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal3"
                >
                  <h3 class="CTAs text-black fs-24  me-3">Payroll Reporting   </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal3"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal3"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel3"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">Payroll Reporting   </h3>
                            <p class="text-dark font-20-16">
                                Stay informed about all the payroll activities with our in-depth payroll reporting solutions. Our professionals provide timely reports to help businesses and their customers keep track of payroll costs and make the best strategic decisions ever.   
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Defining the payroll policies 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Gathering all employees' inputs 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Calculating the payroll in different stages 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Managing all the payrolls within the system 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Preparing detailed payroll reports 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal4"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Compliance  
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal4"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal4"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel4"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Compliance 
                            </h3>
                            <p class="text-dark font-20-16">
                                Ensuring compliance with all the latest rules and regulations is a hard nut to crack. But, with our outsourcing payroll processing services, you can reduce the risk of penalties and fines with no miss and ensure smooth compliance during payroll processing.    
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Updating payroll compliance as per changing regulations 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Verifying employee classification  
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Ensuring employee benefits administration 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Maintaining accurate records for payroll compliance 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Mitigating all the risks and penalties involved  
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="policy-processing mt-4" data-aos="zoom-in">
                <div id="headingOne" class="d-flex p-3"
                data-bs-toggle="modal"
                    data-bs-target="#MobModal5"
                >
                  <h3 class="CTAs text-black fs-24  me-3">
                    Accounting System Integration 
                  </h3>
                  <img
                    class="service_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#MobModal5"
                     appCdnUrl="assets/Icon/Accord-close.svg"
                  />
                </div>
                </div>
                <div
                  class="modal Policy-Processing fade"
                  id="MobModal5"
                  tabindex="-1"
                  aria-labelledby="MobModalLabel5"
                  aria-hidden="true"
                >
                  <div class="modal-dialog policy-dialog">
                    <div class="modal-content">
                      <div class="modal-header policy-header mt-3">
                        <img
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                        />
                      </div>
                      <div class="card policy-processing-services-card-mob mx-3 py-3">
                        <div class="details ms-2">
                          <div class="content">
                            <h3 class="font-48-30 text-dark">
                                Accounting System Integration 
                            </h3>
                            <p class="text-dark font-20-16">
                                Are you struggling with seamless data transfer and disrupted payroll processing efficiency? Our accounting system integration solutions can be your savior. We can integrate systems with existing accounting software to automate all the accounting processes.  
                            </p>
                            <ul class="ul-policy">
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Defining the integration scope 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Setting up the payroll accounting 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Integrating payroll software 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Conducting data synchronization 
                              </li>
                              <li class="list-item pt-2 text-dark font-20-16">
                                <img
                                  class="arrow-img"
                                   appCdnUrl="assets/Icon/Arrow-blue.svg"
                                />Ensuring payroll processing and managing changes 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <img class="mt-5" appCdnUrl="assets/Icon/white-snake-vector.svg"/>
    </div>
  </section>
  
  <section class="container py-5">
    <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
    <div class="d-none d-xl-flex">
      <div class="row">
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool QuickBooks "  appCdnUrl="assets/Tools/quickbooks.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg" />
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"/>
        </div>
        <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
        </div>
      </div>
    </div>
    <div class="d-flex d-xl-none">
      <div class="trusted-slider-logo">
        <div class="trusted-slide-track">
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool Zoho  " appCdnUrl="assets/Tools/zoho.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool QuickBooks   " appCdnUrl="assets/Tools/quickbooks.svg" />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
            <img class="img-fluid" alt=" Accounting & Bookkeeping tool NetSuite " appCdnUrl="assets/Tools/netsuite.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool odoo " appCdnUrl="assets/Tools/odoo.svg"  />
          </div>
          <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
            <img class="img-fluid" alt="Accounting & Bookkeeping tool xero " appCdnUrl="assets/Tools/xero.svg" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Thinking -->
  <app-service-case-study  [category]="[{ category: 'accountingandbookkeeping' }]"></app-service-case-study>
  <!-- Know best -->
<app-know-best
[cards]="[
  { description: 'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
  name: 'Francesca Furlanetto', designation: 'Director Owner'
 },
  { description: 'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
  name: 'Monica Spivey', designation: 'Directory of Accounting'
 },

]"
  [videos]="[
     { 
     thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
     alt:'Listen to our client '
     },
     {
     
      thumbnail: 'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt:'Our Success Stories '
    },
  ]">
</app-know-best>
  <section class="py-6">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6" data-aos="fade-right">
          <app-faq></app-faq>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0" data-aos="fade-left">
          <div class="accordion" id="accordionFAQ">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button font-28-20"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Industries"
                  aria-expanded="true"
                  aria-controls="Industries"
                >
                What services do you offer in payroll processing?
                </button>
              </h2>
              <div
                id="Industries"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  We cover all types of payroll processing services. Here are the major services we deal in -   
                  <ul class="pt-3">
                    <li>Payroll calculation </li>
                    <li>Payroll record-keeping  </li>
                    <li>Accounting system administration </li>
                    <li>Payroll reporting  </li>
                    <li>Compliance</li>
      
                    <li>Benefit administration  </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Services"
                  aria-expanded="false"
                  aria-controls="Services"
                >
                How do you ensure compliance?  
                </button>
              </h2>
              <div
                id="Services"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  We understand the importance of accurate and compliant payroll processing. Hence, our team strictly adheres to the US Generally Accepted Accounting Principles (US GAAP), which are a set of accounting standards. These principles ensure consistency, transparency, and comparability across different businesses. 
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#About"
                  aria-expanded="false"
                  aria-controls="About"
                >
                How long does your outsourcing company take for payroll processing?    
                </button>
              </h2>
              <div
                id="About"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  The time required for payroll processing completely depends on the client, whether they want payroll weekly, monthly, or bi-weekly.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button font-28-20 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Resources"
                  aria-expanded="false"
                  aria-controls="Resources"
                >
                What technology do you use for payroll processing? 
                </button>
              </h2>
              <div
                id="Resources"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionFAQ"
              >
                <div class="accordion-body">
                  Generally, we use third-party payroll processing management companies' assistance for payroll processing, including ADP payroll and Paychex. But if a client wants a direct electronic cheque from the client's bank, we can do that as well.  
                </div>
              </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button font-28-20 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#differentiators"
                    aria-expanded="false"
                    aria-controls="differentiators"
                  >
                  Can you handle global payroll processing?     
                  </button>
                </h2>
                <div
                  id="differentiators"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionFAQ"
                >
                  <div class="accordion-body">
                    Yes, we can help you with global payroll processing. Feel free to share your requirements with our professionals and get the best payroll processing outsourcing services.
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  