import { Component , OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-inventory-management',
    standalone: true,
    templateUrl: './inventory-management.component.html',
    styleUrl: './inventory-management.component.css',
    imports: [
    FaqComponent,
    ServiceCaseStudyComponent, KnowBestComponent, RouterLink, RouterOutlet, CdnUrlDirective,
    ourAssetsComponent
]
})
export class InventoryManagement implements OnInit{
  constructor(private meta: Meta, private seoService: SeoService,private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    const seoData: SeoData = {
      title: 'Outsource Inventory Management Services | FBSPL',
      description:
        "Outsource inventory management services to FBSPL to manage your inventory efficiently with the latest technologies. Learn about our SKU & supply management, product tracking & more.",
      keywords: 'ecommerce outsourcing services, outsource inventory management',
      ogImage: '/ogImage/Inventory management - banner.jpg'
    };
    this.addJsonLdScript();
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/e-commerce-inventory-management' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "How can outsourcing inventory management help me in reducing my order processing time?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "By implementing advanced automation techniques and capitalizing on intelligent decision-making systems, we neatly eliminate manual interventions and streamline your entire order fulfillment process. Moreover, we employ real-time monitoring and predictive analytics to uphold precise inventory records and promptly replenish stocks as needed." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What approach do your fulfillment services take for multi-unit orders?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Through our sophisticated algorithms in batch processing and inventory management, we efficiently handle multi-unit orders by grouping them into batches and ensuring their punctual deliveries through real-time tracking and predictive analytics. Moreover, our order fulfillment service systems prioritize order consolidation and optimize shipping methods to reduce shipment costs and environmental impact." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How can your inventory management team support my business during peak seasons?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our team analyzes historical data patterns and implements advanced techniques to predict order volumes during peak seasons. Furthermore, we strengthen infrastructure like server capacity and network bandwidth to accommodate these demands. Also, through continuous process improvement initiatives, we refine operational workflows and automate redundant tasks to ensure efficiency and maximize customer satisfaction." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How can you assist me in demand planning?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our team offers data-driven insights and collaborates with you to develop forecasting models, utilizing statistical methods like regression analysis and exponential smoothing. However, our assistance extends beyond this. Our advanced inventory optimization algorithms and supply chain management systems guarantee timely replenishment and minimize stockouts by synchronizing supply with demand." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose you as my right ecommerce outsourcing service provider?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should partner with us if you are looking for: 

<ul><li>360-degree assistance that meets your dynamic business needs round the clock</li> 

<li>Professionally trained employees</li> 

<li>Buck-up services at zero additional cost</li> 

<li>Dedicated customer support, vendor management, and much more</li></ul> 

Partnering with us means aligning with the industry excellence. " 

      } 

    } 

  ] 

}`;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      {  

  

  "@context": "https://schema.org",  

   "@type": "BreadcrumbList",  

   "itemListElement": [  

     {  

       "@type": "ListItem",  

       "position": 1,  

       "name": "Home",  

       "item": "https://www.fbspl.com/"  

     },  

     {  

       "@type": "ListItem",  

       "position": 2,  

       "name": "Services",  

       "item": "https://www.fbspl.com/bpm-and-bpo-services"  

     },  

     {  

  

      "@type": "ListItem",  

       "position": 3,  

       "name": "eCommerce",  

       "item": "https://www.fbspl.com/services/e-commerce-outsourcing-services"  

     },  

     {  

  

      "@type": "ListItem",  

  

      "position": 4,  

       "name": "Inventory Management Services",  

       "item": "https://www.fbspl.com/services/e-commerce-inventory-management"  

     }  

  

  ]  

  

}  `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/e-commerce-inventory-management`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
