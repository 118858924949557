import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = environment.apiUrl; // Ensure your API URL is set correctly in environment files

  constructor(private http: HttpClient) {}

  sendEmail(data: any): Observable<any> {
    // Structured and formatted email content for better readability
    const emailPayload = {
      subject: "Book A Consultation",  // Subject field
      Name: `${data.firstname}`,
      Email: `${data.email}` ,
      Company_Name: `${data.company}`,
      Services: `${data.services}`,
      Brief_Requirement: `${data.write_your_message}`
    };

    return this.http.post(`${this.apiUrl}/api/hubspot/data`, emailPayload); // Replace with your backend API endpoint
  }
}
