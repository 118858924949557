<div class="container d-none d-lg-block">
  <div class="gradientBanner">
    <img appCdnUrl="assets/Terms/main-banner.png" class="desktop-banner" alt="Terms & Conditions " />
    <div class="bannerText" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Terms & Conditions
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary fw-500 pt-3">Terms & Conditions</h1>
    </div>
  </div>
</div>
<div class="container text-dark slide d-block d-lg-none pt-5">
  <div class="gradientBannerMob">
    <img appCdnUrl="assets/Terms/mobile-banner.png" class="card-img-mob" alt="Terms & Conditions " />
    <div class="bannerTextMob" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Terms & Conditions
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary fw-500 pt-3">Terms & Conditions</h1>
    </div>
  </div>
</div>

<section class="container py-6">
  <p class="font-24-16 text-light fw-normal mb-1">
    These terms and conditions will be a binding agreement between us (FBSPL)
    and you (Clients). You can refer to these points in the future to learn
    about the terms, conditions, and other notices. Clients or users availing of
    a specific service on the website are subject to services and materials that
    may contain terms and conditions.
  </p>
  <p class="font-24-16 text-light fw-normal">
    By using or availing services from the website, you agree to and are bound
    by the terms of use. Your agreement with us regarding compliance with the
    terms of use becomes effective upon starting to use this site.Furthermore,
    you accept the following terms and conditions by utilizing our services or
    visiting our website. If you disagree with any of them, you should cease
    accessing the services and site.
  </p>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Business Purpose</h3>
    <p class="font-24-16 text-light fw-normal mb-1">
      We operate to provide skilled professionals and make them available for
      companies like you who wish to outsource tasks to external experts.
    </p>
    <p class="font-24-16 text-light fw-normal">
      The specialists provided to the client by FBSPL are FBSPL employees and
      not the client's employees. Therefore, the client should always utilize
      the services offered by FBSPL and not consider the assigned employee as
      the sole point of contact.
    </p>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Responsibility</h3>
    <p class="font-24-16 text-light fw-normal mb-1">
      It is the client's responsibility to understand the geographical distance
      between themselves and the virtual assistant. The collaborative and
      professional relationship necessitates mutual respect and courtesy.As an
      FBSPL client, you must acknowledge the importance of communication via
      email, chat, phone, or video call. It is the client's responsibility to
      understand that virtual staff may require timely training or clarification
      on projects. By keeping all communication channels open, the client will
      receive proper support and better-quality work.
    </p>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Payment Options</h3>
    <p class="font-24-16 text-light fw-normal">
      All our services are paid, except for the free trial. We accept credit
      cards as a means of money transfer for your selected service. More details
      regarding payment, such as when and how much to pay, can be discussed with
      your designated Account Manager.
    </p>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Confidentiality</h3>
    <p class="font-24-16 text-light fw-normal">
      FBSPL and the client agree that all sensitive information should not be
      disclosed to any third party. The company also assures the client that all
      employees are bound by the agreement not to disclose any confidential
      client information. We ensure that all client data will be handled with
      the utmost care. Additionally, we will request your consent before using
      it and will only use it once you provide consent for the given reason.
    </p>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Termination & Cancellation</h3>
    <p class="font-24-16 text-light fw-normal mb-1">
      We reserve the right to terminate services by any assigned employee to the
      client. You also have the right to discontinue using this website at your
      discretion.
    </p>
    <p class="font-24-16 text-light fw-normal">
      You can cancel your subscription or service tenure by directly emailing us
      at
      <a
        href="mailto:info@fbspl.com"
        class="text-primary text-decoration-underline"
        >info&#64;fbspl.com</a
      >
      The agreement serves as a master agreement between the client and FBSPL.
      The terms established and written here apply to various cases and
      conditions.
    </p>
  </div>
</section>
