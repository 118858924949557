<section class="container py-5">
  <h2 class="text-primary font-48-30" data-aos="fade-up">Our Assets</h2>
  <div class="trusted-slider-logo">
    <div class="trusted-slide-track">
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="100"
          class=""
          appCdnUrl="assets/services/assets-logos/AllCare.svg"
          alt="All Care Consultants, Inc "
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="200"
          class=""
          appCdnUrl="assets/services/assets-logos/alternative.svg"
          alt="Alternative Claim Management "
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="300"
          class=""
          appCdnUrl="assets/services/assets-logos/chapman.svg"
          alt="Chapman Insurance Group"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="400"
          class=""
          appCdnUrl="assets/services/assets-logos/CRS.svg"
          alt="CRS Insurance Brokerage"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="500"
          class=""
          appCdnUrl="assets/services/assets-logos/chrp.svg"
          alt="chrp"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="600"
          class=""
          appCdnUrl="assets/services/assets-logos/wg.svg"
          alt="Western Gold Insurance Agency"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="700"
          class=""
          appCdnUrl="assets/services/assets-logos/AllCare.svg"
          alt="All Care Consultants"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="800"
          class=""
          appCdnUrl="assets/services/assets-logos/alternative.svg"
          alt="Alternative Claim Management "
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="900"
          class=""
          appCdnUrl="assets/services/assets-logos/chapman.svg"
          alt="Chapman Insurance Group"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="1000"
          class=""
          appCdnUrl="assets/services/assets-logos/CRS.svg"
          alt="CRS Insurance Brokerage"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="1100"
          class=""
          appCdnUrl="assets/services/assets-logos/chrp.svg"
          alt="chrp"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="1200"
          class=""
          appCdnUrl="assets/services/assets-logos/wg.svg"
          alt="Western Gold Insurance"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="100"
          class=""
          appCdnUrl="assets/services/assets-logos/AllCare.svg"
          alt="All Care Consultants"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="200"
          class=""
          appCdnUrl="assets/services/assets-logos/alternative.svg"
          alt="Alternative Claim Management "
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="300"
          class=""
          appCdnUrl="assets/services/assets-logos/chapman.svg"
          alt="Chapman Insurance Group"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="400"
          class=""
          appCdnUrl="assets/services/assets-logos/CRS.svg"
          alt="CRS Insurance Brokerage"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="500"
          class=""
          appCdnUrl="assets/services/assets-logos/chrp.svg"
          alt="chrp"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="600"
          class=""
          appCdnUrl="assets/services/assets-logos/wg.svg"
          alt="Western Gold Insurance"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="700"
          class=""
          appCdnUrl="assets/services/assets-logos/AllCare.svg"
          alt="All Care Consultants"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="800"
          class=""
          appCdnUrl="assets/services/assets-logos/alternative.svg"
          alt="Alternative Claim Management "
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="900"
          class=""
          appCdnUrl="assets/services/assets-logos/chapman.svg"
          alt="Chapman Insurance Group"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="1000"
          class=""
          appCdnUrl="assets/services/assets-logos/CRS.svg"
          alt="CRS Insurance Brokerage"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="1100"
          class=""
          appCdnUrl="assets/services/assets-logos/chrp.svg"
          alt="chrp"
          height="150px"
          width="240px"
        />
      </div>
      <div class="trust-slide">
        <img
          data-aos="fade-right"
          data-aos-delay="1200"
          class=""
          appCdnUrl="assets/services/assets-logos/wg.svg"
          alt="Western Gold Insurance"
          height="150px"
          width="240px"
        />
      </div>
    </div>
  </div>
</section>
