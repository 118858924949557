import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private apiUrl = environment.apiUrl; // Ensure your API URL is set correctly in environment files

  constructor(private http: HttpClient) {}

  sendEmail(data: any): Observable<any> {
    // Structured and formatted email content for better readability
    const emailPayload = {
      subject: "Outsourcing Services Contact Us",  // Subject field
      Name: `${data.firstname}`,
      Email: `${data.email}` ,
      Phone_number: `${data.phone}`,
      Write_message_to_us: `${data.write_message_to_us}`,
      Where_did_you_find_us: `${data.where_did_you_find_us}`
    };

    return this.http.post(`${this.apiUrl}/api/hubspot/data`, emailPayload); // Replace with your backend API endpoint
  }
}
