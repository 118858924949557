import { Component, OnInit,Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SeoService } from '../../../services/seo/seo.service';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ourAssetsModule } from '../../../components/HomeComponents/ourAssets/ourAssets.module';
import { ToolsSectionModule } from '../../../components/ServiceComponent/ToolsSection/ToolsSection.module';
import { SeoData } from '../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../components/HomeComponents/faq/faq.component';
import { ServiceVimeoComponent } from '../../../components/HomeComponents/service-vimeo/service-vimeo.component';
import { ServiceCaseStudyComponent } from '../../../components/HomeComponents/service-case-study/service-case-study.component';
import { ourAssetsComponent } from '../../../components/HomeComponents/ourAssets/ourAssets.component';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { KnowBestComponent } from '../../../components/HomeComponents/know-best/know-best.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-digital-marketing',
  standalone: true,
  templateUrl: './digital-marketing.component.html',
  styleUrl: './digital-marketing.component.css',
  imports: [
    RouterLink,
    RouterOutlet,
    SlickCarouselModule,
    ourAssetsModule,
    ToolsSectionModule,
    FaqComponent,
    ServiceVimeoComponent,
    ServiceCaseStudyComponent,
    CdnUrlDirective,
    ourAssetsComponent,
    KnowBestComponent
],
})
export class DigitalMarketingComponent implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService,private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title:
        'Digital Marketing Services for Traffic, Leads, & More',
      description:
        "Build your business a strong online presence with FBSPL digital marketing services to increase traffic, leads and elevate your brand's recognition with us.",
      keywords:
        'digital marketing services, online presence, customer engagement',
      ogImage: '/digital marketing/DM Main Banner 1.jpg',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/digital-marketing' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          { 

      "@type": "Question", 

      "name": "Would my business benefit from digital marketing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Today, having a robust online presence is a must to withstand this highly competitive market. So, when you avail digital marketing services and solutions from a leading firm like FBSPL, you can ensure increased business growth, better performance, high ROI, measurable results, and more." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you ensure that your digital marketing strategies are aligned with our business goals?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "First, our digital marketing experts fully understand your brand identity and goals. Based on that, we prepare tailored strategies based on the current business needs and requirements. Our aim is to help businesses grow and maintain a robust brand presence and we leave no stone unturned to ensure that." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What are the types of outsourcing digital marketing solutions you offer?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We offer four major types of digital marketing outsourcing services, including - 
        <ul><li>Content marketing</li> 
        <li>Search engine optimization</li> 
        <li>Graphic Designing</li> 
        <li>UX/UI designing services</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "I need content marketing services for my business? Can you help me with that?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Yes, we can help you offer the best content marketing services to help your business generate more leads while educating your potential audience and customers correctly. Our team of experienced content writers will help you deliver 100% error-free and non-plagiarized content." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How can FBSPL be a trusted partner for outsourcing digital marketing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our focus is to offer innovative and result-oriented digital marketing solutions. So, when you choose us for digital marketing, here’s what you get. 
        <ul><li>Industrial expertise</li> 
        <li>Data-driven approach</li> 
        <li>Scalable and flexible solutions</li> 
        <li>Latest tools implementation</li> 
        <li>Improved business growth</li> 
        <li>Better ROI</li> 
        <li>Availability as per your time zone</li> 
        <li>Tailor-made solutions</li></ul>" 

      } 

    } 
        ]
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": " Digital Marketing Services ", 

      "item": "https://www.fbspl.com/services/digital-marketing" 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
    const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/digital-marketing`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
  }
}
