import { Component, OnInit,Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SeoService } from '../../../services/seo/seo.service';

import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { SeoData } from '../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../components/HomeComponents/faq/faq.component';
import { ServiceCaseStudyComponent } from '../../../components/HomeComponents/service-case-study/service-case-study.component';
import { KnowBestComponent } from '../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { environment } from '../../../../environments/environment';
import { ourAssetsComponent } from "../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-customer-support',
  standalone: true,
  templateUrl: './customer-support.component.html',
  styleUrl: './customer-support.component.css',
  imports: [
    RouterLink,
    RouterOutlet,
    SlickCarouselModule,
    FaqComponent,
    ServiceCaseStudyComponent,
    KnowBestComponent,
    CdnUrlDirective,
    ourAssetsComponent
],
})
export class CustomerSupportComponent implements OnInit {
  cdnUrl = environment.cdnUrl;
  constructor(private meta: Meta, private seoService: SeoService, private router: Router, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Customer Support Outsourcing & Management',
      description:
        'Upgrade your business with FBSPL customer support outsourcing & management to boost customer retention, loyalty & engagement. Empower your brand today!',
      keywords: 'customer support outsourcing, customer support management',
      ogImage: '/Customer Support/Banner/Banner Customer Support 1.jpg',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/customer-support-outsourcing' });
  }
  slides = [
    {
      banner: true,
      img: `${this.cdnUrl}assets/Customer Support/Banner/Banner Customer Support 1.jpg`,
      img_mob: `${this.cdnUrl}assets/Customer Support/Banner/Mobile-banner1.jpg`,
      Title: 'Customer Support Services-Answering the Why',
      Description:
        "Befriending your customers. Building trust and brand reputation with every interaction. Sign up for our best-in-class processes and technologies that help you eliminate critical points of friction, gain deeper insights into your customer's needs, and predict their future needs.",
      customersupport: 'Sign Up Now',
      Link: '/book-free-consultation-call',
      alt:'Customer Support Services '
    },

    {
      banner: true,
      img: `${this.cdnUrl}assets/Customer Support/Banner/Banner Customer Support 2.jpg`,
      img_mob: `${this.cdnUrl}assets/Customer Support/Banner/Mobile-banner2.jpg`,
      Title: 'How Psychology Affects the Voice Support Customer Experience ',
      Description:
        "Learn how understanding customers'psychology helps you offer them a more personalized experience and build stronger relationships.",
      customersupport: 'Begin Here',
      Link: '/case-studies#customerSupport',
      alt:'Read about our Customized Customer Support Service-Case Study '
    },
    {
      banner: true,
      img: `${this.cdnUrl}assets/Customer Support/Banner/Banner Customer Support 3.jpg`,
      img_mob: `${this.cdnUrl}assets/Customer Support/Banner/Mobile-banner3.jpg`,
      Title:
        'Customer ticketing and Server Management optimization with FBSPL ',
      Description:
        'Improved efficiency, maintained 24/7 availability, optimized ticketing platform, and more. ',
      customersupport: 'Learn Here',
      Link: '/blogs#customerSupport',
      alt:'Latest Industry News & Blogs-Customer Support '
    },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Tablet breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on tablets
          slidesToScroll: 1, // Number of slides to scroll on tablets
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on tablets
          arrows: true, // Enable arrows on tablets
        },
      },
      {
        breakpoint: 1024, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
      {
        breakpoint: 1200, // Desktop breakpoint
        settings: {
          slidesToShow: 1, // Number of slides to show on desktops
          slidesToScroll: 1, // Number of slides to scroll on desktops
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
          dots: false, // Enable dots on desktops
          arrows: false, // Enable arrows on desktops
        },
      },
    ],
  };
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "How does your customer support management team handle situations where customers point out gaps or shortcomings in our offerings?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our professionals handle such situations by:  
<ul><li>Actively listening to and acknowledging the feedback.</li> 

<li>Providing alternative services or features that enhance the customer experience.</li> 

<li>Thoughtfully analyze bulk feedback to identify areas for improvement.</li> 

<li>Collecting real-time insights on products using surveys and feedback tools.</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you ensure consistency in customer experience across various channels such as phone, e-mail, and chat?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "To ensure consistency, we: 

<ul><li>Conduct regular training sessions on comprehensive brand guidelines for customer-facing teams, irrespective of channels.</li> 

<li>Use integrated platforms to ensure customer information and interaction history are accessible across all channels.</li> 

<li>Regularly review customers' feedback from various channels to identify inconsistencies and implement necessary solutions.</li> 

<li>Consistently update our centralized information base with FAQs, troubleshooting guides, and best practices to maintain a unified viewpoint of interactions.</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How does your customer support service team manage a large volume of inquiries?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "To manage a large volume of inquiries, we: 

<ul><li>Invest in advanced and scalable technologies such as customer relationship management (CRM) systems, ticketing systems, automation tools, etc.</li> 

<li>Prioritize inquiries based on their urgency and impact.</li> 

<li>Follow a tiered support structure, where each team is specialized to handle a pre-defined inquiry group.</li> 

<li>Continuously identify trends and patterns in inquiry volume to align our efforts and resources in the right direction.</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you deal with confused, frustrated customers with vague expectations?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We understand that customers may feel frustrated before or after purchasing a product. Therefore, before offering any recommendations, our team puts themselves in their position. The goal is to present solutions and foster a situation that benefits both parties." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose you as my right ecommerce outsourcing service provider?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should partner with us if you are looking for: 

<ul><li>360-degree assistance that meets your dynamic business needs round the clock</li> 

<li>Professionally trained employees</li> 

<li>Buck-up services at zero additional cost</li> 

<li>Dedicated customer support, vendor management, and much more</li></ul> 

Partnering with us means aligning with the industry excellence. " 

      } 

    } 

  ] 

} }`;
      this.renderer.appendChild(this.document.head, script);
    }
    // Check and add BreadcrumbList script
  if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
    const breadcrumbScript = this.renderer.createElement('script');
    breadcrumbScript.type = 'application/ld+json';
    breadcrumbScript.setAttribute('data-script', 'breadcrumb');
    breadcrumbScript.text = `
   { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Customer Support", 

      "item": "https://www.fbspl.com/services/customer-support-outsourcing" 

    } 

  ] 

} `;
    this.renderer.appendChild(this.document.head, breadcrumbScript);
  }
  const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/customer-support-outsourcing`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
