import { Component, OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { ServiceCaseStudyComponent } from '../../../../../components/HomeComponents/service-case-study/service-case-study.component';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from '../../../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-payable-management',
  standalone: true,
  templateUrl: './payable-management.component.html',
  styleUrl: './payable-management.component.css',
  imports: [FaqComponent, KnowBestComponent, ServiceCaseStudyComponent,CdnUrlDirective,ourAssetsComponent],
})
export class PayableManagementComponent implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) {}
  ngOnInit(): void {
   this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Outsourcing Accounts Payable Services  | FBSPL',
      description:
        "Outsource your accounts payable with FBSPL's advanced technologies to achieve accuracy and elevate financial productivity effortlessly.",
      keywords: 'outsource accounts payable, accounts payable management',
      ogImage: '/accoutning Payable Management/Payable Management-banner-1.jpg',
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/accounts-payable-management' });
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "Why do I need account payable management services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Account payable management services help to handle and optimize your invoice payment and vendor relationship management. By outsourcing payable management, you can streamline your financial operations, reduce human errors, improve cash flow, and maintain strong vendor relationships." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What are the major services you offer under accounts payable management?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We offer wide range of services to help businesses manage their payables seamlessly. Our major payable management solutions include - 

<ul> 

<li>Invoice processing</li> 

<li>Vendor management</li> 

<li>Document management</li> 

<li>Financial reporting</li> 

<li>Data management</li> 

<li>Reconciliation</li> 

<li>Cash flow management</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do your account payable management services ensure compliance and workflow approval?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "With our account payable management services, you can get customized approval workflows based on the business hierarchies and rules. It will ensure 100% compliance and proper authorization of account payable management." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is the process to get started with account payable management services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "If you want our payable management outsourcing services, get in touch with us via our website, fill in the form, or contact us directly. We will schedule your consultation with one of our leading professionals, and they will offer you the most suitable payable management solution right away. Feel free to get in touch with us at any hour of the day." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I avail your payable management services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": " Although there are multitude of accounting and bookkeeping outsourcing services providers offering payable management services, but what truly sets us apart are - <ul> 

<li>Availability As Per Client Time Zones</li> 

<li>All-in One Expertise</li> 

<li>Dedicated Staff</li> 

<li>99% Accuracy</li> 

<li>Tailor-Made Solutions</li> 
<li>Quick turnaround time </li></ul>" 

      } 

    } 

  ] 

}  `;
      this.renderer.appendChild(this.document.head, script);
    }
    // Check and add BreadcrumbList script
  if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
    const breadcrumbScript = this.renderer.createElement('script');
    breadcrumbScript.type = 'application/ld+json';
    breadcrumbScript.setAttribute('data-script', 'breadcrumb');
    breadcrumbScript.text = `
    { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Accounting & Bookkeeping", 

      "item": "https://www.fbspl.com/services/accounting-bookkeeping-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "Payable Management Services", 

      "item": "https://www.fbspl.com/services/accounts-payable-management" 

    } 

  ] 

} `;
    this.renderer.appendChild(this.document.head, breadcrumbScript);
  }
  const alternateLinkDefault = this.renderer.createElement('link');
        
  alternateLinkDefault.rel = 'alternate';
  alternateLinkDefault.href = `https://www.fbspl.com`;
  alternateLinkDefault.hreflang = 'x-default';
  this.renderer.appendChild(this.document.head, alternateLinkDefault);

  const alternateLinkEn = this.renderer.createElement('link');
  alternateLinkEn.rel = 'alternate';
  alternateLinkEn.href = `https://www.fbspl.com/services/accounts-payable-management`;
  alternateLinkEn.hreflang = 'en-us';
  this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
}
}
