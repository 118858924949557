import { Component, OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-recievable-management',
  standalone: true,
  templateUrl: './recievable-management.component.html',
  styleUrl: './recievable-management.component.css',
  imports: [
    FaqComponent, KnowBestComponent,
    ServiceCaseStudyComponent, CdnUrlDirective,
    ourAssetsComponent
  ]
})
export class RecievableManagementComponent implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Outsource Accounts Receivable Management Servies | FBSPL',
      description:
        'Outsource your accounts receivables management services at FBSPL expert team to transform your cash flow management, accelerate payments and more. Call today for a free consultation.',
      keywords: 'outsource accounts receivable management, outsource accounts receivable',
      ogImage: '/accoutning Receivable Management/Accounts Receivable.jpg'
    };
    this.seoService.setMetaTags(seoData);
    this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/services/accounts-receivable-management' });
  }

  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": 
        [ 
        { 
        "@type": "Question",
        "name": "Why should I choose you?",
        "acceptedAnswer": { 
        "@type": "Answer", 
        "text": "You should partner with us if you are looking for: 
        <ul> 
        <li>360-degree assistance that meets your dynamic business needs round the clock</li> 
        <li>Professionally trained employees</li> 
        <li>Buck-up services at zero additional cost</li> 
        <li>Dedicated customer support, vendor management, and much more</li> 
        </ul> 
        Partnering with us means aligning with the industry excellence." 
        } 
        },
        { 
        "@type": "Question", 
        "name": "Is there any benefit to outsourcing accounts receivable management?", 
        "acceptedAnswer": { 
        "@type": "Answer", 
        "text": "Outsourcing account receivable management can free up your time and resources, allowing you to focus on other business aspects. You can ensure cost savings, increased efficiency, and get access to the latest tools and technologies to ensure smooth account receivables." 
        } 
        }, 
        { 
        "@type": "Question",
        "name": "How does your outsourcing company handle Accounts Receivable management?", 
        "acceptedAnswer": { 
         "@type": "Answer", 
         "text": "Our outsourcing company follows a comprehensive process for managing accounts receivable. Here is a quick glimpse of the process we follow - 
         <ul> 
         <li>Processing the invoices</li> 
         <li>Tracking customers payments</li> 
         <li>Tracking and resolving customer queries</li> 
         <li>Ensuring debt recovery </li> 
         <li>Conducting final reporting</li> 
         </ul>" 
         } 
          }, 
           { 
          "@type": "Question", 
          "name": "What is your tool's expertise?",
          "acceptedAnswer": { 
           "@type": "Answer", 
            "text": " Our team is well-versed with using and integrating all the latest tools and technologies required to ensure smooth accounting and bookkeeping. Here are some of the major tools we have hands-on experience of.  
            <ul> 
            <li>QuickBooks</li> 
            <li>Xero</li> 
            <li>Zoho</li> 
            <li>Oddo</li> 
            <li>Sage</li> 
            <li>NetSuite</li>  
            </ul>" 
            } 
            }, 
            { 
            "@type": "Question", 
            "name": " How do you expedite the account receivable procedures?", 
            "acceptedAnswer": { 
            "@type": "Answer",
            "text": "For expediting the account receivable, we do cash collections for the amount overdue, due and will be due in the near future." 
            } 
            } 
            ] 
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
     // Check and add BreadcrumbList script
  if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
    const breadcrumbScript = this.renderer.createElement('script');
    breadcrumbScript.type = 'application/ld+json';
    breadcrumbScript.setAttribute('data-script', 'breadcrumb');
    breadcrumbScript.text = `
    { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Accounting & Bookkeeping", 

      "item": "https://www.fbspl.com/services/accounting-bookkeeping-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "Accounts Receivable Services", 

      "item": "https://www.fbspl.com/services/accounts-receivable-management" 

    } 

  ] 

} `;
    this.renderer.appendChild(this.document.head, breadcrumbScript);
  }
  const alternateLinkDefault = this.renderer.createElement('link');
        
    alternateLinkDefault.rel = 'alternate';
    alternateLinkDefault.href = `https://www.fbspl.com`;
    alternateLinkDefault.hreflang = 'x-default';
    this.renderer.appendChild(this.document.head, alternateLinkDefault);
  
    const alternateLinkEn = this.renderer.createElement('link');
    alternateLinkEn.rel = 'alternate';
    alternateLinkEn.href = `https://www.fbspl.com/services/accounts-receivable-management`;
    alternateLinkEn.hreflang = 'en-us';
    this.renderer.appendChild(this.document.head, alternateLinkEn);
  }
  }
}
