<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
       appCdnUrl="assets/Insurance new business/Insurance new business copy.jpg"
      class="card-img-desktop desktop-banner"
      alt="FBSPL Insurance New Business Servicing "
    />
    <div class="card-img-overlay overlay-text bannerText">
        <div class="main_bannar pt-3"  >
          <div data-aos="fade-up">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item" aria-current="page">
                  <a href="/bpm-and-bpo-services">Services</a>
                </li>
                <li class="breadcrumb-item" >
                  <a href="/services/insurance-bpo-outsourcing">Insurance</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  New Business
                </li>
              </ol>
            </nav>
            <h1 class="card-text font-34-19 text-primary pt-3">
              Insurance New Business Servicing
            </h1>
            <p class="card-text font-20-16">
              Get access to the right resources and set up your insurance business with the assistance of experienced professionals. Avail insurance new business services from us and get full suite insurance back-office operations solutions. 
            </p>
            <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
              <h3 class="text-primary font-24-16 clickable-heading">
                Connect with Experts
                <div class="headingCTA">
                  <button class="blueCTA"></button>
                </div>
              </h3>
            </a>
          </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
       appCdnUrl="assets/Insurance new business/Insurance new business mobile copy.jpg"
      class="card-img-mob"
      alt="FBSPL Insurance New Business Servicing "
     
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div  data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item" aria-current="page">
            <a href="./">Services</a>
          </li>
          <li class="breadcrumb-item" >
                <a href="/services/insurance-bpo-outsourcing">Insurance</a>
              </li>
          <li class="breadcrumb-item active" aria-current="page">
            New Business
          </li>
        </ol>
      </nav>
      <h1 class="card-text font-34-19 text-primary">
        Insurance New Business Servicing
      </h1>
      <p class="card-text font-20-16">
        Get access to the right resources and set up your insurance business with the assistance of experienced professionals. Avail insurance new business services from us and get full suite insurance back-office operations solutions. 
      </p>
      <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
        <h3 class="text-primary font-24-16 clickable-heading">
          Connect with Experts
          <div class="headingCTA">
            <button class="blueCTA"></button>
          </div>
        </h3>
      </a>
    </div>
  </div>
</div>
</div>
<app-ourAssets></app-ourAssets>


<section class="container-fluid policy-process-service" id="Insurance New Business Services">
  <div class="container">
    <div class="container_emp ps-lg-3">
      <h2 class="text-white font-48-30 py-3"  data-aos="fade-up">
        Empower Change with Insurance New Business Services
      </h2>
      <img  appCdnUrl="assets/Icon/squarevector.svg" class="top-right-float vector mt-3" />
    </div>
    <div class="d-none d-lg-block">
      <div class="row py-3 px-2">
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Issuance p-3"  data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
            data-bs-target="#exampleModal">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Policy Issuance
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                   
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <!-- Modal -->
          <div
            class="modal Policy-Processing fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Policy Issuance</h3>
                      <p class="text-dark font-20-16">
                        From pre-underwriting support, policy setup, and data
                        verification, get all the business processes done under
                        one roof. Our team streamlines operations, ensuring
                        accurate and efficient policy issuance.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Validating insurance policy paperwork
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Generating new insurance policies
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Creating policy setup documents
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Verifying customer's policy details
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Generating final insurance policies
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/Insurance new business/Policy Issuance_.png"
                      class="bordered"
                      alt="FBSPL Policy Issuance Service-new insurance business servicing "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy-Checking p-3"  data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
            data-bs-target="#exampleModal1">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Quotes Management
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                   
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Quotes Management</h3>
                      <p class="text-dark font-20-16">
                        Get all your policy quotes and documents managed
                        efficiently. Our team carefully verifies all the details
                        with quotes mentioned to ensure data accuracy. Focus on
                        the core while we manage new business policy quotes.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Submitting the request for quotes
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Conducting risk assessment
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Calculating premium for the policyholder
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Delivering the quotes to the policyholder
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Following up with policyholders and addressing
                          queries
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/Insurance new business/Quotes Management.webp"
                      class="bordered"
                      alt="FBSPL Quotes Management- new insurance business servicing "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Loss_Runs_Processing p-3"  data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
            data-bs-target="#exampleModal2">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Policy Checking
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                   
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel2"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Policy Checking</h3>
                      <p class="text-dark font-20-16">
                        Ensure the compliance and accuracy of your insurance
                        policies with our  <a class="anchor"href="/services/insurance-policy-processing">policy-checking services</a>. Improve
                        compliance operational efficiency and enhance customer
                        satisfaction while choosing us.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Submitting the documents for policy checking
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Verifying the document's authenticity
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Accessing the risks associated with the policy
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Reviewing policy, coverage, and terms & conditions
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Ensuring policy approval and rejection
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/Insurance new business/Policy Checking_.png"
                      class="bordered"
                      alt="FBSPL Policy Checking - new insurance business servicing "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Renewals p-3"  data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
            data-bs-target="#exampleModal3">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Support
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                   
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal3"
            tabindex="-1"
            aria-labelledby="exampleModalLabel3"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Support</h3>
                      <p class="text-dark font-20-16">
                        Streamline the insurance process for your new business
                        with our pre-underwriting services. We ensure faster
                        decision-making, reduce operational costs, and improve
                        customer satisfaction.
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Receiving application receipts
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Conducting preliminary risk assessment
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Collecting documents from the applicants
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Performing detailed evaluation of risk profile
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Making decisions based on the evaluation
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/Insurance new business/support.png"
                      class="bordered"
                      alt=" FBSPL Support Service- new insurance business servicing "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Management p-3"  data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"  data-bs-toggle="modal"
            data-bs-target="#exampleModal4">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Insurance Data Entry
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                   
                  ></button>
                </div>
              </h3>
            </a>
          </div>
          <div
            class="modal Policy-Processing fade"
            id="exampleModal4"
            tabindex="-1"
            aria-labelledby="exampleModalLabel4"
            aria-hidden="true"
          >
            <div class="modal-dialog policy-dialog pt-0 mt-0">
              <div class="modal-content">
                <div class="modal-header policy-header">
                  <img
                    data-bs-dismiss="modal"
                    aria-label="Close"
                     appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                  />
                </div>
                <div class="card policy-processing-services-card mx-5">
                  <div class="details ms-4">
                    <div class="content">
                      <h3 class="font-48-30 text-dark">Insurance Data Entry</h3>
                      <p class="text-dark font-20-16">
                        Capture and digitize your insurance new business
                        data entry accurately with our insurance new
                        business servicing. Our solutions aid in accurate
                        policy creation, timely claim processing, and <a class="anchor" target="_blank" href="/services/customer-support-outsourcing">better
                          customer service.</a>
                      </p>
                      <ul class="ul-policy">
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Receiving an application for insurance
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Verifying the data authenticity
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Entering the data into the systems
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Adding data for validation
                        </li>
                        <li class="list-item pt-2 text-dark font-20-16">
                          <img
                            class="arrow-img"
                             appCdnUrl="assets/Icon/Arrow-blue.svg"
                          />Ensuring data storage and retrieval
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="imgBx d-none d-lg-block">
                    <img
                       appCdnUrl="assets/Insurance new business/Insurance Data Entry_.png"
                      class="bordered"
                      alt="FBSPL Data Entry Service - new insurance business servicing "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 col-md-6 py-3">
          <div
            class="card insurance-card policy-process-card Policy_Endorsements p-3"  data-aos="zoom-in"
          >
            <a class="CTA-Zindex anchorCTA mb-3 p-2"   data-bs-toggle="modal"
            data-bs-target="#exampleModal5">
              <h3 class="text-black font-34-19 my-auto clickable-heading">
                Insurance Administration
                <div class="headingCTA">
                  <button
                    class="blueCTA btn_hover"
                  
                  ></button>
                </div>
              </h3>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal Policy-Processing fade"
      id="exampleModal5"
      tabindex="-1"
      aria-labelledby="exampleModalLabel5"
      aria-hidden="true"
    >
      <div class="modal-dialog policy-dialog pt-0 mt-0">
        <div class="modal-content">
          <div class="modal-header policy-header">
            <img
              data-bs-dismiss="modal"
              aria-label="Close"
               appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
            />
          </div>
          <div class="card policy-processing-services-card mx-5">
            <div class="details ms-4">
              <div class="content">
                <h3 class="font-48-30 text-dark">Insurance Administration</h3>
                <p class="text-dark font-20-16">
                  Need experts’ assistance for regulatory compliance, optimizing
                  operations, and processing claims? Effective insurance
                  administrative service is the key. <a class="anchor" target="_blank" href="/book-free-consultation-call">Connect with us to get your
                    new business administration </a>on track.
                </p>
                <ul class="ul-policy">
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Issuing the policy documents
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Collecting the premiums from policyholders
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Filing and verifying the claims
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Sending policy renewal reminders
                  </li>
                  <li class="list-item pt-2 text-dark font-20-16">
                    <img
                      class="arrow-img"
                       appCdnUrl="assets/Icon/Arrow-blue.svg"
                    />Providing ongoing support to policyholders
                  </li>
                </ul>
              </div>
            </div>
            <div class="imgBx d-none d-lg-block">
              <img
                 appCdnUrl="assets/Insurance new business/Insurance Administration_.png"
                class="bordered"
                alt="FBSPL Insurance Administration - new insurance business servicing "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class=" p-3">
    <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-12 mt-0 mt-sm-4">
          <div class="accordion" id="ServicePage">
            <div class="policy-processing"  data-aos="zoom-in" data-bs-toggle="modal"
            data-bs-target="#MobModal">
              <div
                data-bs-toggle="collapse"
                data-bs-target="#Policy_Processing"
                aria-expanded="true"
                aria-controls="Policy_Processing"
                id="headingOne"
                class="headingCTA CTA-Zindex d-flex  p-3"
              >
                <h3 class="CTAs text-black fs-24  me-3">Policy Issuance</h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal"
                   appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
            </div>
            <div
              class="modal Policy-Processing fade"
              id="MobModal"
              tabindex="-1"
              aria-labelledby="MobModalModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog policy-dialog">
                <div class="modal-content">
                  <div class="modal-header policy-header mt-3">
                    <img
                      data-bs-dismiss="modal"
                      aria-label="Close"
                       appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                    />
                  </div>
                  <div class="card policy-processing-services-card-mob mx-3 container py-3">
                    <div class="details ms-2">
                      <div class="content">
                        <h3 class="font-48-30 text-dark">Policy Issuance</h3>
                        <p class="text-dark font-20-16">
                          From pre-underwriting support, policy setup, and data
                          verification, get all the business processes done
                          under one roof. Our team streamlines operations,
                          ensuring accurate and efficient policy issuance.
                        </p>
                        <ul class="ul-policy">
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Validating insurance policy paperwork
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Generating new insurance policies
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Creating policy setup documents
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Verifying customer's policy details
                          </li>
                          <li class="list-item pt-2 text-dark font-20-16">
                            <img
                              class="arrow-img"
                               appCdnUrl="assets/Icon/Arrow-blue.svg"
                            />Generating final insurance policies
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div
                data-bs-toggle="collapse"
                data-bs-target="#Claims_Management"
                aria-expanded="true"
                aria-controls="Claims_Management"
                id="headingOne"
                class="headingCTA CTA-Zindex d-flex  p-3" 
                data-bs-toggle="modal"
                data-bs-target="#MobModal1" 
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Quotes Management
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal1"
                   appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal1"
                tabindex="-1"
                aria-labelledby="MobModal1Lable1"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Quotes Management
                          </h3>
                          <p class="text-dark font-20-16">
                            Get all your policy quotes and documents managed
                            efficiently. Our team carefully verifies all the
                            details with quotes mentioned to ensure data
                            accuracy. Focus on the core while we manage new
                            business policy quotes.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Submitting the request for quotes
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Conducting risk assessment
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Calculating premium for the policyholder
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Delivering the quotes to the policyholder
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Following up with policyholders and addressing
                              queries
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div id="headingOne" class="d-flex p-3" 
              data-bs-toggle="modal"  
              data-bs-target="#MobModal5"
              >
                <h3 class="CTAs text-black fs-24  me-3">Policy Checking</h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal5"
                   appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal5"
                tabindex="-1"
                aria-labelledby="MobModalLabel5"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Policy Checking</h3>
                          <p class="text-dark font-20-16">
                            From pre-underwriting support, policy setup, and
                            data verification, get all the business processes
                            done under one roof. Our team streamlines
                            operations, ensuring accurate and efficient policy
                            issuance.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Submitting the documents for policy checking
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Verifying the document's authenticity
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Accessing the risks associated with the policy
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Reviewing policy, coverage, and terms &
                              conditions
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Ensuring policy approval and rejection
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div class="d-flex p-3" 
              data-bs-toggle="modal"
                  data-bs-target="#MobModal2"
              >
                <h3 class="CTAs text-black fs-24  me-3">Support</h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal2"
                   appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal2"
                tabindex="-1"
                aria-labelledby="MobModalLabel2"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">Support</h3>
                          <p class="text-dark font-20-16">
                            Streamline the insurance process for your new
                            business with our pre-underwriting services. We
                            ensure faster decision-making, reduce operational
                            costs, and improve customer satisfaction.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Receiving application receipts
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Conducting preliminary risk assessment
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Collecting documents from the applicants
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Performing detailed evaluation of risk profile
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Making decisions based on the evaluation
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="imgBx d-none d-lg-block">
                        <img
                           appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div class="d-flex p-3"  
              data-bs-toggle="modal"
              data-bs-target="#MobModal3"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Insurance Data Entry
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal3"
                   appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal3"
                tabindex="-1"
                aria-labelledby="MobModalLabel3"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Insurance Data Entry
                          </h3>
                          <p class="text-dark font-20-16">
                            Capture and digitize your insurance new business
                            data entry accurately with our insurance new
                            business servicing. Our solutions aid in accurate
                            policy creation, timely claim processing, and <a class="anchor" target="_blank" href="/services/customer-support-outsourcing">better
                              customer service.</a>
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Receiving an application for insurance
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Verifying the data authenticity
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Entering the data into the systems
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Adding data for validation
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Ensuring data storage and retrieval
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="imgBx d-none d-lg-block">
                        <img
                           appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="policy-processing mt-4" data-aos="zoom-in">
              <div class="d-flex p-3" 
              data-bs-toggle="modal"
              data-bs-target="#MobModal4"
              >
                <h3 class="CTAs text-black fs-24  me-3">
                  Insurance Administration
                </h3>
                <img
                  class="service_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#MobModal4"
                   appCdnUrl="assets/Icon/Accord-close.svg"
                />
              </div>
              </div>
              <div
                class="modal Policy-Processing fade"
                id="MobModal4"
                tabindex="-1"
                aria-labelledby="MobModalLabel4"
                aria-hidden="true"
              >
                <div class="modal-dialog policy-dialog">
                  <div class="modal-content">
                    <div class="modal-header policy-header mt-3">
                      <img
                        data-bs-dismiss="modal"
                        aria-label="Close"
                         appCdnUrl="assets/Icon/Accord-open.svg"  class="close_btn"
                      />
                    </div>
                    <div class="card policy-processing-services-card-mob mx-3 container py-3">
                      <div class="details ms-2">
                        <div class="content">
                          <h3 class="font-48-30 text-dark">
                            Insurance Administration
                          </h3>
                          <p class="text-dark font-20-16">
                            Need experts’ assistance for regulatory compliance,
                            optimizing operations, and processing claims?
                            Effective insurance administrative service is the
                            key. Connect with us to get your new business
                            administration on track.
                          </p>
                          <ul class="ul-policy">
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Issuing the policy documents
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Collecting the premiums from policyholders
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Filing and verifying the claims
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Sending policy renewal reminders
                            </li>
                            <li class="list-item pt-2 text-dark font-20-16">
                              <img
                                class="arrow-img"
                                 appCdnUrl="assets/Icon/Arrow-blue.svg"
                              />Providing ongoing support to policyholders
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="imgBx d-none d-lg-block">
                        <img
                           appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <img class="mt-5" appCdnUrl="assets/Icon/white-snake-vector.svg" />
  </div>
</section>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">Tools That Drive Excellence</h2>
  <div class="d-none d-xl-flex">
    <div class="row g-5">
      <div class="col-2"  data-aos="fade-right" data-aos-delay="0">
        <img class="img-fluid" alt="Insurance tool Applied System  "  appCdnUrl="assets/Tools/applied.svg" />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="100">
        <img class="img-fluid" alt="Insurance tool Hawksoft   "  appCdnUrl="assets/Tools/hawksoft.svg" />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="200">
        <img
          class="img-fluid" alt="Insurance tool PL Rating "
           appCdnUrl="assets/Tools/pl rating.svg"
         
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="300">
        <img class="img-fluid" alt="Insurance tool QQCatalyst "  appCdnUrl="assets/Tools/catalyst.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="Insurance tool AMS 360 "  appCdnUrl="assets/Tools/ams360.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="500">
        <img class="img-fluid" alt="Insurance tool EZLynx " appCdnUrl="assets/Tools/ezlynx.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="600">
        <img class="img-fluid" alt="Insurance tool CSR24"  appCdnUrl="assets/Tools/csr27.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="700">
        <img class="img-fluid" alt="Insurance tool NowCerts"  appCdnUrl="assets/Tools/nowcerts.svg"  />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="800">
        <img alt="Insurance tool Power Broker "
          class="img-fluid"
           appCdnUrl="assets/Tools/powerbroker.svg"
         
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="800">
        <img alt="Insurance tool AgencyMatrix "
          class="img-fluid"
           appCdnUrl="assets/Tools/agencymatrix.svg"
          
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="900">
        <img alt="Insurance tool employeeNavigator "
          class="img-fluid"
           appCdnUrl="assets/Tools/employeeNavigator.svg"
       
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="1000">
        <img class="img-fluid" alt=" Insurance tool indio " appCdnUrl="assets/Tools/indio.svg"  />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool Applied System  "  appCdnUrl="assets/Tools/applied.svg" data-aos="fade-right" data-aos-delay="0" />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="100"
            class="img-fluid" alt="Insurance tool Hawksoft  "
             appCdnUrl="assets/Tools/hawksoft.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="200"
            class="img-fluid" alt="Insurance tool PL Rating "
             appCdnUrl="assets/Tools/pl rating.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool QQCatalyst " data-aos="fade-right" data-aos-delay="300"  appCdnUrl="assets/Tools/catalyst.svg" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool AMS 360 "  appCdnUrl="assets/Tools/ams360.svg" data-aos="fade-right" data-aos-delay="400" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool EZLynx " appCdnUrl="assets/Tools/ezlynx.svg" data-aos="fade-right" data-aos-delay="500" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool CSR24  " appCdnUrl="assets/Tools/csr27.svg" data-aos="fade-right" data-aos-delay="600" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool NowCerts " appCdnUrl="assets/Tools/nowcerts.svg" data-aos="fade-right" data-aos-delay="700" />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid" alt=" Insurance tool Power Broker" data-aos="fade-right" data-aos-delay="800"
             appCdnUrl="assets/Tools/powerbroker.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="900"
            class="img-fluid" alt="Insurance tool AgencyMatrix "
             appCdnUrl="assets/Tools/agencymatrix.svg"
            
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="1000"
            class="img-fluid" aria-multiline=" Insurance tool employeeNavigator "
             appCdnUrl="assets/Tools/employeeNavigator.svg"
            alt="Employee Navigator"
          />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" aria-multiline=" Insurance tool indio " data-aos="fade-right" data-aos-delay="1100"  appCdnUrl="assets/Tools/indio.svg" alt="Indio" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool Applied System"  appCdnUrl="assets/Tools/applied.svg" data-aos="fade-right" data-aos-delay="0" />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="100"
            class="img-fluid" alt="Insurance tool Hawksoft   "
             appCdnUrl="assets/Tools/hawksoft.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="200"
            class="img-fluid" alt="Insurance tool PL Rating "
             appCdnUrl="assets/Tools/pl rating.svg"
           
          />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool QQCatalyst " data-aos="fade-right" data-aos-delay="300"  appCdnUrl="assets/Tools/catalyst.svg" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool AMS 360 "  appCdnUrl="assets/Tools/ams360.svg" data-aos="fade-right" data-aos-delay="400" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool EZLynx " appCdnUrl="assets/Tools/ezlynx.svg" data-aos="fade-right" data-aos-delay="500" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt="Insurance tool CSR24  " appCdnUrl="assets/Tools/csr27.svg" data-aos="fade-right" data-aos-delay="600" />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool NowCerts  "  appCdnUrl="assets/Tools/nowcerts.svg" data-aos="fade-right" data-aos-delay="700" />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid" data-aos="fade-right" data-aos-delay="800"
             appCdnUrl="assets/Tools/powerbroker.svg" alt="Insurance tool Power Broker "
           
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="900"
            class="img-fluid" alt="Insurance tool AgencyMatrix "
             appCdnUrl="assets/Tools/agencymatrix.svg"
            
          />
        </div>
        <div class="trust-slide">
          <img data-aos="fade-right" data-aos-delay="1000"
            class="img-fluid" alt="Insurance tool employeeNavigator "
             appCdnUrl="assets/Tools/employeeNavigator.svg"
            alt="Employee Navigator"
          />
        </div>
        <div class="trust-slide">
          <img class="img-fluid" alt=" Insurance tool indio " data-aos="fade-right" data-aos-delay="1100"  appCdnUrl="assets/Tools/indio.svg" alt="Indio" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Thinking -->
<app-service-case-study  [category]="[{ category: 'insurance' }]"></app-service-case-study>
<app-know-best
[cards]="[
  {
    description: 'I must say that our partnership with Fusion Business Solutions (FBSPL) has been truly exceptional. What sets FBSPL apart is their remarkable ability to not only grasp our ever-evolving business needs but also their genuine curiosity to understand our culture. Their flexibility and agility have been invaluable. FBSPL’s proactive approach, coupled with their rigorous processes and scalability, has significantly enhanced our customer experiences and AI operations.',
    name: 'Chin Ma',
    designation: 'Founder & President'
  },
  {
    description: 'We reached out to Fusion in the Fall of 2022 as we were having issues recruiting a skilled and motivated workforce locally to fill back-office roles in our organization. What started as a simple documentation validation exercise quickly grew into the team supporting more strategic review and reconciliation activities. The team has shown repeatedly that they are willing to take on more and more complicated tasks, and the leadership team is quick to rally and support their employees when things fall off track.',
    name: 'James Oickle',
    designation: 'President & CEO'
  },
  {
    description: 'Fusion Business Solutions has been a game-changer for our company. Their responsive team, seamless onboarding, and commitment to excellence have significantly reduced our stress levels, allowing us to focus on what matters most – our clients. We\'ve experienced measurable improvements in client retention and employee satisfaction since partnering with Fusion, making them the top choice for any business seeking efficient virtual assistant services.',
    name: 'Isom Rigell M. Ed.',
    designation: 'Chief People Officer'
  }
]"

  [videos]="[
      {
      videoUrl:
        'https://player.vimeo.com/video/969702390',
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 01 Chin Ma.jpg',
        alt:'Listen to our client- new insurance business servicing'
    },
    {
      
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10  James Oickle.jpg',
        alt:'Our Success Stories- new insurance business servicing '
    },
    {
      videoUrl:
        'https://player.vimeo.com/video/962238538',
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 07 Stacey DiSpigno copy.jpg',
        alt:'FBSPL Client Testimonial- new insurance business servicing '
    },
   
  ]"
 >
</app-know-best>
<section class="py-6">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6"  data-aos="fade-right">
        <app-faq></app-faq>
      </div>
      <div class="col-12 col-md-6 mt-3 mt-md-0"  data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button font-28-20"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Industries"
                aria-expanded="true"
                aria-controls="Industries"
              >
                What is the insurance new business setup process you follow?
              </button>
            </h2>
            <div
              id="Industries"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                As a leading <a class="anchor" target="_blank" href="/services/insurance-bpo-outsourcing">insurance outsourcing services provider</a>, we provide
                the best solutions to new businesses with a quick turnaround
                time. Here is a streamlined process that we follow.
                <li>Identifying the objects to be insured</li>
                <li>Preparing the new insurance policies</li>
                <li>Reviewing the policies for accuracy</li>
                <li>Creating policies with the terms and conditions</li>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Services"
                aria-expanded="false"
                aria-controls="Services"
              >
                Why choose us for insurance new business servicing?
              </button>
            </h2>
            <div
              id="Services"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                If you are wondering why our new business service solutions are
                the best, here is what makes us stand out from the rest.
                <ul>
                  <li>All-in-one expertise</li>
                  <li>Availability as per your time zone</li>
                  <li>Quick turnaround time</li>
                  <li>Tailor-made solutions</li>
                  <li>99% accuracy</li>
                  <li>Scalable solutions</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#About"
                aria-expanded="false"
                aria-controls="About"
              >
                How will you ensure the accuracy of the Proposal or Quote?
              </button>
            </h2>
            <div
              id="About"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Our insurance veterans cross-check the quote against the quote
                request to ensure the coverage, demographic, and premium
                information, and then prepare a proposal to ensure accuracy.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Resources"
                aria-expanded="false"
                aria-controls="Resources"
              >
                How will you collect the information for quoting?
              </button>
            </h2>
            <div
              id="Resources"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Our team exchanges the intake or questionnaire form with the
                prospect or account manager to collect the information to
                initiate the quoting.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Preferred"
                aria-expanded="false"
                aria-controls="Resources"
              >
                What is the preferred method to get the document signed for
                binding?
              </button>
            </h2>
            <div
              id="Preferred"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Upon the Account Manager's approval, we create a packet of
                proposal + Acord forms/quote application or supplemental apps +
                TRIA or surplus lines forms (depending on the product and
                carrier) and send the documents to the customer for signature
                through DocuSign.
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
